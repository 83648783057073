import React from "react";

import "./nf.css";

const NotFound = () => {
  return (
    <React.Fragment>
      <div className="body2">
        <div title="404">404</div>
      </div>
    </React.Fragment>
  );
};

export default NotFound;
