import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";
import { Container, Tabs, Tab, Box, AppBar } from "@mui/material";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Chart_year from "./Chart_year";
import Chart_month from "./Chart_month";
import Chart_day from "./Chart_day";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index &&
        <Box sx={{ p: 2 }}>
          {children}
        </Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const Old_data = () => {
  var url = new URL(window.location.href);
  let xPage = url.searchParams.get("page"),
    xDevice1 = url.searchParams.get("d1"),
    xDevice2 = url.searchParams.get("d2");

  const [Head_name, setHead_name] = useState("");

  useEffect(() => {
    if (xPage == "RIHES1_IAQ") {
      setHead_name("อาคาร 1");
    }
    if (xPage == "RIHES2_IAQ") {
      setHead_name("อาคารสำนักวิชาการวิจัยวิทยาศาสตร์สุขภาพ");
    }
    if (xPage == "IAQ_test") {
      setHead_name("ห้องผู้อำนวยการ");
    }
    if (xPage == "Pongnoi") {
      setHead_name("ห้องเรียนโรงเรียนบ้านโป่งน้อย");
    }
    if (xPage == "Pasuk41") {
      setHead_name("ห้อง 4/1 โรงเรียนผาสุกมณีจักรมิตรภาพที่ 116");
    }
    if (xPage == "Pasuk42") {
      setHead_name("ห้อง 4/2 โรงเรียนผาสุกมณีจักรมิตรภาพที่ 116");
    }
    if (xPage == "Pasuk43") {
      setHead_name("ห้อง 4/3 โรงเรียนผาสุกมณีจักรมิตรภาพที่ 116");
    }
    if (xPage == "Watkoo4") {
      setHead_name("ห้องป.4 โรงเรียนวัดกู้ (นันทาภิวัฒน์วิทยา)");
    }
    if (xPage == "Watkoo5") {
      setHead_name("ห้องป.5 โรงเรียนวัดกู้ (นันทาภิวัฒน์วิทยา)");
    }
  }, []);

  const [value, setValue] = useState(0);

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Navbar />

      <Container>
        <h3
          style={{
            textAlign: "center",
            marginTop: "100px",
            marginBottom: "50px"
          }}
        >
          ค่า PM2.5 เฉลี่ย 5 เดือน (มกราคม-พฤษภาคม) นอกและใน {Head_name}{" "}
          (มคก./ลบม.)
        </h3>

        <Chart_year device1={xDevice1} device2={xDevice2} />

        <hr style={{ marginTop: "50px" }} />

        <Box sx={{ width: "100%", mt: 5 }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <AppBar position="static" style={{ background: "#F0F5FF" }}>
              <Tabs value={value} onChange={TabChange} variant="fullWidth">
                <Tab
                  icon={""}
                  label={
                    <span style={{ fontFamily: "kanit" }}>ข้อมูลรายเดือน</span>
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  icon={""}
                  label={
                    <span style={{ fontFamily: "kanit" }}>ข้อมูลรายวัน</span>
                  }
                  {...a11yProps(1)}
                />
              </Tabs>
            </AppBar>
          </Box>

          <TabPanel value={value} index={0}>
            <Chart_month
              device1={xDevice1}
              device2={xDevice2}
              head={Head_name}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Chart_day device1={xDevice1} device2={xDevice2} head={Head_name} />
          </TabPanel>
        </Box>

        <div style={{ fontSize: "14px", marginTop: "30px" }}>
          <p>หมายเหตุ เป้าหมายคือ</p>
          <p style={{ marginTop: "-10px" }}>
            1. ค่า PM2.5 เฉลี่ยภายในอาคารในช่วงเวลา 08.00-18.00 น.
            ต้องต่ำกว่าภายนอกเกิน 50%
          </p>
          <p style={{ marginTop: "-10px" }}>
            2. ค่า PM2.5 เฉลี่ยภายในอาคารในช่วงเวลา 08.00-18.00 น. ต้องต่ำกว่า
            35 มคก./ลบม.
          </p>
        </div>
      </Container>

      <Footer />
    </React.Fragment>
  );
};

export default Old_data;
