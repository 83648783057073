import React, { useState } from "react";

import PropTypes from "prop-types";
import { Container, Tabs, Tab, Box } from "@mui/material";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import PublicIcon from "@mui/icons-material/Public";
import ListIcon from "@mui/icons-material/List";

import AQHI1min from "./AQHI1min";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index &&
        <Box sx={{ p: 2 }}>
          {children}
        </Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const Maindata = () => {
  const [value, setValue] = useState(0);

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Container>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              borderTop: 2,
              borderColor: "divider",
              backgroundColor: "white",
              zIndex: 100
            }}
          >
            <Tabs value={value} onChange={TabChange} variant="fullWidth">
              <Tab
                icon={<LocationOnIcon />}
                label={
                  <span style={{ fontFamily: "kanit", fontSize: 10 }}>
                    ตำแหน่งปัจจุบัน
                  </span>
                }
                {...a11yProps(0)}
              />
              <Tab
                icon={<PublicIcon />}
                label={
                  <span style={{ fontFamily: "kanit", fontSize: 10 }}>
                    แผนที่
                  </span>
                }
                {...a11yProps(1)}
              />
              <Tab
                icon={<ListIcon />}
                label={
                  <span style={{ fontFamily: "kanit", fontSize: 10 }}>
                    อันดับ
                  </span>
                }
                {...a11yProps(2)}
              />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <div style={{ marginTop: "-25px" }}>
              <AQHI1min />
            </div>
          </TabPanel>

          <TabPanel value={value} index={1}>
            <div style={{ marginTop: "-25px" }}>....2</div>
          </TabPanel>

          <TabPanel value={value} index={2}>
            <div style={{ marginTop: "-25px" }}>....3</div>
          </TabPanel>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default Maindata;
