import React, { useState, useEffect } from "react";
import axios from "axios";

import {
  Container,
  IconButton,
  Modal,
  Card,
  CardContent,
  Box,
  TextField,
  Stack,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  Switch
} from "@mui/material";

import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";

import Navbar_Admin from "../../../components/Navbar_Admin";
import Footer from "../../../components/Footer";

import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import { Icon } from "@iconify/react";

import Swal from "sweetalert2";

import * as xlsx from "xlsx";

const Edit_device = () => {
  let xURL = "";
  xURL = "https://www2.ntaqhi.info/api/";

  const [dgdataSource, setdgdataSource] = useState([]);
  const [dgdataSource2, setdgdataSource2] = useState([]);
  const [dataDevice, setdataDevice] = useState([""]);
  const [Statusvalue, setStatusValue] = useState("");

  const [exdataSource, setexdataSource] = useState([]);

  const dataStatus = [
    { id: 1, name: "On site" },
    { id: 2, name: "รอติดตั้ง" },
    { id: 3, name: "ส่งซ่อม" },
    { id: 4, name: "รอส่งคืน" },
    { id: 0, name: "ย้าย/ยกเลิก" },
    { id: 11, name: "Indoor" },
    { id: 8, name: "ปิดชั่วคราว" }
  ];

  const dataStatus2 = [
    { id: 99, name: "ทั้งหมด" },
    { id: 1, name: "On site" },
    { id: 2, name: "รอติดตั้ง" },
    { id: 3, name: "ส่งซ่อม" },
    { id: 4, name: "รอส่งคืน" },
    { id: 0, name: "ย้าย/ยกเลิก" },
    { id: 11, name: "Indoor" },
    { id: 8, name: "ปิดชั่วคราว" }
  ];

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = zdata => {
    // console.log(zdata);

    setdataDevice(zdata);
    setStatusValue(zdata.status);

    setOpen(true);
  };

  const [zHeight, setzHeight] = useState(603);

  useEffect(() => {
    const token = localStorage.getItem("token");

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          getDevice("");
          getDeviceNanogen("");
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/admin";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const getDevice = device_name => {
    let xdevice_name = "";

    if (!device_name) {
      xdevice_name = "";
    } else {
      xdevice_name = device_name;
    }

    axios
      .post(xURL + "getdeviceAdmin", { name: xdevice_name })
      .then(result => {
        const Tb1 = [],
          Tb2 = [];
        let xstatus = "",
          xarea_point = "",
          xdistricts = "",
          xamphures = "",
          xprovinces = "";

        result.data.forEach((i, idx) => {
          if (i.status == 1) {
            xstatus = "On site";
          } else if (i.status == 2) {
            xstatus = "รอติดตั้ง";
          } else if (i.status == 3) {
            xstatus = "ส่งซ่อม";
          } else if (i.status == 4) {
            xstatus = "รอส่งคืน";
          } else if (i.status == 8) {
            xstatus = "ปิดชั่วคราว";
          } else if (i.status == 11) {
            xstatus = "Indoor";
          } else if (i.status == 0) {
            xstatus = "ย้าย/ยกเลิก";
          } else {
            xstatus = "";
          }

          xarea_point = "/?area-point=" + i.id+'&pages=admin';

          if (i.districts) {
            xdistricts = "ต." + i.districts;
          } else {
            xdistricts = "";
          }

          if (i.amphures) {
            xamphures = "อ." + i.amphures;
          } else {
            xamphures = "";
          }

          if (i.provinces) {
            xprovinces = "จ." + i.provinces;
          } else {
            xprovinces = "";
          }

          Tb1.push({
            Number: parseInt(idx + 1),
            device_id: "ID " + i.cmaqhi_device_id,
            device_name: (
              <a
                style={{ textDecoration: "none" }}
                href={xarea_point}
                target="_blank"
              >
                {i.name} {xdistricts} {xamphures} {xprovinces}
              </a>
            ),
            status: xstatus,
            edit_device: (
              <IconButton size="small" color="primary" aria-label="edit device">
                <EditIcon fontSize="inherit" onClick={() => handleOpen(i)} />
              </IconButton>
            )
          });

          Tb2.push({
            Number: parseInt(idx + 1),
            device_id: "ID " + i.cmaqhi_device_id,
            device_name: i.name,
            tambol: i.districts,
            amphures: i.amphures,
            provinces: i.provinces,
            lat: i.poi_lat,
            long: i.poi_lng
          });
        });

        setdgdataSource(Tb1);
        setexdataSource(Tb2);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const getDeviceNanogen = device_name => {
    let xdevice_name = "";

    if (!device_name) {
      xdevice_name = "";
    } else {
      xdevice_name = device_name;
    }

    axios
      .post(xURL + "getdeviceNanogen", { name: xdevice_name })
      .then(result => {
        const Tb1 = [],
          xstatus = true;

        let xarea_point = "",
          xdistricts = "",
          xamphures = "",
          xprovinces = "";

        let xheight = 603;

        result.data.forEach((i, idx) => {
          if (idx > 13) {
            xheight = xheight + 40;
          }

          xarea_point = "/?area-point=" + i.id+'&pages=admin';

          if (i.districts) {
            xdistricts = "ต." + i.districts;
          } else {
            xdistricts = "";
          }

          if (i.amphures) {
            xamphures = "อ." + i.amphures;
          } else {
            xamphures = "";
          }

          if (i.provinces) {
            xprovinces = "จ." + i.provinces;
          } else {
            xprovinces = "";
          }

          Tb1.push({
            Number: parseInt(idx + 1),
            device_id: "ID " + i.device_id,
            device_name: (
              <a
                style={{ textDecoration: "none" }}
                href={xarea_point}
                target="_blank"
              >
                {i.name} {xdistricts} {xamphures} {xprovinces}
              </a>
            ),
            status: (
              <Switch
                size="small"
                defaultChecked={i.chkstatus}
                onChange={(e, c) => {
                  // console.log('event:', c,i)
                  // console.log(c, i);

                  Swal.fire({
                    title: "ต้องการเปลี่ยนสถานะ?",
                    text: "จุดตรวจ Nanogen : " + i.name,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "ยืนยัน",
                    cancelButtonText: "ยกเลิก"
                  }).then(result => {
                    if (result.isConfirmed) {
                      Swal.fire({
                        title: "เปลี่ยนสถานะเรียบร้อยแล้ว!",
                        text: "",
                        icon: "success"
                      }).then(result => {
                        // console.log(event.target.checked);
                        changestatus(c, i);

                        window.location.reload();
                      });
                    } else {
                      window.location.reload();
                    }
                  });
                }}
              />
            )
          });

          setdgdataSource2(Tb1);
          setzHeight(xheight);
        });
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const changestatus = (status, data) => {
    let xstatus = 0;
    if (status == true) {
      xstatus = 1;
    } else {
      xstatus = 0;
    }

    // console.log(xstatus,data);
    axios
      .post(xURL + "updateStatus", {
        status: xstatus,
        devices_id: data.device_id
      })
      .then(result => {})
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const handleEdit = event => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    // console.log(data.get("devices_name"), Statusvalue);
    if (!data.get("devices_name")) {
      Swal.fire({
        title: "แก้ไขไม่ได้!",
        text: "ยังไม่ได้คีย์ สถานีตรวจวัด",
        icon: "warning",
        showConfirmButton: false,
        timer: 2000,
        customClass: {
          container: "my-swal"
        }
      });
    } else {
      editDevice(event);
    }
  };

  const editDevice = event => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    // console.log(
    //   data.get("devices_name"),
    //   data.get("devices_id"),
    //   data.get("cmaqhi_id"),
    //   data.get("latitude"),
    //   data.get("longitude"),
    //   data.get("districts"),
    //   data.get("amphures"),
    //   data.get("provinces"),
    //   data.get("token"),
    //   data.get("note"),
    //   data.get("owner"),
    //   Statusvalue
    // );

    axios
      .post(xURL + "updateDevice", {
        devices_id: data.get("devices_id"),
        device_name: data.get("devices_name"),
        cmaqhi_id: data.get("cmaqhi_id"),
        latitude: data.get("latitude"),
        longitude: data.get("longitude"),
        districts: data.get("districts"),
        amphures: data.get("amphures"),
        provinces: data.get("provinces"),
        token: data.get("token"),
        note: data.get("note"),
        owner: data.get("owner"),
        status: Statusvalue
      })
      .then(result => {
        Swal.fire({
          title: "แก้ไขสำเร็จ!",
          text: "^_^",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
          customClass: {
            container: "my-swal"
          }
        }).then(result => {
          window.location.reload(false);
        });
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const excelclick = () => {
    let xdata = [];
    let header = [
      "Number",
      "Divice ID",
      "ชื่อสถานี",
      "ตำบล",
      "อำเภอ",
      "จังหวัด",
      "Latitude",
      "Longtitude"
    ];

    xdata.push(["ข้อมูลของจุดตรวจ"]);
    xdata.push([""]);
    xdata.push(header);

    for (var row in exdataSource) {
      const xx = [];

      for (var col in exdataSource[row]) {
        xx.push(exdataSource[row][col]);
      }

      xdata.push(xx);
    }

    var wb = xlsx.utils.book_new(),
      ws = xlsx.utils.json_to_sheet(xdata, { skipHeader: true });

    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    xlsx.writeFile(wb, "ข้อมูลของจุดตรวจ.xlsx");
  };

  const dgcolumns = [
    {
      name: "Number",
      header: "Number",
      maxWidth: 100,
      minWidth: 50,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      type: "number",
      sortable: false
    },
    {
      name: "device_id",
      header: "Device ID",
      maxWidth: 200,
      minWidth: 100,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      type: "number",
      sortable: false
    },
    {
      name: "device_name",
      header: "สถานีตรวจวัด",
      maxWidth: 900,
      minWidth: 175,
      defaultFlex: 1,
      headerAlign: "center",
      sortable: false
    },
    {
      name: "status",
      header: "สถานะ",
      maxWidth: 100,
      minWidth: 100,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false
    },
    {
      name: "edit_device",
      header: "แก้ไข",
      maxWidth: 100,
      minWidth: 100,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false
    }
  ];

  const dgcolumns2 = [
    {
      name: "Number",
      header: "Number",
      maxWidth: 100,
      minWidth: 50,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      type: "number",
      sortable: false
    },
    {
      name: "device_id",
      header: "Device ID",
      maxWidth: 200,
      minWidth: 100,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      type: "number",
      sortable: false
    },
    {
      name: "device_name",
      header: "สถานีตรวจวัด",
      maxWidth: 900,
      minWidth: 175,
      defaultFlex: 1,
      headerAlign: "center",
      sortable: false
    },
    {
      name: "status",
      header: "สถานะ",
      maxWidth: 150,
      minWidth: 100,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false
    }
  ];

  const gridStyle = { minHeight: 603 };
  const gridStyle2 = { minHeight: zHeight };

  const scrollProps = Object.assign(
    {},
    ReactDataGrid.defaultProps.scrollProps,
    {
      autoHide: false
    }
  );

  const searchname = values => {
    // console.log(values);

    getDevice(values);
  };

  const searchname2 = values => {
    // console.log(values);

    getDeviceNanogen(values);
  };

  const handleChange = event => {
    setStatusValue(event.target.value);
  };

  const handleChange2 = event => {
    // console.log(event.target.value);

    axios
      .post(xURL + "getstatusAdmin", { status: event.target.value })
      .then(result => {
        const Tb1 = [],
          Tb2 = [];
        let xstatus = "",
          xarea_point = "",
          xdistricts = "",
          xamphures = "",
          xprovinces = "";

        result.data.forEach((i, idx) => {
          if (i.status == 1) {
            xstatus = "On site";
          } else if (i.status == 2) {
            xstatus = "รอติดตั้ง";
          } else if (i.status == 3) {
            xstatus = "ส่งซ่อม";
          } else if (i.status == 4) {
            xstatus = "รอส่งคืน";
          } else if (i.status == 8) {
            xstatus = "ปิดชั่วคราว";
          } else if (i.status == 11) {
            xstatus = "Indoor";
          }else if (i.status == 0) {
            xstatus = "ย้าย/ยกเลิก";
          } else {
            xstatus = "";
          }

          xarea_point = "/?area-point=" + i.id+'&pages=admin';

          if (i.districts) {
            xdistricts = "ต." + i.districts;
          } else {
            xdistricts = "";
          }

          if (i.amphures) {
            xamphures = "อ." + i.amphures;
          } else {
            xamphures = "";
          }

          if (i.provinces) {
            xprovinces = "จ." + i.provinces;
          } else {
            xprovinces = "";
          }

          Tb1.push({
            Number: parseInt(idx + 1),
            device_id: "ID " + i.cmaqhi_device_id,
            device_name: (
              <a
                style={{ textDecoration: "none" }}
                href={xarea_point}
                target="_blank"
              >
                {i.name} {xdistricts} {xamphures} {xprovinces}
              </a>
            ),
            status: xstatus,
            edit_device: (
              <IconButton size="small" color="primary" aria-label="edit device">
                <EditIcon fontSize="inherit" onClick={() => handleOpen(i)} />
              </IconButton>
            )
          });

          Tb2.push({
            Number: parseInt(idx + 1),
            device_id: "ID " + i.cmaqhi_device_id,
            device_name: i.name,
            tambol: i.districts,
            amphures: i.amphures,
            provinces: i.provinces,
            lat: i.poi_lat,
            long: i.poi_lng
          });
        });

        setdgdataSource(Tb1);
        setexdataSource(Tb2);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  return (
    <React.Fragment>
      <div style={{ marginBottom: "100px" }} />
      <Navbar_Admin />

      <Container>
        <h2>แก้ไขข้อมูลของจุดตรวจ</h2>

        <TextField
          margin="normal"
          size="small"
          id="search_name"
          label="ค้นหาสถานีตรวจวัด"
          name="search_name"
          // autoFocus
          sx={{ width: 320 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          onChange={event => {
            searchname(event.target.value);
          }}
          style={{ zIndex: 0 }}
        />

        <FormControl margin="normal" sx={{ ml: 2 }} style={{ zIndex: 0 }}>
          <InputLabel id="demo-select-small">สถานะ</InputLabel>
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            size="small"
            name="year"
            defaultValue={99}
            label="สถานะ"
            onChange={handleChange2}
            sx={{ width: 150 }}
          >
            {dataStatus2.map(i =>
              <MenuItem key={i.id} value={i.id}>
                {i.name}
              </MenuItem>
            )}
          </Select>
        </FormControl>

        <ReactDataGrid
          idProperty="Number"
          columns={dgcolumns}
          dataSource={dgdataSource}
          style={gridStyle}
          enableClipboard
          scrollProps={scrollProps}
        />

        <div
          style={{
            display: "flex",
            marginTop: "10px",
            justifyContent: "flex-end",
            marginLeft: "auto",
            marginRight: 0
          }}
        >
          <Button
            style={{ textTransform: "none", fontFamily: "kanit" }}
            variant="text"
            color="success"
            sx={{ mt: 1, width: 150 }}
            startIcon={<Icon icon="vscode-icons:file-type-excel2" />}
            onClick={() => {
              excelclick();
            }}
          >
            ดาวน์โหลด Excel
          </Button>
        </div>

        <h2 style={{ marginTop: "50px" }}>แก้ไขสถานะของจุดตรวจ Nanogen</h2>

        <TextField
          margin="normal"
          size="small"
          id="search_name2"
          label="ค้นหาสถานีตรวจวัด Nanogen"
          name="search_name2"
          // autoFocus
          sx={{ width: 320 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          onChange={event => {
            searchname2(event.target.value);
          }}
          style={{ zIndex: 0 }}
        />

        <ReactDataGrid
          idProperty="Number"
          columns={dgcolumns2}
          dataSource={dgdataSource2}
          style={gridStyle2}
          enableClipboard
          scrollProps={scrollProps}
        />
      </Container>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ zIndex: 0 }}
      >
        <Card
          sx={{
            maxWidth: 1000,
            maxHeight: 650,
            overflow: "auto",
            margin: "70px auto",
            float: "none"
          }}
        >
          <CardContent>
            <p style={{ fontSize: "22px", marginTop: "8px" }}>
              แก้ไข MASTER FILE
            </p>
            <hr style={{ marginTop: "0px" }} />
            <Box component="form" onSubmit={handleEdit} noValidate>
              <TextField
                fullWidth
                margin="normal"
                id="devices_name"
                label="สถานีตรวจวัด"
                name="devices_name"
                defaultValue={dataDevice.name}
                variant="outlined"
                size="small"
                sx={{ mt: 1 }}
              />
              <TextField
                fullWidth
                margin="normal"
                id="devices_id"
                label="Device-ID"
                name="devices_id"
                defaultValue={dataDevice.device_id}
                variant="filled"
                size="small"
                InputProps={{
                  readOnly: true
                }}
                sx={{ mt: 0 }}
              />
              <TextField
                fullWidth
                margin="normal"
                id="cmaqhi_id"
                label="ID วัดฝุ่น"
                name="cmaqhi_id"
                defaultValue={dataDevice.cmaqhi_device_id}
                variant="filled"
                size="small"
                InputProps={{
                  readOnly: true
                }}
                sx={{ mt: 0 }}
              />
              <TextField
                fullWidth
                margin="normal"
                id="latitude"
                label="Latitude"
                name="latitude"
                defaultValue={dataDevice.poi_lat}
                variant="outlined"
                size="small"
                sx={{ mt: 0 }}
              />
              <TextField
                fullWidth
                margin="normal"
                id="longitude"
                label="Longitude"
                name="longitude"
                defaultValue={dataDevice.poi_lng}
                variant="outlined"
                size="small"
                sx={{ mt: 0 }}
              />
              <TextField
                fullWidth
                margin="normal"
                id="districts"
                label="ตำบล"
                name="districts"
                defaultValue={dataDevice.districts}
                variant="outlined"
                size="small"
                sx={{ mt: 0 }}
              />
              <TextField
                fullWidth
                margin="normal"
                id="amphures"
                label="อำเภอ"
                name="amphures"
                defaultValue={dataDevice.amphures}
                variant="outlined"
                size="small"
                sx={{ mt: 0 }}
              />
              <TextField
                fullWidth
                margin="normal"
                id="provinces"
                label="จังหวัด"
                name="provinces"
                defaultValue={dataDevice.provinces}
                variant="outlined"
                size="small"
                sx={{ mt: 0 }}
              />
              <TextField
                fullWidth
                margin="normal"
                id="token"
                label="Line token"
                name="token"
                defaultValue={dataDevice.line_notify}
                variant="outlined"
                size="small"
                sx={{ mt: 0 }}
              />
              <TextField
                fullWidth
                margin="normal"
                id="note"
                label="Note"
                name="note"
                defaultValue={dataDevice.note}
                variant="outlined"
                size="small"
                sx={{ mt: 0 }}
              />
              <TextField
                fullWidth
                margin="normal"
                id="owner"
                label="ผู้รับผิดชอบ"
                name="owner"
                defaultValue={dataDevice.owner}
                variant="outlined"
                size="small"
                sx={{ mt: 0 }}
              />

              <FormControl margin="normal">
                <InputLabel id="demo-select-small">สถานะ</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  size="small"
                  name="status"
                  // value={dataDevice.status}
                  defaultValue={dataDevice.status}
                  label="สถานะ"
                  onChange={handleChange}
                  sx={{ width: 340 }}
                >
                  {dataStatus.map(status =>
                    <MenuItem key={status.id} value={status.id}>
                      {status.name}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>

              <hr style={{ marginBottom: "-5px" }} />

              <div
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  float: "right"
                }}
              >
                <Button
                  // variant="contained"
                  style={{ fontFamily: "kanit" }}
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  ปิด
                </Button>
                <Button
                  variant="contained"
                  style={{ fontFamily: "kanit" }}
                  type="submit"
                >
                  บันทึก
                </Button>
              </div>

              <div
                style={{
                  height: "50px"
                }}
              />
            </Box>
          </CardContent>
        </Card>
      </Modal>

      <Footer />
    </React.Fragment>
  );
};

export default Edit_device;
