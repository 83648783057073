import React from "react";

import { Card, Grid } from "@mui/material";

import "./css/Highcharts.css";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsAccessibility from "highcharts/modules/accessibility";

HighchartsExporting(Highcharts);
HighchartsMore(Highcharts);
HighchartsAccessibility(Highcharts);

const Highcharts1Min = prop => {
  let xpm25 = prop.pm25,
    xair_quality = "",
    xcolor = "",
    xcolor_cha = "",
    xaqhi = "",
    xfontsize = "45px",
    xmagintop = "-30px";

  //AQHI
  if (xpm25 <= 12) {
    xaqhi = Math.round(4.1857 * parseInt(xpm25) + 0.0524).toString();
  } else if (xpm25 > 12 && xpm25 <= 35) {
    xaqhi = Math.round(2.1 * parseInt(xpm25) + 25.7).toString();
  } else if (xpm25 > 35 && xpm25 <= 55) {
    xaqhi = Math.round(2.4762 * parseInt(xpm25) + 13).toString();
  } else if (xpm25 > 55 && xpm25 <= 150) {
    xaqhi = Math.round(0.5161 * parseInt(xpm25) + 122.1).toString();
  } else if (xpm25 > 150 && xpm25 <= 205) {
    xaqhi = Math.round(parseInt(xpm25) + 50).toString();
  } else if (xpm25 > 205 && xpm25 <= 350) {
    xaqhi = Math.round(parseInt(xpm25) + 50).toString();
  } else if (xpm25 > 305 && xpm25 <= 500) {
    xaqhi = Math.round(0.667 * parseInt(xpm25) + 167).toString();
  } else if (xpm25 > 500) {
    xaqhi = "Beyond AQHI";
  } else {
    xaqhi = "-";

    xfontsize = "30px";
    xmagintop = "-10px";

    xpm25 = "เครื่องออฟไลน์";
  }

  // console.log(prop.realtime)

  if (parseInt(xaqhi) <= 50) {
    xair_quality = "อากาศดี";
    xcolor = "#00e400";
    xcolor_cha = "#000000";
  } else if (parseInt(xaqhi) > 50 && parseInt(xaqhi) <= 100) {
    xair_quality = "ปานกลาง";
    xcolor = "#ffff00";
    xcolor_cha = "#000000";
  } else if (parseInt(xaqhi) > 100 && parseInt(xaqhi) <= 150) {
    xair_quality = "ไม่ดีต่อกลุ่มเสี่ยง";
    xcolor = "#ff7e00";
    xcolor_cha = "#000000";
  } else if (parseInt(xaqhi) > 150 && parseInt(xaqhi) <= 200) {
    xair_quality = "อากาศไม่ดี";
    xcolor = "#ff0000";
    xcolor_cha = "#ffffff";
  } else if (parseInt(xaqhi) > 200 && parseInt(xaqhi) <= 300) {
    xair_quality = "ไม่ดีอย่างยิ่ง";
    xcolor = "#99004c";
    xcolor_cha = "#ffffff";
  } else if (parseInt(xaqhi) > 300 && parseInt(xaqhi) <= 500) {
    xair_quality = "อันตราย";
    xcolor = "#4c0026";
    xcolor_cha = "#ffffff";
  } else {
    xair_quality = "ไม่สามารถรายงานได้";
    xcolor = "#4c0026";
    xcolor_cha = "#ffffff";
  }

  const getOptions = type => ({
    chart: {
      type,
      backgroundColor: null
    },
    title: {
      text: ""
    },
    accessibility: {
      description: "The gauge chart shows the pm2.5  between 0 and 500."
    },
    pane: {
      startAngle: -90,
      endAngle: 89.9,
      background: null,
      center: ["50%", "90%"],
      size: "93%"
    },
    yAxis: {
      min: 0,
      max: 500,
      minorTickInterval: null,
      tickPositions: [0, 50, 100, 150, 150, 200, 300, 500],
      tickPosition: "inside",
      tickColor: Highcharts.defaultOptions.chart.backgroundColor || "#FFFFFF",
      tickLength: null,
      tickWidth: 0,
      labels: {
        step: 1,
        rotation: "auto",
        distance: 10
      },
      lineWidth: 0,
      plotBands: [
        {
          from: 0,
          to: 50,
          color: "#00e400",
          thickness: 80
        },
        {
          from: 50,
          to: 100,
          color: "#ffff00",
          thickness: 80
        },
        {
          from: 100,
          to: 150,
          color: "#ff7e00",
          thickness: 80
        },
        {
          from: 150,
          to: 200,
          color: "#ff0000",
          thickness: 80
        },
        {
          from: 200,
          to: 300,
          color: "#99004c",
          thickness: 80
        },
        {
          from: 300,
          to: 500,
          color: "#4c0026",
          thickness: 80
        }
      ]
    },
    series: [
      {
        name: "PM 2.5 ",
        data: [parseInt(xaqhi)],
        tooltip: {
          valueSuffix: " มคก./ลบม."
        },
        dataLabels: {
          format: "",
          borderWidth: 0,
          color: "#333333",
          style: {
            fontSize: "20px"
          }
        },
        dial: {
          radius: "50%",
          backgroundColor: "gray",
          baseWidth: 14,
          baseLength: "0%",
          rearLength: "0%"
        },
        pivot: {
          backgroundColor: "gray",
          radius: 7
        }
      }
    ],
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    }
  });

  return (
    <React.Fragment>
      <div style={{ marginTop: "-130px", position: "relative", zIndex: -1 }}>
        <HighchartsReact
          highcharts={Highcharts}
          options={getOptions("gauge")}
        />
      </div>

      <Grid container spacing={2}>
        <Grid item xs={2} sm={2} md={3.5} />
        <Grid item xs={8} sm={8} md={5}>
          <p
            style={{
              color: "#000000",
              fontSize: "22px",
              textAlign: "center",
              marginTop: "-20px"
            }}
          >
            คุณภาพอากาศ
          </p>

          <Card
            sx={{ width: "100%", height: 50 }}
            style={{
              backgroundColor: xcolor,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "-15px"
            }}
          >
            <p
              style={{
                color: xcolor_cha,
                fontSize: "22px"
              }}
            >
              {xair_quality}
            </p>
          </Card>
        </Grid>
        <Grid item xs={2} sm={2} md={3.5} />
      </Grid>

      <br />
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={6}>
          <Card
            className="card-style"
            sx={{ width: "100%" }}
            style={{
              // border: "3px solid #4FC0D0"
              backgroundColor: xcolor
            }}
          >
            <p
              style={{
                textAlign: "center",
                color: xcolor_cha,
                fontSize: "15px"
              }}
            >
              AQHI รายนาที
            </p>

            <p
              style={{
                textAlign: "center",
                color: xcolor_cha,
                fontSize: "45px",
                marginTop: "-15px"
              }}
            >
              {xaqhi}
            </p>
          </Card>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Card
            className="card-style2"
            sx={{ width: "100%" }}
            style={{
              border: "3px solid #4FC0D0"
            }}
          >
            <p
              style={{
                textAlign: "center",
                color: "#000000",
                fontSize: "15px"
              }}
            >
              PM2.5 รายนาที <br />
              <p style={{ fontSize: "13px", marginTop: "-3px" }}>(มคก./ลบม.)</p>
            </p>
            <p
              style={{
                textAlign: "center",
                color: "#000000",
                fontSize: xfontsize,
                marginTop: xmagintop
              }}
            >
              {xpm25}
            </p>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Highcharts1Min;
