import React, { useState } from "react";

import PropTypes from "prop-types";
import { Tabs, Tab, Box, AppBar } from "@mui/material";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ListIcon from "@mui/icons-material/List";
import PublicIcon from "@mui/icons-material/Public";

import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";

import Tdata from "./Tdata";
import Tdata2 from "./Tdata2";
import Tdata3 from "./Tdata3";

import TdataAir1 from "./TdataAir1";
import TdataAir2 from "./TdataAir2";
import TdataAir3 from "./TdataAir3";

import Tindex from "./Tindex";
import TindexPCD from "./TindexPCD";

import Map from "./Map";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index &&
        <Box sx={{ p: 3 }}>
          {children}
        </Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

function a11yProps2(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

function a11yProps3(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const Maindata = () => {
  const [value, setValue] = useState(0);
  const [value2, setValue2] = useState(0);
  const [value3, setValue3] = useState(0);

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };

  const TabChange2 = (event, newValue) => {
    setValue2(newValue);
  };

  const TabChange3 = (event, newValue) => {
    setValue3(newValue);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          width: "100%"
        }}
      >
        <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
          <AppBar position="static" style={{ background: "#F0F5FF" }}>
            <Tabs
              value={value2}
              onChange={TabChange2}
              aria-label="basic tabs example"
              variant="fullWidth"
              indicatorColor="primary"
              textColor="secondary"
            >
              <Tab
                icon={<OnlinePredictionIcon />}
                label={
                  <p
                    style={{
                      marginTop: "-2px",
                      marginBottom: "-2px",
                      fontWeight: "bold",
                      fontFamily: "kanit"
                    }}
                  >
                    จุดตรวจของ NTAQHI
                  </p>
                }
                {...a11yProps2(0)}
              />
              <Tab
                icon={<OnlinePredictionIcon />}
                label={
                  <p
                    style={{
                      marginTop: "-2px",
                      marginBottom: "-2px",
                      fontWeight: "bold",
                      fontFamily: "kanit"
                    }}
                  >
                    จุดตรวจกรมควบคุมมลพิษ
                  </p>
                }
                {...a11yProps2(1)}
              />
            </Tabs>
          </AppBar>
        </Box>

        <TabPanel value={value2} index={0}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={TabChange}
                variant="scrollable"
                style={{ marginLeft: "-25px" }}
              >
                <Tab
                  icon={<CalendarTodayIcon />}
                  label={<span style={{ fontFamily: "kanit" }}>รายวัน</span>}
                  {...a11yProps(0)}
                  sx={{ minWidth: "fit-content", flex: 1 }}
                />
                <Tab
                  icon={<CalendarMonthIcon />}
                  label={<span style={{ fontFamily: "kanit" }}>รายเดือน</span>}
                  {...a11yProps(1)}
                  sx={{ minWidth: "fit-content", flex: 1 }}
                />
                <Tab
                  icon={<EventAvailableIcon />}
                  label={<span style={{ fontFamily: "kanit" }}>รายปี</span>}
                  {...a11yProps(2)}
                  sx={{ minWidth: "fit-content", flex: 1 }}
                />
                <Tab
                  icon={<PublicIcon />}
                  label={
                    <span style={{ fontFamily: "kanit" }}>
                      แผนที่
                    </span>
                  }
                  {...a11yProps(3)}
                  sx={{ minWidth: "fit-content", flex: 1 }}
                  wrapped
                />
                <Tab
                  icon={<ListIcon />}
                  label={
                    <span style={{ fontFamily: "kanit" }}>
                      อันดับ
                    </span>
                  }
                  {...a11yProps(4)}
                  sx={{ minWidth: "fit-content", flex: 1 }}
                  wrapped
                />
              </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
              <Tdata />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Tdata2 />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Tdata3 />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Map />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <Tindex />
            </TabPanel>
          </Box>
        </TabPanel>
        <TabPanel value={value2} index={1}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value3}
                onChange={TabChange3}
                aria-label="basic tabs example"
                variant="fullWidth"
                style={{ marginLeft: "-25px" }}
              >
                <Tab
                  icon={<CalendarTodayIcon />}
                  label={<span style={{ fontFamily: "kanit" }}>รายวัน</span>}
                  {...a11yProps3(0)}
                />
                <Tab
                  icon={<CalendarMonthIcon />}
                  label={<span style={{ fontFamily: "kanit" }}>รายเดือน</span>}
                  {...a11yProps3(1)}
                />
                <Tab
                  icon={<EventAvailableIcon />}
                  label={<span style={{ fontFamily: "kanit" }}>รายปี</span>}
                  {...a11yProps3(2)}
                />
                <Tab
                  icon={<ListIcon />}
                  label={
                    <span style={{ fontFamily: "kanit" }}>
                      อันดับคุณภาพอากาศ
                    </span>
                  }
                  {...a11yProps3(3)}
                  wrapped
                />
              </Tabs>
            </Box>

            <TabPanel value={value3} index={0}>
              <TdataAir1 />
            </TabPanel>
            <TabPanel value={value3} index={1}>
              <TdataAir2 />
            </TabPanel>
            <TabPanel value={value3} index={2}>
              <TdataAir3 />
            </TabPanel>
            <TabPanel value={value3} index={3}>
              <TindexPCD />
            </TabPanel>
          </Box>
        </TabPanel>
      </Box>
    </React.Fragment>
  );
};

export default Maindata;
