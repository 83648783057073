import React, { useEffect } from "react";

import axios from "axios";

import "./css/Safezone.css";

const Map = () => {
  let xURL = "";
  xURL = "https://www2.ntaqhi.info/api/";

  useEffect(() => {
    getMarker();
    // setMap();
  }, []);

  function setMap(mark) {
    const script = document.createElement("script");
    script.src = `https://api.longdo.com/map/?key=16f9234b232cc53f721c5e7ac40f5965`;
    script.id = "longdoMapScript";
    document.body.appendChild(script);

    script.onload = () => {
      mapCallback(mark);
    };

    const mapCallback = () => {
      let longdo = window.longdo;
      let map = new window.longdo.Map({
        placeholder: document.getElementById("longdo-map"),
        language: "th"
      });

      map.location(
        {
          // พิกัด zoom 8
          // lat: 18.200195,
          // lon: 99.600751
          // พิกัด zoom 7
          // lat: 15.800195,
          // lon: 101.500751
          // พิกัด zoom 8 new
          lat: 18.700195,
          lon: 99.600751
        },
        true
      );
      map.zoom(8);
      map.zoomRange({
        min: 7,
        max: 17
      });

      // map.Layers.setBase(longdo.Layers.POLITICAL);
      map.Layers.setBase(longdo.Layers.TOMTOM_NORMAL);

      map.Ui.LayerSelector.visible(true);
      map.Ui.Mouse.enable(true);
      map.Ui.Mouse.enableDrag(true);
      map.Ui.DPad.visible(true);
      map.Ui.LayerSelector.visible(false);
      map.Ui.Toolbar.visible(false);
      // map.Ui.Fullscreen.visible(false);
      map.Ui.Crosshair.visible(false);
      map.Ui.Scale.visible(true);

      map.Overlays.clear();

      mark.forEach(i => {
        let titlepin = "",
          detailpin = "",
          markerpin = "",
          xchildren = "";

        if (i.children) {
          xchildren = i.children;
        }

        titlepin = "<p class='title-style-cr'>" + i.name + "</p>";
        detailpin =
          "<p class='detail-style-cr'>จำนวน = " +
          i.room +
          " ห้อง</p> <br> <p class='detail-style-cr'>จำนวนเด็ก " +
          xchildren +
          " คน</p>";

        markerpin =
          "<div class='pin'><p class='label1-cr'>" + i.room + "</p></div>";

        let marker = new longdo.Marker(
          { lon: i.lon, lat: i.lat },
          {
            title: titlepin,
            detail: detailpin,
            icon: {
              html: markerpin,
              offset: { x: 10, y: 10 }
            },

            size: {
              width: 190,
              height: 170
            }
          }
        );

        map.Overlays.add(marker);
      });
    };
  }

  function getMarker() {
    axios
      .get(xURL + "getCleanroom")
      .then(result => {
        // console.log(result.data);
        const Tb1 = [];

        result.data.forEach((i, idx) => {
          Tb1.push({
            name: i.name,
            lat: i.poi_lat,
            lon: i.poi_lng,
            room: i.room,
            children: i.children
          });
        });

        //console.log(Tb1);

        setMap(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }

  return (
    <React.Fragment>
      <div
        id="longdo-map"
        style={{
          height: "700px",
          border: "1px solid #B3B6B7",
          borderRadius: "10px",
          padding: "1%"
        }}
      />

      <div style={{ marginBottom: "30px", backgroundColor: "#fff" }}>
        <span style={{ fontSize: "12px", color: "#748DA6" }}>
          - ขอขอบคุณ{" "}
          <a style={{ textDecoration: "none" }} href="https://map.longdo.com/">
            Longdo Map
          </a>{" "}
          ที่สนับสนุนการทำแผนที่
        </span>
      </div>
    </React.Fragment>
  );
};

export default Map;
