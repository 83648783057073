import React from "react";

const Listdetail = () => {
  return (
    <React.Fragment>
      <br /> <br />
      <a
        style={{
          color: "#0000ff",
          textDecoration: "none",
          fontSize: "20px"
        }}
        href="https://www.cmu.ac.th/th/privacy/policy"
        target="new"
        rel="noopener"
      >
        ประกาศมหาวิทยาลัยเชียงใหม่ เรื่อง นโยบายคุ้มครองข้อมูลส่วนบุคคล
        มหาวิทยาลัยเชียงใหม่ (CMU Privacy Policy)
      </a>
      <br /> <br />
      <a
        style={{
          color: "#0000ff",
          textDecoration: "none"
        }}
        href="https://www.cmu.ac.th/th/privacy/notice"
        target="new"
        rel="noopener"
      >
        ประกาศมหาวิทยาลัยเชียงใหม่ เรื่อง คำประกาศความเป็นส่วนตัว
        มหาวิทยาลัยเชียงใหม่ (CMU Privacy Notice)
      </a>
      <br /> <br />
      <h3 style={{ fontSize: "16px" }}>
        นโยบายความเป็นส่วนตัวการใช้บริการของเว็บไซต์มหาวิทยาลัยเชียงใหม่
      </h3>
      <p style={{ fontSize: "14px" }}>
        มหาวิทยาลัยเชียงใหม่
        อาจมีการใช้คุกกี้หรือเทคโนโลยีอื่นใดที่มีลักษณะใกล้เคียงกัน (“คุกกี้”)
        บนเว็บไซต์ เพื่อช่วยให้คุณได้รับประสบการณ์ที่ดีจากการใช้บริการ
        และช่วยให้เว็บไซต์มหาวิทยาลัยเชียงใหม่สามารถพัฒนาคุณภาพของบริการให้ตอบสนองต่อความต้องการของคุณมากยิ่งขึ้น
      </p>
      <h3 style={{ fontSize: "16px" }}>คุกกี้คืออะไร?</h3>
      <p style={{ fontSize: "14px" }}>
        คุกกี้ คือ ไฟล์เล็ก ๆ เพื่อจัดเก็บข้อมูลการเข้าใช้งานเว็บไซต์ เช่น
        วันเวลา ลิงค์ที่คลิก หน้าที่เข้าชม เงื่อนไขการตั้งค่าต่าง ๆ
        โดยจะบันทึกลงไปในอุปกรณ์คอมพิวเตอร์ และ/หรือ
        เครื่องมือสื่อสารที่เข้าใช้งานของท่าน เช่น โน๊ตบุ๊ค แท็บเล็ต หรือ
        สมาร์ทโฟน ผ่านทางเว็บเบราว์เซอร์ในขณะที่ท่านเข้าสู่เว็บไซต์
        โดยคุกกี้จะไม่ก่อให้เกิดอันตรายต่ออุปกรณ์คอมพิวเตอร์ และ/หรือ
        เครื่องมือสื่อสารของท่าน ในกรณีดังต่อไปนี้
        ข้อมูลส่วนบุคคลของท่านอาจถูกจัดเก็บเพื่อใช้เพิ่มประสบการณ์การใช้งานบริการทางออนไลน์
        โดยจะจำเอกลักษณ์ของภาษาและปรับแต่งข้อมูลการใช้งานตามความต้องการของท่าน
        เป็นการยืนยันคุณลักษณะเฉพาะตัว ข้อมูลความปลอดภัยของท่าน
        รวมถึงบริการที่ท่านสนใจ
        นอกจากนี้คุกกี้ยังถูกใช้เพื่อวัดปริมาณการเข้าใช้งานบริการทางออนไลน์
        การปรับเปลี่ยนเนื้อหาตามการใช้งานของท่านโดยพิจารณาจากพฤติกรรมการเข้าใช้งานครั้งก่อน
        ๆ และ ณ ปัจจุบัน และอาจมีวัตถุประสงค์เพื่อการโฆษณาประชาสัมพันธ์
      </p>
      <h3 style={{ fontSize: "16px" }}>ประโยชน์ของคุกกี้</h3>
      <p style={{ fontSize: "14px" }}>
        คุกกี้ช่วยให้เว็บไซต์มหาวิทยาลัยเชียงใหม่ทราบว่าคุณเข้าชมส่วนใดในเว็บไซต์
        เพื่อที่เว็บไซต์มหาวิทยาลัยเชียงใหม่จะสามารถมอบประสบการณ์การใช้บริการที่ดีขึ้นและตรงกับความต้องการของคุณและช่วยให้คุณสามารถใช้บริการเว็บไซต์ได้อย่างต่อเนื่อง
        นอกจากนี้
        การบันทึกการตั้งค่าแรกของบริการด้วยคุกกี้จะช่วยให้คุณเข้าถึงบริการด้วยค่าที่ตั้งไว้ทุกครั้งที่ใช้งาน
        ยกเว้นในกรณีที่คุกกี้ถูกลบซึ่งจะทำให้การตั้งค่าทุกอย่างจะกลับไปที่ค่าเริ่มต้น
      </p>
      <h3 style={{ fontSize: "16px" }}>ประเภทของคุกกี้ที่ใช้</h3>
      <p style={{ fontSize: "14px" }}>
        ประเภทของคุกกี้ที่เว็บไซต์มหาวิทยาลัยเชียงใหม่ใช้รวมถึงแต่ไม่จำกัดเฉพาะรายการต่อไปนี้
      </p>
      <br />
      <div>
        <table>
          <thead style={{ textAlign: "left" }}>
            <tr style={{ fontSize: "16px" }}>
              <th>ประเภทของคุกกี้</th>
              <th>รายละเอียด</th>
              <th>ตัวอย่าง</th>
            </tr>
          </thead>
          <tbody style={{ fontSize: "14px" }}>
            <tr>
              <td>
                คุกกี้ประเภทจำเป็นถาวร<br />
                (Strictly Necessary cookies)
              </td>
              <td>
                คุกกี้ประเภทนี้จำเป็นสำหรับการทำงานของเว็บไซต์มหาวิทยาลัยเชียงใหม่เพื่อช่วยให้คุณสามารถเข้าถึงข้อมูล
                และใช้เว็บไซต์มหาวิทยาลัยเชียงใหม่ได้อย่างปลอดภัย
                จึงไม่สามารถปิดการใช้คุกกี้ประเภทนี้ได้
              </td>
              <td>
                <ul>
                  <li>SessionID</li>
                  <li>Load Balancing</li>
                  <li>User ID</li>
                  <li>Security</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                คุกกี้ประเภทการวิเคราะห์ และวัดผลการทำงาน<br />
                (Analytic/Performance cookie)
              </td>
              <td>
                คุกกี้ประเภทนี้ช่วยให้เว็บไซต์มหาวิทยาลัยเชียงใหม่
                สามารถวิเคราะห์หรือวัดผลการทำงาน
                เพื่อให้เว็บไซต์มหาวิทยาลัยเชียงใหม่เข้าใจถึงความสนใจของคุณ เช่น
                การประมวลผลจำนวนผู้เยี่ยมชมเว็บไซต์มหาวิทยาลัยเชียงใหม่
                พฤติกรรมการเยี่ยมชมเว็บไซต์มหาวิทยาลัยเชียงใหม่
                จำนวนหน้าที่คุณเข้าใช้งาน
                โดยมหาวิทยาลัยเชียงใหม่จะใช้ข้อมูลดังกล่าวในการปรับปรุงและพัฒนาประสิทธิภาพการทำงานของเว็บไซต์มหาวิทยาลัยเชียงใหม่
                ให้ตอบสนองตามความต้องการและการใช้งานของคุณให้ดียิ่งขึ้น
              </td>
              <td>
                <ul>
                  <li>Google Analytics</li>
                  <li>Adobe</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                คุกกี้ประเภทการโฆษณา<br />
                (advertising cookies)
              </td>
              <td>
                คุกกี้ประเภทนี้จะถูกบันทึกบนอุปกรณ์ของคุณเพื่อเก็บข้อมูลการเข้าใช้งานและลิงก์ที่คุณติดตามหรือเยี่ยมชม
                เพื่อให้เข้าใจความต้องการของคุณและใช้ในการ นำเสนอสิทธิประโยชน์
                ประชาสัมพันธ์ โฆษณา ให้เหมาะสมกับความสนใจของคุณมากที่สุด
              </td>
              <td>
                <ul>
                  <li>Adobe Audience Manager</li>
                  <li>Adobe Target</li>
                  <li>Google Analytics</li>
                  <li>Facebook Pixels</li>
                  <li>Facebook API</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                คุกกี้ประเภทการทำงาน<br />
                (Functional cookies)
              </td>
              <td>
                คุกกี้ประเภทนี้จะช่วยให้เว็บไซต์มหาวิทยาลัยเชียงใหม่ “จดจำ”
                คุณระหว่างการเยี่ยมชมและตั้งค่าเว็บไซต์มหาวิทยาลัยเชียงใหม่
                ตามลักษณะการใช้งานให้สอดคล้องกับสิ่งที่คุณเลือก
                เพื่ออำนวยความสะดวกเมื่อคุณกลับเข้ามาใช้เว็บไซต์มหาวิทยาลัยเชียงใหม่ในครั้งถัดไป
                เช่น การจดจำชื่อผู้ใช้งานของคุณ การปรับขนาดตัวอักษร
                ภาษาและส่วนอื่นๆ บนหน้าเว็บไซต์มหาวิทยาลัยเชียงใหม่
              </td>
              <td>
                <ul>
                  <li>Google Analytics</li>
                  <li>SessionID</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p style={{ fontSize: "14px" }}>
        ในกรณีที่มีการเชื่อมโยงกับแพลตฟอร์มของบุคคลที่สาม เช่น เครือข่ายการโฆษณา
        สื่อสังคมออนไลน์ ผู้ให้บริการเว็บไซต์ภายนอกอื่นๆ เป็นต้น
        คุกกี้บางประเภทอาจมีการจัดการโดยบุคคลที่สาม
        จึงแนะนำให้คุณศึกษาและทำความเข้าใจนโยบายการใช้คุกกี้และนโยบายการคุ้มครองข้อมูลส่วนบุคคลของบุคคลที่สามด้วย
      </p>
      <h3 style={{ fontSize: "16px" }}>การตั้งค่าและการปฏิเสธคุกกี้</h3>
      <p style={{ fontSize: "14px" }}>
        คุณสามารถเลือกปฏิเสธการทำงานของคุกกี้ได้ตามความต้องการของคุณ
        โดยการตั้งค่าเบราว์เซอร์หรือการตั้งค่าความเป็นส่วนตัวของคุณ
        เพื่อระงับการเก็บรวบรวมข้อมูลโดยคุกกี้ในอนาคต อย่างไรก็ตาม
        หากคุณตั้งค่าเบราว์เซอร์หรือค่าความเป็นส่วนตัวของคุณด้วยการปฏิเสธการทำงานของคุกกี้ทั้งหมด
        คุณอาจไม่สามารถใช้งานฟังก์ชั่นบางอย่างหรือทั้งหมดบนเว็บไซต์มหาวิทยาลัยเชียงใหม่
        ได้อย่างมีประสิทธิภาพ
      </p>
      <h3 style={{ fontSize: "16px" }}>การเปลี่ยนแปลงนโยบาย</h3>
      <p style={{ fontSize: "14px" }}>
        ประกาศนี้อาจมีการปรับปรุงให้เหมาะสมและสอดคล้องกับสถานการณ์และตามการให้บริการจริง
        โดยเว็บไซต์มหาวิทยาลัยเชียงใหม่จะมีการแจ้งประกาศที่มีการปรับปรุงใหม่บนเว็บไซต์นี้
        ดังนั้น
        เว็บไซต์มหาวิทยาลัยเชียงใหม่ขอแนะนำให้ท่านตรวจสอบให้แน่ใจว่าท่านได้เข้าใจการเปลี่ยนแปลงตามข้อกำหนดดังกล่าว
      </p>
      <h3 style={{ fontSize: "16px" }}>
        คุณสามารถจัดการคุกกี้ในเบราว์เซอร์ของท่านได้โดยการตั้งค่า ดังนี้
      </h3>
      <p style={{ fontSize: "14px" }}>
        <span id="cphPageContent_wucCMUContent_lbContent">
          Cookies settings in Chrome (<a
            style={{ textDecoration: "none" }}
            href="https://support.google.com/chrome/answer/95647?hl=en&amp;ref_topic=14666"
            target="_blank"
          >
            https://support.google.com/chrome/answer/95647?hl=en&amp;ref_topic=14666
          </a>)
        </span>
      </p>
      <p style={{ fontSize: "14px" }}>
        Cookies settings in Firefox (<a
          style={{ textDecoration: "none" }}
          href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&amp;redirectslug=Cookies"
          target="_blank"
        >
          https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&amp;redirectslug=Cookies
        </a>)
      </p>
      <p style={{ fontSize: "14px" }}>
        Cookies settings in Safari (<a
          style={{ textDecoration: "none" }}
          href="https://support.apple.com/th-th/guide/safari/sfri11471/mac"
          target="_blank"
        >
          https://support.apple.com/th-th/guide/safari/sfri11471/mac
        </a>)
      </p>
      <p style={{ fontSize: "14px" }}>
        Cookies settings in Internet Explorer (<a
          style={{ textDecoration: "none" }}
          href="https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-10"
          target="_blank"
        >
          https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-10
        </a>)
      </p>
    </React.Fragment>
  );
};

export default Listdetail;
