import React, { useState } from "react";

import PropTypes from "prop-types";
import { AppBar, Box, Toolbar, useScrollTrigger, Slide } from "@mui/material/";

import "./css/Navbar.css";
import logo from "../imgs/NTAQHI_small1.png";

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
};

const Navbar = props => {
  const [toggle, setToggle] = useState(false);

  return (
    <div className="Navbar">
      <Box sx={{ flexGrow: 1 }}>
        <HideOnScroll {...props}>
          <AppBar
            // position="sticky"
            style={{
              backgroundColor: "#009874"
              // opacity: 0.9
            }}
          >
            <Toolbar>
              <a class="navbar-brand" href="/">
                <img src={logo} alt="CMAQHI" width="120" />
              </a>

              <a
                href="#"
                class="toggle-button"
                onClick={() => setToggle(!toggle)}
              >
                <span class="bar" />
                <span class="bar" />
                <span class="bar" />
              </a>

              <div className="navbar-links">
                <ul>
                  <li>
                    <a style={{ fontSize: "14px" }} href="/">
                      หน้าหลัก
                    </a>
                  </li>
                  <li>
                    <a style={{ fontSize: "14px" }} href="/safezone">
                      ห้องปลอดฝุ่น
                    </a>
                  </li>
                  <li>
                    <a style={{ fontSize: "14px" }} href="/cal">
                      ผลกระทบต่อสุขภาพ
                    </a>
                  </li>
                  <li>
                    <a style={{ fontSize: "14px" }} href="/qa">
                      คำถาม-คำตอบ
                    </a>
                  </li>
                  <li>
                    <a style={{ fontSize: "14px" }} href="/olddata">
                      ข้อมูลย้อนหลัง
                    </a>
                  </li>
                  <li>
                    <a style={{ fontSize: "14px" }} href="/calibration">
                      สอบเทียบข้อมูล
                    </a>
                  </li>

                  <li>
                    <a style={{ fontSize: "14px" }} href="/infographic">
                      Infographic
                    </a>
                  </li>

                  <li>
                    <a style={{ fontSize: "14px" }} href="/about">
                      เกี่ยวกับ NTAQHI
                    </a>
                  </li>
                  <li>
                    <a style={{ fontSize: "14px" }} href="/privacy">
                      PRIVACY
                    </a>
                  </li>
                </ul>
              </div>
            </Toolbar>

            {toggle &&
              <ul className="list-group">
                <li>
                  <a href="/">หน้าหลัก</a>
                </li>
                <li>
                  <a href="/safezone">ห้องปลอดฝุ่น</a>
                </li>
                <li>
                  <a href="/cal">ผลกระทบต่อสุขภาพ</a>
                </li>
                <li>
                  <a href="/qa">คำถาม-คำตอบ</a>
                </li>
                <li>
                  <a href="/olddata">ข้อมูลย้อนหลัง</a>
                </li>
                <li>
                  <a href="/calibration">สอบเทียบข้อมูล</a>
                </li>
                <li>
                  <a href="/infographic">Infographic</a>
                </li>
                <li>
                  <a href="/about">เกี่ยวกับ NTAQHI</a>
                </li>
                <li>
                  <a href="/privacy">PRIVACY</a>
                </li>
              </ul>}
          </AppBar>
        </HideOnScroll>
      </Box>
    </div>
  );
};

export default Navbar;
