import React from "react";

const Listdetail = () => {
  return (
    <React.Fragment>
      <p style={{ fontSize: "1.0em" }}>
        NTAQHI ย่อมาจาก Northern Thailand Air Quality Health Index หรือ
        ดัชนีคุณภาพอากาศเพื่อสุขภาพชาวเหนือ พัฒนาต่อมาจาก CMAQHI ซึ่งย่อมาจาก
        ChiangMai Air Quality Health Index หรือ
        ดัชนีคุณภาพอากาศเพื่อสุขภาพชาวเชียงใหม่
      </p>

      <p style={{ fontSize: "1.0em" }}>
        เว็บไซต์ www.ntaqhi.info
        ถูกสร้างขึ้นเพื่อให้ชาวเหนือได้รับรู้คุณภาพอากาศในพื้นที่ทั้งแบบเวลาจริงทุกชั่วโมง
        และแบบค่าเฉลี่ยใน 24 ชม.ที่ผ่านมา พร้อมคำอธิบายระดับดัชนีคุณภาพอากาศ
        และคำเตือนเพื่อลดความเสี่ยงจากผลกระทบของมลพิษอากาศต่อสุขภาพ
      </p>
      <p style={{ fontSize: "1.0em" }}>
        เว็บไซต์นี้และค่าดัชนีคุณภาพอากาศเพื่อสุขภาพชาวเหนือ
        เกิดขึ้นจากความร่วมมือของหลายภาคส่วนโดยมีที่มาตามลำดับเวลาดังนี้
      </p>

      <p style={{ fontSize: "0.9em" }}>
        <ol>
          <li>
            {" "}กลุ่มแพทย์ พยาบาล นักวิชาการ
            และผู้สนใจศึกษาเรื่องคุณภาพอากาศกลุ่มเล็กๆ
            ในมหาวิทยาลัยเชียงใหม่ได้รวมตัวกันพูดคุย แลกเปลี่ยนความรู้
            ความเห็นกันในไลน์กลุ่ม Suandok Air Alert จากไม่ถึง 10
            ท่านจนปัจจุบันมีผู้สนใจเข้าร่วมเกือบ 200 ท่าน
            โดยปรึกษากันถึงผลกระทบต่อสุขภาพของฝุ่นละอองขนาดจิ๋ว (PM2.5)
            ที่มีความเข้มข้นในอากาศสูงในช่วงฤดูหมอกควันในจังหวัดเชียงใหม่ที่เกิดจากการเผาเกษตรในภาคเหนือตอนบน
            เมียนม่าร์ ลาว และจีนตอนล่าง
          </li>
          <li>
            {" "}ในกลุ่มมีความเห็นร่วมกันว่าภาครัฐโดยกรมควบคุมมลพิษ
            ให้คำแนะนำแก่ประชาชนในการป้องกันตนจากปัญหาหมอกควันที่ผ่านมาไม่ถูกต้องเท่าที่ควร
            ทำให้อาจเกิดอันตรายอย่างร้ายแรงต่อสุขภาพของประชาชนชาวเชียงใหม่ได้
            เนื่องจากกรมฯ
            แจ้งดัชนีคุณภาพอากาศโดยใช้ค่าเฉลี่ยรายวันและรายปีเฉพาะค่า PM10
            ซึ่งไม่สามารถสะท้อนระดับมลพิษได้ดีเท่า PM2.5
            แม้สถานีตรวจวัดคุณภาพอากาศที่จังหวัดเชียงใหม่สามารถวัดค่า PM2.5
            ได้มาหลายปีแล้วก็ตาม ยิ่งไปกว่านั้นยังใช้ค่า PM10
            ในการแบ่งระดับดัชนีคุณภาพอากาศที่สูงกว่าที่องค์การอนามัยโลกแนะนำกว่า
            2 เท่า (ค่าเฉลี่ยรายวันกรมฯใช้ค่า PM10 120 มคก./ลบม.
            ในขณะที่องค์การอนามัยโลกใช้ ค่า 50 มคก./ลบม. ส่วนค่าเฉลี่ยรายปี กรมฯ
            ใช้ค่า 50 มคก./ลบม. ในขณะที่องค์การอนามัยโลกใช้ค่า 20 มคก./ลบม.)
            นอกจากนี้กรมฯ ประกาศเตือนแก่สาธารณะโดยใช้ข้อมูลย้อนหลัง 24 ชม.
            เพียงวันละ 1 ครั้ง
            ซึ่งไม่เพียงพอที่ประชาชนจะนำไปใช้ลดความเสี่ยงในการดำเนินกิจกรรมประจำวันได้
            เพราะค่าดัชนีคุณภาพอากาศมีความแปรปรวนระหว่างวันสูง
          </li>
          <li>
            {" "}ทางกลุ่มเห็นว่าสมควรนำค่าดัชนีคุณภาพอากาศของประเทศสหรัฐอเมริกา
            (The United States Environmental Protection Agency หรือ EPA)
            มาใช้เนื่องจากมีหลักฐานเชิงวิชาการกำกับชัดเจนและมีหลายประเทศนำไปใช้
            ศ. ขวัญชัย ศุภรัตน์ภิญโญและทีมงาน IT ของสถาบันวิจัยวิทยาศาสตร์สุขภาพ
            มหาวิทยาลัยเชียงใหม่จึงดำริให้จัดทำเวบไซต์ www.cmaqhi.org
            ขึ้นโดยนำข้อมูล PM2.5 ของ รศ. ดร. พิศิษฐ์ สิงห์ใจ คณะวิทยาศาสตร์
            มหาวิทยาลัยเชียงใหม่ (www.aqith.com)
            ซึ่งดำเนินการติดตั้งจุดตรวจวัดแบบ realtime
            ขึ้นหลายจุดในจังหวัดเชียงใหม่ และในปีแรกได้รับข้อมูลคุณภาพอากาศจาก
            ศูนย์ข้อมูลการเปลี่ยนแปลงสภาพภูมิอากาศ (Climate Change Data Center:
            CCDC) มหาวิทยาลัยเชียงใหม่ (www.cmuccdc.org)
            เพื่อให้สามารถแสดงข้อมูลคุณภาพอากาศได้ครอบคลุมทุกอำเภอในจังหวัดเชียงใหม่อย่างทั่วถึง
          </li>
          <li>
            {" "}ในวันที่ 2 เมษายน 2561 ศ. นพ. ชายชาญ โพธิรัตน์ คณะแพทยศาสตร์
            มหาวิทยาลัยเชียงใหม่
            ได้เป็นตัวแทนไปให้ข้อมูลความรู้เกี่ยวกับผลกระทบของมลพิษหมอกควันต่อสุขภาพชาวเชียงใหม่
            และมาตรฐานขององค์การอนามัยโลก ระดับดัชนีคุณภาพอากาศที่มีมาตรฐานสากล
            พร้อมคำแนะนำในการเตือนประชาชนที่เหมาะสมแก่ท่านผู้ว่าราชการจังหวัดเชียงใหม่
            และทีมงานที่ร่วมประชุมติดตามสถานการณ์เพื่อแก้ไขปัญหาหมอกควันในจังหวัด
            และได้รับคำชี้แนะจากที่ประชุมขอให้เว็บไซต์ www.cmaqhi.org เตือนด้วย
            2 ดัชนีคุณภาพอากาศ ทั้งดัชนีที่คำนวณจากค่า PM10 และค่า PM2.5
            เพื่อให้ความรู้ความเข้าใจแก่ผู้ที่เกี่ยวข้องในช่วงระยะเวลาเปลี่ยนผ่านและเพื่อป้องกันความสับสน
            โดยจะพยายามสร้างความรู้ความเข้าใจและความตระหนักเกี่ยวกับค่า PM2.5
            ให้สังคมรับทราบอย่างกว้างขวางมากขึ้น
          </li>
          <li>
            {" "}เมื่อทางจังหวัดนำโดยท่านผู้ว่าราชการจังหวัดเชียงใหม่
            และตัวแทนของมหาวิทยาลัยเชียงใหม่ ได้ประสานงานกันจนได้ข้อสรุปร่วมกัน
            ในการเริ่มประกาศใช้ดัชนีคุณภาพอากาศเพื่อสุขภาพชาวเชียงใหม่ในวันที่
            12 เมษายน 2561 เป็นต้นมา
          </li>
          <li>
            {" "}ในปี พ.ศ. 2562 มหาวิทยาลัยเชียงใหม่
            ได้สนับสนุนงบประมาณในโครงการ "คืนลมหายใจบริสุทธ์ให้ชาวเชียงใหม่"
            ในการติดตั้งเครื่องตรวจวัดคุณภาพอากาศที่ใช้เซนเซอร์ขนาดเล็กให้ครบ
            205 ตำบลทั่วจังหวัดเชียงใหม่
            เพื่อสร้างเครือข่ายระบบเฝ้าระวังและแจ้งเตือนประชาชนจากผลกระทบของปัญหาหมอกควันต่อสุขภาพอย่างทั่วถึงทั้งในเขตเมืองและชนบทเป็นจังหวัดแรกในประเทศไทย
          </li>
          <li>
            {" "}เดือนกุมภาพันธ์ 2562 ได้รับความอนุเคราะห์จาก รศ. ดร. พานิช
            อินต๊ะ หัวหน้าหน่วยวิจัยสนามไฟฟ้าประยุกต์ในงานวิศวกรรม (RUEE)
            มหาวิทยาลัยเทคโนโลยีราชมงคลล้านนา
            ดอยสะเก็ด​ให้นำเครื่องตรวจวัดคุณภาพอากาศด้วยเซนเซอร์ขนาดเล็กไปวัดเปรียบเทียบกับเครื่องวัดมาตรฐาน​
            teom1405f ที่ได้รับการรับรองคุณภาพจาก​ US EPA และ รศ. ดร.
            พิศิษฐ์นำค่าความชื้นสัมพัทธ์มาปรับค่าของเครื่องวัด​ให้ได้ผลการวัดเปรียบเทียบใกล้เคียง​กับเครื่องวัดมาตรฐาน​
          </li>
          <li>
            {" "}เดือนกุมภาพันธ์ 2564 มหาวิทยาลัยเชียงใหม่
            ได้สนับสนุนงบประมาณในการบำรุงรักษาเครื่องตรวจวัดคุณภาพอากาศทั้ง 205
            เครื่อง
            ทั้งนี้เพื่อให้เครื่องวัดมีมาตรฐานในการตรวจวัดใกล้เคียงกับของเดิม
            มีการปรับพื้นที่ติดตั้งเดิมในจังหวัดเชียงใหม่ให้เหมาะสมมากขึ้น
            รวมทั้งขยายการติดตั้งเครื่องวัดไปใน 40 พื้นที่ของจังหวัดลำพูน
            เชียงราย และแม่ฮ่องสอน
            เพื่อขยายเครือข่ายระบบเฝ้าระวังและแจ้งเตือนประชาชนจากผลกระทบของปัญหาหมอกควันต่อสุขภาพไปยังจังหวัดอื่นในภาคเหนือของประเทศไทยเพิ่มเติมจากจังหวัดเชียงใหม่ซึ่งเป็นจังหวัดนำร่อง
            ดังนั้นจึงเปลี่ยนชื่อจาก CMAQHI (ChiangMai Air Quality Health Index
            หรือ ดัชนีคุณภาพอากาศเพื่อสุขภาพชาวเชียงใหม่) เป็น NTAQHI (Northern
            Thailand Air Quality Health Index หรือ
            ดัชนีคุณภาพอากาศเพื่อสุขภาพชาวเหนือ)
            ซึ่งในอนาคตจะได้ขยายพื้นที่ออกไปให้ครอบคลุมทุกจังหวัดภาคเหนือต่อไป
          </li>
          <li>
            ปี พ.ศ. 2565 มหาวิทยาลัยเชียงใหม่ ร่วมกับสภาลมหายใจภาคเหนือและบริษัท
            THAIPBS
            ได้สนับสนุนงบประมาณในการจัดหาและติดตั้งเครื่องตรวจวัดคุณภาพอากาศจำนวน
            40 เครื่อง เพื่อขยายพื้นที่การติดตั้งเครื่องวัดไปในจังหวัดลำปาง แพร่
            น่าน พะเยา และตาก ทำให้เครือข่าย NTAQHI ครอบคลุมครบทั้ง 9
            จังหวัดภาคเหนือตอนบนที่ประสบปัญหาหมอกควันรุนแรงที่สุดในประเทศมานานกว่า
            20 ปี
            ซึ่งในอนาคตจะได้ขยายพื้นที่ออกไปให้ครอบคลุมทุกจังหวัดในภาคเหนือต่อไป
          </li>
          <li>
            {" "}ปี พ.ศ. 2566
            ได้รับงบประมาณสนับสนุนจากมูลนิธิเวชดุสิตในพระอุปถัมภ์สมเด็จพระเจ้าพี่นางเธอเจ้าฟ้ากัลยาณิวัฒนากรมหลวงนราธิวาสราชนครินทร์
            ในการจัดทำคลีนรูมโดยใช้ชื่อโครงการคลีนรูมเพื่อเด็กน้อย
            ทำห้องปลอดฝุ่นในศูนย์พัฒนาเด็กเล็กในพื้นที่จังหวัดลำพูน ลำปาง
            เชียงใหม่ และเชียงราย จำนวน 21 ศูนย์ รวม 43 ห้อง เด็กๆ
            ที่ได้รับประโยชน์จากโครงการทั้งสิ้น 629 คน
          </li>
        </ol>
      </p>
    </React.Fragment>
  );
};

export default Listdetail;
