import React, { useState, useEffect } from "react";
import axios from "axios";

import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";

import moment from "moment";
import "moment/locale/th";

import SearchIcon from "@mui/icons-material/Search";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import { Icon } from "@iconify/react";

import {
  Box,
  TextField,
  Autocomplete,
  Button,
  Backdrop,
  CircularProgress
} from "@mui/material";

import Swal from "sweetalert2";

import * as xlsx from "xlsx";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

import "./css/setImg.css";

const TdataAir3 = () => {
  let xURL = "";
  xURL = "https://www2.ntaqhi.info/api/";

  const [deviceName, setdeviceName] = useState([""]);
  const [value, setValue] = useState([]);

  const [dgdataSource, setdgdataSource] = useState([]);
  const [dataChart, setdataChart] = useState([]);

  const [xHeight, setxHeight] = useState(523);

  const [open, setOpen] = useState(false);
  const [show, setshow] = useState(false);

  useEffect(() => {
    getDevice();
  }, []);

  const bclearClick = () => {
    window.location.reload(false);
  };

  const getDevice = () => {
    axios
      .get(xURL + "getdevice2")
      .then(result => {
        //console.log(result.data);
        const Tb1 = [];

        result.data.forEach((i, idx) => {
          Tb1.push({
            label: i.name,
            cmaqhi_device_id: i.cmaqhi_device_id
          });
        });

        setdeviceName(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const excelclick = () => {
    let xdata = [];
    let header = ["ปี", "PM2.5 เฉลี่ย 24 ชม. (มคก./ลบม.)"];

    xdata.push(["ข้อมูลย้อนหลัง (กรมควบคุมมลพิษ)"]);
    xdata.push(["จุดตรวจ " + value.label]);
    xdata.push([""]);
    xdata.push(header);

    for (var row in dgdataSource) {
      const xx = [];

      for (var col in dgdataSource[row]) {
        xx.push(dgdataSource[row][col]);
      }

      xdata.push(xx);
    }

    var wb = xlsx.utils.book_new(),
      ws = xlsx.utils.json_to_sheet(xdata, { skipHeader: true });

    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    xlsx.writeFile(
      wb,
      "ข้อมูลย้อนหลัง PM2.5 (รายปี) เฉลี่ย 24 ชม. (มคก./ลบม.).xlsx"
    );
  };

  const handleSubmit = event => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (!value.label || value.label == null) {
      Swal.fire({
        title: "ค้นหาไม่ได้!",
        text: "ยังไม่ได้เลือก สถานีตรวจวัด",
        icon: "warning",
        showConfirmButton: false,
        timer: 2000,
        customClass: {
          container: "my-swal"
        }
      });
    } else {
      getData();
    }
  };

  const getData = () => {
    setOpen(!open);

    axios
      .post(xURL + "getpmAir3", {
        entity: value.label.slice(-4).substr(0, 3),
        order: 1
      })
      .then(result => {
        // console.log(result.data);

        const Tb1 = [],
          Tb2 = [];
        let barColors = "";
        let htable = 0;

        let xcheck = 0;
        result.data.forEach((i, idx) => {
          //bug null row 0
          if (
            value.label.slice(-4).substr(0, 3) == "o29" ||
            // value.label.slice(-4).substr(0, 3) == "o65" ||
            value.label.slice(-4).substr(0, 3) == "o28" ||
            value.label.slice(-4).substr(0, 3) == "86t" ||
            value.label.slice(-4).substr(0, 3) == "36t"
          ) {
            if (Math.round(i.pm2_5) <= 5) {
              barColors = "#00e400";
            } else if (Math.round(i.pm2_5) > 5) {
              barColors = "#ff0000";
            }

            Tb2.push({
              dates: moment(i.ts_h).format("YYYY"),
              pm25: Math.round(i.pm2_5),
              fill: barColors
            });

            Tb1.push({
              dates: moment(i.ts_h).format("YYYY"),
              pm25: Math.round(i.pm2_5)
            });

            htable = htable + 40;
          } else {
            if (idx > 0) {
              if (Math.round(i.pm2_5) <= 5) {
                barColors = "#00e400";
              } else if (Math.round(i.pm2_5) > 5) {
                barColors = "#ff0000";
              }

              Tb2.push({
                dates: moment(i.ts_h).format("YYYY"),
                pm25: Math.round(i.pm2_5),
                fill: barColors
              });

              Tb1.push({
                dates: moment(i.ts_h).format("YYYY"),
                pm25: Math.round(i.pm2_5)
              });

              htable = htable + 40;
            }
          }
        });

        axios
          .post(xURL + "getpmAir3", {
            entity: value.label.slice(-4).substr(0, 3),
            order: 2
          })
          .then(result2 => {
            result2.data.forEach((i, idx) => {
              // if (Math.round(i.pm2_5) <= 12) {
              //   barColors = "#00e400";
              // } else if (
              //   Math.round(i.pm2_5) > 12 &&
              //   Math.round(i.pm2_5) <= 35
              // ) {
              //   barColors = "#ffff00";
              // } else if (
              //   Math.round(i.pm2_5) > 35 &&
              //   Math.round(i.pm2_5) <= 55
              // ) {
              //   barColors = "#ff7e00";
              // } else if (
              //   Math.round(i.pm2_5) > 55 &&
              //   Math.round(i.pm2_5) <= 150
              // ) {
              //   barColors = "#ff0000";
              // } else if (
              //   Math.round(i.pm2_5) > 150 &&
              //   Math.round(i.pm2_5) <= 250
              // ) {
              //   barColors = "#99004c";
              // } else if (Math.round(i.pm2_5) > 250) {
              //   barColors = "#4c0026";
              // }

              if (Math.round(i.pm2_5) <= 5) {
                barColors = "#00e400";
              } else if (Math.round(i.pm2_5) > 5) {
                barColors = "#ff0000";
              }

              Tb2.push({
                dates: moment(i.ts_h).format("YYYY"),
                pm25: Math.round(i.pm2_5),
                fill: barColors
              });

              Tb1.push({
                dates: moment(i.ts_h).format("YYYY"),
                pm25: Math.round(i.pm2_5)
              });

              htable = htable + 40;
            });

            // console.log(Tb1);
            if (Tb1 == "") {
              Swal.fire({
                title: "ไม่มีข้อมูลตามเงื่อนไขที่เลือก!",
                text: ";(",
                icon: "warning",
                showConfirmButton: false,
                timer: 2000,
                customClass: {
                  container: "my-swal"
                }
              });

              setOpen(false);
            } else {
              setshow(true);

              setxHeight(40 + htable);
              setdgdataSource(Tb1);
              setdataChart(Tb2);

              setOpen(false);
            }
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const dgcolumns = [
    {
      name: "dates",
      header: "ปี",
      maxWidth: 400,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false
    },
    {
      name: "pm25",
      header: "PM2.5 เฉลี่ย 24 ชม. (มคก./ลบ.ม.)",
      maxWidth: 750,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false
    }
  ];

  const gridStyle = { minHeight: xHeight };

  return (
    <React.Fragment>
      <p>
        หน้านี้สำหรับการสืบค้นข้อมูล PM2.5 เฉลี่ยรายปี
        จากจุดตรวจของกรมควบคุมมลพิษ ณ สถานีตรวจวัดใน 9 จังหวัดภาคเหนือตอนบน
      </p>
      <p>เลือกสถานีตรวจวัด ที่ต้องการค้นข้อมูล แล้วกดปุ่มค้นหา</p>

      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <Autocomplete
          disablePortal
          name="entity"
          id="combo-box-demo"
          size="small"
          options={deviceName}
          sx={{ width: 310 }}
          renderInput={params => <TextField {...params} label="สถานีตรวจวัด" />}
          onChange={(event, newValue) => {
            if (newValue) {
              setValue(newValue);
            } else {
              setValue("");
            }
          }}
        />

        <br />
        <Button
          style={{ fontFamily: "kanit" }}
          type="submit"
          variant="contained"
          sx={{ mt: 1, mr: 1, width: 150 }}
          startIcon={<SearchIcon />}
        >
          ค้นหา
        </Button>

        <Button
          style={{ fontFamily: "kanit" }}
          variant="contained"
          color="error"
          sx={{ mt: 1, width: 150 }}
          startIcon={<CleaningServicesIcon />}
          onClick={() => {
            bclearClick();
          }}
        >
          เคลียร์
        </Button>
      </Box>

      <br />

      {show
        ? <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "19px"
              }}
            >
              <p>กราฟแสดง PM2.5 เฉลี่ยรายปี (มคก./ลบ.ม.)</p>
            </div>
            <ResponsiveContainer width="100%" height={480}>
              <BarChart
                data={dataChart}
                margin={{ top: 20, right: 20, bottom: 40, left: 20 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="dates"
                  textAnchor="end"
                  sclaeToFit="true"
                  verticalAnchor="start"
                  interval={0}
                  angle="-45"
                  stroke="#8884d8"
                  tick={{ fontSize: 13 }}
                />
                <YAxis />
                <Tooltip />

                <Legend wrapperStyle={{ top: 460 }} height={36} />
                <Bar
                  name="PM2.5"
                  dataKey="pm25"
                  legendType="circle"
                  fill="#00a0fc"
                />
              </BarChart>
            </ResponsiveContainer>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "16px",
                color: "#F31E4E"
                // fontWeight: "bold"
              }}
            >
              <p>
                ตามมาตรฐานขององค์การอนามัยโลก ค่า PM2.5 เฉลี่ยรายปีไม่ควรเกิน 5
                มคก./ลบม.
              </p>
            </div>

            <br />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "19px"
              }}
            >
              <p>ตารางแสดง PM2.5 เฉลี่ยรายปี (มคก./ลบ.ม.)</p>
            </div>
            <ReactDataGrid
              idProperty="id"
              columns={dgcolumns}
              dataSource={dgdataSource}
              style={gridStyle}
              // showZebraRows={false}
              enableClipboard
            />
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                justifyContent: "flex-end",
                marginLeft: "auto",
                marginRight: 0
              }}
            >
              <Button
                style={{ textTransform: "none", fontFamily: "kanit" }}
                variant="text"
                size="small"
                sx={{ width: 120 }}
                startIcon={<Icon icon="vscode-icons:file-type-excel2" />}
                onClick={() => {
                  excelclick();
                }}
              >
                Export Excel
              </Button>
            </div>
          </div>
        : <div />}

      <Backdrop
        sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
};

export default TdataAir3;
