import React from "react";

import Container from "@mui/material/Container";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

import Header from "./Header";
import Listdetail from "./Listdetail";

const BeyondAQI = () => {
  return (
    <React.Fragment>
      <Navbar />

      <Container>
        <div style={{ marginTop: "80px" }} />

        <Header />

        <div className="lineLeft">
          <Listdetail />
        </div>
      </Container>

      <Footer />
    </React.Fragment>
  );
};

export default BeyondAQI;
