import React from "react";

const Advice = () => {
  return (
    <React.Fragment>
      <div
        style={{
          border: "1px solid #B3B6B7",
          borderRadius: "5px",
          padding: "1%",
          marginTop: "-50px",
          marginBottom: "-30px"
        }}
      >
        <h2
          style={{
            textAlign: "center",
            color: "#717D7E",
            fontWeight: "normal",
            marginTop: "-3px"
          }}
        >
          คำแนะนำในการป้องกันมลพิษทางอากาศ
        </h2>

        <table
          style={{
            width: "100%",
            textAlign: "center",
            marginTop: "-10px"
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  textAlign: "center",
                  width: "125px",
                  backgroundColor: "#ECF0F1",
                  color: "#7B7D7D"
                }}
              >
                AQHI<br />(PM<sub>2.5</sub> 24 ชม.)
              </th>
              <th
                style={{
                  textAlign: "center",
                  width: "125px",
                  backgroundColor: "#ECF0F1",
                  color: "#7B7D7D"
                }}
              >
                PM<sub>2.5</sub> 24 ชม.<br />(มคก./ลบ.ม.)
              </th>
              <th
                style={{
                  textAlign: "center",
                  width: "125px",
                  backgroundColor: "#ECF0F1",
                  color: "#7B7D7D"
                }}
              >
                ประชาชนในกลุ่มเสี่ยง
              </th>
              <th
                style={{
                  textAlign: "center",
                  width: "125px",
                  backgroundColor: "#ECF0F1",
                  color: "#7B7D7D"
                }}
              >
                ประชาชนทั่วไป
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <th
                style={{
                  textAlign: "center",
                  backgroundColor: "#12bb11",
                  color: "black"
                }}
              >
                อากาศดี<br />0-50
              </th>
              <td
                style={{
                  textAlign: "center",
                  backgroundColor: "#12bb11",
                  color: "black"
                }}
              >
                0-12
              </td>
              <td
                style={{
                  textAlign: "center",
                  backgroundColor: "#12bb11",
                  color: "black"
                }}
                colspan="2"
              >
                ประชาชนทุกคนใช้ชีวิตได้ตามปกติ
              </td>
            </tr>
            <tr>
              <th
                style={{
                  textAlign: "center",
                  backgroundColor: "yellow",
                  color: "black"
                }}
              >
                ปานกลาง<br />51-100
              </th>
              <td
                style={{
                  textAlign: "center",
                  backgroundColor: "yellow",
                  color: "black"
                }}
              >
                13-35
              </td>
              <td
                style={{
                  textAlign: "center",
                  backgroundColor: "yellow",
                  color: "black"
                }}
                colspan="2"
              >
                ใช้ชีวิตได้ตามปกติ <br />(ผู้ที่มีความไวต่อมลพิษมากกว่าคนทั่วไป{" "}
                <br />ควร<u>ลด</u>กิจกรรมนอกอาคารที่ใช้แรง<u>หนัก</u>หรือ<u>เป็นเวลานาน</u>)
              </td>
            </tr>
            <tr>
              <th
                style={{
                  textAlign: "center",
                  backgroundColor: "#ff7e00",
                  color: "black"
                }}
              >
                {" "}ไม่ดีต่อกลุ่มเสี่ยง<br />101-150
              </th>
              <td
                style={{
                  textAlign: "center",
                  backgroundColor: "#ff7e00",
                  color: "black"
                }}
              >
                36-55
              </td>
              <td
                style={{
                  textAlign: "center",
                  backgroundColor: "#ff7e00",
                  color: "black"
                }}
              >
                <u>ลด</u>กิจกรรมนอกอาคารที่ใช้แรง<u>หนัก</u> หรือ<u>เป็นเวลานาน</u>
              </td>
              <td
                style={{
                  textAlign: "center",
                  backgroundColor: "#ff7e00",
                  color: "black"
                }}
              >
                ใช้ชีวิตได้ตามปกติ
              </td>
            </tr>
            <tr>
              <th
                style={{
                  textAlign: "center",
                  backgroundColor: "red",
                  color: "white"
                }}
              >
                อากาศไม่ดี<br />151-200
              </th>
              <td
                style={{
                  textAlign: "center",
                  backgroundColor: "red",
                  color: "white"
                }}
              >
                56-150
              </td>
              <td
                style={{
                  textAlign: "center",
                  backgroundColor: "red",
                  color: "white"
                }}
              >
                <u>งด</u>กิจกรรมนอกอาคารที่ใช้แรง<u>หนัก</u> หรือ<u>เป็นเวลานาน</u>
              </td>
              <td
                style={{
                  textAlign: "center",
                  backgroundColor: "red",
                  color: "white"
                }}
              >
                <u>ลด</u>กิจกรรมนอกอาคารที่ใช้แรง<u>หนัก</u> หรือ<u>เป็นเวลานาน</u>
              </td>
            </tr>
            <tr>
              <th
                style={{
                  textAlign: "center",
                  backgroundColor: "#99004c",
                  color: "white"
                }}
              >
                ไม่ดีอย่างยิ่ง<br />201-300
              </th>
              <td
                style={{
                  textAlign: "center",
                  backgroundColor: "#99004c",
                  color: "white"
                }}
              >
                151-250
              </td>
              <td
                style={{
                  textAlign: "center",
                  backgroundColor: "#99004c",
                  color: "white"
                }}
              >
                <u>งด</u>กิจกรรมนอกอาคารทุกชนิด
              </td>
              <td
                style={{
                  textAlign: "center",
                  backgroundColor: "#99004c",
                  color: "white"
                }}
              >
                <u>งด</u>กิจกรรมนอกอาคารที่ใช้แรง<u>หนัก</u> หรือ<u>เป็นเวลานาน</u>
              </td>
            </tr>
            <tr>
              <th
                style={{
                  textAlign: "center",
                  backgroundColor: "#4c0026",
                  color: "white"
                }}
              >
                อันตราย<br />301-500
              </th>
              <td
                style={{
                  textAlign: "center",
                  backgroundColor: "#4c0026",
                  color: "white"
                }}
              >
                251-500
              </td>
              <td
                style={{
                  textAlign: "center",
                  backgroundColor: "#4c0026",
                  color: "white"
                }}
                colspan="2"
              >
                <u>งด</u>กิจกรรมนอกอาคารทุกชนิด
              </td>
            </tr>
          </tbody>
        </table>

        <p style={{ color: "#717D7E", fontSize: "14px", marginTop: "5px" }}>
          <strong>
            <u style={{ color: "black" }}>หมายเหตุ</u>
          </strong>
          <br />
          <ol style={{ marginTop: "0px" }}>
            <li>
              ใช้แถบสี ช่วงของ PM2.5 และคำแนะนำ ตามแนวทางของ{" "}
              <a
                href="https://www.airnow.gov/aqi/aqi-basics/"
                class="text-success"
                target="_blank"
              >
                US AQI
              </a>
            </li>
            <li>
              กลุ่มเสี่ยง ได้แก่ เด็ก วัยรุ่น ผู้สูงอายุ สตรีมีครรภ์
              ผู้ที่มีโรคระบบทางเดินหายใจ โรคหัวใจ และโรคหลอดเลือดสมอง
            </li>
            <li>
              ค่าดัชนีเกิน 500 ถือว่าเกินกว่าที่จะรายงานได้{" "}
              <a href="/BeyondAQI" class="text-success" target="_blank">
                อ่านเพิ่มเติมที่นี่
              </a>
            </li>
          </ol>
        </p>
      </div>
    </React.Fragment>
  );
};

export default Advice;
