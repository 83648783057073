import React from "react";

import "./css/Aqhi_now.css";

import { Container } from "@mui/material";

import logo from "../../imgs/NTAQHI_small2.png";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Maindata from "./Maindata";
import Advice from "../../components/Advice";

import CookieConsent from "react-cookie-consent";

const Aqhi_now = () => {
  return (
    <React.Fragment>
      <Navbar />

      <div style={{ marginTop: "70px" }} />

      <Container>
        <div className="head-title">
          <a
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
            href="/"
          >
            <img src={logo} alt="CMAQHI" width="140" />
          </a>

          <p className="title">
            Northern Thailand Air Quality Health Index
            {/* <span style={{ color: "#8D918D" }}> (NTAQHI)</span> <br /> */}
            <p
              style={{
                textAlign: "center",
                fontSize: "22px",
                marginTop: "0px",
                color: "#9e76b4",
                fontWeight: "100"
              }}
            >
              ดัชนีคุณภาพอากาศเพื่อสุขภาพชาวเหนือ
            </p>
          </p>
        </div>
      </Container>

      <Maindata />

      <br />
      <Container>
        <Advice />
      </Container>

      <Footer />

      <CookieConsent
        location="bottom"
        buttonText="ยอมรับ"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B", opacity: 0.9 }}
        buttonStyle={{
          color: "#4e503b",
          fontSize: "16px",
          fontFamily: "Kanit"
        }}
        expires={150}
      >
        <p style={{ textAlign: "center", fontSize: "15px" }}>
          ntaqhi.info ใช้คุกกี้ เพื่อจัดการข้อมูลส่วนบุคคล
          และพัฒนาประสบการณ์การใช้งานให้กับผู้ใช้ เงื่อนไขการใช้งานเว็บไซต์ และ
          นโยบายสิทธิส่วนบุคคล{" "}
          <a style={{ color: "white" }} href="/privacy">
            Privacy policy{" "}
          </a>
        </p>
      </CookieConsent>
    </React.Fragment>
  );
};

export default Aqhi_now;
