import React, { useEffect, useState } from "react";
import axios from "axios";

import { Container } from "@mui/material";

import moment from "moment";
import "moment/locale/th";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Highcharts_realtime from "./Highcharts_realtime";

import "./css/realtime.css";

const Realtime_rihes = () => {
  let xURL = "";
  xURL = "https://www2.ntaqhi.info/api/";

  const [dateRealtime, setdateRealtime] = useState([]);
  const [dataRealtime, setdataRealtime] = useState([]);
  const [dataRealtime2, setdataRealtime2] = useState([]);

  const [AvrPM25_1, setAvrPM25_1] = useState();
  const [AvrPM25_2, setAvrPM25_2] = useState();
  const [AvrAQHI_1, setAvrAQHI_1] = useState();
  const [AvrAQHI_2, setAvrAQHI_2] = useState();

  const [MaxPM25_1, setMaxPM25_1] = useState();
  const [MaxPM25_2, setMaxPM25_2] = useState();
  const [MinPM25_1, setMinPM25_1] = useState();
  const [MinPM25_2, setMinPM25_2] = useState();

  const [MaxAQHI_1, setMaxAQHI_1] = useState();
  const [MaxAQHI_2, setMaxAQHI_2] = useState();
  const [MinAQHI_1, setMinAQHI_1] = useState();
  const [MinAQHI_2, setMinAQHI_2] = useState();

  useEffect(() => {
    getDevice();
  }, []);

  setInterval(() => {
    if (moment().format("ss") == "00") {
      getDevice();
    }
  }, 1000);

  const getDevice = () => {
    axios
      .get(xURL + "getDevice_rihes")
      .then(result => {
        getRealtime(result.data);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const getRealtime = divices => {
    let device502 = divices[1].device_id,
      device501 = divices[0].device_id;

    // console.log(device206, device501);

    let dateed = moment()
      .add(-8, "hours")
      .add(10, "minutes")
      .format("YYYY-MM-DD HH:mm");
    let dateend = (Math.round(new Date(dateed).getTime() / 1000) - 600) * 1000;

    let dateed2 = moment().add(10, "minutes").format("YYYY-MM-DD HH:mm");
    let dateend2 =
      (Math.round(new Date(dateed2).getTime() / 1000) - 600) * 1000;

    // ค่าเฉลี่ย 08.00-18.00
    let dateed3 = moment().format("YYYY-MM-DD 08:10:00");
    let dateend3 =
      (Math.round(new Date(dateed3).getTime() / 1000) - 600) * 1000;

    let dateed4 = moment().format("YYYY-MM-DD 18:10:00");
    let dateend4 =
      (Math.round(new Date(dateed4).getTime() / 1000) - 600) * 1000;

    // console.log(dateed, dateed2);

    axios
      .post(xURL + "getrealtime_rihes", {
        device1: device502,
        device2: device501,
        sdate: dateend,
        edate: dateend2
      })
      .then(result => {
        // console.log(result.data.rows);
        if (result.data.rows.length > 0) {
          const Tb1 = [],
            Tb2 = [],
            Tbtime = [];

          result.data.rows.forEach((i, idx) => {
            if (i.entity_id == device502) {
              // Tb1.push(Math.round(i.valuepm * 1.35));
              Tb1.push(parseInt(i.valuepm));
              // Tbtime.push(moment(i.ts_m).format("LT"));
            }

            if (i.entity_id == device501) {
              Tb2.push(parseInt(i.valuepm));
              Tbtime.push(moment(i.ts_m).format("LT"));
            }
          });

          setdataRealtime(Tb1);
          setdataRealtime2(Tb2);

          setdateRealtime(Tbtime);
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    axios
      .post(xURL + "getavr_rihes", {
        device1: device502,
        device2: device501,
        sdate: dateend3,
        edate: dateend4
      })
      .then(result => {
        if (result.data.rows.length > 0) {
          let xpmIndoor = 0,
            xmax = 0,
            xmin = 0;

          result.data.rows.forEach((i, idx) => {
            if (i.entity_id == device502) {
              // xpmIndoor = Math.round(i.valuepm * 1.35);
              // xmax = Math.round(i.valuemax * 1.35);
              // xmin = Math.round(i.valuemin * 1.35);

              xpmIndoor = i.valuepm;
              xmax = i.valuemax;
              xmin = i.valuemin;
            } else {
              xpmIndoor = i.valuepm;
              xmax = i.valuemax;
              xmin = i.valuemin;
            }

            if (i.entity_id == device502) {
              setAvrPM25_2(
                <a
                  style={{
                    padding: "5px",
                    margin: "0 5px",
                    borderRadius: "5px"
                  }}
                >
                  {xpmIndoor}
                </a>
              );
              setAvrAQHI_2(
                <a
                  style={{
                    backgroundColor: AqhiCal(xpmIndoor).color,
                    color: AqhiCal(xpmIndoor).color_cha,
                    padding: "5px",
                    margin: "0 5px",
                    borderRadius: "5px"
                  }}
                >
                  {AqhiCal(xpmIndoor).aqhi}
                </a>
              );

              setMaxPM25_2(xmax);
              setMinPM25_2(xmin);

              setMaxAQHI_2(
                <a
                  style={{
                    backgroundColor: AqhiCal(xmax).color,
                    color: AqhiCal(xmax).color_cha,
                    padding: "5px",
                    margin: "0 5px",
                    borderRadius: "5px"
                  }}
                >
                  {AqhiCal(xmax).aqhi}
                </a>
              );

              setMinAQHI_2(
                <a
                  style={{
                    backgroundColor: AqhiCal(xmin).color,
                    color: AqhiCal(xmin).color_cha,
                    padding: "5px",
                    margin: "0 5px",
                    borderRadius: "5px"
                  }}
                >
                  {AqhiCal(xmin).aqhi}
                </a>
              );
            }

            if (i.entity_id == device501) {
              setAvrPM25_1(
                <a
                  style={{
                    padding: "5px",
                    margin: "0 5px",
                    borderRadius: "5px"
                  }}
                >
                  {xpmIndoor}
                </a>
              );

              setAvrAQHI_1(
                <a
                  style={{
                    backgroundColor: AqhiCal(xpmIndoor).color,
                    color: AqhiCal(xpmIndoor).color_cha,
                    padding: "5px",
                    margin: "0 5px",
                    borderRadius: "5px"
                  }}
                >
                  {AqhiCal(xpmIndoor).aqhi}
                </a>
              );

              setMaxPM25_1(xmax);
              setMinPM25_1(xmin);

              setMaxAQHI_1(
                <a
                  style={{
                    backgroundColor: AqhiCal(xmax).color,
                    color: AqhiCal(xmax).color_cha,
                    padding: "5px",
                    margin: "0 5px",
                    borderRadius: "5px"
                  }}
                >
                  {AqhiCal(xmax).aqhi}
                </a>
              );

              setMinAQHI_1(
                <a
                  style={{
                    backgroundColor: AqhiCal(xmin).color,
                    color: AqhiCal(xmin).color_cha,
                    padding: "5px",
                    margin: "0 5px",
                    borderRadius: "5px"
                  }}
                >
                  {AqhiCal(xmin).aqhi}
                </a>
              );
            }
          });
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  function AqhiCal(pm25) {
    let xaqhi = "",
      xcolor = "",
      xcolor_cha = "";

    if (pm25 <= 12) {
      xaqhi = Math.round(4.1857 * parseInt(pm25) + 0.0524).toString();
    } else if (pm25 > 12 && pm25 <= 35) {
      xaqhi = Math.round(2.1 * parseInt(pm25) + 25.7).toString();
    } else if (pm25 > 35 && pm25 <= 55) {
      xaqhi = Math.round(2.4762 * parseInt(pm25) + 13).toString();
    } else if (pm25 > 55 && pm25 <= 150) {
      xaqhi = Math.round(0.5161 * parseInt(pm25) + 122.1).toString();
    } else if (pm25 > 150 && pm25 <= 205) {
      xaqhi = Math.round(parseInt(pm25) + 50).toString();
    } else if (pm25 > 205 && pm25 <= 350) {
      xaqhi = Math.round(parseInt(pm25) + 50).toString();
    } else if (pm25 > 305 && pm25 <= 500) {
      xaqhi = Math.round(0.667 * parseInt(pm25) + 167).toString();
    } else if (pm25 > 500) {
      xaqhi = "Beyond AQHI";
    } else {
      xaqhi = "-";
    }

    if (xaqhi >= 0 && xaqhi <= 50) {
      xcolor = "#00e400";
      xcolor_cha = "#000000";
    } else if (xaqhi > 50 && xaqhi <= 100) {
      xcolor = "#ffff00";
      xcolor_cha = "#000000";
    } else if (xaqhi > 100 && xaqhi <= 150) {
      xcolor = "#ff7e00";
      xcolor_cha = "#000000";
    } else if (xaqhi > 150 && xaqhi <= 200) {
      xcolor = "#ff0000";
      xcolor_cha = "#ffffff";
    } else if (xaqhi > 200 && xaqhi <= 300) {
      xcolor = "#99004c";
      xcolor_cha = "#ffffff";
    } else if (xaqhi > 300 && xaqhi <= 500) {
      xcolor = "#4c0026";
      xcolor_cha = "#ffffff";
    } else {
      xcolor = "#4c0026";
      xcolor_cha = "#ffffff";
    }

    return { aqhi: xaqhi, color: xcolor, color_cha: xcolor_cha };
  }

  return (
    <React.Fragment>
      <div style={{ marginBottom: "100px" }} />

      <Navbar />

      <Container>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <h3
            className="lineUp"
            style={{ fontSize: "28px", color: "#009874", fontWeight: "100" }}
          >
            คุณภาพอากาศภายในอาคาร 1 สถาบันวิจัยวิทยาศาสตร์สุขภาพ
          </h3>
        </div>

        <p
          style={{ textAlign: "center", marginTop: "-30px", fontSize: "18px" }}
        >
          วันที่ {moment().add(543, "year").format("D MMMM YYYY")}
        </p>
        <Highcharts_realtime
          dateRealtime={dateRealtime}
          dataRealtime1={dataRealtime}
          dataRealtime2={dataRealtime2}
        />

        <table className="realtime">
          <thead>
            <tr style={{ width: "400px", height: "50px" }}>
              <th style={{ border: "1px solid black" }} colSpan={2}>
                คุณภาพอากาศช่วง 08.00-18.00 น.
              </th>
              <th
                style={{
                  width: "200px",
                  border: "1px solid black"
                }}
              >
                สูงสุด
              </th>
              <th
                style={{
                  width: "200px",
                  border: "1px solid black"
                }}
              >
                ต่ำสุด
              </th>
              <th
                style={{
                  width: "200px",
                  border: "1px solid black"
                }}
              >
                เฉลี่ย
              </th>
            </tr>
          </thead>

          <tbody>
            <tr style={{ height: "45px" }}>
              <th
                style={{
                  textAlign: "left",
                  paddingLeft: "15px",
                  border: "1px solid black"
                }}
                rowSpan={2}
              >
                ภายนอกอาคาร
              </th>
              <td
                style={{
                  textAlign: "left",
                  border: "1px solid black",
                  paddingLeft: "15px"
                }}
              >
                PM2.5 (มคก./ลบม.)
              </td>
              <td
                style={{
                  border: "1px solid black"
                }}
              >
                {MaxPM25_1}
              </td>
              <td
                style={{
                  border: "1px solid black"
                }}
              >
                {MinPM25_1}
              </td>
              <td
                style={{
                  border: "1px solid black"
                }}
              >
                {AvrPM25_1}
              </td>
            </tr>
            <tr style={{ height: "45px" }}>
              <td
                style={{
                  textAlign: "left",
                  border: "1px solid black",
                  paddingLeft: "15px"
                }}
              >
                AQHI
              </td>
              <td
                style={{
                  border: "1px solid black"
                }}
              >
                {MaxAQHI_1}
              </td>
              <td
                style={{
                  border: "1px solid black"
                }}
              >
                {MinAQHI_1}
              </td>
              <td
                style={{
                  border: "1px solid black"
                }}
              >
                {AvrAQHI_1}
              </td>
            </tr>

            <tr style={{ height: "45px" }}>
              <th
                style={{
                  textAlign: "left",
                  paddingLeft: "15px",
                  border: "1px solid black"
                }}
                rowSpan={2}
              >
                ภายในอาคาร
              </th>
              <td
                style={{
                  textAlign: "left",
                  border: "1px solid black",
                  paddingLeft: "15px"
                }}
              >
                PM2.5 (มคก./ลบม.)
              </td>
              <td
                style={{
                  border: "1px solid black"
                }}
              >
                {MaxPM25_2}
              </td>
              <td
                style={{
                  border: "1px solid black"
                }}
              >
                {MinPM25_2}
              </td>
              <td
                style={{
                  border: "1px solid black"
                }}
              >
                {AvrPM25_2}
              </td>
            </tr>
            <tr style={{ height: "45px" }}>
              <td
                style={{
                  textAlign: "left",
                  border: "1px solid black",
                  paddingLeft: "15px"
                }}
              >
                AQHI
              </td>
              <td
                style={{
                  border: "1px solid black"
                }}
              >
                {MaxAQHI_2}
              </td>
              <td
                style={{
                  border: "1px solid black"
                }}
              >
                {MinAQHI_2}
              </td>
              <td
                style={{
                  border: "1px solid black"
                }}
              >
                {AvrAQHI_2}
              </td>
            </tr>
          </tbody>
        </table>

        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <a
            style={{ color: "red" }}
            href={"/realtime_rihes/IAQ"}
            target="_blank"
          >
            ดูข้อมูลย้อนหลัง
          </a>
        </div>

        <div style={{ fontSize: "14px", marginTop: "30px" }}>
          <p>หมายเหตุ เป้าหมายคือ</p>
          <p style={{ marginTop: "-10px" }}>
            1. ค่า PM2.5 เฉลี่ยภายในอาคารในช่วงเวลา 08.00-18.00 น.
            ต้องต่ำกว่าภายนอกเกิน 50%
          </p>
          <p style={{ marginTop: "-10px" }}>
            2. ค่า PM2.5 เฉลี่ยภายในอาคารในช่วงเวลา 08.00-18.00 น. ต้องต่ำกว่า
            35 มคก./ลบม.
          </p>
        </div>
      </Container>

      <Footer />
    </React.Fragment>
  );
};

export default Realtime_rihes;
