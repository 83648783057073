import React, { useState } from "react";

import PropTypes from "prop-types";
import { Container, Tabs, Tab, Box, AppBar } from "@mui/material";

import Chart_year from "./Charts_year";
import Chart_month from "./Chart_month";
import Chart_day from "./Chart_day";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index &&
        <Box sx={{ p: 2 }}>
          {children}
        </Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const Realtime_olddata = () => {
  const [value, setValue] = useState(0);

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Navbar />

      <Container>
        <h3
          style={{
            textAlign: "center",
            marginTop: "100px",
            marginBottom: "50px"
          }}
        >
          ค่า PM2.5 เฉลี่ย 5 เดือน (มกราคม-พฤษภาคม) นอกและในอาคาร 1 (มคก./ลบม.)
        </h3>

        <Chart_year />

        <hr style={{ marginTop: "50px" }} />
        <Box sx={{ width: "100%", mt: 5 }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <AppBar position="static" style={{ background: "#F0F5FF" }}>
              <Tabs value={value} onChange={TabChange} variant="fullWidth">
                <Tab
                  icon={""}
                  label={
                    <span style={{ fontFamily: "kanit" }}>ข้อมูลรายเดือน</span>
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  icon={""}
                  label={
                    <span style={{ fontFamily: "kanit" }}>ข้อมูลรายวัน</span>
                  }
                  {...a11yProps(1)}
                />
              </Tabs>
            </AppBar>
          </Box>

          <TabPanel value={value} index={0}>
            <Chart_month />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Chart_day />
          </TabPanel>
        </Box>

        <div style={{ fontSize: "14px", marginTop: "30px" }}>
          <p>หมายเหตุ เป้าหมายคือ</p>
          <p style={{ marginTop: "-10px" }}>
            1. ค่า PM2.5 เฉลี่ยภายในอาคารในช่วงเวลา 08.00-18.00 น.
            ต้องต่ำกว่าภายนอกเกิน 50%
          </p>
          <p style={{ marginTop: "-10px" }}>
            2. ค่า PM2.5 เฉลี่ยภายในอาคารในช่วงเวลา 08.00-18.00 น. ต้องต่ำกว่า
            35 มคก./ลบม.
          </p>
        </div>
      </Container>

      <Footer />
    </React.Fragment>
  );
};

export default Realtime_olddata;
