import React, { useEffect, useState } from "react";
import axios from "axios";

import moment from "moment";
import "moment/locale/th";

const Descript_firmsnasa = () => {
  let xURL = "";
  xURL = "https://www2.ntaqhi.info/api/";

  const [TextHead_date, setTextHead_date] = useState("");

  const [M_Tha, setM_Tha] = useState(0);
  const [M_Mmr, setM_Mmr] = useState(0);
  const [M_Lao, setM_Lao] = useState(0);

  const [V_Tha, setV_Tha] = useState(0);
  const [V_Mmr, setV_Mmr] = useState(0);
  const [V_Lao, setV_Lao] = useState(0);

  const [V2_Tha, setV2_Tha] = useState(0);
  const [V2_Mmr, setV2_Mmr] = useState(0);
  const [V2_Lao, setV2_Lao] = useState(0);

  useEffect(() => {
    CountNasa();
  }, []);

  function toBuddhistYear(moment, format) {
    var christianYear = moment.format("YYYY");
    var buddhishYear = (parseInt(christianYear) + 543).toString();
    return moment
      .format(
        format
          .replace("YYYY", buddhishYear)
          .replace("YY", buddhishYear.substring(2, 4))
      )
      .replace(christianYear, buddhishYear);
  }

  const CountNasa = () => {
    axios
      .get(xURL + "getFirmsNasa2")
      .then(result => {
        // let xtext = moment(result.data[0].date_last).add(543, "year").format("LLL");
        let xtext = toBuddhistYear(moment(result.data[0].date_last), "LLL");

        xtext = "ค้นข้อมูลวันที่ " + xtext + " น.";

        setTextHead_date(xtext);

        let xM_Lao = 0,
          xM_Mmr = 0,
          xM_Tha = 0,
          xV_Lao = 0,
          xV_Mmr = 0,
          xV_Tha = 0,
          xV2_Lao = 0,
          xV2_Mmr = 0,
          xV2_Tha = 0;

        result.data.forEach((i, idx) => {
          if (i.satellite.substring(0, 1) == "M") {
            if (i.country_id == "LAO") {
              xM_Lao = i.Numcountry.toLocaleString(undefined);
            } else if (i.country_id == "MMR") {
              xM_Mmr = i.Numcountry.toLocaleString(undefined);
            } else if (i.country_id == "THA") {
              xM_Tha = i.Numcountry.toLocaleString(undefined);
            }
          } else if (i.satellite.substring(0, 1) == "V") {
            if (i.satellite.substring(6, 7) == "S") {
              if (i.country_id == "LAO") {
                xV_Lao = i.Numcountry.toLocaleString(undefined);
              } else if (i.country_id == "MMR") {
                xV_Mmr = i.Numcountry.toLocaleString(undefined);
              } else if (i.country_id == "THA") {
                xV_Tha = i.Numcountry.toLocaleString(undefined);
              }
            } else if (i.satellite.substring(6, 7) == "N") {
              if (i.country_id == "LAO") {
                xV2_Lao = i.Numcountry.toLocaleString(undefined);
              } else if (i.country_id == "MMR") {
                xV2_Mmr = i.Numcountry.toLocaleString(undefined);
              } else if (i.country_id == "THA") {
                xV2_Tha = i.Numcountry.toLocaleString(undefined);
              }
            }
          }
        });

        setM_Lao(xM_Lao);
        setM_Mmr(xM_Mmr);
        setM_Tha(xM_Tha);
        setV_Lao(xV_Lao);
        setV_Mmr(xV_Mmr);
        setV_Tha(xV_Tha);
        setV2_Lao(xV2_Lao);
        setV2_Mmr(xV2_Mmr);
        setV2_Tha(xV2_Tha);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  return (
    <React.Fragment>
      <div
        style={{
          border: "1px solid #B3B6B7",
          borderRadius: "5px",
          padding: "1%",
          marginTop: "10px",
          marginBottom: "10px",
          marginLeft: "-20px"
        }}
      >
        <h2
          style={{
            textAlign: "center",
            color: "#717D7E",
            fontWeight: "normal",
            marginTop: "-3px"
          }}
        >
          จำนวนจุดความร้อนจากดาวเทียมของ NASA
        </h2>
        <h3
          style={{
            textAlign: "center",
            fontWeight: "normal",
            marginTop: "-20px"
          }}
        >
          {TextHead_date}
        </h3>

        <table
          style={{
            width: "100%",
            textAlign: "center",
            marginTop: "-10px"
          }}
        >
          <thead style={{ height: "60px" }}>
            <tr>
              <th
                style={{
                  textAlign: "center",
                  width: "125px",
                  backgroundColor: "#4473C5",
                  color: "white"
                }}
              >
                ประเทศ
              </th>
              <th
                style={{
                  textAlign: "center",
                  width: "125px",
                  backgroundColor: "#4473C5",
                  color: "white"
                }}
              >
                ดาวเทียม MODIS (NRT)
              </th>
              <th
                style={{
                  textAlign: "center",
                  width: "125px",
                  backgroundColor: "#4473C5",
                  color: "white"
                }}
              >
                ดาวเทียม S-NPP (NRT)
              </th>
              <th
                style={{
                  textAlign: "center",
                  width: "125px",
                  backgroundColor: "#4473C5",
                  color: "white"
                }}
              >
                ดาวเทียม NOAA-20 (NRT)
              </th>
            </tr>
          </thead>

          <tbody style={{ height: "150px" }}>
            <tr>
              <th
                style={{
                  textAlign: "center",
                  backgroundColor: "#CFD5EB",
                  color: "black"
                }}
              >
                ไทย
              </th>
              <td
                style={{
                  textAlign: "center",
                  backgroundColor: "#CFD5EB",
                  color: "black"
                }}
              >
                {M_Tha}
              </td>
              <td
                style={{
                  textAlign: "center",
                  backgroundColor: "#CFD5EB",
                  color: "black"
                }}
              >
                {V_Tha}
              </td>
              <td
                style={{
                  textAlign: "center",
                  backgroundColor: "#CFD5EB",
                  color: "black"
                }}
              >
                {V2_Tha}
              </td>
            </tr>
            <tr>
              <th
                style={{
                  textAlign: "center",
                  backgroundColor: "#E9ECF5",
                  color: "black"
                }}
              >
                พม่า
              </th>
              <td
                style={{
                  textAlign: "center",
                  backgroundColor: "#E9ECF5",
                  color: "black"
                }}
              >
                {M_Mmr}
              </td>
              <td
                style={{
                  textAlign: "center",
                  backgroundColor: "#E9ECF5",
                  color: "black"
                }}
              >
                {V_Mmr}
              </td>
              <td
                style={{
                  textAlign: "center",
                  backgroundColor: "#E9ECF5",
                  color: "black"
                }}
              >
                {V2_Mmr}
              </td>
            </tr>
            <tr>
              <th
                style={{
                  textAlign: "center",
                  backgroundColor: "#CFD5EB",
                  color: "black"
                }}
              >
                ลาว
              </th>
              <td
                style={{
                  textAlign: "center",
                  backgroundColor: "#CFD5EB",
                  color: "black"
                }}
              >
                {M_Lao}
              </td>
              <td
                style={{
                  textAlign: "center",
                  backgroundColor: "#CFD5EB",
                  color: "black"
                }}
              >
                {V_Lao}
              </td>
              <td
                style={{
                  textAlign: "center",
                  backgroundColor: "#CFD5EB",
                  color: "black"
                }}
              >
                {V2_Lao}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default Descript_firmsnasa;
