import React from "react";
import { Container } from "@mui/material";

import logo from "../../../imgs/NTAQHI_small2.png";

import "./css/Header.css";

const Header = () => {
  return (
    <React.Fragment>
      <Container>
        <div className="head-title-m">
          <a
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
            href="/mobile/home"
          >
            <img src={logo} alt="CMAQHI" width="100" />
          </a>

          <p className="title-m">
            Northern Thailand Air Quality Health Index
            <p className="title-m2">ดัชนีคุณภาพอากาศเพื่อสุขภาพชาวเหนือ</p>
          </p>
        </div>

        <hr className="style1" />
      </Container>
    </React.Fragment>
  );
};

export default Header;
