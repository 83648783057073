import React from "react";

import { Container } from "@mui/material";

import "./css/setImg.css";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Contact from "../../components/Contact";

import Pic_DIY from "../../imgs/make_diy.png";
import Pic_DIY2 from "../../imgs/make_diy2.jpg";

const Make_diy = () => {
  return (
    <React.Fragment>
      <Navbar />

      <Container>
        <div style={{ marginTop: "100px" }} />
        <p style={{ marginTop: "30px", fontWeight: 600 }}>
          การทำเครื่องฟอกอากาศ DIY จากกล่องอากาศดี
        </p>

        <ol>
          <li>คุณสมบัติที่พึงประสงค์ของเครื่องฟอกอากาศ DIY</li>
          <ol>
            <li>
              ใช้แผ่นกรองอากาศที่สามารถกรอง PM2.5 ได้ดี แนะนำให้ใช้แผ่นกรองอากาศ
              HEPA ระดับตั้งแต่ H11 ขึ้นไป (ส่วนใหญ่โรงพยาบาลใช้ระดับ H13 ขึ้นไป
              ซึ่งมีประสิทธิภาพในการกรอง PM2.5 ดีมากแต่ราคาค่อนข้างแพง)
            </li>
            <img
              style={{
                maxWidth: "75%",
                height: "auto",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "25px",
                marginBottom: "25px"
              }}
              className="img-artboard"
              src={Pic_DIY}
              alt="Pic_DIY"
            />

            <li>
              สามารถลดระดับของฝุ่น PM2.5 เฉลี่ยภายในห้องไม่ให้เกิน 35 มคก./ลบม.
              ตลอดเวลาที่เปิดใช้งาน (มาตรฐานคุณภาพอากาศภายในบ้านโดย Indoor Air
              Hygiene Institute คือ ควรมี PM2.5 เฉลี่ยต่ำกว่า 12 มคก./ลบม.
              และไม่ควรมีช่วงเวลาใดที่ PM2.5 เกิน 35 มคก./ลบม.)
            </li>
            <li>พัดลมดูดอากาศมีเสียงรบกวนไม่มากเกินไป</li>
          </ol>
          <li>อุปกรณ์ที่ใช้</li>
          <ol>
            <li>กล่องอากาศดี©</li>
            <li>พัดลมดูดอากาศอินไลน์ทรงกระบอกขนาดเส้นผ่าศูนย์กลาง 8 นิ้ว</li>
            <li>แผ่นกรองอากาศ HEPA ชนิดสี่เหลี่ยม</li>
            <li>เทปกาวต่างๆ</li>
          </ol>
          <li>กลไกการทำงานของเครื่องฟอกอากาศ DIY จากกล่องอากาศดี</li>
          <img
            style={{
              maxWidth: "75%",
              height: "auto",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "25px",
              marginBottom: "25px"
            }}
            className="img-artboard"
            src={Pic_DIY2}
            alt="Pic_DIY2"
          />
          <li>
            วิธีการทำเครื่องฟอกอากาศ DIY จากกล่องอากาศดี
            (กรณีทำเป็นเครื่องฟอกอากาศไม่ต้องต่อท่อลม วางกล่องไว้ในห้องได้เลย)
          </li>

          <div
            style={{ marginTop: "20px", height: "375px", marginBottom: "50px" }}
          >
            <iframe
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto"
              }}
              width="75%"
              height="100%"
              src="https://www.youtube.com/embed/nEH95kPO5AQ?si=84HTOIpMiPHeJbuy"
              title="youtube video"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            />
          </div>

          <li>
            วิธีการทำเครื่องฟอกอากาศ DIY สำหรับห้องขนาดเล็กจากกล่องอากาศดี
          </li>

          <div
            style={{ marginTop: "20px", height: "375px", marginBottom: "50px" }}
          >
            <iframe
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto"
              }}
              width="75%"
              height="100%"
              src="https://www.youtube.com/embed/ItSdxhpCISA?si=yGyT8TuvmRepN3U_"
              title="youtube video2"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            />
          </div>

          <li>วิธีการทำเครื่องฟอกอากาศ DIY แบบง่าย (กรณีไม่มีกล่องอากาศดี)</li>

          <div
            style={{ marginTop: "20px", height: "375px" }}
          >
            <iframe
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto"
              }}
              width="75%"
              height="100%"
              src="https://www.youtube.com/embed/NKCS8EslFYg?si=g_ZBfP-8137Ze3w2"
              title="youtube video3"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            />
          </div>
        </ol>
      </Container>

      <div style={{ height: "50px" }} />
      <Contact />
      <Footer />
    </React.Fragment>
  );
};

export default Make_diy;
