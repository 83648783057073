import React, { useState, useEffect } from "react";
import axios from "axios";

import moment from "moment";
import "moment/locale/th";

import {
  Box,
  TextField,
  Autocomplete,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
  Backdrop,
  CircularProgress,
  Grid
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import { Icon } from "@iconify/react";

import Swal from "sweetalert2";

import * as xlsx from "xlsx";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

import board from "../../imgs/Artboard 1.png";
import "./css/setImg.css";

const Tdata = () => {
  let xURL = "";

  // xURL = "http://localhost:5000/api/";
  xURL = "https://www2.ntaqhi.info/api/";

  const [dataChart, setdataChart] = useState([]);
  const [dataChart2, setdataChart2] = useState([]);
  const [dataChart3, setdataChart3] = useState([]);
  const [dataChart4, setdataChart4] = useState([]);
  const [dataChart5, setdataChart5] = useState([]);
  const [dataChart6, setdataChart6] = useState([]);
  const [dataChart7, setdataChart7] = useState([]);
  const [dataChart8, setdataChart8] = useState([]);
  const [dataChart9, setdataChart9] = useState([]);
  const [dataChart10, setdataChart10] = useState([]);
  const [dataChart11, setdataChart11] = useState([]);
  const [dataChart12, setdataChart12] = useState([]);

  const [deviceName, setdeviceName] = useState([""]);
  const [value, setValue] = useState([]);

  const [dataEx, setdataEx] = useState([]);

  const [Yeardata, setYeardata] = useState([""]);
  const [dYearName, setdYearName] = useState([""]);

  const [open, setOpen] = useState(false);
  const [show, setshow] = useState(false);

  const [count15, setcount15] = useState();
  const [count37_5, setcount37_5] = useState();

  useEffect(() => {
    getDevice();
    getYear();
  }, []);

  const bclearClick = () => {
    window.location.reload(false);
  };

  function getYear() {
    var xyear = 0;
    let tb = [];

    xyear = moment().format("YYYY");

    tb.push("");
    for (let i = 0; i < 10; i++) {
      let oyear = parseInt(xyear - i);

      if (oyear >= 2018) {
        tb.push(parseInt(xyear - i));
      }
    }

    setYeardata(tb);
  }

  const getDevice = () => {
    axios
      .get(xURL + "getdevice")
      .then(result => {
        //console.log(result.data);
        const Tb1 = [];

        result.data.forEach((i, idx) => {
          Tb1.push({
            label: i.name,
            cmaqhi_device_id: i.cmaqhi_device_id
          });
        });

        setdeviceName(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const handleSubmit = event => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (!value.label || value.label == null) {
      Swal.fire({
        title: "ค้นหาไม่ได้!",
        text: "ยังไม่ได้เลือก สถานีตรวจวัด",
        icon: "warning",
        showConfirmButton: false,
        timer: 2000,
        customClass: {
          container: "my-swal"
        }
      });
    } else if (dYearName == "") {
      Swal.fire({
        title: "ค้นหาไม่ได้!",
        text: "ยังไม่ได้เลือก ปี",
        icon: "warning",
        showConfirmButton: false,
        timer: 2000,
        customClass: {
          container: "my-swal"
        }
      });
    } else {
      setdataChart([]);
      setdataChart2([]);
      setdataChart3([]);
      setdataChart4([]);
      setdataChart5([]);
      setdataChart6([]);
      setdataChart7([]);
      setdataChart8([]);
      setdataChart9([]);
      setdataChart10([]);
      setdataChart11([]);
      setdataChart12([]);

      getData();

      setdataEx();
      getExData();
    }
  };

  const getData = () => {
    //console.log(entity, month, year);

    setOpen(!open);

    let xsdate = "",
      xedate = "",
      xedate2 = "";

    let xcheck = 0;

    let xcount1 = 0,
      xcount2 = 0;

    for (let i = 1; i <= 12; i++) {
      if (i < 10) {
        xsdate = moment(dYearName + "-0" + i.toString() + "-01")
          .startOf("month")
          .format("YYYY-MM-DD");
        xedate = moment(dYearName + "-0" + i.toString() + "-01")
          .endOf("month")
          .format("YYYY-MM-DD");
        xedate2 = moment(dYearName + "-0" + i.toString() + "-01")
          .endOf("month")
          .add(1, "days")
          .format("YYYY-MM-DD");
      } else {
        xsdate = moment(dYearName + "-" + i.toString() + "-01")
          .startOf("month")
          .format("YYYY-MM-DD");
        xedate = moment(dYearName + "-" + i.toString() + "-01")
          .endOf("month")
          .format("YYYY-MM-DD");
        xedate2 = moment(dYearName + "-" + i.toString() + "-01")
          .endOf("month")
          .add(1, "days")
          .format("YYYY-MM-DD");
      }

      // console.log(xsdate, xedate);

      axios
        .post(xURL + "getpm", {
          name: value.label,
          sdate: xsdate,
          edate: xedate,
          edate2: xedate2
        })
        .then(result => {
          const Tb2 = [];
          let barColors = "";
          let chkm = 0;

          result.data.forEach((i, idx) => {
            if (Math.round(i.pm2_5) <= 12) {
              barColors = "#00e400";
            } else if (Math.round(i.pm2_5) > 12 && Math.round(i.pm2_5) <= 35) {
              barColors = "#ffff00";
            } else if (Math.round(i.pm2_5) > 35 && Math.round(i.pm2_5) <= 55) {
              barColors = "#ff7e00";
            } else if (Math.round(i.pm2_5) > 55 && Math.round(i.pm2_5) <= 150) {
              barColors = "#ff0000";
            } else if (
              Math.round(i.pm2_5) > 150 &&
              Math.round(i.pm2_5) <= 250
            ) {
              barColors = "#99004c";
            } else if (Math.round(i.pm2_5) > 250) {
              barColors = "#4c0026";
            }

            if (Math.round(i.pm2_5) > 15) {
              xcount1 = xcount1 + 1;
            }

            if (Math.round(i.pm2_5) > 37.5) {
              xcount2 = xcount2 + 1;
            }

            Tb2.push({
              dates: moment(i.ts_h).format("DD/MM/YYYY"),
              pm25: Math.round(i.pm2_5),
              fill: barColors
            });

            if (Math.round(i.pm2_5) != 0) {
              chkm = 1;
            }
          });

          if (chkm == 1) {
            xcheck = 1;

            if (i == 1) {
              setdataChart(Tb2);
            } else if (i == 2) {
              setdataChart2(Tb2);
            } else if (i == 3) {
              setdataChart3(Tb2);
            } else if (i == 4) {
              setdataChart4(Tb2);
            } else if (i == 5) {
              setdataChart5(Tb2);
            } else if (i == 6) {
              setdataChart6(Tb2);
            } else if (i == 7) {
              setdataChart7(Tb2);
            } else if (i == 8) {
              setdataChart8(Tb2);
            } else if (i == 9) {
              setdataChart9(Tb2);
            } else if (i == 10) {
              setdataChart10(Tb2);
            } else if (i == 11) {
              setdataChart11(Tb2);
            } else if (i == 12) {
              setdataChart12(Tb2);
            }
          }

          if (i == 12) {
            if (xcheck == 1) {
              setshow(true);
              setOpen(false);
            } else {
              setshow(false);
              setOpen(false);
            }

            if (xcheck == 0) {
              Swal.fire({
                title: "ไม่มีข้อมูลตามเงื่อนไขที่เลือก!",
                text: ";(",
                icon: "warning",
                showConfirmButton: false,
                timer: 2000,
                customClass: {
                  container: "my-swal"
                }
              });
            }
          }

          setcount15(xcount1);
          setcount37_5(xcount2);
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    }
  };

  async function getExData() {
    let xsdate = "",
      xedate = "";

    const Tb1 = [];

    for (let i = 1; i <= 12; i++) {
      if (i < 10) {
        xsdate = moment(dYearName + "-0" + i.toString() + "-01")
          .startOf("month")
          .format("YYYY-MM-DD");
        xedate = moment(dYearName + "-0" + i.toString() + "-01")
          .endOf("month")
          .format("YYYY-MM-DD");
      } else {
        xsdate = moment(dYearName + "-" + i.toString() + "-01")
          .startOf("month")
          .format("YYYY-MM-DD");
        xedate = moment(dYearName + "-" + i.toString() + "-01")
          .endOf("month")
          .format("YYYY-MM-DD");
      }

      await axios
        .post(xURL + "getExpm", {
          name: value.label,
          sdate: xsdate,
          edate: xedate
        })
        .then(result => {
          let xaqhi = 0;

          result.data.forEach((i, idx) => {
            if (Math.round(i.pm2_5) <= 12) {
              xaqhi = Math.round(
                4.1857 * parseInt(Math.round(i.pm2_5)) + 0.0524
              );
            } else if (Math.round(i.pm2_5) > 12 && Math.round(i.pm2_5) <= 35) {
              xaqhi = Math.round(2.1 * parseInt(Math.round(i.pm2_5)) + 25.7);
            } else if (Math.round(i.pm2_5) > 35 && Math.round(i.pm2_5) <= 55) {
              xaqhi = Math.round(2.4762 * parseInt(Math.round(i.pm2_5)) + 13);
            } else if (Math.round(i.pm2_5) > 55 && Math.round(i.pm2_5) <= 150) {
              xaqhi = Math.round(
                0.5161 * parseInt(Math.round(i.pm2_5)) + 122.1
              );
            } else if (
              Math.round(i.pm2_5) > 150 &&
              Math.round(i.pm2_5) <= 205
            ) {
              xaqhi = Math.round(parseInt(Math.round(i.pm2_5)) + 50);
            } else if (
              Math.round(i.pm2_5) > 205 &&
              Math.round(i.pm2_5) <= 350
            ) {
              xaqhi = Math.round(parseInt(Math.round(i.pm2_5)) + 50);
            } else if (
              Math.round(i.pm2_5) > 305 &&
              Math.round(i.pm2_5) <= 500
            ) {
              xaqhi = Math.round(0.667 * parseInt(Math.round(i.pm2_5)) + 167);
            }
            // else if (Math.round(i.pm2_5) > 500) {
            //   xaqhi = "Beyond AQHI";
            // } else {
            //   xaqhi = "-";
            // }

            Tb1.push({
              dates: moment(i.ts_h).format("DD/MM/YYYY"),
              pm25: Math.round(i.pm2_5),
              aqhi: xaqhi
            });
          });

          setdataEx(Tb1);
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    }
  }

  const excelclick = () => {
    setOpen(!open);

    setTimeout(() => {
      setOpen(false);

      let xdata = [];
      let header = [
        "วันที่",
        "PM2.5 เฉลี่ย 24 ชม. (มคก./ลบม.)",
        "AQHI เฉลี่ย 24 ชม. (มคก./ลบม.)"
      ];

      xdata.push(["ข้อมูลย้อนหลัง (NTAQHI)"]);
      xdata.push(["จุดตรวจ " + value.label + " ปี " + dYearName]);
      xdata.push([""]);
      xdata.push(header);

      for (var row in dataEx) {
        const xx = [];

        for (var col in dataEx[row]) {
          xx.push(dataEx[row][col]);
        }

        xdata.push(xx);
      }

      var wb = xlsx.utils.book_new(),
        ws = xlsx.utils.json_to_sheet(xdata, { skipHeader: true });

      xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
      xlsx.writeFile(
        wb,
        value.label.split(" ")[0] + " ปี " + dYearName + ".xlsx"
      );
    }, "2000");
  };

  const handleChange = event => {
    setdYearName(event.target.value);
  };

  return (
    <React.Fragment>
      <p>
        เลือกสถานีตรวจวัดและปีที่ต้องการค้นข้อมูลคุณภาพอากาศรายวันย้อนหลัง
        (PM2.5 เฉลี่ย 24 ชม.) แล้วกดปุ่มค้นหา
      </p>

      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <Autocomplete
          disablePortal
          name="entity"
          id="combo-box-demo"
          size="small"
          options={deviceName}
          sx={{ width: 310 }}
          renderInput={params => <TextField {...params} label="สถานีตรวจวัด" />}
          onChange={(event, newValue) => {
            if (newValue) {
              setValue(newValue);
            } else {
              setValue("");
            }
          }}
        />

        <FormControl margin="normal" required>
          <InputLabel id="demo-select-small">ปี</InputLabel>
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            size="small"
            name="year"
            value={dYearName}
            label="ปี"
            onChange={handleChange}
            sx={{ width: 150 }}
          >
            {Yeardata.map(Yeardata =>
              <MenuItem key={Yeardata} value={Yeardata}>
                {Yeardata}
              </MenuItem>
            )}
          </Select>
        </FormControl>

        <br />
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 1, mr: 1, width: 150 }}
          startIcon={<SearchIcon />}
          style={{ fontFamily: "kanit" }}
        >
          ค้นหา
        </Button>

        <Button
          variant="contained"
          color="error"
          sx={{ mt: 1, width: 150 }}
          startIcon={<CleaningServicesIcon />}
          onClick={() => {
            bclearClick();
          }}
          style={{ fontFamily: "kanit" }}
        >
          เคลียร์
        </Button>
      </Box>

      <br />

      {show
        ? <div>
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                justifyContent: "flex-end",
                marginLeft: "auto",
                marginRight: 0
              }}
            >
              <Button
                style={{ textTransform: "none", fontFamily: "kanit" }}
                variant="text"
                color="success"
                sx={{ mt: 1, width: 150 }}
                startIcon={<Icon icon="vscode-icons:file-type-excel2" />}
                onClick={() => {
                  excelclick();
                }}
              >
                ดาวน์โหลด Excel
              </Button>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "19px"
              }}
            >
              <p>กราฟแสดง PM2.5 เฉลี่ย 24 ชม. (มคก./ลบ.ม.)</p>
            </div>

            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                {/* เดือน 1 */}
                {dataChart.length > 0
                  ? <Grid item xs={12} md={6}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "16px"
                        }}
                      >
                        <p>มกราคม</p>
                      </div>

                      <ResponsiveContainer width="100%" height={480}>
                        <BarChart
                          data={dataChart}
                          margin={{ top: 20, right: 20, bottom: 40, left: 20 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="dates"
                            textAnchor="end"
                            sclaeToFit="true"
                            verticalAnchor="start"
                            interval={1}
                            angle="-45"
                            stroke="#8884d8"
                            tick={{ fontSize: 13 }}
                          />
                          <YAxis />
                          <Tooltip />

                          <Legend wrapperStyle={{ top: 460 }} height={36} />
                          <Bar
                            name="PM2.5"
                            dataKey="pm25"
                            legendType="circle"
                            fill="#00a0fc"
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </Grid>
                  : <div />}

                {/* เดือน 2 */}
                {dataChart2.length > 0
                  ? <Grid item xs={12} md={6}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "16px"
                        }}
                      >
                        <p>กุมภาพันธ์</p>
                      </div>

                      <ResponsiveContainer width="100%" height={480}>
                        <BarChart
                          data={dataChart2}
                          margin={{ top: 20, right: 20, bottom: 40, left: 20 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="dates"
                            textAnchor="end"
                            sclaeToFit="true"
                            verticalAnchor="start"
                            interval={1}
                            angle="-45"
                            stroke="#8884d8"
                            tick={{ fontSize: 13 }}
                          />
                          <YAxis />
                          <Tooltip />

                          <Legend wrapperStyle={{ top: 460 }} height={36} />
                          <Bar
                            name="PM2.5"
                            dataKey="pm25"
                            legendType="circle"
                            fill="#00a0fc"
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </Grid>
                  : <div />}

                {/* เดือน 3 */}
                {dataChart3.length > 0
                  ? <Grid item xs={12} md={6}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "16px"
                        }}
                      >
                        <p>มีนาคม</p>
                      </div>

                      <ResponsiveContainer width="100%" height={480}>
                        <BarChart
                          data={dataChart3}
                          margin={{ top: 20, right: 20, bottom: 40, left: 20 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="dates"
                            textAnchor="end"
                            sclaeToFit="true"
                            verticalAnchor="start"
                            interval={1}
                            angle="-45"
                            stroke="#8884d8"
                            tick={{ fontSize: 13 }}
                          />
                          <YAxis />
                          <Tooltip />

                          <Legend wrapperStyle={{ top: 460 }} height={36} />
                          <Bar
                            name="PM2.5"
                            dataKey="pm25"
                            legendType="circle"
                            fill="#00a0fc"
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </Grid>
                  : <div />}

                {/* เดือน 4 */}
                {dataChart4.length > 0
                  ? <Grid item xs={12} md={6}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "16px"
                        }}
                      >
                        <p>เมษายน</p>
                      </div>

                      <ResponsiveContainer width="100%" height={480}>
                        <BarChart
                          data={dataChart4}
                          margin={{ top: 20, right: 20, bottom: 40, left: 20 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="dates"
                            textAnchor="end"
                            sclaeToFit="true"
                            verticalAnchor="start"
                            interval={1}
                            angle="-45"
                            stroke="#8884d8"
                            tick={{ fontSize: 13 }}
                          />
                          <YAxis />
                          <Tooltip />

                          <Legend wrapperStyle={{ top: 460 }} height={36} />
                          <Bar
                            name="PM2.5"
                            dataKey="pm25"
                            legendType="circle"
                            fill="#00a0fc"
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </Grid>
                  : <div />}

                {/* เดือน 5 */}
                {dataChart5.length > 0
                  ? <Grid item xs={12} md={6}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "16px"
                        }}
                      >
                        <p>พฤษภาคม</p>
                      </div>

                      <ResponsiveContainer width="100%" height={480}>
                        <BarChart
                          data={dataChart5}
                          margin={{ top: 20, right: 20, bottom: 40, left: 20 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="dates"
                            textAnchor="end"
                            sclaeToFit="true"
                            verticalAnchor="start"
                            interval={1}
                            angle="-45"
                            stroke="#8884d8"
                            tick={{ fontSize: 13 }}
                          />
                          <YAxis />
                          <Tooltip />

                          <Legend wrapperStyle={{ top: 460 }} height={36} />
                          <Bar
                            name="PM2.5"
                            dataKey="pm25"
                            legendType="circle"
                            fill="#00a0fc"
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </Grid>
                  : <div />}

                {/* เดือน 6 */}
                {dataChart6.length > 0
                  ? <Grid item xs={12} md={6}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "16px"
                        }}
                      >
                        <p>มิถุนายน</p>
                      </div>

                      <ResponsiveContainer width="100%" height={480}>
                        <BarChart
                          data={dataChart6}
                          margin={{ top: 20, right: 20, bottom: 40, left: 20 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="dates"
                            textAnchor="end"
                            sclaeToFit="true"
                            verticalAnchor="start"
                            interval={1}
                            angle="-45"
                            stroke="#8884d8"
                            tick={{ fontSize: 13 }}
                          />
                          <YAxis />
                          <Tooltip />

                          <Legend wrapperStyle={{ top: 460 }} height={36} />
                          <Bar
                            name="PM2.5"
                            dataKey="pm25"
                            legendType="circle"
                            fill="#00a0fc"
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </Grid>
                  : <div />}

                {/* เดือน 7 */}
                {dataChart7.length > 0
                  ? <Grid item xs={12} md={6}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "16px"
                        }}
                      >
                        <p>กรกฎาคม</p>
                      </div>

                      <ResponsiveContainer width="100%" height={480}>
                        <BarChart
                          data={dataChart7}
                          margin={{ top: 20, right: 20, bottom: 40, left: 20 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="dates"
                            textAnchor="end"
                            sclaeToFit="true"
                            verticalAnchor="start"
                            interval={1}
                            angle="-45"
                            stroke="#8884d8"
                            tick={{ fontSize: 13 }}
                          />
                          <YAxis />
                          <Tooltip />

                          <Legend wrapperStyle={{ top: 460 }} height={36} />
                          <Bar
                            name="PM2.5"
                            dataKey="pm25"
                            legendType="circle"
                            fill="#00a0fc"
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </Grid>
                  : <div />}

                {/* เดือน 8 */}
                {dataChart8.length > 0
                  ? <Grid item xs={12} md={6}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "16px"
                        }}
                      >
                        <p>สิงหาคม</p>
                      </div>

                      <ResponsiveContainer width="100%" height={480}>
                        <BarChart
                          data={dataChart8}
                          margin={{ top: 20, right: 20, bottom: 40, left: 20 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="dates"
                            textAnchor="end"
                            sclaeToFit="true"
                            verticalAnchor="start"
                            interval={1}
                            angle="-45"
                            stroke="#8884d8"
                            tick={{ fontSize: 13 }}
                          />
                          <YAxis />
                          <Tooltip />

                          <Legend wrapperStyle={{ top: 460 }} height={36} />
                          <Bar
                            name="PM2.5"
                            dataKey="pm25"
                            legendType="circle"
                            fill="#00a0fc"
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </Grid>
                  : <div />}

                {/* เดือน 9 */}
                {dataChart9.length > 0
                  ? <Grid item xs={12} md={6}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "16px"
                        }}
                      >
                        <p>กันยายน</p>
                      </div>

                      <ResponsiveContainer width="100%" height={480}>
                        <BarChart
                          data={dataChart9}
                          margin={{ top: 20, right: 20, bottom: 40, left: 20 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="dates"
                            textAnchor="end"
                            sclaeToFit="true"
                            verticalAnchor="start"
                            interval={1}
                            angle="-45"
                            stroke="#8884d8"
                            tick={{ fontSize: 13 }}
                          />
                          <YAxis />
                          <Tooltip />

                          <Legend wrapperStyle={{ top: 460 }} height={36} />
                          <Bar
                            name="PM2.5"
                            dataKey="pm25"
                            legendType="circle"
                            fill="#00a0fc"
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </Grid>
                  : <div />}

                {/* เดือน 10 */}
                {dataChart10.length > 0
                  ? <Grid item xs={12} md={6}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "16px"
                        }}
                      >
                        <p>ตุลาคม</p>
                      </div>

                      <ResponsiveContainer width="100%" height={480}>
                        <BarChart
                          data={dataChart10}
                          margin={{ top: 20, right: 20, bottom: 40, left: 20 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="dates"
                            textAnchor="end"
                            sclaeToFit="true"
                            verticalAnchor="start"
                            interval={1}
                            angle="-45"
                            stroke="#8884d8"
                            tick={{ fontSize: 13 }}
                          />
                          <YAxis />
                          <Tooltip />

                          <Legend wrapperStyle={{ top: 460 }} height={36} />
                          <Bar
                            name="PM2.5"
                            dataKey="pm25"
                            legendType="circle"
                            fill="#00a0fc"
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </Grid>
                  : <div />}

                {/* เดือน 11 */}
                {dataChart11.length > 0
                  ? <Grid item xs={12} md={6}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "16px"
                        }}
                      >
                        <p>พฤศจิกายน</p>
                      </div>

                      <ResponsiveContainer width="100%" height={480}>
                        <BarChart
                          data={dataChart11}
                          margin={{ top: 20, right: 20, bottom: 40, left: 20 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="dates"
                            textAnchor="end"
                            sclaeToFit="true"
                            verticalAnchor="start"
                            interval={1}
                            angle="-45"
                            stroke="#8884d8"
                            tick={{ fontSize: 13 }}
                          />
                          <YAxis />
                          <Tooltip />

                          <Legend wrapperStyle={{ top: 460 }} height={36} />
                          <Bar
                            name="PM2.5"
                            dataKey="pm25"
                            legendType="circle"
                            fill="#00a0fc"
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </Grid>
                  : <div />}

                {/* เดือน 12 */}
                {dataChart12.length > 0
                  ? <Grid item xs={12} md={6}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "16px"
                        }}
                      >
                        <p>ธันวาคม</p>
                      </div>

                      <ResponsiveContainer width="100%" height={480}>
                        <BarChart
                          data={dataChart12}
                          margin={{ top: 20, right: 20, bottom: 40, left: 20 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="dates"
                            textAnchor="end"
                            sclaeToFit="true"
                            verticalAnchor="start"
                            interval={1}
                            angle="-45"
                            stroke="#8884d8"
                            tick={{ fontSize: 13 }}
                          />
                          <YAxis />
                          <Tooltip />

                          <Legend wrapperStyle={{ top: 460 }} height={36} />
                          <Bar
                            name="PM2.5"
                            dataKey="pm25"
                            legendType="circle"
                            fill="#00a0fc"
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </Grid>
                  : <div />}
              </Grid>
            </Box>

            <img
              src={board}
              style={{
                maxWidth: "100%",
                height: "auto",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto"
              }}
              className="img-artboard"
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "15px",
                color: "GrayText"
              }}
            >
              <p>
                ตามมาตรฐานขององค์การอนามัยโลก ค่า PM2.5 เฉลี่ย 24 ชม. ไม่ควรเกิน
                15 มคก./ลบม.
              </p>
            </div>

            <div
              style={{
                marginTop: "30px",
                fontSize: "15px"
              }}
            >
              <table className="realtime">
                <thead>
                  <tr style={{ height: "45px" }}>
                    <th style={{ border: "1px solid black" }}>
                      PM2.5 เฉลี่ยรายวัน (มคก./ลบม.)
                    </th>
                    <th>จำนวนวัน</th>
                  </tr>
                </thead>

                <tbody>
                  <tr style={{ height: "45px" }}>
                    <th
                      style={{
                        textAlign: "left",
                        paddingLeft: "15px"
                      }}
                    >
                      เกินค่ามาตรฐานขององค์การอนามัยโลก (15 มคก./ลบม.)
                    </th>
                    <td
                      style={{
                        border: "1px solid black"
                      }}
                    >
                      {count15}
                    </td>
                  </tr>

                  <tr style={{ height: "45px" }}>
                    <th
                      style={{
                        textAlign: "left",
                        paddingLeft: "15px",
                        border: "1px solid black"
                      }}
                    >
                      เกินค่ามาตรฐานของประเทศไทย (37.5 มคก./ลบม.)
                    </th>
                    <td
                      style={{
                        border: "1px solid black"
                      }}
                    >
                      {count37_5}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        : <div />}

      <Backdrop
        sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
};

export default Tdata;
