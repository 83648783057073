import React from "react";

import { Container, Box, Grid } from "@mui/material";

import "./css/setImg.css";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Contact from "../../components/Contact";

import Pic_Protect1 from "../../imgs/protection1.png";
import Pic_Protect2 from "../../imgs/protection2.png";
import Pic_Protect3 from "../../imgs/protection3.png";

const Cleanroom1 = () => {
  return (
    <React.Fragment>
      <Navbar />

      <Container>
        <div style={{ marginTop: "100px" }} />
        <p style={{ marginTop: "30px", fontWeight: 600 }}>
          การป้องกันฝุ่นจากภายนอก
        </p>

        <ol>
          <li>
            ปิดประตูหน้าต่างทุกบานตลอดเวลา หากจำเป็นควรให้เข้าออกเพียงประตูเดียว
          </li>
          <li>
            เปิดประตูหน้าต่างเท่าที่จำเป็นและรีบปิดทันที
            ห้ามเปิดประตูหน้าต่างทิ้งไว้
          </li>
          <li>
            ปิดรูรั่วของห้องทุกแห่ง โดยเฉพาะที่กรอบหน้าต่าง บานเกร็ด ประตู
          </li>
        </ol>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <img
                style={{
                  maxWidth: "100%",
                  height: "400px",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: "10px"
                }}
                className="img-artboard"
                src={Pic_Protect1}
                alt="Protect1"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <img
                style={{
                  maxWidth: "100%",
                  height: "400px",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: "10px"
                }}
                className="img-artboard"
                src={Pic_Protect2}
                alt="Protect2"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <img
                style={{
                  maxWidth: "100%",
                  height: "400px",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto"
                }}
                className="img-artboard"
                src={Pic_Protect3}
                alt="Protect3"
              />
            </Grid>
          </Grid>
        </Box>
      </Container>

      <div style={{ height: "50px" }} />
      <Contact />
      <Footer />
    </React.Fragment>
  );
};

export default Cleanroom1;
