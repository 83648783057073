import React, { useEffect, useState } from "react";

import moment from "moment";
import "moment/locale/th";

import "./css/Header.css";

import logo_nasa from "../../imgs/NASA_icon.png";

const Header = prop => {
  const [Daydata, setDaydata] = useState([""]);
  const [Timedata, setTimedata] = useState([""]);

  useEffect(() => {
    let xday = "",
      xtime = "";
    // if (prop.pages == 6) {
    //   xday = moment().utcOffset(7).format("Do MMMM YYYY");

    //   if (
    //     moment().utcOffset(7).format("HH") >= 14 &&
    //     moment().utcOffset(7).format("mm") >= 30
    //   ) {
    //     xtime = moment().utcOffset(7).format("14:30");
    //   } else if (
    //     moment().utcOffset(7).format("HH") >= 16 &&
    //     moment().utcOffset(7).format("mm") >= 30
    //   ) {
    //     xtime = moment().utcOffset(7).format("16:30");
    //   } else if (
    //     moment().utcOffset(7).format("HH") >= 18 &&
    //     moment().utcOffset(7).format("mm") >= 30
    //   ) {
    //     xtime = moment().utcOffset(7).format("18:30");
    //   } else {
    //     xtime = moment().utcOffset(7).format("05:30");
    //   }
    // } else {
    xday = moment().utcOffset(7).format("Do MMMM YYYY");
    xtime = moment().utcOffset(7).format("HH:00");
    // }

    // console.log(prop);

    xday = xday.toString();

    setDaydata(xday);
    setTimedata(xtime);
  }, []);

  return (
    <React.Fragment>
      <div className="header">
        <div>
          <div
            style={{
              textAlign: "center",
              marginTop: "0px"
            }}
          >
            <h3
              style={{
                fontSize: "27px",
                color: "#728FCE"
              }}
            >
              {prop.name}{" "}
              {prop.pages !== 3 && prop.pages !== 4 && prop.pages !== 6
                ? <sup style={{ fontSize: "13px" }}>
                    [{prop.deviceId}]
                  </sup>
                : <p />}
            </h3>
          </div>

          <div
            style={{
              textAlign: "center",
              marginTop: "-30px"
            }}
          >
            <h3
              style={{
                fontSize: "14px",
                color: "#124076",
                fontWeight: 100
              }}
            >
              {prop.pages == 1 || prop.pages == 2 || prop.pages == 5
                ? prop.detail
                : <p />}
            </h3>
          </div>

          {prop.pages == 1 || prop.pages == 2 || prop.pages == 5
            ? <div
                style={{
                  textAlign: "center",
                  marginTop: "-20px"
                }}
              >
                {prop.pages !== 5
                  ? <h3 style={{ fontSize: "19px", color: "#8D918D" }}>
                      วันที่ {Daydata} เวลา {Timedata} น.
                    </h3>
                  : <h3 style={{ fontSize: "19px", color: "#8D918D" }}>
                      วันที่ {Daydata} เวลา{" "}
                      {prop.time1m ? prop.time1m : moment().format("HH:mm")} น.
                    </h3>}
              </div>
            : <div
                style={{
                  textAlign: "center",
                  marginTop: "-35px"
                }}
              >
                {prop.pages !== 5
                  ? <h3 style={{ fontSize: "19px", color: "#8D918D" }}>
                      วันที่ {Daydata} เวลา {Timedata} น.
                    </h3>
                  : <h3 style={{ fontSize: "19px", color: "#8D918D" }}>
                      วันที่ {Daydata} เวลา{" "}
                      {prop.time1m ? prop.time1m : moment().format("HH:mm")} น.
                    </h3>}
              </div>}
        </div>

        {/* {prop.pages == 4
          ? <a
              target="_blank"
              href="https://firms.modaps.eosdis.nasa.gov/map/#d:24hrs;l:fires_all,street;@101.4,13.5,5.6z"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <img src={logo_nasa} alt="firms_nasa" width="90" />
            </a>
          : <div />} */}
      </div>
    </React.Fragment>
  );
};

export default Header;
