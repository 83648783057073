import React, { useEffect, useState } from "react";
import axios from "axios";

import moment from "moment";
import "moment/locale/th";

import Header from "./Header";
import Highcharts_realtime from "./Highcharts_realtime";
import Highcharts1Min from "./Hightcharts1Min";
import Descript1Min from "./Descript1Min";

import { Box, Grid } from "@mui/material";

import Swal from "sweetalert2";

const AQHI1Min = () => {
  let xURL = "";
  xURL = "https://www2.ntaqhi.info/api/";

  //   const geolocationAPI = navigator.geolocation;

  const [hname, sethname] = useState("");
  const [hdetailname, sethdetailname] = useState("");

  const [pm25, setpm25] = useState();
  const [pm10, setpm10] = useState();
  const [pm1, setpm1] = useState();
  const [temp, settemp] = useState();
  const [humi, sethumi] = useState();
  const [time1m, settime1m] = useState();

  const [hdevice_id, sethdevice_id] = useState("");

  const [dataRealtime, setdataRealtime] = useState([]);
  const [dateRealtime, setdateRealtime] = useState([]);

  const [dataRealtime2, setdataRealtime2] = useState([]);
  const [dataRealtime3, setdataRealtime3] = useState([]);

  const [dataRealtime4, setdataRealtime4] = useState([]);
  const [dataRealtime5, setdataRealtime5] = useState([]);

  useEffect(() => {
    getLocation();
  }, []);

  setInterval(() => {
    if (moment().format("ss") == "00") {
      getLocation();
    }
  }, 1000);

  const getLocation = () => {
    var url = new URL(window.location.href);
    let xarea_point = url.searchParams.get("area-point");
    let xPages = url.searchParams.get("pages");

    if (xarea_point) {
      axios
        .post(xURL + "getlocation", {
          area_point: xarea_point,
          pages: xPages
        })
        .then(result => {
          if (xarea_point) {
            if (!result.data[0]) {
              Swal.fire({
                icon: "error",
                title: "เกิดข้อผิดพลาด",
                text: "สถานที่นี้ offline หรือ ไม่มี area-point นี้",
                footer: "กรุณากรอก area-point ใหม่"
              });

              //setOpen(false);
            } else {
              let xdetail = "";

              if (result.data[0].districts) {
                xdetail = "ต." + result.data[0].districts;
              } else {
                xdetail = "";
              }

              if (result.data[0].amphures) {
                xdetail = xdetail + " อ." + result.data[0].amphures;
              } else {
                xdetail = xdetail + "";
              }

              if (result.data[0].provinces) {
                xdetail = xdetail + " จ." + result.data[0].provinces;
              } else {
                xdetail = xdetail + "";
              }

              sethname(result.data[0].name);
              sethdetailname(xdetail);

              sethdevice_id(result.data[0].cmaqhi_device_id);

              getRealtime(result.data[0].device_id);
            }
          }
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    }
  };

  const getRealtime = device_id => {
    // console.log(device_id);

    let dateed = moment()
      .add(-6, "hours")
      .add(10, "minutes")
      .format("YYYY-MM-DD HH:mm");
    let dateend = (Math.round(new Date(dateed).getTime() / 1000) - 600) * 1000;

    let dateed2 = moment().add(10, "minutes").format("YYYY-MM-DD HH:mm");
    let dateend2 =
      (Math.round(new Date(dateed2).getTime() / 1000) - 600) * 1000;

    // console.log(dateed,dateed2);
    axios
      .post(xURL + "getrealtime", {
        device_id: device_id,
        sdate: dateend,
        edate: dateend2
      })
      .then(result => {
        // console.log(result.data.rows);
        if (result.data.rows.length > 0) {
          const Tb1 = [],
            Tb2 = [],
            Tb3 = [],
            Tb4 = [],
            Tb5 = [];

          let xtime = "",
            xcount = 0,
            xchk25 = 0,
            xchk1 = 0,
            xchk10 = 0,
            xchktmp = 0,
            xtime1m = "";

          result.data.rows.forEach((i, idx) => {
            if (xtime !== "") {
              if (xtime !== moment(i.ts_m).format("LT")) {
                if (xcount == 1) {
                  if (xchk25 == 1 && xchk10 == 0 && xchk1 == 0) {
                    Tb2.push(0);
                    Tb3.push(0);
                  } else if (xchk10 == 1 && xchk25 == 0 && xchk1 == 0) {
                    Tb1.push(0);
                    Tb3.push(0);
                  } else if (xchk1 == 1 && xchk10 == 0 && xchk25 == 0) {
                    Tb1.push(0);
                    Tb2.push(0);
                  }
                }

                if (xcount == 2) {
                  if (xchk25 == 0) {
                    Tb1.push(0);
                  } else if (xchk1 == 0) {
                    Tb3.push(0);
                  }
                }

                if (xcount == 0) {
                  Tb1.push(0);
                  Tb2.push(0);
                  Tb3.push(0);
                }

                xcount = 0;
                xchk25 = 0;
                xchk1 = 0;
                xchk10 = 0;
              }
            }

            if (i.key == "pm2_5") {
              Tb1.push(parseInt(i.valuepm));
              xcount = xcount + 1;
              xchk25 = 1;

              setpm25(parseInt(i.valuepm));
            }

            if (i.key == "pm10") {
              Tb2.push(parseInt(i.valuepm));
              xcount = xcount + 1;
              xchk10 = 1;
              // Tb4.push(moment(i.ts_m).format("LT"));

              setpm10(parseInt(i.valuepm));
            }

            if (i.key == "pm1") {
              Tb3.push(parseInt(i.valuepm));
              xcount = xcount + 1;
              xchk1 = 1;

              setpm1(parseInt(i.valuepm));
            }

            if (i.key == "temperature") {
              Tb4.push(parseInt(i.valuepm2));

              settemp(parseInt(i.valuepm2));
            }

            if (i.key == "humidity") {
              Tb5.push(parseInt(i.valuepm2));

              sethumi(parseInt(i.valuepm2));
            }

            xtime = moment(i.ts_m).format("LT");

            xtime1m = moment(i.ts_m).format("LT");
          });
          // console.log(Tb1, Tb2, Tb3);
          setdataRealtime(Tb1);
          setdataRealtime2(Tb2);
          setdataRealtime3(Tb3);

          setdataRealtime4(Tb4);
          setdataRealtime5(Tb5);

          settime1m(xtime1m);

          // setdateRealtime(Tb4);
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    axios
      .post(xURL + "getrealtimedate", {
        device_id: device_id,
        sdate: dateend,
        edate: dateend2
      })
      .then(result => {
        if (result.data.rows.length > 0) {
          const Tb1 = [];

          result.data.rows.forEach((i, idx) => {
            Tb1.push(moment(i.ts_m).format("LT"));
          });
          // console.log(Tb1, Tb2, Tb3, Tb4);

          setdateRealtime(Tb1);
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  return (
    <React.Fragment>
      <Header
        name={hname}
        detail={hdetailname}
        deviceId={hdevice_id}
        pages={5}
        time1m={time1m}
      />

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6.5}>
            <Highcharts1Min pm25={pm25} />
          </Grid>
          <Grid item xs={12} sm={5.5}>
            <Descript1Min
              pm25={pm25}
              pm10={pm10}
              pm1={pm1}
              temp={temp}
              humi={humi}
            />
          </Grid>
        </Grid>
      </Box>

      <div style={{ height: "50px" }} />

      <Highcharts_realtime
        dataRealtime={dataRealtime}
        dateRealtime={dateRealtime}
        dataRealtime2={dataRealtime2}
        dataRealtime3={dataRealtime3}
        dataRealtime4={dataRealtime4}
        dataRealtime5={dataRealtime5}
      />
    </React.Fragment>
  );
};

export default AQHI1Min;
