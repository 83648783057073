import React, { useState, useEffect } from "react";
import axios from "axios";

import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";

import moment from "moment";
import "moment/locale/th";

import {
  Box,
  TextField,
  Autocomplete,
  Button,
  Backdrop,
  CircularProgress
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import { Icon } from "@iconify/react";

import Swal from "sweetalert2";

import * as xlsx from "xlsx";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

import "./css/setImg.css";

const Tdata3 = () => {
  let xURL = "";

  // xURL = "http://localhost:5000/api/";
  xURL = "https://www2.ntaqhi.info/api/";

  const [deviceName, setdeviceName] = useState([""]);
  const [value, setValue] = useState([]);

  const [dgdataSource, setdgdataSource] = useState([]);
  const [dataChart, setdataChart] = useState([]);

  const [xHeight, setxHeight] = useState(523);

  const [open, setOpen] = useState(false);
  const [show, setshow] = useState(false);

  useEffect(() => {
    getDevice();
  }, []);

  const bclearClick = () => {
    window.location.reload(false);
  };

  const getDevice = () => {
    axios
      .get(xURL + "getdevice")
      .then(result => {
        //console.log(result.data);
        const Tb1 = [];

        result.data.forEach((i, idx) => {
          Tb1.push({
            label: i.name,
            cmaqhi_device_id: i.cmaqhi_device_id
          });
        });

        setdeviceName(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const handleSubmit = event => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (!value.label || value.label == null) {
      Swal.fire({
        title: "ค้นหาไม่ได้!",
        text: "ยังไม่ได้เลือก สถานีตรวจวัด",
        icon: "warning",
        showConfirmButton: false,
        timer: 2000,
        customClass: {
          container: "my-swal"
        }
      });
    } else {
      getData();
    }
  };

  const getData = () => {
    setOpen(!open);

    axios
      .post(xURL + "getid2", { entity: value.label })
      .then(result => {
        //console.log(result.data[0].device_id);

        axios
          .post(xURL + "getpm3", {
            entity: result.data[0].cmaqhi_device_id,
            device_source: result.data[0].device_source
          })
          .then(result2 => {
            //console.log(result2.data.rows);
            if (result2.data.length == 0) {
              Swal.fire({
                title: "ไม่มีข้อมูลตามเงื่อนไขที่เลือก!",
                text: ";(",
                icon: "warning",
                showConfirmButton: false,
                timer: 2000,
                customClass: {
                  container: "my-swal"
                }
              });

              setOpen(false);
            } else {
              setshow(true);

              const Tb1 = [],
                Tb2 = [];
              let barColors = "";
              let htable = 0;

              result2.data.forEach((i, idx) => {
                if (Math.round(i.pm2_5) <= 5) {
                  barColors = "#00e400";
                } else if (Math.round(i.pm2_5) > 5) {
                  barColors = "#ff0000";
                }

                Tb1.push({
                  dates: moment(i.ts_h).format("YYYY"),
                  pm25: Math.round(i.pm2_5),
                  fill: barColors
                });

                Tb2.push({
                  dates: moment(i.ts_h).format("YYYY"),
                  pm25: Math.round(i.pm2_5)
                });

                htable = htable + 40;
              });

              setxHeight(40 + htable);

              setdataChart(Tb1);
              setdgdataSource(Tb2);

              setOpen(false);
            }
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const dgcolumns = [
    {
      name: "dates",
      header: "ปี",
      maxWidth: 400,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false
    },
    {
      name: "pm25",
      header: "PM2.5 เฉลี่ย 24 ชม. (มคก./ลบ.ม.)",
      maxWidth: 750,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false
    }
  ];

  const excelclick = () => {
    let xdata = [];
    let header = ["ปี", "PM2.5 เฉลี่ย 24 ชม. (มคก./ลบม.)"];

    xdata.push(["ข้อมูลย้อนหลัง (NTAQHI)"]);
    xdata.push(["จุดตรวจ " + value.label]);
    xdata.push([""]);
    xdata.push(header);

    for (var row in dgdataSource) {
      const xx = [];

      for (var col in dgdataSource[row]) {
        xx.push(dgdataSource[row][col]);
      }

      xdata.push(xx);
    }

    var wb = xlsx.utils.book_new(),
      ws = xlsx.utils.json_to_sheet(xdata, { skipHeader: true });

    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    xlsx.writeFile(
      wb,
      "ข้อมูลย้อนหลัง PM2.5 (รายปี) เฉลี่ย 24 ชม. (มคก./ลบม.).xlsx"
    );
  };

  const gridStyle = { minHeight: xHeight };

  return (
    <React.Fragment>
      <p>
        เลือกสถานีตรวจวัดที่ต้องการค้นข้อมูลคุณภาพอากาศรายปีย้อนหลัง (PM2.5
        เฉลี่ยทั้งปี) แล้วกดปุ่มค้นหา
      </p>

      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <Autocomplete
          disablePortal
          name="entity"
          id="combo-box-demo"
          size="small"
          options={deviceName}
          sx={{ width: 310 }}
          renderInput={params => <TextField {...params} label="สถานีตรวจวัด" />}
          onChange={(event, newValue) => {
            if (newValue) {
              setValue(newValue);
            } else {
              setValue("");
            }
          }}
        />

        <br />
        <Button
          style={{ fontFamily: "kanit" }}
          type="submit"
          variant="contained"
          sx={{ mt: 1, mr: 1, width: 150 }}
          startIcon={<SearchIcon />}
        >
          ค้นหา
        </Button>

        <Button
          style={{ fontFamily: "kanit" }}
          variant="contained"
          color="error"
          sx={{ mt: 1, width: 150 }}
          startIcon={<CleaningServicesIcon />}
          onClick={() => {
            bclearClick();
          }}
        >
          เคลียร์
        </Button>
      </Box>
      <br />

      {show
        ? <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "19px"
              }}
            >
              <p>กราฟแสดง PM2.5 เฉลี่ยรายปี (มคก./ลบ.ม.)</p>
            </div>
            <ResponsiveContainer width="100%" height={480}>
              <BarChart
                data={dataChart}
                margin={{ top: 20, right: 20, bottom: 40, left: 20 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="dates"
                  textAnchor="end"
                  sclaeToFit="true"
                  verticalAnchor="start"
                  interval={0}
                  angle="-45"
                  stroke="#8884d8"
                  tick={{ fontSize: 13 }}
                />
                <YAxis />
                <Tooltip />

                <Legend wrapperStyle={{ top: 460 }} height={36} />
                <Bar
                  name="PM2.5"
                  dataKey="pm25"
                  legendType="circle"
                  fill="#00a0fc"
                />
              </BarChart>
            </ResponsiveContainer>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "16px",
                color: "#F31E4E"
                // fontWeight: "bold"
              }}
            >
              <p>
                ตามมาตรฐานขององค์การอนามัยโลก ค่า PM2.5 เฉลี่ยรายปีไม่ควรเกิน 5
                มคก./ลบม.
              </p>
            </div>

            <br />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "19px"
              }}
            >
              <p>ตารางแสดง PM2.5 เฉลี่ยรายปี (มคก./ลบ.ม.)</p>
            </div>
            <ReactDataGrid
              idProperty="id"
              columns={dgcolumns}
              dataSource={dgdataSource}
              style={gridStyle}
              // showZebraRows={false}
              enableClipboard
            />
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                justifyContent: "flex-end",
                marginLeft: "auto",
                marginRight: 0
              }}
            >
              <Button
                style={{ textTransform: "none", fontFamily: "kanit" }}
                variant="text"
                size="small"
                sx={{ width: 120 }}
                startIcon={<Icon icon="vscode-icons:file-type-excel2" />}
                onClick={() => {
                  excelclick();
                }}
              >
                Export Excel
              </Button>
            </div>
          </div>
        : <div />}

      <Backdrop
        sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
};

export default Tdata3;
