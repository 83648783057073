import React, { useEffect, useState } from "react";
import axios from "axios";

import moment from "moment";
import "moment/locale/th";

import Header from "./Header";
import Highcharts from "./Highcharts";
import Descript from "./Descript";

import { Container, Box, Grid } from "@mui/material";

import Swal from "sweetalert2";

const Gauge = () => {
  // let min = 0;
  // let max = 600;
  // let random = Math.floor(Math.random() * (+max + 1 - +min)) + +min;
  // var xpm25 = Math.round(random);
  // var xpm25 = 40;

  let xURL = "";
  xURL = "https://www2.ntaqhi.info/api/";

  const [hname, sethname] = useState("");
  const [pm25, setpm25] = useState();

  const [dataChart, setdataChart] = useState([]);

  const [dateRealtime, setdateRealtime] = useState([]);

  useEffect(() => {
    getDataLocation();
  }, []);

  setInterval(() => {
    if (moment().format("mm:ss") == "00:10") {
      window.location.reload();
      // console.log(555);
    }
  }, 2000);

  const getDataLocation = () => {
    var url = new URL(window.location.href);
    let xarea_point = url.searchParams.get("area-point");
    //  console.log(url.searchParams.get("area-point"));

    // let dateed = moment().add(-1, "hours").format("YYYY-MM-DD HH:10");
    // let dateend = (Math.round(new Date(dateed).getTime() / 1000) - 600) * 1000;

    // let dateed2 = moment().format("YYYY-MM-DD HH:10");
    // let dateend2 =
    //   (Math.round(new Date(dateed2).getTime() / 1000) - 600) * 1000;

    let dateed = moment().format("YYYY-MM-DD HH:00:00");
    let dateed2 = moment().add(1, "hours").format("YYYY-MM-DD HH:00:00");

    axios
      .post(xURL + "getlocation", {
        area_point: xarea_point
      })
      .then(result => {
        if (xarea_point) {
          if (!result.data[0]) {
            Swal.fire({
              icon: "error",
              title: "เกิดข้อผิดพลาด",
              text: "สถานที่นี้ offline หรือ ไม่มี area-point นี้",
              footer: "กรุณากรอก area-point ใหม่"
            });
          } else {
            sethname(result.data[0].name);
            getRealtime(result.data[0].device_id);

            // axios
            //   .post(xURL + "checkoffline", {
            //     device_id: result.data[0].device_id,
            //     sdate: dateend,
            //     edate: dateend2
            //   })
            //   .then(result3 => {
            //     // console.log(result3.data.rowCount);
            //     if (result3.data.rowCount !== 0) {
            axios
              .post(xURL + "getdatagaugeNew", {
                device_id: result.data[0].device_id,
                sdate: dateed,
                edate: dateed2
              })
              .then(result2 => {
                // console.log(result2.data.rows[0].pm2_5);
                if (result2.data[0].pm25) {
                  setpm25(result2.data[0].pm25);
                } else {
                  setpm25(0);
                }
              })
              .catch(function(error) {
                // handle error
                console.log("problem here", error);
              });

            getAQHI(result.data[0].device_id);
            // } else {
            //   setpm25("เครื่องออฟไลน์");
            // }
            // })
            // .catch(function(error) {
            //   // handle error
            //   console.log("problem here", error);
            // });
          }
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const getAQHI = device_id => {
    if (device_id) {
      let dateed = moment().add(-12, "hours").format("YYYY-MM-DD HH:00:00");
      // let dateend =
      //   (Math.round(new Date(dateed).getTime() / 1000) - 600) * 1000;

      let dateed2 = moment().add(1, "hours").format("YYYY-MM-DD HH:00:00");
      // let dateend2 =
      //   (Math.round(new Date(dateed2).getTime() / 1000) - 600) * 1000;

      // console.log(dateed, dateed2, dateend, dateend2);

      axios
        .post(xURL + "getchartgauge2", {
          device_id: device_id,
          sdate: dateed,
          edate: dateed2
        })
        .then(result => {
          if (result.data.length > 0) {
            const Tb1 = [];

            let barColors = "",
              barColors2 = "",
              xmaxaqhi = 0,
              xmaxpm25 = 0;

            result.data.forEach((i, idx) => {
              if (i.aqhi <= 50) {
                barColors = "#00e400";
              } else if (i.aqhi > 50 && i.aqhi <= 100) {
                barColors = "#ffff00";
              } else if (i.aqhi > 100 && i.aqhi <= 150) {
                barColors = "#ff7e00";
              } else if (i.aqhi > 150 && i.aqhi <= 200) {
                barColors = "#ff0000";
              } else if (i.aqhi > 200 && i.aqhi <= 300) {
                barColors = "#99004c";
              } else if (i.aqhi > 300) {
                barColors = "#4c0026";
              }

              if (i.pm25 >= 0 && i.pm25 <= 12) {
                barColors2 = "#00e400";
              } else if (i.pm25 > 12 && i.pm25 <= 35) {
                barColors2 = "#ffff00";
              } else if (i.pm25 > 35 && i.pm25 <= 55) {
                barColors2 = "#ff7e00";
              } else if (i.pm25 > 55 && i.pm25 <= 150) {
                barColors2 = "#ff0000";
              } else if (i.pm25 > 150 && i.pm25 <= 250) {
                barColors2 = "#99004c";
              } else if (i.pm25 > 205) {
                barColors2 = "#4c0026";
              }

              if (i.aqhi > xmaxaqhi) {
                xmaxaqhi = i.aqhi;
              }

              if (i.pm25 > xmaxpm25) {
                xmaxpm25 = i.pm25;
              }

              Tb1.push({
                dates: moment(i.d_date).format("HH:00"),
                aqhi: i.aqhi,
                fill: barColors,
                maxv: xmaxaqhi,
                pm25: i.pm25,
                fill2: barColors2,
                maxv2: xmaxpm25
              });
            });

            setdataChart(Tb1);
          }
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    }
  };

  const getRealtime = device_id => {
    let dateed = moment()
      .utcOffset(7)
      .add(-2, "hours")
      .add(10, "minutes")
      .format("YYYY-MM-DD HH:mm");
    let dateend = (Math.round(new Date(dateed).getTime() / 1000) - 600) * 1000;

    let dateed2 = moment()
      .utcOffset(7)
      .add(10, "minutes")
      .format("YYYY-MM-DD HH:mm");
    let dateend2 =
      (Math.round(new Date(dateed2).getTime() / 1000) - 600) * 1000;

    axios
      .post(xURL + "getrealtimedate", {
        device_id: device_id,
        sdate: dateend,
        edate: dateend2
      })
      .then(result => {
        if (result.data.rows.length > 0) {
          const Tb1 = [];

          result.data.rows.forEach((i, idx) => {
            Tb1.push(moment(i.ts_m).utcOffset(7).format("LT"));
          });
          // console.log(Tb1, Tb2, Tb3, Tb4);

          setdateRealtime(Tb1);
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  return (
    <React.Fragment>
      <div style={{ marginTop: "20px" }} />

      <Header name={hname} />

      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6.5}>
              <Highcharts pm25={pm25} />
            </Grid>
            <Grid item xs={12} sm={5.5}>
              <Descript
                pm25={pm25}
                dataChart={dataChart}
                realtime={dateRealtime}
              />
            </Grid>
            <Grid item xs={12}>
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "20px",
                  marginTop: "-30px"
                }}
              >
                รายละเอียดเพิ่มเติม&nbsp;&nbsp;
                <a
                  style={{ textDecoration: "none" }}
                  href="https://ntaqhi.info/"
                  target="_blank"
                >
                  https://ntaqhi.info/
                </a>
              </p>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default Gauge;
