import React, { useState, useEffect } from "react";

import moment from "moment";
import "moment/locale/th";

import "./css/Header_data.css";

const Header_data = prop => {
  const [Daydata, setDaydata] = useState([""]);

  useEffect(() => {
    let xday = "";

    xday = moment().format("Do MMMM YYYY");
    xday = xday.toString();

    setDaydata(xday);
  }, []);

  return (
    <React.Fragment>
      <div
        style={{
          textAlign: "center",
          marginTop: "0px"
        }}
      >
        <h3 className="font-head">
          {prop.name}
        </h3>
      </div>

      <div
        style={{
          textAlign: "center",
          marginTop: "-20px"
        }}
      >
        <h3 className="font-head2">
          วันที่ {Daydata} เวลา{" "}
          {prop.time1m ? prop.time1m : moment().format("HH:mm")} น.
        </h3>
      </div>
    </React.Fragment>
  );
};

export default Header_data;
