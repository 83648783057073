import React, { useEffect, useState } from "react";
import axios from "axios";

import moment from "moment";
import "moment/locale/th";

import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";

import "./css/IndexAqhi.css";

import Header from "./Header";

import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const IndexAqhi = () => {
  let xURL = "";
  xURL = "https://www2.ntaqhi.info/api/";

  const [hname, sethname] = useState("อันดับคุณภาพอากาศรายชั่วโมง");
  const [dgdataSource, setdgdataSource] = useState([]);

  useEffect(() => {
    getIndex();
  }, []);

  const getIndex = name => {
    let dateed = moment().utcOffset(7).format("YYYY-MM-DD HH:00:00");
    let xapi = "";
    //  console.log(dateed);

    if (!name) {
      xapi = "getindex_aqhi";
    } else {
      xapi = "getfind_aqhi";
    }
    // console.log(xapi);
    axios
      .post(xURL + xapi, { datehr: dateed, name: name, type: "hr" })
      .then(result => {
        const Tb1 = [];
        let xbcolor = "",
          xcolor = "",
          xarea_point = "";

        result.data.forEach((i, idx) => {
          if (i.aqhi <= 50) {
            xbcolor = "#00e400";
            xcolor = "#000000";
          } else if (i.aqhi > 50 && i.aqhi <= 100) {
            xbcolor = "#ffff00";
            xcolor = "#000000";
          } else if (i.aqhi > 100 && i.aqhi <= 150) {
            xbcolor = "#ff7e00";
            xcolor = "#000000";
          } else if (i.aqhi > 150 && i.aqhi <= 200) {
            xbcolor = "#ff0000";
            xcolor = "#ffffff";
          } else if (i.aqhi > 200 && i.aqhi <= 300) {
            xbcolor = "#99004c";
            xcolor = "#ffffff";
          } else if (i.aqhi > 300) {
            xbcolor = "#4c0026";
            xcolor = "#ffffff";
          }

          xarea_point = "/?area-point=" + i.area_point;

          // xarea_point =
          //   "http://localhost:3000/aqhi_now/?area-point=" + i.area_point;

          let xamphures = "",
            xcity = "";

          if (i.amphures !== "null") {
            xamphures = "อ." + i.amphures;
          }
          if (i.d_city !== "null") {
            xcity = "จ." + i.d_city;
          }

          Tb1.push({
            no: idx + 1,
            entity: (
              <a
                style={{ textDecoration: "none" }}
                href={xarea_point}
                target="_blank"
              >
                {i.d_name + " " + xamphures + " " + xcity}
              </a>
            ),
            aqhi: (
              <p
                className="aqhi-style"
                style={{
                  backgroundColor: xbcolor,
                  color: xcolor
                }}
              >
                {i.aqhi}
              </p>
            ),
            pm25: i.pm25
          });
        });
        // console.log(Tb1);
        setdgdataSource(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const dgcolumns = [
    {
      name: "no",
      header: "No.",
      maxWidth: 70,
      minWidth: 50,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false
    },
    {
      name: "entity",
      header: "สถานีตรวจวัด",
      maxWidth: 550,
      minWidth: 120,
      defaultFlex: 1,
      headerAlign: "center",
      //   textAlign: "center",
      sortable: false
    },
    {
      name: "aqhi",
      header: "AQHI (1 ชม.)",
      maxWidth: 250,
      minWidth: 70,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false
    },
    {
      name: "pm25",
      header: "PM2.5 (1 ชม.)",
      maxWidth: 250,
      minWidth: 70,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false
    }
  ];

  const gridStyle = { minHeight: 643 };

  const scrollProps = Object.assign(
    {},
    ReactDataGrid.defaultProps.scrollProps,
    {
      autoHide: false
    }
  );

  const searchname = values => {
    // console.log(values);
    getIndex(values);
  };

  return (
    <React.Fragment>
      <Header name={hname} pages={3} />

      <TextField
        margin="normal"
        size="small"
        id="search_name"
        label="ค้นหาสถานีตรวจวัด"
        name="search_name"
        // autoFocus
        sx={{ width: 320 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          )
        }}
        onChange={event => {
          searchname(event.target.value);
        }}
      />

      <ReactDataGrid
        idProperty="id"
        columns={dgcolumns}
        dataSource={dgdataSource}
        style={gridStyle}
        // showZebraRows={false}
        enableClipboard
        scrollProps={scrollProps}
      />

      <div style={{ height: "50px" }} />
    </React.Fragment>
  );
};

export default IndexAqhi;
