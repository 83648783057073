import React from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsAccessibility from "highcharts/modules/accessibility";

HighchartsExporting(Highcharts);
HighchartsMore(Highcharts);
HighchartsAccessibility(Highcharts);

const Highcharts_realtime = prop => {
  let xtitle1 = "",
    xtitle2 = "";

  if (prop.device1 == "513") {
    xtitle1 = " (Outdoor)";
    xtitle2 = " (Indoor)";
  }

  const getOptions = () => ({
    title: {
      text: ""
    },
    subtitle: {
      text: ""
    },
    chart: {
      height: "450px",
      plotBorderColor: "#000",
      plotBorderWidth: 1
    },
    yAxis: {
      title: {
        text: ""
      }
    },
    xAxis: {
      categories: prop.dateRealtime,
      crosshair: true,
      tickInterval: 30
    },
    legend: {
      layout: "vertical",
      align: "center",
      verticalAlign: "bottom"
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false
        }
      }
    },
    series: [
      {
        name:
          "PM2.5 ID" + prop.device1 + " " + prop.device_name1 + "" + xtitle1,
        data: prop.dataRealtime1,
        color: "red"
      },
      {
        name:
          "PM2.5 ID" + prop.device2 + " " + prop.device_name2 + "" + xtitle2,
        data: prop.dataRealtime2,
        color: "#009874"
      }
    ],
    exporting: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom"
            }
          }
        }
      ]
    }
  });

  return (
    <React.Fragment>
      <div>
        <HighchartsReact highcharts={Highcharts} options={getOptions()} />
      </div>
    </React.Fragment>
  );
};

export default Highcharts_realtime;
