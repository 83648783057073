import React, { useState, useEffect } from "react";
import axios from "axios";

import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";

import moment from "moment";
import "moment/locale/th";

import {
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
  Backdrop,
  CircularProgress
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import { Icon } from "@iconify/react";

import Swal from "sweetalert2";
import * as xlsx from "xlsx";

const Tindex = () => {
  let xURL = "";
  xURL = "https://www2.ntaqhi.info/api/";

  const [Yeardata, setYeardata] = useState([""]);
  const [dYearName, setdYearName] = useState([""]);

  const [dgdataSource, setdgdataSource] = useState([]);

  const [open, setOpen] = useState(false);
  const [show, setshow] = useState(false);

  const [xHeight, setxHeight] = useState(523);

  useEffect(() => {
    getYear();
  }, []);

  const bclearClick = () => {
    window.location.reload(false);
  };

  function getYear() {
    var xyear = 0;
    let tb = [];

    xyear = moment().format("YYYY");

    tb.push("");
    for (let i = 0; i < 10; i++) {
      let oyear = parseInt(xyear - i);

      if (oyear >= 2018) {
        tb.push(parseInt(xyear - i));
      }
    }

    setYeardata(tb);
  }

  const handleSubmit = event => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (dYearName == "") {
      Swal.fire({
        title: "ค้นหาไม่ได้!",
        text: "ยังไม่ได้เลือก ปี",
        icon: "warning",
        showConfirmButton: false,
        timer: 2000,
        customClass: {
          container: "my-swal"
        }
      });
    } else {
      getData();
    }
  };

  const getData = () => {
    // console.log(dYearName);
    setOpen(!open);

    axios
      .post(xURL + "getindex", {
        years: dYearName
      })
      .then(result => {
        // console.log(result.data);
        setshow(true);

        const Tb1 = [];
        let htable = 40;

        let xamphures = "",
          xcity = "";

        result.data.forEach((i, idx) => {
          if (i.amphures) {
            xamphures = " อ." + i.amphures;
          } else {
            xamphures = "";
          }

          if (i.d_city) {
            xcity = " จ." + i.d_city;
          } else {
            xcity = "";
          }

          Tb1.push({
            no: idx + 1,
            entity: i.name + "" + xamphures + "" + xcity,
            pm25: i.pm25
          });

          htable = htable + 40;

          if (idx > 19) {
            htable = 523 + 320;
          }
        });
        // console.log(Tb1);
        setxHeight(htable);
        setdgdataSource(Tb1);

        setOpen(false);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const excelclick = () => {
    let xdata = [];
    let header = ["No.", "สถานีตรวจวัด", "PM2.5 รายปี"];

    xdata.push(["อันดับคุณภาพอากาศ (จุดตรวจของ NTAQHI)"]);
    xdata.push(["ปี " + dYearName]);
    xdata.push([""]);
    xdata.push(header);

    for (var row in dgdataSource) {
      const xx = [];

      for (var col in dgdataSource[row]) {
        xx.push(dgdataSource[row][col]);
      }

      xdata.push(xx);
    }

    var wb = xlsx.utils.book_new(),
      ws = xlsx.utils.json_to_sheet(xdata, { skipHeader: true });

    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    xlsx.writeFile(wb, "อันดับคุณภาพอากาศ (จุดตรวจของ NTAQHI).xlsx");
  };

  const handleChange = event => {
    setdYearName(event.target.value);
  };

  const dgcolumns = [
    {
      name: "no",
      header: "No.",
      maxWidth: 100,
      minWidth: 70,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false
    },
    {
      name: "entity",
      header: "สถานีตรวจวัด",
      maxWidth: 750,
      minWidth: 200,
      defaultFlex: 1,
      headerAlign: "center",
      // textAlign: "center",
      sortable: false
    },
    {
      name: "pm25",
      header: "PM2.5 รายปี",
      maxWidth: 250,
      minWidth: 100,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false
    }
  ];

  const gridStyle = { minHeight: xHeight };

  const scrollProps = Object.assign(
    {},
    ReactDataGrid.defaultProps.scrollProps,
    {
      autoHide: false
    }
  );

  return (
    <React.Fragment>
      <p>
        เลือกปีที่ต้องการค้นข้อมูล แล้วกดปุ่มค้นหาเพื่อแสดงค่า PM2.5
        เฉลี่ยรายปีของสถานีตรวจวัดในเครือข่าย NTAQHI เรียงลำดับจากสูงสุด
        (คุณภาพอากาศแย่ที่สุด) ไปต่ำสุด (คุณภาพอากาศดีที่สุด)
      </p>

      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <FormControl margin="normal" required>
          <InputLabel id="demo-select-small">ปี</InputLabel>
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            size="small"
            name="year"
            value={dYearName}
            label="ปี"
            onChange={handleChange}
            sx={{ width: 150 }}
          >
            {Yeardata.map(Yeardata =>
              <MenuItem key={Yeardata} value={Yeardata}>
                {Yeardata}
              </MenuItem>
            )}
          </Select>
        </FormControl>

        <br />
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 1, mr: 1, width: 150 }}
          startIcon={<SearchIcon />}
        >
          ค้นหา
        </Button>

        <Button
          variant="contained"
          color="error"
          sx={{ mt: 1, width: 150 }}
          startIcon={<CleaningServicesIcon />}
          onClick={() => {
            bclearClick();
          }}
        >
          เคลียร์
        </Button>

        <br />
      </Box>

      <br />
      <ReactDataGrid
        idProperty="id"
        columns={dgcolumns}
        dataSource={dgdataSource}
        style={gridStyle}
        // showZebraRows={false}
        enableClipboard
        scrollProps={scrollProps}
      />

      {show
        ? <div
            style={{
              display: "flex",
              marginTop: "10px",
              justifyContent: "flex-end",
              marginLeft: "auto",
              marginRight: 0
            }}
          >
            <Button
              style={{ textTransform: "none", fontFamily: "kanit" }}
              variant="text"
              size="small"
              sx={{ width: 120 }}
              startIcon={<Icon icon="vscode-icons:file-type-excel2" />}
              onClick={() => {
                excelclick();
              }}
            >
              Export Excel
            </Button>
          </div>
        : <div />}

      <Backdrop
        sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
};

export default Tindex;
