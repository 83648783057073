import React, { useState, useEffect } from "react";

import { Grid } from "@mui/material";

import "./css/setImg.css";

import Pic_CleanRoom01 from "../../imgs/clean_room_Header1_small.png";
import Pic_CleanRoom02 from "../../imgs/clean_room_Header2_small.png";
import Pic_CleanRoomT1 from "../../imgs/clean_room_Topic1.png";
import Pic_CleanRoomT2 from "../../imgs/clean_room_Topic2.png";
import Pic_CleanRoomT3 from "../../imgs/clean_room_Topic3.png";

const Learning = () => {
  return (
    <React.Fragment>
      <div>
        <a href="/safezone/detail" target="_blank">
          <img
            style={{
              maxWidth: "90%",
              height: "auto",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto"
            }}
            className="img-artboard"
            src={Pic_CleanRoom01}
            alt="CleanRoom"
          />
        </a>

        <a href="/safezone/plan" target="_blank">
          <img
            style={{
              maxWidth: "90%",
              height: "auto",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto"
            }}
            className="img-artboard"
            src={Pic_CleanRoom02}
            alt="CleanRoom2"
          />
        </a>

        <Grid sx={{ mt: 1 }} container spacing={2}>
          <Grid className="Grid-style" item xs={4}>
            <a href="/safezone/protection" target="_blank">
              <img
                style={{
                  width: "100%",
                  height: "90%"
                }}
                className="img-artboard"
                src={Pic_CleanRoomT1}
                alt="CleanRoomT1"
              />
            </a>
          </Grid>
          <Grid className="Grid-style" item xs={4}>
            <a href="/safezone/purification" target="_blank">
              <img
                style={{
                  width: "100%",
                  height: "90%"
                }}
                className="img-artboard"
                src={Pic_CleanRoomT2}
                alt="CleanRoomT2"
              />
            </a>
          </Grid>
          <Grid className="Grid-style" item xs={4}>
            <a href="/safezone/air_fulfil" target="_blank">
              <img
                style={{
                  width: "100%",
                  height: "90%"
                }}
                className="img-artboard"
                src={Pic_CleanRoomT3}
                alt="CleanRoomT3"
              />
            </a>
          </Grid>
        </Grid>
      </div>

      <em>
        <p style={{ marginTop: "30px" }}>
          * กรุณาคลิกที่รูปภาพหรือตัวเลขเพื่อดูรายละเอียดเพิ่มเติม
        </p>
      </em>
    </React.Fragment>
  );
};

export default Learning;
