import React from "react";

import "./css/Descript.css";

import { Card, Box, Grid, Stack } from "@mui/material";

import DehazeIcon from "@mui/icons-material/Dehaze";
import AppsIcon from "@mui/icons-material/Apps";
import MicrosoftIcon from "@mui/icons-material/Microsoft";
import DeviceThermostatIcon from "@mui/icons-material/DeviceThermostat";
import WaterDropIcon from "@mui/icons-material/WaterDrop";

const Descript = prop => {
  let xpm25 = prop.pm25,
    xhead1 = "",
    xhead2 = "",
    xadvice1 = "",
    xadvice2 = "",
    xaqhi = "",
    xmarginTop = "35px",
    xfontsize = "",
    xcolor = "",
    xcolor_cha = "";

  //AQHI
  if (xpm25 <= 12) {
    xaqhi = Math.round(4.1857 * parseInt(xpm25) + 0.0524).toString();
  } else if (xpm25 > 12 && xpm25 <= 35) {
    xaqhi = Math.round(2.1 * parseInt(xpm25) + 25.7).toString();
  } else if (xpm25 > 35 && xpm25 <= 55) {
    xaqhi = Math.round(2.4762 * parseInt(xpm25) + 13).toString();
  } else if (xpm25 > 55 && xpm25 <= 150) {
    xaqhi = Math.round(0.5161 * parseInt(xpm25) + 122.1).toString();
  } else if (xpm25 > 150 && xpm25 <= 205) {
    xaqhi = Math.round(parseInt(xpm25) + 50).toString();
  } else if (xpm25 > 205 && xpm25 <= 350) {
    xaqhi = Math.round(parseInt(xpm25) + 50).toString();
  } else if (xpm25 > 305 && xpm25 <= 500) {
    xaqhi = Math.round(0.667 * parseInt(xpm25) + 167).toString();
  } else if (xpm25 > 500) {
    xaqhi = "Beyond AQHI";
  } else {
    xaqhi = "-";
  }

  if (xaqhi >= 0 && xaqhi <= 50) {
    xhead1 = "";
    xhead2 = "";

    xadvice1 = "ประชาชนทุกคนใช้ชีวิตได้ตามปกติ";
    xadvice2 = "";

    xmarginTop = "20px";

    xfontsize = "17px";

    xcolor = "#00e400";
    xcolor_cha = "#000000";
  } else if (xaqhi > 50 && xaqhi <= 100) {
    xhead1 = "";
    xhead2 = "";

    xadvice1 =
      "ใช้ชีวิตได้ตามปกติ (ผู้ที่มีความไวต่อมลพิษมากกว่าคนทั่วไป ควรลดกิจกรรมนอกอาคารที่ใช้แรงหนักหรือเป็นเวลานาน)";
    xadvice2 = "";

    xmarginTop = "20px";

    xfontsize = "16px";

    xcolor = "#ffff00";
    xcolor_cha = "#000000";
  } else if (xaqhi > 100 && xaqhi <= 150) {
    xhead1 = "กลุ่มเสี่ยง";
    xhead2 = "ประชาชนทั่วไป";

    xadvice1 = "ลดกิจกรรมนอกอาคารที่ใช้แรงหนัก หรือเป็นเวลานาน";
    xadvice2 = "ใช้ชีวิตได้ตามปกติ";

    xfontsize = "16px";

    xcolor = "#ff7e00";
    xcolor_cha = "#000000";
  } else if (xaqhi > 150 && xaqhi <= 200) {
    xhead1 = "กลุ่มเสี่ยง";
    xhead2 = "ประชาชนทั่วไป";

    xadvice1 = "งดกิจกรรมนอกอาคารที่ใช้แรงหนัก หรือเป็นเวลานาน";
    xadvice2 = "ลดกิจกรรมนอกอาคารที่ใช้แรงหนัก หรือเป็นเวลานาน";

    xfontsize = "16px";

    xcolor = "#ff0000";
    xcolor_cha = "#ffffff";
  } else if (xaqhi > 200 && xaqhi <= 300) {
    xhead1 = "กลุ่มเสี่ยง";
    xhead2 = "ประชาชนทั่วไป";

    xadvice1 = "งดกิจกรรมนอกอาคารทุกชนิด";
    xadvice2 = "งดกิจกรรมนอกอาคารที่ใช้แรงหนัก หรือเป็นเวลานาน";

    xfontsize = "16px";

    xcolor = "#99004c";
    xcolor_cha = "#ffffff";
  } else if (xaqhi > 300 && xaqhi <= 500) {
    xhead1 = "";
    xhead2 = "";

    xadvice1 = "งดกิจกรรมนอกอาคารทุกชนิด";
    xadvice2 = "";

    xmarginTop = "20px";

    xfontsize = "17px";

    xcolor = "#4c0026";
    xcolor_cha = "#ffffff";
  } else {
    xhead1 = "";
    xhead2 = "";

    if (xaqhi == "-") {
      xadvice1 = "-";
    } else {
      xadvice1 = "งดกิจกรรมนอกอาคารทุกชนิด";
    }

    xadvice2 = "";

    xmarginTop = "20px";

    xfontsize = "17px";

    xcolor = "#4c0026";
    xcolor_cha = "#ffffff";
  }

  return (
    <React.Fragment>
      <Card
        className="cardmagin"
        sx={{ width: "100%" }}
        style={{
          border: "2px solid",
          borderColor: xcolor
        }}
      >
        <p
          style={{
            textAlign: "center",
            color: "#000000",
            fontSize: "18px"
          }}
        >
          การป้องกันมลพิษทางอากาศ
        </p>
      </Card>

      <Card
        sx={{ width: "101%" }}
        style={{
          // border: "3px solid #4FC0D0",
          marginTop: "-5px",
          backgroundColor: xcolor
        }}
      >
        {xhead1
          ? <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <p
                    style={{
                      color: xcolor_cha,
                      fontSize: "16px",
                      marginLeft: "10px"
                    }}
                  >
                    <ins>
                      {xhead1}
                    </ins>
                  </p>
                </Grid>
                <Grid item xs={9}>
                  <p
                    style={{
                      color: xcolor_cha,
                      fontSize: "16px"
                    }}
                  >
                    {xadvice1}
                  </p>
                </Grid>
                <Grid item xs={3}>
                  <p
                    style={{
                      color: xcolor_cha,
                      fontSize: "16px",
                      marginTop: "-25px",
                      marginLeft: "10px"
                    }}
                  >
                    <ins>
                      {xhead2}
                    </ins>
                  </p>
                </Grid>
                <Grid item xs={9}>
                  <p
                    style={{
                      color: xcolor_cha,
                      fontSize: "16px",
                      marginTop: "-25px"
                    }}
                  >
                    {xadvice2}
                  </p>
                </Grid>
              </Grid>
            </Box>
          : <p
              style={{
                textAlign: "center",
                color: xcolor_cha,
                fontSize: xfontsize,
                marginTop: xmarginTop
              }}
            >
              {xadvice1}
            </p>}
      </Card>
    </React.Fragment>
  );
};

export default Descript;
