import React, { useState } from "react";

import PropTypes from "prop-types";
import { Container, Tabs, Tab, Box, AppBar } from "@mui/material";

import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Contact from "../../components/Contact";

// import Header from "./Header";
import Learning from "./Learning";
import Map from "./Map";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index &&
        <Box sx={{ p: 3 }}>
          {children}
        </Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const Safezone = () => {
  const [value, setValue] = useState(0);

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Navbar />

      <Container>
        <div style={{ marginTop: "100px" }} />

        <div className="lineLeft">
          <Box
            sx={{
              width: "100%"
            }}
          >
            <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
              <AppBar position="static" style={{ background: "#F0F5FF" }}>
                <Tabs
                  value={value}
                  onChange={TabChange}
                  aria-label="basic tabs example"
                  variant="fullWidth"
                  indicatorColor="primary"
                  textColor="secondary"
                >
                  <Tab
                    icon={<CastForEducationIcon />}
                    label={
                      <p
                        style={{
                          marginTop: "-2px",
                          marginBottom: "-2px",
                          fontWeight: "bold",
                          fontFamily: "kanit"
                        }}
                      >
                        ศูนย์เรียนรู้การทำห้องปลอดฝุ่น
                      </p>
                    }
                    {...a11yProps(0)}
                  />
                  <Tab
                    icon={<MapsHomeWorkIcon />}
                    label={
                      <p
                        style={{
                          marginTop: "-2px",
                          marginBottom: "-2px",
                          fontWeight: "bold",
                          fontFamily: "kanit"
                        }}
                      >
                        แผนที่ห้องปลอดฝุ่น
                      </p>
                    }
                    {...a11yProps(1)}
                  />
                </Tabs>
              </AppBar>
            </Box>
            <TabPanel value={value} index={0}>
              <Learning />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Map />
            </TabPanel>
          </Box>
        </div>
      </Container>

      <Contact />
      <Footer />
    </React.Fragment>
  );
};

export default Safezone;
