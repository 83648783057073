import React, { useState, useEffect } from "react";
import axios from "axios";

import PropTypes from "prop-types";
import { Container, Tabs, Tab, Box, AppBar } from "@mui/material";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

import Header from "./Header";
import Tdata_month from "./Tdata_month";
import Chart_data from "./Chart_data";
import Remark from "./Remark";
import Chart_data_Year from "./Chart_data_Year";

import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";

import moment from "moment";
import "moment/locale/th";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index &&
        <Box sx={{ p: 3 }}>
          {children}
        </Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const Calibration = () => {
  let xURL = "";
  xURL = "https://www2.ntaqhi.info/api/";

  const [dateLast, setdateLast] = useState();

  useEffect(() => {
    getDate();
  }, []);

  const getDate = () => {
    axios
      .get(xURL + "getdatelast")
      .then(result => {
        // result.data.forEach((i, idx) => {});

        // console.log(result.data[0].d_date);
        setdateLast(result.data[0].d_date);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const [value, setValue] = useState(0);

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Navbar />

      <Container>
        <div style={{ marginTop: "80px" }}>
          <Header />

          <div className="lineLeft">
            <Box
              sx={{
                width: "100%"
              }}
            >
              <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
                <AppBar position="static" style={{ background: "#F0F5FF" }}>
                  <Tabs
                    value={value}
                    onChange={TabChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="secondary"
                  >
                    <Tab
                      icon={<OnlinePredictionIcon />}
                      label={
                        <p
                          style={{
                            marginTop: "-2px",
                            marginBottom: "-2px",
                            fontWeight: "bold",
                            fontFamily: "kanit"
                          }}
                        >
                          สอบเทียบข้อมูลรายสัปดาห์
                        </p>
                      }
                      {...a11yProps(0)}
                    />
                    <Tab
                      icon={<OnlinePredictionIcon />}
                      label={
                        <p
                          style={{
                            marginTop: "-2px",
                            marginBottom: "-2px",
                            fontWeight: "bold",
                            fontFamily: "kanit"
                          }}
                        >
                          สอบเทียบข้อมูลรายปี
                        </p>
                      }
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </AppBar>
              </Box>

              <TabPanel value={value} index={0}>
                <p style={{ fontSize: "16px" }}>
                  การสอบเทียบภาคสนามระหว่างเครื่องตรวจวัดคุณภาพอากาศด้วยเซนเซอร์ขนาดเล็กของ
                  NTAQHI กับเครื่องวัดมาตรฐานของกรมควบคุมมลพิษ (PCD)
                  ที่อยู่ในบริเวณใกล้เคียงกัน โดยนำข้อมูลค่า PM2.5
                  เฉลี่ยย้อนหลัง 24 ชั่วโมง มาเปรียบเทียบกัน
                  เพื่อให้เกิดความมั่นใจว่าข้อมูลจากทั้ง 2
                  แหล่งมีความเที่ยงตรงและใกล้เคียงกัน
                </p>

                <p style={{ fontSize: "16px", color: "#1452E1" }}>
                  ข้อมูลถึงวันที่ {dateLast}
                </p>

                <Tdata_month />

                <Chart_data />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Chart_data_Year />
              </TabPanel>
            </Box>
          </div>

          <Remark />
        </div>
      </Container>

      <Footer />
    </React.Fragment>
  );
};

export default Calibration;
