import React from "react";
import { Container } from "@mui/material";

import Header from "./Header";
import Maindata from "./Maindata";

const Home = () => {
  return (
    <React.Fragment>
      <Header />

      <Maindata />
    </React.Fragment>
  );
};

export default Home;
