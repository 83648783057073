import React from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsAccessibility from "highcharts/modules/accessibility";

HighchartsExporting(Highcharts);
HighchartsMore(Highcharts);
HighchartsAccessibility(Highcharts);

const Scatter_chart = prop => {
  const series = [
    {
      name: "PM2.5",
      id: "pm25",
      marker: {
        symbol: "circle"
      },
      data: prop.dataChart.map(e => ({
        x: e.pm25V1,
        y: e.pm25V2,
        name: e.dates
      }))
    }
  ];

  const getOptions = () => ({
    chart: {
      type: "scatter",
      zoomType: "xy",
      height: "350px",
      plotBorderColor: "#000",
      plotBorderWidth: 1
    },
    title: {
      text: ""
    },
    subtitle: {
      text: ""
    },
    xAxis: {
      title: {
        text: "ID" + prop.device2
      },
      labels: {
        format: "{value}"
      }
    },
    yAxis: {
      title: {
        text: prop.device1
      },
      labels: {
        format: "{value}"
      }
    },
    legend: {
      enabled: true
    },
    plotOptions: {
      scatter: {
        marker: {
          radius: 2.5,
          symbol: "circle",
          states: {
            hover: {
              enabled: true,
              lineColor: "rgb(100,100,100)"
            }
          }
        },
        states: {
          hover: {
            marker: {
              enabled: false
            }
          }
        },
        jitter: {
          x: 0.005
        }
      }
    },
    tooltip: {
      pointFormat:
        "วันที่ : {point.name} <br/>" +
        prop.device1 +
        ": {point.y} มคก.ลบม.  <br/> ID" +
        prop.device2 +
        ": {point.x} มคก.ลบม."
    },
    series,
    exporting: {
      enabled: false
    },
    credits: {
      enabled: false
    }
  });

  return (
    <React.Fragment>
      <HighchartsReact highcharts={Highcharts} options={getOptions()} />
    </React.Fragment>
  );
};

export default Scatter_chart;
