import React, { useEffect, useState } from "react";

import moment from "moment";
import "moment/locale/th";

const Header = prop => {
  const [Daydata, setDaydata] = useState([""]);
  const [Timedata, setTimedata] = useState([""]);

  useEffect(() => {
    let xday = "",
      xtime = "";

    xday = moment().format("Do MMMM YYYY");
    xtime = moment().format("HH:00");

    xday = xday.toString();

    setDaydata(xday);
    setTimedata(xtime);
  }, []);

  return (
    <React.Fragment>
      <div
        style={{
          textAlign: "center",
          marginTop: "0px"
        }}
      >
        <h3
          style={{
            fontSize: "27px",
            color: "#728FCE"
          }}
        >
          {prop.name}
        </h3>
      </div>

      <div
        style={{
          textAlign: "center",
          marginTop: "-35px"
        }}
      >
        <h3 style={{ fontSize: "19px", color: "#8D918D" }}>
          วันที่ {Daydata} เวลา {Timedata} น.
        </h3>
      </div>
    </React.Fragment>
  );
};
export default Header;
