import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./App.css";

import Aqhi_now from "./pages/aqhi-now/Aqhi_now";
import Calibration from "./pages/calibration/Calibration";
import Olddata from "./pages/olddata/Olddata";
import Infographic from "./pages/infographic/Infographic";
import Gauge from "./pages/gauge/Gauge";
import Qa from "./pages/qa/Qa";
import About from "./pages/about/About";
import Privacy from "./pages/privacy/Privacy";
import Cal from "./pages/cal/Cal";
import Safezone from "./pages/safezone/Safezone";
import BeyondAQI from "./pages/BeyondAQI/BeyondAQI";
import NotFound from "./pages/Notfound";
import Realtime_rihes from "./pages/realtime-rihes/Realtime_rihes";
import Realtime_olddata from "./pages/realtime-rihes/Realtime_olddata";
import Realtime_iaq_test from "./pages/iaq_test/Realtime_iaq_test";
import Cleanroom_Main from "./pages/safezone/Cleanroom_Main";
import Cleanroom_Main2 from "./pages/safezone/Cleanroom_Main2";
import Cleanroom1 from "./pages/safezone/Cleanroom1";
import Cleanroom2 from "./pages/safezone/Cleanroom2";
import Cleanroom3 from "./pages/safezone/Cleanroom3";
import Make_diy from "./pages/safezone/Make_diy";
import Make_air from "./pages/safezone/Make_air";
import Iaq from "./pages/iaq/Iaq";
import Iaq_home from "./pages/iaq/Iaq_home";
import Old_data from "./pages/iaq/Old_data";

// import Map from "./pages/map/Map";
//mobile
// import MobileDemo from "./pages/mobile/Demo1/Mobile";
import Mobile from "./pages/mobile/ntaqhi-app/Home";
//admin
import MapOnline from "./pages/admin/map-online/Maponline";
import Humidity from "./pages/admin/humidity/Humidity";
import Edit_device from "./pages/admin/edit-device/Edit_device";
import Admin_login from "./pages/admin/login/Admin_login";
import Line_alert from "./pages/admin/line-noti/Line_alert";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Aqhi_now />} />
        <Route path="/calibration" element={<Calibration />} />
        <Route path="/olddata" element={<Olddata />} />
        <Route path="/infographic" element={<Infographic />} />
        <Route path="/gauge" element={<Gauge />} />
        <Route path="/qa" element={<Qa />} />
        <Route path="/about" element={<About />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/cal" element={<Cal />} />
        <Route path="/safezone" element={<Safezone />} />
        <Route path="/BeyondAQI" element={<BeyondAQI />} />
        <Route path="/realtime_rihes" element={<Realtime_rihes />} />
        <Route path="/realtime_rihes/IAQ" element={<Realtime_olddata />} />
        <Route path="/IAQ_test" element={<Realtime_iaq_test />} />
        <Route path="/safezone/detail" element={<Cleanroom_Main />} />
        <Route path="/safezone/plan" element={<Cleanroom_Main2 />} />
        <Route path="/safezone/protection" element={<Cleanroom1 />} />
        <Route path="/safezone/purification" element={<Cleanroom2 />} />
        <Route path="/safezone/air_fulfil" element={<Cleanroom3 />} />
        <Route path="/safezone/purification/makeDIY" element={<Make_diy />} />
        <Route path="/safezone/purification/makeAIR" element={<Make_air />} />

        {/* realtime */}

        <Route path="/IAQ" element={<Iaq />} />
        <Route
          path="/IAQ/RIHES1_IAQ"
          element={
            <Iaq_home
              device1="501"
              device2="502"
              page_name="คุณภาพอากาศภายในอาคาร 1 สถาบันวิจัยวิทยาศาสตร์สุขภาพ"
            />
          }
        />
        <Route
          path="/IAQ/RIHES2_IAQ"
          element={
            <Iaq_home
              device1="512"
              device2="515"
              page_name="คุณภาพอากาศภายในอาคารสำนักวิชาการวิจัยวิทยาศาสตร์สุขภาพ"
            />
          }
        />
        <Route
          path="/IAQ/IAQ_test"
          element={
            <Iaq_home
              device1="501"
              device2="104"
              page_name="คุณภาพอากาศภายในห้องผู้อำนวยการ"
            />
          }
        />
        {/* <Route
          path="/IAQ/Pongnoi"
          element={
            <Iaq_home
              device1="513"
              device2="514"
              page_name="คุณภาพอากาศภายในห้องเรียนโรงเรียนบ้านโป่งน้อย"
            />
          }
        /> */}
        <Route
          path="/IAQ/Pasuk41"
          element={
            <Iaq_home
              device1="87"
              device2="504"
              page_name="คุณภาพอากาศภายในห้อง 4/1 โรงเรียนผาสุกมณีจักรมิตรภาพที่ 116"
            />
          }
        />
        <Route
          path="/IAQ/Pasuk42"
          element={
            <Iaq_home
              device1="87"
              device2="506"
              page_name="คุณภาพอากาศภายในห้อง 4/2 โรงเรียนผาสุกมณีจักรมิตรภาพที่ 116"
            />
          }
        />
        <Route
          path="/IAQ/Pasuk43"
          element={
            <Iaq_home
              device1="87"
              device2="508"
              page_name="คุณภาพอากาศภายในห้อง 4/3 โรงเรียนผาสุกมณีจักรมิตรภาพที่ 116"
            />
          }
        />
        <Route
          path="/IAQ/Watkoo4"
          element={
            <Iaq_home
              device1="269"
              device2="509"
              page_name="คุณภาพอากาศภายในห้องป.4 โรงเรียนวัดกู้ (นันทาภิวัฒน์วิทยา)"
            />
          }
        />
        <Route
          path="/IAQ/Watkoo5"
          element={
            <Iaq_home
              device1="269"
              device2="510"
              page_name="คุณภาพอากาศภายในห้องป.5 โรงเรียนวัดกู้ (นันทาภิวัฒน์วิทยา)"
            />
          }
        />

        <Route path="/IAQ/Old_data" element={<Old_data />} />

        {/* admin */}
        <Route path="/admin" element={<Admin_login />} />
        <Route path="/admin/online" element={<MapOnline />} />
        <Route path="/admin/humidity" element={<Humidity />} />
        <Route path="/admin/device" element={<Edit_device />} />
        <Route path="/admin/line_noti" element={<Line_alert />} />
        {/* mobile*/}
        <Route path="/mobile/home" element={<Mobile />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
