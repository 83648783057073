import React from "react";

import "../../components/css/Animation.css";

const Header = () => {
  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <h3
          className="lineUp"
          style={{ fontSize: "28px", color: "#009874", fontWeight: "100" }}
        >
          ผลกระทบต่อสุขภาพ
        </h3>
      </div>
    </React.Fragment>
  );
};

export default Header;
