import React, { useState, useEffect } from "react";
import axios from "axios";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

import { Backdrop, CircularProgress } from "@mui/material";
import moment from "moment";

const Chart_year = () => {
  let xURL = "";
  xURL = "https://www2.ntaqhi.info/api/";

  const [dataChart, setdataChart] = useState();
  const [dataTable, setdataTable] = useState([]);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    getdataYear();
  }, []);

  const getdataYear = () => {
    setOpen(!open);

    axios
      .get(xURL + "getIAQYear")
      .then(result => {
        const Tb1 = [],
          Tb2 = [];

        result.data.forEach((i, idx) => {
          let xyear = "",
            day5m = 0;

          xyear = i.th_y;
          // day5m =
          //   moment(xyear + "-01", "YYYY-MM").daysInMonth() +
          //   moment(xyear + "-02", "YYYY-MM").daysInMonth() +
          //   moment(xyear + "-03", "YYYY-MM").daysInMonth() +
          //   moment(xyear + "-04", "YYYY-MM").daysInMonth() +
          //   moment(xyear + "-05", "YYYY-MM").daysInMonth();

          if (i.dayOut > i.dayIn) {
            day5m = i.dayOut;
          } else {
            day5m = i.dayIn;
          }

          Tb1.push({
            name: i.th_y,
            Outdoor: i.Outdoor,
            Indoor: i.Indoor
          });

          Tb2.push({
            column: i.th_y,
            Outdoor: i.Outdoor,
            Indoor: i.Indoor,
            maxOut: i.maxpmOut,
            maxIn: i.maxpmIn,
            sum: day5m
          });
        });

        // console.log(Tb2);
        setdataChart(Tb1);
        setdataTable(Tb2);

        setOpen(open);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  return (
    <React.Fragment>
      <ResponsiveContainer width="100%" height={335}>
        <BarChart data={dataChart} margin={{ right: 10, left: 10 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="Outdoor" fill="red" />
          <Bar dataKey="Indoor" fill="#009874" />
        </BarChart>
      </ResponsiveContainer>

      <table className="realtime" style={{ marginTop: "30px" }}>
        <thead>
          <tr style={{ height: "40px" }}>
            <th style={{ width: "400px", border: "1px solid black" }} />

            {dataTable.map(year =>
              <th
                style={{
                  width: "100px",
                  border: "1px solid black"
                }}
              >
                {year.column}
              </th>
            )}
          </tr>
        </thead>

        <tbody>
          <tr style={{ height: "35px" }}>
            <th
              style={{
                textAlign: "left",
                paddingLeft: "15px",
                border: "1px solid black"
              }}
            >
              ค่า PM2.5 เฉลี่ยนอกอาคารในช่วงเวลา 08.00-18.00 น. (มคก./ลบม.)
            </th>

            {dataTable.map(i =>
              <td
                style={{
                  border: "1px solid black",
                  paddingLeft: "15px"
                }}
              >
                {i.Outdoor}
              </td>
            )}
          </tr>
          <tr style={{ height: "35px" }}>
            <th
              style={{
                textAlign: "left",
                paddingLeft: "15px",
                border: "1px solid black"
              }}
            >
              ค่า PM2.5 เฉลี่ยภายในอาคารในช่วงเวลา 08.00-18.00 น. (มคก./ลบม.)
            </th>

            {dataTable.map(i =>
              <td
                style={{
                  border: "1px solid black",
                  paddingLeft: "15px"
                }}
              >
                {i.Indoor}
              </td>
            )}
          </tr>
          <tr style={{ height: "35px" }}>
            <th
              style={{
                textAlign: "left",
                paddingLeft: "15px",
                border: "1px solid black"
              }}
            >
              จำนวนวันที่ PM2.5 นอกอาคารสูงกว่า 35 มคก./ลบม.
            </th>

            {dataTable.map(i =>
              <td
                style={{
                  border: "1px solid black",
                  paddingLeft: "15px"
                }}
              >
                {i.maxOut}
              </td>
            )}
          </tr>
          <tr style={{ height: "35px" }}>
            <th
              style={{
                textAlign: "left",
                paddingLeft: "15px",
                border: "1px solid black"
              }}
            >
              จำนวนวันที่ PM2.5 ในอาคารสูงกว่า 35 มคก./ลบม.
            </th>

            {dataTable.map(i =>
              <td
                style={{
                  border: "1px solid black",
                  paddingLeft: "15px"
                }}
              >
                {i.maxIn}
              </td>
            )}
          </tr>
          <tr style={{ height: "35px" }}>
            <th
              style={{
                textAlign: "left",
                paddingLeft: "15px",
                border: "1px solid black"
              }}
            >
              จำนวนวันทำงานทั้งหมดใน 5 เดือน
            </th>

            {dataTable.map(i =>
              <td
                style={{
                  border: "1px solid black",
                  paddingLeft: "15px"
                }}
              >
                {i.sum}
              </td>
            )}
          </tr>
        </tbody>
      </table>

      <Backdrop
        sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
};

export default Chart_year;
