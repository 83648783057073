import React, { useEffect, useState } from "react";
import axios from "axios";

import moment from "moment";
import "moment/locale/th";

import Header from "./Header";
import Highcharts from "./Highcharts";
import Descript from "./Descript";

import { Box, Grid, Backdrop, CircularProgress } from "@mui/material";

import Swal from "sweetalert2";

const AQHI1Day = () => {
  let xURL = "";
  xURL = "https://www2.ntaqhi.info/api/";

  const geolocationAPI = navigator.geolocation;

  const [hname, sethname] = useState("");
  const [hdetailname, sethdetailname] = useState("");
  const [pm25, setpm25] = useState();

  const [dataChart, setdataChart] = useState([]);

  const [hdevice_id, sethdevice_id] = useState("");

  const [open, setOpen] = useState(false);

  useEffect(() => {
    getLocation();
  }, []);

  const getLocation = () => {
    setOpen(!open);

    var url = new URL(window.location.href);
    let xarea_point = url.searchParams.get("area-point");
    let xPages = url.searchParams.get("pages");

    // let dateed = moment().add(-1, "days").format("YYYY-MM-DD HH:10");
    // let dateend = (Math.round(new Date(dateed).getTime() / 1000) - 600) * 1000;

    // let dateed2 = moment().format("YYYY-MM-DD HH:10");
    // let dateend2 =
    //   (Math.round(new Date(dateed2).getTime() / 1000) - 600) * 1000;

    let dateed = moment().utcOffset(7).format("YYYY-MM-DD HH:00:00");

    //  console.log(dateend, dateend2);
    let datechklo = moment().format("YYYY-MM-DD HH:00");

    if (!xarea_point) {
      geolocationAPI.getCurrentPosition(position => {
        const { coords } = position;

        // console.log(coords.latitude, coords.longitude);
        //  console.log(coords.latitude.toFixed(1), coords.longitude.toFixed(1));

        axios
          .post(xURL + "getlocation2", {
            lat: coords.latitude,
            long: coords.longitude,
            datechklo: datechklo
          })
          .then(result => {
            sethname(result.data[0].name);

            // axios
            //   .post(xURL + "checkoffline2", {
            //     device_id: result.data[0].device_id,
            //     sdate: dateend,
            //     edate: dateend2
            //   })
            //   .then(result3 => {
            //     if (result3.data.rowCount !== 0) {
            axios
              .post(xURL + "getdatagauge2", {
                device_id: result.data[0].device_id,
                sdate: dateed
              })
              .then(result2 => {
                //  console.log(result2.data.rows[0].pm2_5);
                if (result2.data[0].pm2_5) {
                  setpm25(result2.data[0].pm2_5);
                } else {
                  setpm25(0);
                }
              })
              .catch(function(error) {
                // handle error
                console.log("problem here", error);
              });

            getAQHI(result.data[0].device_id);
            // } else {
            //   setpm25("เครื่องออฟไลน์");
            // }

            setOpen(false);
            // })
            // .catch(function(error) {
            //   // handle error
            //   console.log("problem here", error);
            // });
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      });
    } else {
      axios
        .post(xURL + "getlocation", {
          area_point: xarea_point,
          pages: xPages
        })
        .then(result => {
          if (xarea_point) {
            if (!result.data[0]) {
              Swal.fire({
                icon: "error",
                title: "เกิดข้อผิดพลาด",
                text: "สถานที่นี้ offline หรือ ไม่มี area-point นี้",
                footer: "กรุณากรอก area-point ใหม่"
              });

              setOpen(false);
            } else {
              let xdetail = "";

              if (result.data[0].districts) {
                xdetail = "ต." + result.data[0].districts;
              } else {
                xdetail = "";
              }

              if (result.data[0].amphures) {
                xdetail = xdetail + " อ." + result.data[0].amphures;
              } else {
                xdetail = xdetail + "";
              }

              if (result.data[0].provinces) {
                xdetail = xdetail + " จ." + result.data[0].provinces;
              } else {
                xdetail = xdetail + "";
              }

              sethname(result.data[0].name);
              sethdetailname(xdetail);

              sethdevice_id(result.data[0].cmaqhi_device_id);

              // axios
              //   .post(xURL + "checkoffline", {
              //     device_id: result.data[0].device_id,
              //     sdate: dateend,
              //     edate: dateend2
              //   })
              //   .then(result3 => {
              //     // console.log(result3.data.rowCount);
              //     if (result3.data.rowCount !== 0) {
              axios
                .post(xURL + "getdatagauge", {
                  device_id: result.data[0].device_id,
                  sdate: dateed
                })
                .then(result2 => {
                  // console.log(result2.data.rows[0].pm2_5);
                  if (result2.data[0].pm2_5) {
                    setpm25(result2.data[0].pm2_5);
                  } else {
                    setpm25(0);
                  }
                })
                .catch(function(error) {
                  // handle error
                  console.log("problem here", error);
                });

              getAQHI(result.data[0].device_id);
              // } else {
              //   setpm25("เครื่องออฟไลน์");
              // }

              setOpen(false);
              // })
              // .catch(function(error) {
              //   // handle error
              //   console.log("problem here", error);
              // });
            }
          }
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    }
  };

  const getAQHI = device_id => {
    if (device_id) {
      let dateed = moment()
        .utcOffset(7)
        .add(-7, "days")
        .format("YYYY-MM-DD 00:05:00");
      // let dateend =
      //   (Math.round(new Date(dateed).getTime() / 1000) - 600) * 1000;

      let dateed2 = moment().utcOffset(7).format("YYYY-MM-DD 00:05:00");
      // let dateend2 =
      //   (Math.round(new Date(dateed2).getTime() / 1000) - 600) * 1000;

      // console.log(dateed, dateed2, dateend, dateend2);

      axios
        .post(xURL + "getchartgauge3", {
          device_id: device_id,
          sdate: dateed,
          edate: dateed2
        })
        .then(result => {
          if (result.data.length > 0) {
            const Tb1 = [];
            let barColors = "",
              barColors2 = "",
              xmaxaqhi = 0,
              xmaxpm25 = 0;

            result.data.forEach((i, idx) => {
              if (i.aqhi <= 50) {
                barColors = "#00e400";
              } else if (i.aqhi > 50 && i.aqhi <= 100) {
                barColors = "#ffff00";
              } else if (i.aqhi > 100 && i.aqhi <= 150) {
                barColors = "#ff7e00";
              } else if (i.aqhi > 150 && i.aqhi <= 200) {
                barColors = "#ff0000";
              } else if (i.aqhi > 200 && i.aqhi <= 300) {
                barColors = "#99004c";
              } else if (i.aqhi > 300) {
                barColors = "#4c0026";
              }

              if (i.pm25 >= 0 && i.pm25 <= 12) {
                barColors2 = "#00e400";
              } else if (i.pm25 > 12 && i.pm25 <= 35) {
                barColors2 = "#ffff00";
              } else if (i.pm25 > 35 && i.pm25 <= 55) {
                barColors2 = "#ff7e00";
              } else if (i.pm25 > 55 && i.pm25 <= 150) {
                barColors2 = "#ff0000";
              } else if (i.pm25 > 150 && i.pm25 <= 250) {
                barColors2 = "#99004c";
              } else if (i.pm25 > 205) {
                barColors2 = "#4c0026";
              }

              if (i.aqhi > xmaxaqhi) {
                xmaxaqhi = i.aqhi;
              }

              if (i.pm25 > xmaxpm25) {
                xmaxpm25 = i.pm25;
              }

              Tb1.push({
                dates: moment(i.d_date).format("D MMM"),
                aqhi: i.aqhi,
                fill: barColors,
                maxv: xmaxaqhi,
                pm25: i.pm25,
                fill2: barColors2,
                maxv2: xmaxpm25
              });
            });

            setdataChart(Tb1);
          }
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    }
  };

  return (
    <React.Fragment>
      <Header
        name={hname}
        detail={hdetailname}
        deviceId={hdevice_id}
        pages={2}
      />

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6.5}>
            <Highcharts pm25={pm25} pages={2} />
          </Grid>
          <Grid item xs={12} sm={5.5}>
            <Descript pm25={pm25} dataChart={dataChart} pages={2} />
          </Grid>
        </Grid>
      </Box>

      <Backdrop
        sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
};

export default AQHI1Day;
