import React from "react";

import { Container } from "@mui/material";

import "./css/setImg.css";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Contact from "../../components/Contact";

import Pic_CleanRoom from "../../imgs/clean_room0_small.png";

const Cleanroom_Main = () => {
  return (
    <React.Fragment>
      <Navbar />

      <Container>
        <div style={{ marginTop: "100px" }} />

        <img
          style={{
            maxWidth: "75%",
            height: "auto",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto"
          }}
          className="img-artboard"
          src={Pic_CleanRoom}
          alt="CleanRoom"
        />

        <p style={{ marginTop: "30px", fontWeight: 600 }}>
          ห้องทั่วไปในฤดูหมอกควัน
        </p>
        <ol>
          <li>
            ส่วนมากฝุ่น PM2.5 เกิดขึ้นจากภายนอกบ้าน เช่น ควันจากท่อไอเสียรถยนต์
            โรงงานอุตสาหกรรม การเผาวัสดุเหลือใช้ทางเกษตรกรรม และการเผาป่า
            โดยฝุ่น PM2.5 มักเข้าในห้องทางประตูหน้าต่างและรูรั่วต่างๆ
            แต่บางกรณีฝุ่น PM2.5 อาจมีแหล่งกำเนิดจากภายในบ้าน เช่น การทำครัว
            การสูบบุหรี่ การจุดธูป เป็นต้น
          </li>
          <li>
            อาคารหรือบ้านที่เปิดประตูหน้าต่างไว้จะมีฝุ่น PM2.5 เท่ากับภายนอก
          </li>
          <li>
            ห้องแอร์ขนาดใหญ่ที่ซีลไม่สนิท มีช่องให้อากาศภายนอกเข้าออกได้
            หรือมีคนเข้าออกตลอดเวลา เช่น ร้านอาหาร ห้องเรียน ห้องทำงาน
            ห้องรับแขก ห้องประชุม ปริมาณฝุ่น PM2.5 ในห้องจะลดลงได้น้อยและช้ามาก
            หรือแทบจะลดไม่ได้เลย
          </li>
          <li>
            ห้องแอร์ที่ซีลค่อนข้างดี
            การปิดประตูหน้าต่างให้สนิทจะทำให้ฝุ่นควันต่ำกว่าภายนอกราว 20-30%
          </li>
        </ol>
      </Container>

      <div style={{ height: "50px" }} />
      <Contact />
      <Footer />
    </React.Fragment>
  );
};

export default Cleanroom_Main;
