import React from "react";

import { Container, Box, Grid } from "@mui/material";

import "./css/setImg.css";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Contact from "../../components/Contact";

import Pic_Purification1 from "../../imgs/purification1.jpg";
import Pic_Purification2 from "../../imgs/purification2.jpg";
import Pic_Purification3 from "../../imgs/purification3.png";
import Pic_Purification4 from "../../imgs/purification4.png";
import Pic_Purification5 from "../../imgs/purification5.png";
import Pic_Purification6 from "../../imgs/purification6.png";

const Cleanroom2 = () => {
  return (
    <React.Fragment>
      <Navbar />

      <Container>
        <div style={{ marginTop: "100px" }} />
        <p style={{ marginTop: "30px", fontWeight: 600 }}>
          การฟอกอากาศเพื่อลดฝุ่นในห้อง
        </p>

        <ol>
          <li>ติดตั้งเครื่องฟอกอากาศที่เหมาะสมกับขนาดของห้อง</li>
          <li>
            หากไม่มีเครื่องฟอกอากาศ ให้ติดตั้งอุปกรณ์กรองอากาศ DIY ให้มากที่สุด
            เช่น เครื่องฟอกอากาศ DIY การใช้แผ่นกรองอากาศติดที่เครื่องปรับอากาศ
            พัดลม หรือพัดลมไอน้ำ
          </li>
          <li>
            ถ้าห้องไม่มีเครื่องปรับอากาศและอากาศร้อนอบอ้าว
            ควรเปิดพัดลมให้อากาศหมุนเวียนตลอดเวลา
            หากจำเป็นอาจแง้มประตูหน้าต่างได้เป็นครั้งคราว
            โดยเฉพาะอย่างยิ่งในช่วงบ่ายที่ฝุ่น PM2.5 มักจะต่ำลง
          </li>
        </ol>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <img
                style={{
                  maxWidth: "100%",
                  height: "500px",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: "10px"
                }}
                className="img-artboard"
                src={Pic_Purification1}
                alt="Purification1"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <img
                style={{
                  maxWidth: "100%",
                  height: "500px",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: "10px"
                }}
                className="img-artboard"
                src={Pic_Purification2}
                alt="Purification2"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <img
                style={{
                  maxWidth: "100%",
                  height: "400px",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: "10px"
                }}
                className="img-artboard"
                src={Pic_Purification3}
                alt="Purification3"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <img
                style={{
                  maxWidth: "100%",
                  height: "400px",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: "10px"
                }}
                className="img-artboard"
                src={Pic_Purification4}
                alt="Purification4"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <img
                style={{
                  maxWidth: "100%",
                  height: "400px",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: "10px"
                }}
                className="img-artboard"
                src={Pic_Purification5}
                alt="Purification5"
              />
            </Grid>
          </Grid>
        </Box>

        <div style={{ height: "30px" }} />
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={12}>
              <img
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto"
                }}
                className="img-artboard"
                src={Pic_Purification6}
                alt="Purification6"
              />
            </Grid>
          </Grid>
        </Box>
        <a href="/safezone/purification/makeDIY" target="_blank">
          วิธีการทำเครื่องฟอกอากาศ DIY จากกล่องอากาศดี
        </a>
      </Container>

      <div style={{ height: "50px" }} />
      <Contact />
      <Footer />
    </React.Fragment>
  );
};

export default Cleanroom2;
