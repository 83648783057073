import React, { useState, useEffect } from "react";
import axios from "axios";

import moment from "moment";
import "moment/locale/th";

import { Box, Grid } from "@mui/material";

import Header_data from "./Header_data";

import Swal from "sweetalert2";

import Highcharts from "./Highcharts";
import Descript from "./Descript";

const geolocationAPI = navigator.geolocation;

const AQHI1min = () => {
  let xURL = "";
  xURL = "https://www2.ntaqhi.info/api/";

  const geolocationAPI = navigator.geolocation;

  const [hname, sethname] = useState("");
  const [pm25, setpm25] = useState();
  const [time1m, settime1m] = useState();

  useEffect(() => {
    getLocation();
  }, []);

  setInterval(() => {
    if (moment().format("ss") == "00") {
      getLocation();
    }
  }, 1000);

  const getLocation = () => {
    var url = new URL(window.location.href);
    let xarea_point = url.searchParams.get("area-point");

    if (!xarea_point) {
      geolocationAPI.getCurrentPosition(
        position => {
          const { coords } = position;

          axios
            .post(xURL + "getlocation2", {
              lat: coords.latitude,
              long: coords.longitude
            })
            .then(result => {
              // console.log(result.data[0]);

              if (result.data[0]) {
                window.location =
                  "/mobile/home?area-point=" + result.data[0].id;
              }
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });
        },
        error => {
          // console.log("error");
          window.location = "/mobile/home?area-point=50240363";
        }
      );
    } else {
      axios
        .post(xURL + "getlocation", {
          area_point: xarea_point
        })
        .then(result => {
          if (xarea_point) {
            if (result.data[0]) {
              sethname(result.data[0].name);
              getRealtime(result.data[0].device_id);

              // console.log(result.data[0].name);
            }
          }
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    }
  };

  const getRealtime = device_id => {
    let dateed = moment()
      .add(-1, "hours")
      .add(10, "minutes")
      .format("YYYY-MM-DD HH:mm");
    let dateend = (Math.round(new Date(dateed).getTime() / 1000) - 600) * 1000;

    let dateed2 = moment().add(10, "minutes").format("YYYY-MM-DD HH:mm");
    let dateend2 =
      (Math.round(new Date(dateed2).getTime() / 1000) - 600) * 1000;

    // console.log(dateed,dateed2);
    axios
      .post(xURL + "getdatagaugeMobile", {
        device_id: device_id,
        sdate: dateend,
        edate: dateend2
      })
      .then(result => {
        let xtime1m = "";

        if (result.data.rows.length > 0) {
          result.data.rows.forEach((i, idx) => {
            if (i.key == "pm2_5") {
              setpm25(parseInt(i.valuepm));
            }

            xtime1m = moment(i.ts_m).format("LT");
          });
        }

        settime1m(xtime1m);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  return (
    <React.Fragment>
      <Header_data name={hname} pages={1} xtime={time1m} />

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6.5}>
            <Highcharts pm25={pm25} />
          </Grid>
          <Grid item xs={12} sm={5.5}>
            <Descript pm25={pm25} />
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default AQHI1min;
