import React from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsAccessibility from "highcharts/modules/accessibility";

HighchartsExporting(Highcharts);
HighchartsMore(Highcharts);
HighchartsAccessibility(Highcharts);

const Highcharts_realtime = prop => {
  let xURL = "";
  xURL = "https://www2.ntaqhi.info/api/";

  const getOptions = () => ({
    title: {
      text: ""
    },
    subtitle: {
      text: ""
    },
    chart: {
      height: "280px",
      plotBorderColor: "#000",
      plotBorderWidth: 1
    },
    yAxis: {
      title: {
        text: ""
      }
    },
    xAxis: {
      categories: prop.dateRealtime,
      crosshair: true,
      tickInterval: 100
    },
    legend: {
      layout: "vertical",
      align: "center",
      verticalAlign: "bottom"
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false
        }
      }
    },
    series: [
      {
        name: "PM10 (มคก./ลบม.)",
        data: prop.dataRealtime2,
        color: "black"
      },
      {
        name: "PM2.5 (มคก./ลบม.)",
        data: prop.dataRealtime,
        color: "red"
        // zones: [
        //   {
        //     value: 13,
        //     color: "#00e400"
        //   },
        //   {
        //     value: 36,
        //     color: "#ffff00"
        //   },
        //   {
        //     value: 151,
        //     color: "#ff7e00"
        //   },
        //   {
        //     value: 251,
        //     color: "#ff0000"
        //   },
        //   {
        //     value: 301,
        //     color: "#99004c"
        //   },
        //   {
        //     value: 501,
        //     color: "#4c0026"
        //   }
        // ]
      },
      {
        name: "PM1.0 (มคก./ลบม.)",
        data: prop.dataRealtime3,
        color: "grey"
      },
      {
        name: "อุณหภูมิ (°C)",
        data: prop.dataRealtime4,
        color: "#3db159"
      },
      {
        name: "ความชื้นสัมพัทธ์ (%)",
        data: prop.dataRealtime5,
        color: "#5abedc"
      }
    ],
    exporting: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom"
            }
          }
        }
      ]
    }
  });

  return (
    <React.Fragment>
      <div style={{ marginTop: "-30px" }}>
        <p>ค่าฝุ่นควันเรียลไทม์ใน 6 ชั่วโมงที่ผ่านมา</p>
        <HighchartsReact highcharts={Highcharts} options={getOptions()} />
      </div>
    </React.Fragment>
  );
};

export default Highcharts_realtime;
