import React from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsAccessibility from "highcharts/modules/accessibility";

HighchartsExporting(Highcharts);
HighchartsMore(Highcharts);
HighchartsAccessibility(Highcharts);

const Highcharts_realtime2 = prop => {
  let xURL = "";
  xURL = "https://www2.ntaqhi.info/api/";

  const getOptions = () => ({
    title: {
      text: ""
    },
    subtitle: {
      text: ""
    },
    chart: {
      height: "280px",
      plotBorderColor: "#000",
      plotBorderWidth: 1
    },
    yAxis: {
      title: {
        text: ""
      }
    },
    xAxis: {
      categories: prop.dateRealtime,
      crosshair: true,
      tickInterval: 100
    },
    legend: {
      layout: "vertical",
      align: "center",
      verticalAlign: "bottom"
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false
        }
      }
    },
    series: [
      {
        name: "อุณหภูมิ (°C)",
        data: prop.dataRealtime,
        color: "#3db159"
      },
      {
        name: "ความชื้นสัมพัทธ์ (%)",
        data: prop.dataRealtime2,
        color: "#5abedc"
      }
    ],
    exporting: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom"
            }
          }
        }
      ]
    }
  });

  return (
    <React.Fragment>
      <div>
        <p>ค่าอุณหภูมิและความชื้นเรียลไทม์ใน 6 ชั่วโมงที่ผ่านมา</p>
        <HighchartsReact highcharts={Highcharts} options={getOptions()} />
      </div>
    </React.Fragment>
  );
};

export default Highcharts_realtime2;
