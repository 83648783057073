import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";
import { Tabs, Tab, Box, Typography, AppBar, Container } from "@mui/material";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import PublicIcon from "@mui/icons-material/Public";
import ListIcon from "@mui/icons-material/List";

import AQHI1Hr from "./AQHI1Hr";
import AQHI1Day from "./AQHI1Day";
import IndexAqhi from "./IndexAqhi";
import IndexAqhi24hr from "./IndexAqhi24hr";
import MapAqhi from "./MapAqhi";
import MapAqhi24hr from "./MapAqhi24hr";
import AQHI1Min from "./AQHI1Min";
// import Firms_Nasa from "./Firms_nasa";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index &&
        <Box sx={{ p: 2 }}>
          {children}
        </Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

function a11yProps2(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

function a11yProps3(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

function a11yProps4(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const Maindata = () => {
  const [value, setValue] = useState(0);
  const [value2, setValue2] = useState(0);
  const [value3, setValue3] = useState(0);
  const [value4, setValue4] = useState(0);

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };

  const TabChange2 = (event, newValue) => {
    setValue2(newValue);
  };

  const TabChange3 = (event, newValue) => {
    setValue3(newValue);
  };

  const TabChange4 = (event, newValue) => {
    setValue4(newValue);
  };

  useEffect(() => {
    var url = new URL(window.location.href);

    if (url.searchParams.get("tabs")) {
      setValue2(parseInt(url.searchParams.get("tabs")) - 1);
    } else {
      setValue2(0);
    }
  }, []);

  return (
    <React.Fragment>
      <Container>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider", marginTop: "-25px" }}
          >
            <Tabs value={value} onChange={TabChange} variant="fullWidth">
              <Tab
                icon={<LocationOnIcon />}
                label={
                  <span style={{ fontFamily: "kanit" }}>ตำแหน่งปัจจุบัน</span>
                }
                {...a11yProps(0)}
              />
              <Tab
                icon={<PublicIcon />}
                label={<span style={{ fontFamily: "kanit" }}>แผนที่</span>}
                {...a11yProps(1)}
              />
              <Tab
                icon={<ListIcon />}
                label={<span style={{ fontFamily: "kanit" }}>อันดับ</span>}
                {...a11yProps(2)}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Box
              sx={{ width: "104%", marginLeft: "-12px", marginTop: "-15px" }}
            >
              <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
                <Tabs
                  value={value2}
                  onChange={TabChange2}
                  // variant="fullWidth"
                  //   centered
                >
                  <Tab
                    label={
                      <span style={{ fontFamily: "kanit" }}>รายชั่วโมง</span>
                    }
                    {...a11yProps2(0)}
                  />
                  <Tab
                    label={<span style={{ fontFamily: "kanit" }}>รายวัน</span>}
                    {...a11yProps2(1)}
                  />
                  <Tab
                    label={<span style={{ fontFamily: "kanit" }}>รายนาที</span>}
                    {...a11yProps2(2)}
                  />
                </Tabs>
              </Box>

              <TabPanel value={value2} index={0}>
                <div style={{ marginTop: "-25px" }}>
                  <AQHI1Hr />
                </div>
              </TabPanel>

              <TabPanel value={value2} index={1}>
                <div style={{ marginTop: "-25px" }}>
                  <AQHI1Day />
                </div>
              </TabPanel>

              <TabPanel value={value2} index={2}>
                <div style={{ marginTop: "-25px" }}>
                  <AQHI1Min />
                </div>
              </TabPanel>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Box
              sx={{ width: "104%", marginLeft: "-12px", marginTop: "-15px" }}
            >
              <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
                <Tabs
                  value={value3}
                  onChange={TabChange3}
                  // variant="fullWidth"
                  //   centered
                >
                  <Tab
                    label={
                      <span style={{ fontFamily: "kanit" }}>รายชั่วโมง</span>
                    }
                    {...a11yProps3(0)}
                  />
                  <Tab
                    label={<span style={{ fontFamily: "kanit" }}>รายวัน</span>}
                    {...a11yProps3(1)}
                  />
                  {/* <Tab
                    label={
                      <span style={{ fontFamily: "kanit" }}>จุดความร้อน</span>
                    }
                    {...a11yProps3(2)}
                  /> */}
                </Tabs>
              </Box>

              <TabPanel value={value3} index={0}>
                <div style={{ marginTop: "-25px" }}>
                  <MapAqhi />
                </div>
              </TabPanel>

              <TabPanel value={value3} index={1}>
                <div style={{ marginTop: "-25px" }}>
                  <MapAqhi24hr />
                </div>
              </TabPanel>

              {/* <TabPanel value={value3} index={2}>
                <div style={{ marginTop: "-25px" }}>
                  <Firms_Nasa />
                </div>
              </TabPanel> */}
            </Box>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Box
              sx={{ width: "104%", marginLeft: "-12px", marginTop: "-15px" }}
            >
              <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
                <Tabs
                  value={value4}
                  onChange={TabChange4}
                  // variant="fullWidth"
                  //   centered
                >
                  <Tab
                    label={
                      <span style={{ fontFamily: "kanit" }}>รายชั่วโมง</span>
                    }
                    {...a11yProps4(0)}
                  />
                  <Tab
                    label={<span style={{ fontFamily: "kanit" }}>รายวัน</span>}
                    {...a11yProps4(1)}
                  />
                </Tabs>
              </Box>
              <TabPanel value={value4} index={0}>
                <div style={{ marginTop: "-25px" }}>
                  <IndexAqhi />
                </div>
              </TabPanel>

              <TabPanel value={value4} index={1}>
                <div style={{ marginTop: "-25px" }}>
                  <IndexAqhi24hr />
                </div>
              </TabPanel>
            </Box>
          </TabPanel>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default Maindata;
