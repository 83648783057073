import React, { useState } from "react";
import axios from "axios";

import PropTypes from "prop-types";
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab
} from "@mui/material";

import Navbar_Admin from "../../../components/Navbar_Admin";
import Footer from "../../../components/Footer";

import CloseIcon from "@mui/icons-material/Close";

import Swal from "sweetalert2";

const MAX_FILE_SIZE_MB = 1;
const ALLOWED_FILE_TYPES = ["image/jpeg", "image/png", "image/gif"];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index &&
        <Box sx={{ p: 2 }}>
          {children}
        </Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const Line_alert = () => {
  let xURL = "";
  xURL = "https://www2.ntaqhi.info/api/";

  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState(null);

  const dataStatus = [
    { id: 1, name: "On site" },
    { id: 2, name: "รอติดตั้ง" },
    { id: 3, name: "ส่งซ่อม" },
    { id: 4, name: "รอส่งคืน" },
    { id: 0, name: "ย้าย/ยกเลิก" },
    { id: 11, name: "Indoor" },
    { id: 8, name: "ปิดชั่วคราว" }
  ];

  const dataSQuality = [
    { id: 1, name: "ดี (เขียว)" },
    { id: 2, name: "ปานกลาง (เหลือง)" },
    { id: 3, name: "ไม่ดีต่อกลุ่มเสี่ยง (ส้ม)" },
    { id: 4, name: "ไม่ดี (แดง)" },
    { id: 5, name: "ไม่ดีอย่างยิ่ง (ม่วง)" },
    { id: 6, name: "อันตราย (น้ำตาล)" }
  ];

  const [Statusvalue, setStatusValue] = useState(1);
  const [Qualityvalue, setQualityValue] = useState(1);

  const handleSubmit = event => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let xcomplete = 0;

    if (!data.get("msg")) {
      Swal.fire({
        title: "ส่งข้อความไม่ได้!",
        text: "ยังไม่ได้คีย์ ข้อความ",
        icon: "warning",
        showConfirmButton: false,
        timer: 2000,
        customClass: {
          container: "my-swal"
        }
      });
    } else if (error) {
      Swal.fire({
        title: "ส่งข้อความไม่ได้!",
        text: "ไฟล์ภาพไม่ถูกต้อง หรือ ไฟล์ภาพมีขนาดใหญ่เกินไป",
        icon: "warning",
        showConfirmButton: false,
        timer: 2000,
        customClass: {
          container: "my-swal"
        }
      });
    } else {
      // else if (value == 2) {
      //   if (!data.get("linetk")) {
      //     Swal.fire({
      //       title: "ส่งข้อความไม่ได้!",
      //       text: "ยังไม่ได้คีย์ Line Token",
      //       icon: "warning",
      //       showConfirmButton: false,
      //       timer: 2000,
      //       customClass: {
      //         container: "my-swal"
      //       }
      //     });
      //   } else {
      //     xcomplete = 1;
      //   }
      // }
      xcomplete = 1;
    }

    if (xcomplete == 1) {
      Swal.fire({
        title: "ยืนยัน การส่งข้อความ",
        text: ":3",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(result => {
        if (result.isConfirmed) {
          if (value == 0) {
            const formData = new FormData();
            formData.append("file", selectedFile);

            if (!selectedFile) {
              axios
                .post(xURL + "alert_line1", {
                  massage: data.get("msg"),
                  img: "",
                  status: Statusvalue
                })
                .then(result => {
                  // console.log(result.data.length);
                })
                .catch(function(error) {
                  // handle error
                  console.log("problem here", error);
                });
            } else {
              axios.post(xURL + "uploadfile", formData).then(res => {
                axios
                  .post(xURL + "alert_line1", {
                    massage: data.get("msg"),
                    img: res.data.filename,
                    status: Statusvalue
                  })
                  .then(result => {
                    // console.log(result.data.length);
                  })
                  .catch(function(error) {
                    // handle error
                    console.log("problem here", error);
                  });
              });
            }
          } else if (value == 1) {
            const formData = new FormData();
            formData.append("file", selectedFile);

            if (!selectedFile) {
              axios
                .post(xURL + "alert_line3", {
                  massage: data.get("msg"),
                  img: "",
                  quality: Qualityvalue
                })
                .then(result => {
                  // console.log(result.data.length);
                })
                .catch(function(error) {
                  // handle error
                  console.log("problem here", error);
                });
            } else {
              axios.post(xURL + "uploadfile", formData).then(res => {
                axios
                  .post(xURL + "alert_line3", {
                    massage: data.get("msg"),
                    img: res.data.filename,
                    quality: Qualityvalue
                  })
                  .then(result => {
                    // console.log(result.data.length);
                  })
                  .catch(function(error) {
                    // handle error
                    console.log("problem here", error);
                  });
              });
            }
          } else if (value == 2) {
            const formData = new FormData();
            formData.append("file", selectedFile);

            // console.log(selectedFile);
            if (!selectedFile) {
              axios
                .post(xURL + "alert_line2", {
                  massage: data.get("msg"),
                  img: "",
                  // token: data.get("linetk")
                })
                .then(result => {
                  // console.log(result.data.length);
                })
                .catch(function(error) {
                  // handle error
                  console.log("problem here", error);
                });
            } else {
              axios.post(xURL + "uploadfile", formData).then(res => {
                axios
                  .post(xURL + "alert_line2", {
                    massage: data.get("msg"),
                    img: res.data.filename,
                    // token: data.get("linetk")
                  })
                  .then(result => {
                    // console.log(result.data.length);
                  })
                  .catch(function(error) {
                    // handle error
                    console.log("problem here", error);
                  });

                // axios
                //   .post(xURL + "delete_filedb", {
                //     file_name: res.data.filename
                //   })
                //   .then(result => {})
                //   .catch(function(error) {
                //     // handle error
                //     console.log("problem here", error);
                //   });
              });
            }
          }

          Swal.fire({
            title: "ส่งข้อความสำเร็จ!",
            text: "^_^",
            icon: "success",
            showConfirmButton: false,
            timer: 2000
          }).then(result => {
            window.location.reload(false);
          });
        }
      });
    }
  };

  const handleFileChange = event => {
    const file = event.target.files[0];

    // File type validation
    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      setError("Invalid file type. Please upload a JPEG, PNG, or GIF image.");
      return;
    }

    // File size validation
    if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
      setError(
        `File size exceeds ${MAX_FILE_SIZE_MB} MB. Please choose a smaller file.`
      );
      return;
    }

    setSelectedFile(file);
    setError(null);
  };

  const handleChange = event => {
    setStatusValue(event.target.value);
  };
  const handleChange2 = event => {
    setQualityValue(event.target.value);
  };

  const [value, setValue] = useState(0);

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <div style={{ marginBottom: "100px" }} />

      <Navbar_Admin />

      <Container>
        <h2>แจ้งเตือนไลน์</h2>

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            id="msg"
            label="ข้อความ"
            name="msg"
            required
            fullWidth
            size="small"
            multiline
            rows={5}
            helperText="ระบุข้อความที่ต้องการจะส่ง"
            autoFocus
          />

          <Box
            p={3}
            border="1px dashed #ccc"
            borderRadius={4}
            textAlign="center"
            sx={{ mt: 1, mb: 2 }}
          >
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: "none" }}
              id="image-file-input"
            />
            <label htmlFor="image-file-input">
              <Button variant="outlined" component="span">
                แนบไฟล์รูปภาพ
              </Button>
            </label>

            {selectedFile &&
              <div>
                <Typography variant="subtitle1" mt={1}>
                  Selected Image: {selectedFile.name}
                </Typography>
                <IconButton
                  color="primary"
                  aria-label="close"
                  onClick={() => {
                    setSelectedFile(null);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>}
            {error &&
              <Typography variant="body2" color="error" mt={2}>
                {error}
              </Typography>}
          </Box>

          <Box>
            <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={TabChange}
                // variant="fullWidth"
                //   centered
              >
                <Tab
                  label={
                    <span style={{ fontFamily: "kanit" }}>
                      แจ้งเตือนเครื่องวัดตามสถานะ
                    </span>
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  label={
                    <span style={{ fontFamily: "kanit" }}>
                      แจ้งเตือนตามระดับคุณภาพอากาศ
                    </span>
                  }
                  {...a11yProps(1)}
                />
                {/* <Tab
                  label={
                    <span style={{ fontFamily: "kanit" }}>
                      แจ้งเตือนเครื่องวัด 1 จุด
                    </span>
                  }
                  {...a11yProps(2)}
                /> */}
                <Tab
                  label={
                    <span style={{ fontFamily: "kanit" }}>
                      แจ้งเตือนเครื่องวัดที่ออฟไลน์
                    </span>
                  }
                  {...a11yProps(2)}
                />
              </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
              <FormControl margin="normal" style={{ zIndex: 0 }}>
                <InputLabel id="demo-select-small">
                  สถานะของเครื่องวัดที่ต้องการส่งข้อความ
                </InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  size="small"
                  name="status"
                  defaultValue={1}
                  label="สถานะของเครื่องวัดที่ต้องการส่งข้อความ"
                  onChange={handleChange}
                  sx={{ width: 300 }}
                >
                  {dataStatus.map(i =>
                    <MenuItem key={i.id} value={i.id}>
                      {i.name}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </TabPanel>

            <TabPanel value={value} index={1}>
              <FormControl margin="normal" style={{ zIndex: 0 }}>
                <InputLabel id="demo-select-small">
                  ระดับคุณภาพอากาศของเครื่องวัดที่ต้องการส่งข้อความ
                </InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  size="small"
                  name="quality"
                  defaultValue={1}
                  label="ระดับคุณภาพอากาศของเครื่องวัดที่ต้องการส่งข้อความ"
                  onChange={handleChange2}
                  sx={{ width: 300 }}
                >
                  {dataSQuality.map(i =>
                    <MenuItem key={i.id} value={i.id}>
                      {i.name}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </TabPanel>

            {/* <TabPanel value={value} index={2}>
              <TextField
                id="linetk"
                label="Line Token"
                name="linetk"
                required
                sx={{ width: 400 }}
                size="small"
                helperText="Line Token ของเครื่องวัด"
              />
            </TabPanel> */}

            <TabPanel value={value} index={2}>
              * ระบบจะทำการ แจ้งเตือนเครื่องวัดที่ออฟไลน์ เกิน 24 ชั่วโมง
            </TabPanel>
          </Box>

          <hr />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
          >
            ส่งข้อความ
          </Button>
        </Box>
      </Container>

      <Footer />
    </React.Fragment>
  );
};

export default Line_alert;
