import React, { useState, useEffect } from "react";
import axios from "axios";

import moment from "moment";
import "moment/locale/th";

import {
  Container,
  Box,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Button,
  Stack
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";

import Highcharts from "./Highcharts";
import Scatter_chart from "./Scatter_chart";

import Swal from "sweetalert2";

import { Icon } from "@iconify/react";

import * as xlsx from "xlsx";

const Chart_data_Year = () => {
  let xURL = "";
  xURL = "https://www2.ntaqhi.info/api/";

  const Devicedata = [
    "PCD 35t vs. NTAQHI ID11",
    "PCD 36t vs. NTAQHI ID501",
    "PCD o28 vs. NTAQHI ID81",
    "PCD 58t vs. NTAQHI ID99",
    "PCD o29 vs. NTAQHI ID137",
    "PCD 67t vs. NTAQHI ID284",
    "PCD 70t vs. NTAQHI ID279",
    "PCD 76t vs. NTAQHI ID186",
    "PCD 37t vs. NTAQHI ID237",
    "PCD 57t vs. NTAQHI ID216",
    "PCD 69t vs. NTAQHI ID225",
    "PCD 86t vs. NTAQHI ID264",
    "PCD 27t vs. NTAQHI ID511",
    "PCD 22t vs. NTAQHI ID87",
    "PCD 29t vs. NTAQHI ID302",
    "PCD 31t vs. NTAQHI ID304"
  ];
  const [DeviceName, setDeviceName] = useState([""]);

  const [Yeardata, setYeardata] = useState([""]);
  const [dYearName, setdYearName] = useState([""]);

  const [dataChart, setdataChart] = useState();
  const [dataChart2, setdataChart2] = useState();
  const [dataDate, setdataDate] = useState();

  const [Maxdata1, setMaxdata1] = useState();
  const [Maxdata2, setMaxdata2] = useState();
  const [Mindata1, setMindata1] = useState();
  const [Mindata2, setMindata2] = useState();
  const [Avrdata1, setAvrdata1] = useState();
  const [Avrdata2, setAvrdata2] = useState();

  const [dataEx, setdataEx] = useState();

  const [show, setshow] = useState(false);

  useEffect(() => {
    getYear();
    // getData();
  }, []);

  const getYear = () => {
    var xyear = 0;
    let tb = [];

    xyear = moment().format("YYYY");

    tb.push("");
    for (let i = 0; i < 10; i++) {
      let oyear = parseInt(xyear - i);

      if (oyear >= 2019) {
        tb.push(parseInt(xyear - i));
      }
    }

    setYeardata(tb);
  };

  const bclearClick = () => {
    setdYearName([""]);
    setDeviceName([""]);

    setshow(false);
  };

  const bSearchClick = () => {
    if (dYearName == "") {
      Swal.fire({
        title: "ค้นหาไม่ได้!",
        text: "ยังไม่ได้เลือก ปี",
        icon: "warning",
        showConfirmButton: false,
        timer: 2000,
        customClass: {
          container: "my-swal"
        }
      });
    } else if (DeviceName == "") {
      Swal.fire({
        title: "ค้นหาไม่ได้!",
        text: "ยังไม่ได้เลือก สถานีตรวจวัด",
        icon: "warning",
        showConfirmButton: false,
        timer: 2000,
        customClass: {
          container: "my-swal"
        }
      });
    } else {
      getData();
    }
  };

  const getData = () => {
    let device1 = DeviceName.split(" ")[1],
      device2 = DeviceName.split(" ")[4].substr(2);

    // console.log(dYearName, device1, device2);
    axios
      .post(xURL + "getchartyear", {
        sYear: dYearName,
        device1: device1,
        device2: device2
      })
      .then(result => {
        if (result.data.length == 0) {
          Swal.fire({
            title: "ไม่มีข้อมูลตามเงื่อนไขที่เลือก!",
            text: ";(",
            icon: "warning",
            showConfirmButton: false,
            timer: 2000,
            customClass: {
              container: "my-swal"
            }
          });

          // setshow(false);
        } else {
          const Tb1 = [],
            Tb2 = [],
            Tb3 = [],
            Tbex = [];

          result.data.forEach((i, idx) => {
            Tb1.push(moment(i.th_y1).format("DD MMMM YYYY"));

            Tb2.push(parseInt(i.value2));

            Tb3.push(parseInt(i.value1));

            Tbex.push({
              dates: moment(i.th_y1).format("DD/MM/YYYY"),
              pm25V1: parseInt(i.value2),
              pm25V2: parseInt(i.value1)
            });
          });

          // console.log(Tb1);
          setdataDate(Tb1);
          setdataChart(Tb2);
          setdataChart2(Tb3);

          setdataEx(Tbex);

          setshow(true);
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    axios
      .post(xURL + "getAvr_year", {
        sYear: dYearName,
        device1: device1,
        device2: device2
      })
      .then(result => {
        setMaxdata1(result.data[0].Maxvalue1);
        setMaxdata2(result.data[0].Maxvalue2);
        setMindata1(result.data[0].Minvalue1);
        setMindata2(result.data[0].Minvalue2);
        setAvrdata1(result.data[0].Avgvalue1);
        setAvrdata2(result.data[0].Avgvalue2);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const excelclick = () => {
    let device1 = DeviceName.split(" ")[1],
      device2 = "ID" + DeviceName.split(" ")[4].substr(2);

    let xdata = [];
    let header = ["วันที่", device1, device2];

    xdata.push(["สอบเทียบข้อมูลรายปี " + dYearName]);
    xdata.push([DeviceName]);
    xdata.push([""]);
    xdata.push(header);

    for (var row in dataEx) {
      const xx = [];

      for (var col in dataEx[row]) {
        xx.push(dataEx[row][col]);
      }

      xdata.push(xx);
    }

    var wb = xlsx.utils.book_new(),
      ws = xlsx.utils.json_to_sheet(xdata, { skipHeader: true });

    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    xlsx.writeFile(wb, "สอบเทียบข้อมูลรายปี.xlsx");
  };

  const handleChange = event => {
    setdYearName(event.target.value);

    setshow(false);
  };

  const handleChange2 = event => {
    setDeviceName(event.target.value);

    setshow(false);
  };

  return (
    <React.Fragment>
      <Container>
        <p>เลือกปีและคู่เทียบ ที่ต้องการ แล้วกดปุ่มค้นหา</p>

        <Box component="form" noValidate sx={{ mb: 3 }}>
          <Stack direction="row" spacing={1}>
            <FormControl margin="normal" required>
              <InputLabel id="demo-select-small">ปี</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                size="small"
                name="year"
                value={dYearName}
                label="ปี"
                onChange={handleChange}
                sx={{ width: 150 }}
              >
                {Yeardata.map(Yeardata =>
                  <MenuItem key={Yeardata} value={Yeardata}>
                    {Yeardata}
                  </MenuItem>
                )}
              </Select>
            </FormControl>

            <FormControl margin="normal">
              <InputLabel sx={{ mt: 2 }} id="demo-select-small">
                สถานีตรวจวัด
              </InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                size="small"
                name="month"
                value={DeviceName}
                label="สถานีตรวจวัด"
                onChange={handleChange2}
                sx={{ width: 260, mt: 2 }}
              >
                {Devicedata.map(Devicedata =>
                  <MenuItem key={Devicedata} value={Devicedata}>
                    {Devicedata}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Stack>

          <Button
            //   type="submit"
            variant="contained"
            sx={{ mt: 1, mr: 1, width: 150 }}
            startIcon={<SearchIcon />}
            style={{ fontFamily: "kanit" }}
            onClick={() => {
              bSearchClick();
            }}
          >
            ค้นหา
          </Button>
          <Button
            variant="contained"
            color="error"
            sx={{ mt: 1, width: 150 }}
            startIcon={<CleaningServicesIcon />}
            onClick={() => {
              bclearClick();
            }}
            style={{ fontFamily: "kanit" }}
          >
            เคลียร์
          </Button>
        </Box>

        {show
          ? <div>
              <div
                style={{
                  display: "flex",
                  marginTop: "10px",
                  justifyContent: "flex-end",
                  marginLeft: "auto",
                  marginRight: 0
                }}
              >
                <Button
                  style={{ textTransform: "none", fontFamily: "kanit" }}
                  variant="text"
                  color="success"
                  sx={{ mt: 1, width: 150 }}
                  startIcon={<Icon icon="vscode-icons:file-type-excel2" />}
                  onClick={() => {
                    excelclick();
                  }}
                >
                  ดาวน์โหลด Excel
                </Button>
              </div>

              <Highcharts
                dataDate={dataDate}
                dataChart={dataChart}
                dataChart2={dataChart2}
                device1={DeviceName.split(" ")[1]}
                device2={DeviceName.split(" ")[4].substr(2)}
              />

              {/* <Scatter_chart
                dataDate={dataDate}
                dataChart={dataEx}
                device1={DeviceName.split(" ")[1]}
                device2={DeviceName.split(" ")[4].substr(2)}
              /> */}

              <table className="realtime" style={{ marginTop: "30px" }}>
                <thead>
                  <tr style={{ height: "40px" }}>
                    <th style={{ width: "400px", border: "1px solid black" }}>
                      PM2.5 เฉลี่ยรายวัน (มคก./ลบม.)
                    </th>
                    <th style={{ width: "200px", border: "1px solid black" }}>
                      สูงสุด
                    </th>
                    <th style={{ width: "200px", border: "1px solid black" }}>
                      ต่ำสุด
                    </th>
                    <th style={{ width: "200px", border: "1px solid black" }}>
                      เฉลี่ย
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr style={{ height: "35px" }}>
                    <th
                      style={{
                        textAlign: "left",
                        paddingLeft: "15px",
                        border: "1px solid black"
                      }}
                    >
                      PCD {DeviceName.split(" ")[1]}
                    </th>
                    <td
                      style={{
                        border: "1px solid black",
                        paddingLeft: "15px"
                      }}
                    >
                      {Maxdata2}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        paddingLeft: "15px"
                      }}
                    >
                      {Mindata2}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        paddingLeft: "15px"
                      }}
                    >
                      {Avrdata2}
                    </td>
                  </tr>
                  <tr style={{ height: "35px" }}>
                    <th
                      style={{
                        textAlign: "left",
                        paddingLeft: "15px",
                        border: "1px solid black"
                      }}
                    >
                      NTAQHI ID{DeviceName.split(" ")[4].substr(2)}
                    </th>
                    <td
                      style={{
                        border: "1px solid black",
                        paddingLeft: "15px"
                      }}
                    >
                      {Maxdata1}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        paddingLeft: "15px"
                      }}
                    >
                      {Mindata1}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        paddingLeft: "15px"
                      }}
                    >
                      {Avrdata1}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          : <div />}
      </Container>
    </React.Fragment>
  );
};

export default Chart_data_Year;
