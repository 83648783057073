import React from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsAccessibility from "highcharts/modules/accessibility";

HighchartsExporting(Highcharts);
HighchartsMore(Highcharts);
HighchartsAccessibility(Highcharts);

const Highchart = prop => {
  let xdevice1 = "PM2.5 " + prop.device1,
    xdevice2 = "PM2.5 ID" + prop.device2;

  const getOptions = () => ({
    title: {
      text: ""
    },
    subtitle: {
      text: ""
    },
    chart: {
      height: "450px",
      plotBorderColor: "#000",
      plotBorderWidth: 1
    },
    yAxis: {
      title: {
        text: ""
      }
    },
    xAxis: {
      categories: prop.dataDate,
      crosshair: true,
      tickInterval: 10
    },
    legend: {
      layout: "vertical",
      align: "center",
      verticalAlign: "bottom"
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false
        }
      }
    },
    series: [
      {
        name: xdevice1,
        data: prop.dataChart,
        color: "#1B5FDE"
      },
      {
        name: xdevice2,
        data: prop.dataChart2,
        color: "#F01818"
      }
    ],
    exporting: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom"
            }
          }
        }
      ]
    }
  });

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={getOptions()} />
    </div>
  );
};

export default Highchart;
