import React from "react";

import { Container, Box, Grid } from "@mui/material";

import "./css/setImg.css";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Contact from "../../components/Contact";

import Pic_Air_fulfil1 from "../../imgs/air_fulfil1.jpg";
import Pic_Air_fulfil2 from "../../imgs/air_fulfil2.jpg";
import Pic_Air_fulfil3 from "../../imgs/air_fulfil3.png";

const Cleanroom3 = () => {
  return (
    <React.Fragment>
      <Navbar />

      <Container>
        <div style={{ marginTop: "100px" }} />
        <p style={{ marginTop: "30px", fontWeight: 600 }}>
          การเติมอากาศสะอาดเข้าในห้อง
        </p>

        <ol>
          <li>
            ติดตั้งเครื่องเติมอากาศจากภายนอกที่ผ่านการฟอกอากาศก่อน
            เพื่อทำให้แรงดันอากาศภายในห้องเป็นบวกช่วยดันฝุ่น PM2.5
            ออกไปตามรูรั่วต่างๆ และป้องกันไม่ให้ฝุ่น PM2.5 ภายนอกเข้ามาในห้อง
          </li>
          <li>
            ข้อดีที่สำคัญอีกอย่างหนึ่งของการเติมอากาศสะอาดเข้าในห้องคือ
            ทำให้การระบายอากาศดีขึ้น
            โดยเฉพาะอย่างยิ่งในห้องหรืออาคารที่มีคนอยู่ภายในจำนวนมาก เช่น
            ห้องเรียน ห้องประชุม ร้านอาหาร ฯลฯ
            โดยแรงดันอากาศที่เป็นบวกจะดันก๊าซคาร์บอกไดออกไซด์หรือก๊าซชนิดอื่นๆที่เราหายใจออกผ่านทางรูรั่วต่างๆ
            ทำให้อากาศภายในห้องไม่อับและไม่ทำให้ผู้ที่อยู่ภายในเกิดความอึดอัด
            (sick office syndrome)
          </li>
          <li>
            หากไม่มีเครื่องเติมอากาศซึ่งราคาค่อนข้างแพง
            ให้ติดตั้งเครื่องเติมอากาศสะอาด DIY
          </li>
        </ol>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <img
                style={{
                  maxWidth: "100%",
                  height: "350px",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: "10px"
                }}
                className="img-artboard"
                src={Pic_Air_fulfil1}
                alt="Air_fulfil1"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <img
                style={{
                  maxWidth: "100%",
                  height: "350px",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: "10px"
                }}
                className="img-artboard"
                src={Pic_Air_fulfil2}
                alt="Air_fulfil2"
              />
            </Grid>

            <Grid item xs={12}>
              <img
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: "10px"
                }}
                className="img-artboard"
                src={Pic_Air_fulfil3}
                alt="Air_fulfil3"
              />
            </Grid>
          </Grid>
        </Box>

        <a href="/safezone/purification/makeAIR" target="_blank">
          วิธีการทำเครื่องเติมอากาศสะอาด DIY จากกล่องอากาศดี
        </a>
      </Container>

      <div style={{ height: "50px" }} />
      <Contact />
      <Footer />
    </React.Fragment>
  );
};

export default Cleanroom3;
