import React, { useState, useEffect } from "react";
import axios from "axios";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

import { Grid } from "@mui/material";

import "./css/Chart_data.css";

const Chart_data = () => {
  let xURL = "";
  // let xlocation = window.location.hostname;

  // if (xlocation === "localhost") {
  //   xURL = "http://localhost:5000/api/";
  // } else {
  //   xURL = "https://www2.ntaqhi.info/api/";
  // }

  xURL = "https://www2.ntaqhi.info/api/";

  const [dataChart1, setdataChart1] = useState([]);
  const [dataChart2, setdataChart2] = useState([]);
  const [dataChart3, setdataChart3] = useState([]);
  const [dataChart4, setdataChart4] = useState([]);
  const [dataChart5, setdataChart5] = useState([]);
  const [dataChart6, setdataChart6] = useState([]);
  const [dataChart7, setdataChart7] = useState([]);
  const [dataChart8, setdataChart8] = useState([]);
  const [dataChart9, setdataChart9] = useState([]);
  const [dataChart10, setdataChart10] = useState([]);
  const [dataChart11, setdataChart11] = useState([]);
  const [dataChart12, setdataChart12] = useState([]);
  const [dataChart13, setdataChart13] = useState([]);
  const [dataChart14, setdataChart14] = useState([]);
  const [dataChart15, setdataChart15] = useState([]);
  const [dataChart16, setdataChart16] = useState([]);

  const [xd_name1, setxd_name1] = useState([]);
  const [yd_name1, setyd_name1] = useState([]);
  const [xd_name2, setxd_name2] = useState([]);
  const [yd_name2, setyd_name2] = useState([]);
  const [xd_name3, setxd_name3] = useState([]);
  const [yd_name3, setyd_name3] = useState([]);
  const [xd_name4, setxd_name4] = useState([]);
  const [yd_name4, setyd_name4] = useState([]);
  const [xd_name5, setxd_name5] = useState([]);
  const [yd_name5, setyd_name5] = useState([]);
  const [xd_name6, setxd_name6] = useState([]);
  const [yd_name6, setyd_name6] = useState([]);
  const [xd_name7, setxd_name7] = useState([]);
  const [yd_name7, setyd_name7] = useState([]);
  const [xd_name8, setxd_name8] = useState([]);
  const [yd_name8, setyd_name8] = useState([]);
  const [xd_name9, setxd_name9] = useState([]);
  const [yd_name9, setyd_name9] = useState([]);
  const [xd_name10, setxd_name10] = useState([]);
  const [yd_name10, setyd_name10] = useState([]);
  const [xd_name11, setxd_name11] = useState([]);
  const [yd_name11, setyd_name11] = useState([]);
  const [xd_name12, setxd_name12] = useState([]);
  const [yd_name12, setyd_name12] = useState([]);
  const [xd_name13, setxd_name13] = useState([]);
  const [yd_name13, setyd_name13] = useState([]);
  const [xd_name14, setxd_name14] = useState([]);
  const [yd_name14, setyd_name14] = useState([]);
  const [xd_name15, setxd_name15] = useState([]);
  const [yd_name15, setyd_name15] = useState([]);
  const [xd_name16, setxd_name16] = useState([]);
  const [yd_name16, setyd_name16] = useState([]);

  const [tavrB1, settavrB1] = useState();
  const [tavrR1, settavrR1] = useState();
  const [tavrB2, settavrB2] = useState();
  const [tavrR2, settavrR2] = useState();
  const [tavrB3, settavrB3] = useState();
  const [tavrR3, settavrR3] = useState();
  const [tavrB4, settavrB4] = useState();
  const [tavrR4, settavrR4] = useState();
  const [tavrB5, settavrB5] = useState();
  const [tavrR5, settavrR5] = useState();
  const [tavrB6, settavrB6] = useState();
  const [tavrR6, settavrR6] = useState();
  const [tavrB7, settavrB7] = useState();
  const [tavrR7, settavrR7] = useState();
  const [tavrB8, settavrB8] = useState();
  const [tavrR8, settavrR8] = useState();
  const [tavrB9, settavrB9] = useState();
  const [tavrR9, settavrR9] = useState();
  const [tavrB10, settavrB10] = useState();
  const [tavrR10, settavrR10] = useState();
  const [tavrB11, settavrB11] = useState();
  const [tavrR11, settavrR11] = useState();
  const [tavrB12, settavrB12] = useState();
  const [tavrR12, settavrR12] = useState();
  const [tavrB13, settavrB13] = useState();
  const [tavrR13, settavrR13] = useState();
  const [tavrB14, settavrB14] = useState();
  const [tavrR14, settavrR14] = useState();
  const [tavrB15, settavrB15] = useState();
  const [tavrR15, settavrR15] = useState();
  const [tavrB16, settavrB16] = useState();
  const [tavrR16, settavrR16] = useState();

  useEffect(() => {
    ///////////////// chart 1 /////////////////
    axios
      .post(xURL + "getchart", { d_id1: "35t", d_id2: "11" })
      .then(result => {
        //console.log(result.data.length);
        const Tb1 = [];

        if (result.data.length !== 7) {
          for (let i = 1; i <= 7 - result.data.length; i++) {
            Tb1.push({
              date: "",
              [result.data[0].id1 + " " + result.data[0].name1]: "",
              ["ID" + result.data[0].id2 + " " + result.data[0].name2]: ""
            });
          }
        }

        let avr = 0,
          avr2 = 0,
          xcount = 0,
          xcount2 = 0;

        result.data.forEach((i, idx) => {
          setxd_name1(i.id1 + " " + i.name1);
          setyd_name1("ID" + i.id2 + " " + i.name2);

          Tb1.push({
            date: i.d_date,
            [i.id1 + " " + i.name1]: i.pm1,
            ["ID" + i.id2 + " " + i.name2]: i.pm2
          });

          avr = avr + i.pm1;
          avr2 = avr2 + i.pm2;

          if (i.pm1) {
            xcount = xcount + 1;
          }
          if (i.pm2) {
            xcount2 = xcount2 + 1;
          }
        });

        avr = Math.round(avr / xcount);
        avr2 = Math.round(avr2 / xcount2);

        settavrB1(avr);
        settavrR1(avr2);
        setdataChart1(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    ///////////////// chart 2 /////////////////
    axios
      .post(xURL + "getchart", { d_id1: "36t", d_id2: "501" })
      .then(result => {
        //console.log(result.data);
        const Tb1 = [];

        if (result.data.length !== 7) {
          for (let i = 1; i <= 7 - result.data.length; i++) {
            Tb1.push({
              date: "",
              [result.data[0].id1 + " " + result.data[0].name1]: "",
              ["ID" + result.data[0].id2 + " " + result.data[0].name2]: ""
            });
          }
        }

        let avr = 0,
          avr2 = 0,
          xcount = 0,
          xcount2 = 0;

        result.data.forEach((i, idx) => {
          setxd_name2(i.id1 + " " + i.name1);
          setyd_name2("ID" + i.id2 + " " + i.name2);

          Tb1.push({
            date: i.d_date,
            [i.id1 + " " + i.name1]: i.pm1,
            ["ID" + i.id2 + " " + i.name2]: i.pm2
          });

          avr = avr + i.pm1;
          avr2 = avr2 + i.pm2;

          if (i.pm1) {
            xcount = xcount + 1;
          }
          if (i.pm2) {
            xcount2 = xcount2 + 1;
          }
        });

        avr = Math.round(avr / xcount);
        avr2 = Math.round(avr2 / xcount2);

        settavrB2(avr);
        settavrR2(avr2);

        setdataChart2(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    ///////////////// chart 3 /////////////////
    axios
      .post(xURL + "getchart", { d_id1: "o28", d_id2: "81" })
      .then(result => {
        //console.log(result.data);
        const Tb1 = [];

        if (result.data.length !== 7) {
          for (let i = 1; i <= 7 - result.data.length; i++) {
            Tb1.push({
              date: "",
              [result.data[0].id1 + " " + result.data[0].name1]: "",
              ["ID" + result.data[0].id2 + " " + result.data[0].name2]: ""
            });
          }
        }

        let avr = 0,
          avr2 = 0,
          xcount = 0,
          xcount2 = 0;

        result.data.forEach((i, idx) => {
          setxd_name3(i.id1 + " " + i.name1);
          setyd_name3("ID" + i.id2 + " " + i.name2);

          Tb1.push({
            date: i.d_date,
            [i.id1 + " " + i.name1]: i.pm1,
            ["ID" + i.id2 + " " + i.name2]: i.pm2
          });

          avr = avr + i.pm1;
          avr2 = avr2 + i.pm2;

          if (i.pm1) {
            xcount = xcount + 1;
          }
          if (i.pm2) {
            xcount2 = xcount2 + 1;
          }
        });

        avr = Math.round(avr / xcount);
        avr2 = Math.round(avr2 / xcount2);

        settavrB3(avr);
        settavrR3(avr2);

        setdataChart3(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    ///////////////// chart 4 /////////////////
    axios
      .post(xURL + "getchart", { d_id1: "o29", d_id2: "137" })
      .then(result => {
        //console.log(result.data);
        const Tb1 = [];

        if (result.data.length !== 7) {
          for (let i = 1; i <= 7 - result.data.length; i++) {
            Tb1.push({
              date: "",
              [result.data[0].id1 + " " + result.data[0].name1]: "",
              ["ID" + result.data[0].id2 + " " + result.data[0].name2]: ""
            });
          }
        }

        let avr = 0,
          avr2 = 0,
          xcount = 0,
          xcount2 = 0;

        result.data.forEach((i, idx) => {
          setxd_name4(i.id1 + " " + i.name1);
          setyd_name4("ID" + i.id2 + " " + i.name2);

          Tb1.push({
            date: i.d_date,
            [i.id1 + " " + i.name1]: i.pm1,
            ["ID" + i.id2 + " " + i.name2]: i.pm2
          });

          avr = avr + i.pm1;
          avr2 = avr2 + i.pm2;

          if (i.pm1) {
            xcount = xcount + 1;
          }
          if (i.pm2) {
            xcount2 = xcount2 + 1;
          }
        });

        avr = Math.round(avr / xcount);
        avr2 = Math.round(avr2 / xcount2);

        settavrB4(avr);
        settavrR4(avr2);

        setdataChart4(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    ///////////////// chart 5 /////////////////
    axios
      .post(xURL + "getchart", { d_id1: "67t", d_id2: "284" })
      .then(result => {
        //console.log(result.data);
        const Tb1 = [];

        if (result.data.length !== 7) {
          for (let i = 1; i <= 7 - result.data.length; i++) {
            Tb1.push({
              date: "",
              [result.data[0].id1 + " " + result.data[0].name1]: "",
              ["ID" + result.data[0].id2 + " " + result.data[0].name2]: ""
            });
          }
        }

        let avr = 0,
          avr2 = 0,
          xcount = 0,
          xcount2 = 0;

        result.data.forEach((i, idx) => {
          setxd_name5(i.id1 + " " + i.name1);
          setyd_name5("ID" + i.id2 + " " + i.name2);

          Tb1.push({
            date: i.d_date,
            [i.id1 + " " + i.name1]: i.pm1,
            ["ID" + i.id2 + " " + i.name2]: i.pm2
          });

          avr = avr + i.pm1;
          avr2 = avr2 + i.pm2;

          if (i.pm1) {
            xcount = xcount + 1;
          }
          if (i.pm2) {
            xcount2 = xcount2 + 1;
          }
        });

        avr = Math.round(avr / xcount);
        avr2 = Math.round(avr2 / xcount2);

        settavrB5(avr);
        settavrR5(avr2);

        setdataChart5(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    ///////////////// chart 6 /////////////////
    axios
      .post(xURL + "getchart", { d_id1: "70t", d_id2: "279" })
      .then(result => {
        //console.log(result.data);
        const Tb1 = [];

        if (result.data.length !== 7) {
          for (let i = 1; i <= 7 - result.data.length; i++) {
            Tb1.push({
              date: "",
              [result.data[0].id1 + " " + result.data[0].name1]: "",
              ["ID" + result.data[0].id2 + " " + result.data[0].name2]: ""
            });
          }
        }

        let avr = 0,
          avr2 = 0,
          xcount = 0,
          xcount2 = 0;

        result.data.forEach((i, idx) => {
          setxd_name6(i.id1 + " " + i.name1);
          setyd_name6("ID" + i.id2 + " " + i.name2);

          Tb1.push({
            date: i.d_date,
            [i.id1 + " " + i.name1]: i.pm1,
            ["ID" + i.id2 + " " + i.name2]: i.pm2
          });
          avr = avr + i.pm1;
          avr2 = avr2 + i.pm2;

          if (i.pm1) {
            xcount = xcount + 1;
          }
          if (i.pm2) {
            xcount2 = xcount2 + 1;
          }
        });

        avr = Math.round(avr / xcount);
        avr2 = Math.round(avr2 / xcount2);

        settavrB6(avr);
        settavrR6(avr2);

        setdataChart6(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    ///////////////// chart 7 /////////////////
    axios
      .post(xURL + "getchart", { d_id1: "76t", d_id2: "186" })
      .then(result => {
        //console.log(result.data);
        const Tb1 = [];

        if (result.data.length !== 7) {
          for (let i = 1; i <= 7 - result.data.length; i++) {
            Tb1.push({
              date: "",
              [result.data[0].id1 + " " + result.data[0].name1]: "",
              ["ID" + result.data[0].id2 + " " + result.data[0].name2]: ""
            });
          }
        }

        let avr = 0,
          avr2 = 0,
          xcount = 0,
          xcount2 = 0;

        result.data.forEach((i, idx) => {
          setxd_name7(i.id1 + " " + i.name1);
          setyd_name7("ID" + i.id2 + " " + i.name2);

          Tb1.push({
            date: i.d_date,
            [i.id1 + " " + i.name1]: i.pm1,
            ["ID" + i.id2 + " " + i.name2]: i.pm2
          });
          avr = avr + i.pm1;
          avr2 = avr2 + i.pm2;

          if (i.pm1) {
            xcount = xcount + 1;
          }
          if (i.pm2) {
            xcount2 = xcount2 + 1;
          }
        });

        avr = Math.round(avr / xcount);
        avr2 = Math.round(avr2 / xcount2);

        settavrB7(avr);
        settavrR7(avr2);

        setdataChart7(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    ///////////////// chart 8 /////////////////
    axios
      .post(xURL + "getchart", { d_id1: "37t", d_id2: "237" })
      .then(result => {
        //console.log(result.data);
        const Tb1 = [];

        if (result.data.length !== 7) {
          for (let i = 1; i <= 7 - result.data.length; i++) {
            Tb1.push({
              date: "",
              [result.data[0].id1 + " " + result.data[0].name1]: "",
              ["ID" + result.data[0].id2 + " " + result.data[0].name2]: ""
            });
          }
        }

        let avr = 0,
          avr2 = 0,
          xcount = 0,
          xcount2 = 0;

        result.data.forEach((i, idx) => {
          setxd_name8(i.id1 + " " + i.name1);
          setyd_name8("ID" + i.id2 + " " + i.name2);

          Tb1.push({
            date: i.d_date,
            [i.id1 + " " + i.name1]: i.pm1,
            ["ID" + i.id2 + " " + i.name2]: i.pm2
          });
          avr = avr + i.pm1;
          avr2 = avr2 + i.pm2;

          if (i.pm1) {
            xcount = xcount + 1;
          }
          if (i.pm2) {
            xcount2 = xcount2 + 1;
          }
        });

        avr = Math.round(avr / xcount);
        avr2 = Math.round(avr2 / xcount2);

        settavrB8(avr);
        settavrR8(avr2);

        setdataChart8(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    ///////////////// chart 9 /////////////////
    axios
      .post(xURL + "getchart", { d_id1: "57t", d_id2: "216" })
      .then(result => {
        //console.log(result.data);
        const Tb1 = [];

        if (result.data.length !== 7) {
          for (let i = 1; i <= 7 - result.data.length; i++) {
            Tb1.push({
              date: "",
              [result.data[0].id1 + " " + result.data[0].name1]: "",
              ["ID" + result.data[0].id2 + " " + result.data[0].name2]: ""
            });
          }
        }

        let avr = 0,
          avr2 = 0,
          xcount = 0,
          xcount2 = 0;

        result.data.forEach((i, idx) => {
          setxd_name9(i.id1 + " " + i.name1);
          setyd_name9("ID" + i.id2 + " " + i.name2);

          Tb1.push({
            date: i.d_date,
            [i.id1 + " " + i.name1]: i.pm1,
            ["ID" + i.id2 + " " + i.name2]: i.pm2
          });
          avr = avr + i.pm1;
          avr2 = avr2 + i.pm2;

          if (i.pm1) {
            xcount = xcount + 1;
          }
          if (i.pm2) {
            xcount2 = xcount2 + 1;
          }
        });

        avr = Math.round(avr / xcount);
        avr2 = Math.round(avr2 / xcount2);

        settavrB9(avr);
        settavrR9(avr2);

        setdataChart9(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    ///////////////// chart 10 /////////////////
    axios
      .post(xURL + "getchart", { d_id1: "69t", d_id2: "225" })
      .then(result => {
        //console.log(result.data);
        const Tb1 = [];

        if (result.data.length !== 7) {
          for (let i = 1; i <= 7 - result.data.length; i++) {
            Tb1.push({
              date: "",
              [result.data[0].id1 + " " + result.data[0].name1]: "",
              ["ID" + result.data[0].id2 + " " + result.data[0].name2]: ""
            });
          }
        }

        let avr = 0,
          avr2 = 0,
          xcount = 0,
          xcount2 = 0;

        result.data.forEach((i, idx) => {
          setxd_name10(i.id1 + " " + i.name1);
          setyd_name10("ID" + i.id2 + " " + i.name2);

          Tb1.push({
            date: i.d_date,
            [i.id1 + " " + i.name1]: i.pm1,
            ["ID" + i.id2 + " " + i.name2]: i.pm2
          });
          avr = avr + i.pm1;
          avr2 = avr2 + i.pm2;

          if (i.pm1) {
            xcount = xcount + 1;
          }
          if (i.pm2) {
            xcount2 = xcount2 + 1;
          }
        });

        avr = Math.round(avr / xcount);
        avr2 = Math.round(avr2 / xcount2);

        settavrB10(avr);
        settavrR10(avr2);

        setdataChart10(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    ///////////////// chart 11 /////////////////
    axios
      .post(xURL + "getchart", { d_id1: "58t", d_id2: "99" })
      .then(result => {
        //console.log(result.data);
        const Tb1 = [];

        if (result.data.length !== 7) {
          for (let i = 1; i <= 7 - result.data.length; i++) {
            Tb1.push({
              date: "",
              [result.data[0].id1 + " " + result.data[0].name1]: "",
              ["ID" + result.data[0].id2 + " " + result.data[0].name2]: ""
            });
          }
        }

        let avr = 0,
          avr2 = 0,
          xcount = 0,
          xcount2 = 0;

        result.data.forEach((i, idx) => {
          setxd_name11(i.id1 + " " + i.name1);
          setyd_name11("ID" + i.id2 + " " + i.name2);

          Tb1.push({
            date: i.d_date,
            [i.id1 + " " + i.name1]: i.pm1,
            ["ID" + i.id2 + " " + i.name2]: i.pm2
          });
          avr = avr + i.pm1;
          avr2 = avr2 + i.pm2;

          if (i.pm1) {
            xcount = xcount + 1;
          }
          if (i.pm2) {
            xcount2 = xcount2 + 1;
          }
        });

        avr = Math.round(avr / xcount);
        avr2 = Math.round(avr2 / xcount2);

        settavrB11(avr);
        settavrR11(avr2);

        setdataChart11(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    ///////////////// chart 12 /////////////////
    axios
      .post(xURL + "getchart", { d_id1: "86t", d_id2: "264" })
      .then(result => {
        //console.log(result.data);
        const Tb1 = [];

        if (result.data.length !== 7) {
          for (let i = 1; i <= 7 - result.data.length; i++) {
            Tb1.push({
              date: "",
              [result.data[0].id1 + " " + result.data[0].name1]: "",
              ["ID" + result.data[0].id2 + " " + result.data[0].name2]: ""
            });
          }
        }

        let avr = 0,
          avr2 = 0,
          xcount = 0,
          xcount2 = 0;

        result.data.forEach((i, idx) => {
          setxd_name12(i.id1 + " " + i.name1);
          setyd_name12("ID" + i.id2 + " " + i.name2);

          Tb1.push({
            date: i.d_date,
            [i.id1 + " " + i.name1]: i.pm1,
            ["ID" + i.id2 + " " + i.name2]: i.pm2
          });
          avr = avr + i.pm1;
          avr2 = avr2 + i.pm2;

          if (i.pm1) {
            xcount = xcount + 1;
          }
          if (i.pm2) {
            xcount2 = xcount2 + 1;
          }
        });

        avr = Math.round(avr / xcount);
        avr2 = Math.round(avr2 / xcount2);

        settavrB12(avr);
        settavrR12(avr2);

        setdataChart12(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    ///////////////// chart 13 /////////////////
    axios
      .post(xURL + "getchart", { d_id1: "27t", d_id2: "511" })
      .then(result => {
        //console.log(result.data);
        const Tb1 = [];

        if (result.data.length !== 7) {
          for (let i = 1; i <= 7 - result.data.length; i++) {
            Tb1.push({
              date: "",
              [result.data[0].id1 + " " + result.data[0].name1]: "",
              ["ID" + result.data[0].id2 + " " + result.data[0].name2]: ""
            });
          }
        }

        let avr = 0,
          avr2 = 0,
          xcount = 0,
          xcount2 = 0;

        result.data.forEach((i, idx) => {
          setxd_name13(i.id1 + " " + i.name1);
          setyd_name13("ID" + i.id2 + " " + i.name2);

          Tb1.push({
            date: i.d_date,
            [i.id1 + " " + i.name1]: i.pm1,
            ["ID" + i.id2 + " " + i.name2]: i.pm2
          });
          avr = avr + i.pm1;
          avr2 = avr2 + i.pm2;

          if (i.pm1) {
            xcount = xcount + 1;
          }
          if (i.pm2) {
            xcount2 = xcount2 + 1;
          }
        });

        avr = Math.round(avr / xcount);
        avr2 = Math.round(avr2 / xcount2);

        settavrB13(avr);
        settavrR13(avr2);

        setdataChart13(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    ///////////////// chart 14 /////////////////
    axios
      .post(xURL + "getchart", { d_id1: "22t", d_id2: "87" })
      .then(result => {
        //console.log(result.data);
        const Tb1 = [];

        if (result.data.length !== 7) {
          for (let i = 1; i <= 7 - result.data.length; i++) {
            Tb1.push({
              date: "",
              [result.data[0].id1 + " " + result.data[0].name1]: "",
              ["ID" + result.data[0].id2 + " " + result.data[0].name2]: ""
            });
          }
        }

        let avr = 0,
          avr2 = 0,
          xcount = 0,
          xcount2 = 0;

        result.data.forEach((i, idx) => {
          setxd_name14(i.id1 + " " + i.name1);
          setyd_name14("ID" + i.id2 + " " + i.name2);

          Tb1.push({
            date: i.d_date,
            [i.id1 + " " + i.name1]: i.pm1,
            ["ID" + i.id2 + " " + i.name2]: i.pm2
          });
          avr = avr + i.pm1;
          avr2 = avr2 + i.pm2;

          if (i.pm1) {
            xcount = xcount + 1;
          }
          if (i.pm2) {
            xcount2 = xcount2 + 1;
          }
        });

        avr = Math.round(avr / xcount);
        avr2 = Math.round(avr2 / xcount2);

        settavrB14(avr);
        settavrR14(avr2);

        setdataChart14(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    ///////////////// chart 15 /////////////////
    axios
      .post(xURL + "getchart", { d_id1: "29t", d_id2: "302" })
      .then(result => {
        //console.log(result.data);
        const Tb1 = [];

        if (result.data.length !== 7) {
          for (let i = 1; i <= 7 - result.data.length; i++) {
            Tb1.push({
              date: "",
              [result.data[0].id1 + " " + result.data[0].name1]: "",
              ["ID" + result.data[0].id2 + " " + result.data[0].name2]: ""
            });
          }
        }

        let avr = 0,
          avr2 = 0,
          xcount = 0,
          xcount2 = 0;

        result.data.forEach((i, idx) => {
          setxd_name15(i.id1 + " " + i.name1);
          setyd_name15("ID" + i.id2 + " " + i.name2);

          Tb1.push({
            date: i.d_date,
            [i.id1 + " " + i.name1]: i.pm1,
            ["ID" + i.id2 + " " + i.name2]: i.pm2
          });
          avr = avr + i.pm1;
          avr2 = avr2 + i.pm2;

          if (i.pm1) {
            xcount = xcount + 1;
          }
          if (i.pm2) {
            xcount2 = xcount2 + 1;
          }
        });

        avr = Math.round(avr / xcount);
        avr2 = Math.round(avr2 / xcount2);

        settavrB15(avr);
        settavrR15(avr2);

        setdataChart15(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    ///////////////// chart 16 /////////////////
    axios
      .post(xURL + "getchart", { d_id1: "31t", d_id2: "304" })
      .then(result => {
        //console.log(result.data);
        const Tb1 = [];

        if (result.data.length !== 7) {
          for (let i = 1; i <= 7 - result.data.length; i++) {
            Tb1.push({
              date: "",
              [result.data[0].id1 + " " + result.data[0].name1]: "",
              ["ID" + result.data[0].id2 + " " + result.data[0].name2]: ""
            });
          }
        }

        let avr = 0,
          avr2 = 0,
          xcount = 0,
          xcount2 = 0;

        result.data.forEach((i, idx) => {
          setxd_name16(i.id1 + " " + i.name1);
          setyd_name16("ID" + i.id2 + " " + i.name2);

          Tb1.push({
            date: i.d_date,
            [i.id1 + " " + i.name1]: i.pm1,
            ["ID" + i.id2 + " " + i.name2]: i.pm2
          });
          avr = avr + i.pm1;
          avr2 = avr2 + i.pm2;

          if (i.pm1) {
            xcount = xcount + 1;
          }
          if (i.pm2) {
            xcount2 = xcount2 + 1;
          }
        });

        avr = Math.round(avr / xcount);
        avr2 = Math.round(avr2 / xcount2);

        settavrB16(avr);
        settavrR16(avr2);

        setdataChart16(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  //Chart option
  let Chart_width = 550,
    Chart_height = 350,
    Chart_magin = {
      top: 70,
      right: 20,
      left: 20,
      bottom: 5
    },
    Chart_color1 = "#1B5FDE",
    Chart_color2 = "#F01818",
    text_style = { fontSize: 16, fontWeight: "" },
    ty1 = "18%",
    ty2 = "25%",
    tx1 = "22%",
    tx2 = "32%",
    tx3 = "38%";

  return (
    <React.Fragment>
      <h4 style={{ marginBottom: "-30px", fontSize: "16px" }}>
        2. กราฟเปรียบเทียบข้อมูลค่า PM2.5 เฉลี่ยรายวันย้อนหลัง 7 วัน
      </h4>

      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <ResponsiveContainer width="100%" height={360}>
            <BarChart
              width={Chart_width}
              height={Chart_height}
              data={dataChart1}
              margin={Chart_magin}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                textAnchor="end"
                sclaeToFit="true"
                verticalAnchor="start"
                interval={0}
                angle="-25"
                stroke="#8884d8"
                tick={{ fontSize: 14 }}
              />
              <YAxis />
              <Tooltip />

              <Legend wrapperStyle={{ top: 345 }} height={36} />
              <Bar dataKey={xd_name1} fill={Chart_color1} />
              <Bar dataKey={yd_name1} fill={Chart_color2} />

              <text x={tx1} y={ty1} style={text_style}>
                เฉลี่ย
              </text>
              <text x={tx2} y={ty1} style={{ fontSize: 26, fill: "#1B5FDE" }}>
                ■
              </text>
              <text x={tx3} y={ty1} style={text_style}>
                {tavrB1} มคก./ลบม.
              </text>
              <text x={tx2} y={ty2} style={{ fontSize: 26, fill: "#F01818" }}>
                ■
              </text>
              <text x={tx3} y={ty2} style={text_style}>
                {tavrR1} มคก./ลบม.
              </text>
            </BarChart>
          </ResponsiveContainer>
        </Grid>

        <Grid item xs={12} md={6}>
          <ResponsiveContainer width="100%" height={360}>
            <BarChart
              width={Chart_width}
              height={Chart_height}
              data={dataChart2}
              margin={Chart_magin}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                textAnchor="end"
                sclaeToFit="true"
                verticalAnchor="start"
                interval={0}
                angle="-25"
                stroke="#8884d8"
                tick={{ fontSize: 14 }}
              />
              <YAxis />
              <Tooltip />
              <Legend wrapperStyle={{ top: 345 }} height={36} />
              <Bar dataKey={xd_name2} fill={Chart_color1} />
              <Bar dataKey={yd_name2} fill={Chart_color2} />

              <text x={tx1} y={ty1} style={text_style}>
                เฉลี่ย
              </text>
              <text x={tx2} y={ty1} style={{ fontSize: 26, fill: "#1B5FDE" }}>
                ■
              </text>
              <text x={tx3} y={ty1} style={text_style}>
                {tavrB2} มคก./ลบม.
              </text>
              <text x={tx2} y={ty2} style={{ fontSize: 26, fill: "#F01818" }}>
                ■
              </text>
              <text x={tx3} y={ty2} style={text_style}>
                {tavrR2} มคก./ลบม.
              </text>
            </BarChart>
          </ResponsiveContainer>
        </Grid>

        <Grid item xs={12} md={6}>
          <ResponsiveContainer width="100%" height={360}>
            <BarChart
              width={Chart_width}
              height={Chart_height}
              data={dataChart3}
              margin={Chart_magin}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                textAnchor="end"
                sclaeToFit="true"
                verticalAnchor="start"
                interval={0}
                angle="-25"
                stroke="#8884d8"
                tick={{ fontSize: 14 }}
              />
              <YAxis />
              <Tooltip />
              <Legend wrapperStyle={{ top: 345 }} height={36} />
              <Bar dataKey={xd_name3} fill={Chart_color1} />
              <Bar dataKey={yd_name3} fill={Chart_color2} />

              <text x={tx1} y={ty1} style={text_style}>
                เฉลี่ย
              </text>
              <text x={tx2} y={ty1} style={{ fontSize: 26, fill: "#1B5FDE" }}>
                ■
              </text>
              <text x={tx3} y={ty1} style={text_style}>
                {tavrB3} มคก./ลบม.
              </text>
              <text x={tx2} y={ty2} style={{ fontSize: 26, fill: "#F01818" }}>
                ■
              </text>
              <text x={tx3} y={ty2} style={text_style}>
                {tavrR3} มคก./ลบม.
              </text>
            </BarChart>
          </ResponsiveContainer>
        </Grid>

        {/* chart 11 เข้าใหม่ คั่น chart 4*/}
        <Grid item xs={12} md={6}>
          <ResponsiveContainer width="100%" height={360}>
            <BarChart
              width={Chart_width}
              height={Chart_height}
              data={dataChart11}
              margin={Chart_magin}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                textAnchor="end"
                sclaeToFit="true"
                verticalAnchor="start"
                interval={0}
                angle="-25"
                stroke="#8884d8"
                tick={{ fontSize: 14 }}
              />
              <YAxis />
              <Tooltip />
              <Legend wrapperStyle={{ top: 345 }} height={36} />
              <Bar dataKey={xd_name11} fill={Chart_color1} />
              <Bar dataKey={yd_name11} fill={Chart_color2} />

              <text x={tx1} y={ty1} style={text_style}>
                เฉลี่ย
              </text>
              <text x={tx2} y={ty1} style={{ fontSize: 26, fill: "#1B5FDE" }}>
                ■
              </text>
              <text x={tx3} y={ty1} style={text_style}>
                {tavrB11} มคก./ลบม.
              </text>
              <text x={tx2} y={ty2} style={{ fontSize: 26, fill: "#F01818" }}>
                ■
              </text>
              <text x={tx3} y={ty2} style={text_style}>
                {tavrR11} มคก./ลบม.
              </text>
            </BarChart>
          </ResponsiveContainer>
        </Grid>

        <Grid item xs={12} md={6}>
          <ResponsiveContainer width="100%" height={360}>
            <BarChart
              width={Chart_width}
              height={Chart_height}
              data={dataChart4}
              margin={Chart_magin}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                textAnchor="end"
                sclaeToFit="true"
                verticalAnchor="start"
                interval={0}
                angle="-25"
                stroke="#8884d8"
                tick={{ fontSize: 14 }}
              />
              <YAxis />
              <Tooltip />
              <Legend wrapperStyle={{ top: 345 }} height={36} />
              <Bar dataKey={xd_name4} fill={Chart_color1} />
              <Bar dataKey={yd_name4} fill={Chart_color2} />

              <text x={tx1} y={ty1} style={text_style}>
                เฉลี่ย
              </text>
              <text x={tx2} y={ty1} style={{ fontSize: 26, fill: "#1B5FDE" }}>
                ■
              </text>
              <text x={tx3} y={ty1} style={text_style}>
                {tavrB4} มคก./ลบม.
              </text>
              <text x={tx2} y={ty2} style={{ fontSize: 26, fill: "#F01818" }}>
                ■
              </text>
              <text x={tx3} y={ty2} style={text_style}>
                {tavrR4} มคก./ลบม.
              </text>
            </BarChart>
          </ResponsiveContainer>
        </Grid>

        <Grid item xs={12} md={6}>
          <ResponsiveContainer width="100%" height={360}>
            <BarChart
              width={Chart_width}
              height={Chart_height}
              data={dataChart5}
              margin={Chart_magin}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                textAnchor="end"
                sclaeToFit="true"
                verticalAnchor="start"
                interval={0}
                angle="-25"
                stroke="#8884d8"
                tick={{ fontSize: 14 }}
              />
              <YAxis />
              <Tooltip />
              <Legend wrapperStyle={{ top: 345 }} height={36} />
              <Bar dataKey={xd_name5} fill={Chart_color1} />
              <Bar dataKey={yd_name5} fill={Chart_color2} />

              <text x={tx1} y={ty1} style={text_style}>
                เฉลี่ย
              </text>
              <text x={tx2} y={ty1} style={{ fontSize: 26, fill: "#1B5FDE" }}>
                ■
              </text>
              <text x={tx3} y={ty1} style={text_style}>
                {tavrB5} มคก./ลบม.
              </text>
              <text x={tx2} y={ty2} style={{ fontSize: 26, fill: "#F01818" }}>
                ■
              </text>
              <text x={tx3} y={ty2} style={text_style}>
                {tavrR5} มคก./ลบม.
              </text>
            </BarChart>
          </ResponsiveContainer>
        </Grid>

        <Grid item xs={12} md={6}>
          <ResponsiveContainer width="100%" height={360}>
            <BarChart
              width={Chart_width}
              height={Chart_height}
              data={dataChart6}
              margin={Chart_magin}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                textAnchor="end"
                sclaeToFit="true"
                verticalAnchor="start"
                interval={0}
                angle="-25"
                stroke="#8884d8"
                tick={{ fontSize: 14 }}
              />
              <YAxis />
              <Tooltip />
              <Legend wrapperStyle={{ top: 345 }} height={36} />
              <Bar dataKey={xd_name6} fill={Chart_color1} />
              <Bar dataKey={yd_name6} fill={Chart_color2} />

              <text x={tx1} y={ty1} style={text_style}>
                เฉลี่ย
              </text>
              <text x={tx2} y={ty1} style={{ fontSize: 26, fill: "#1B5FDE" }}>
                ■
              </text>
              <text x={tx3} y={ty1} style={text_style}>
                {tavrB6} มคก./ลบม.
              </text>
              <text x={tx2} y={ty2} style={{ fontSize: 26, fill: "#F01818" }}>
                ■
              </text>
              <text x={tx3} y={ty2} style={text_style}>
                {tavrR6} มคก./ลบม.
              </text>
            </BarChart>
          </ResponsiveContainer>
        </Grid>

        {/* เข้าใหม่ 4 chart */}

        <Grid item xs={12} md={6}>
          <ResponsiveContainer width="100%" height={360}>
            <BarChart
              width={Chart_width}
              height={Chart_height}
              data={dataChart7}
              margin={Chart_magin}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                textAnchor="end"
                sclaeToFit="true"
                verticalAnchor="start"
                interval={0}
                angle="-25"
                stroke="#8884d8"
                tick={{ fontSize: 14 }}
              />
              <YAxis />
              <Tooltip />
              <Legend wrapperStyle={{ top: 345 }} height={36} />
              <Bar dataKey={xd_name7} fill={Chart_color1} />
              <Bar dataKey={yd_name7} fill={Chart_color2} />

              <text x={tx1} y={ty1} style={text_style}>
                เฉลี่ย
              </text>
              <text x={tx2} y={ty1} style={{ fontSize: 26, fill: "#1B5FDE" }}>
                ■
              </text>
              <text x={tx3} y={ty1} style={text_style}>
                {tavrB7} มคก./ลบม.
              </text>
              <text x={tx2} y={ty2} style={{ fontSize: 26, fill: "#F01818" }}>
                ■
              </text>
              <text x={tx3} y={ty2} style={text_style}>
                {tavrR7} มคก./ลบม.
              </text>
            </BarChart>
          </ResponsiveContainer>
        </Grid>

        <Grid item xs={12} md={6}>
          <ResponsiveContainer width="100%" height={360}>
            <BarChart
              width={Chart_width}
              height={Chart_height}
              data={dataChart8}
              margin={Chart_magin}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                textAnchor="end"
                sclaeToFit="true"
                verticalAnchor="start"
                interval={0}
                angle="-25"
                stroke="#8884d8"
                tick={{ fontSize: 14 }}
              />
              <YAxis />
              <Tooltip />
              <Legend wrapperStyle={{ top: 345 }} height={36} />
              <Bar dataKey={xd_name8} fill={Chart_color1} />
              <Bar dataKey={yd_name8} fill={Chart_color2} />

              <text x={tx1} y={ty1} style={text_style}>
                เฉลี่ย
              </text>
              <text x={tx2} y={ty1} style={{ fontSize: 26, fill: "#1B5FDE" }}>
                ■
              </text>
              <text x={tx3} y={ty1} style={text_style}>
                {tavrB8} มคก./ลบม.
              </text>
              <text x={tx2} y={ty2} style={{ fontSize: 26, fill: "#F01818" }}>
                ■
              </text>
              <text x={tx3} y={ty2} style={text_style}>
                {tavrR8} มคก./ลบม.
              </text>
            </BarChart>
          </ResponsiveContainer>
        </Grid>

        <Grid item xs={12} md={6}>
          <ResponsiveContainer width="100%" height={360}>
            <BarChart
              width={Chart_width}
              height={Chart_height}
              data={dataChart9}
              margin={Chart_magin}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                textAnchor="end"
                sclaeToFit="true"
                verticalAnchor="start"
                interval={0}
                angle="-25"
                stroke="#8884d8"
                tick={{ fontSize: 14 }}
              />
              <YAxis />
              <Tooltip />
              <Legend wrapperStyle={{ top: 345 }} height={36} />
              <Bar dataKey={xd_name9} fill={Chart_color1} />
              <Bar dataKey={yd_name9} fill={Chart_color2} />

              <text x={tx1} y={ty1} style={text_style}>
                เฉลี่ย
              </text>
              <text x={tx2} y={ty1} style={{ fontSize: 26, fill: "#1B5FDE" }}>
                ■
              </text>
              <text x={tx3} y={ty1} style={text_style}>
                {tavrB9} มคก./ลบม.
              </text>
              <text x={tx2} y={ty2} style={{ fontSize: 26, fill: "#F01818" }}>
                ■
              </text>
              <text x={tx3} y={ty2} style={text_style}>
                {tavrR9} มคก./ลบม.
              </text>
            </BarChart>
          </ResponsiveContainer>
        </Grid>

        <Grid item xs={12} md={6}>
          <ResponsiveContainer width="100%" height={360}>
            <BarChart
              width={Chart_width}
              height={Chart_height}
              data={dataChart10}
              margin={Chart_magin}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                textAnchor="end"
                sclaeToFit="true"
                verticalAnchor="start"
                interval={0}
                angle="-25"
                stroke="#8884d8"
                tick={{ fontSize: 14 }}
              />
              <YAxis />
              <Tooltip />
              <Legend wrapperStyle={{ top: 345 }} height={36} />
              <Bar dataKey={xd_name10} fill={Chart_color1} />
              <Bar dataKey={yd_name10} fill={Chart_color2} />

              <text x={tx1} y={ty1} style={text_style}>
                เฉลี่ย
              </text>
              <text x={tx2} y={ty1} style={{ fontSize: 26, fill: "#1B5FDE" }}>
                ■
              </text>
              <text x={tx3} y={ty1} style={text_style}>
                {tavrB10} มคก./ลบม.
              </text>
              <text x={tx2} y={ty2} style={{ fontSize: 26, fill: "#F01818" }}>
                ■
              </text>
              <text x={tx3} y={ty2} style={text_style}>
                {tavrR10} มคก./ลบม.
              </text>
            </BarChart>
          </ResponsiveContainer>
        </Grid>

        {/* chart พิษณุโลก */}

        <Grid item xs={12} md={6}>
          <ResponsiveContainer width="100%" height={360}>
            <BarChart
              width={Chart_width}
              height={Chart_height}
              data={dataChart12}
              margin={Chart_magin}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                textAnchor="end"
                sclaeToFit="true"
                verticalAnchor="start"
                interval={0}
                angle="-25"
                stroke="#8884d8"
                tick={{ fontSize: 14 }}
              />
              <YAxis />
              <Tooltip />
              <Legend wrapperStyle={{ top: 345 }} height={36} />
              <Bar dataKey={xd_name12} fill={Chart_color1} />
              <Bar dataKey={yd_name12} fill={Chart_color2} />

              <text x={tx1} y={ty1} style={text_style}>
                เฉลี่ย
              </text>
              <text x={tx2} y={ty1} style={{ fontSize: 26, fill: "#1B5FDE" }}>
                ■
              </text>
              <text x={tx3} y={ty1} style={text_style}>
                {tavrB12} มคก./ลบม.
              </text>
              <text x={tx2} y={ty2} style={{ fontSize: 26, fill: "#F01818" }}>
                ■
              </text>
              <text x={tx3} y={ty2} style={text_style}>
                {tavrR12} มคก./ลบม.
              </text>
            </BarChart>
          </ResponsiveContainer>
        </Grid>

        {/* chart สมุทรสาคร/นนทบุรี/ระยอง */}

        <Grid item xs={12} md={6}>
          <ResponsiveContainer width="100%" height={360}>
            <BarChart
              width={Chart_width}
              height={Chart_height}
              data={dataChart13}
              margin={Chart_magin}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                textAnchor="end"
                sclaeToFit="true"
                verticalAnchor="start"
                interval={0}
                angle="-25"
                stroke="#8884d8"
                tick={{ fontSize: 14 }}
              />
              <YAxis />
              <Tooltip />
              <Legend wrapperStyle={{ top: 345 }} height={36} />
              <Bar dataKey={xd_name13} fill={Chart_color1} />
              <Bar dataKey={yd_name13} fill={Chart_color2} />

              <text x={tx1} y={ty1} style={text_style}>
                เฉลี่ย
              </text>
              <text x={tx2} y={ty1} style={{ fontSize: 26, fill: "#1B5FDE" }}>
                ■
              </text>
              <text x={tx3} y={ty1} style={text_style}>
                {tavrB13} มคก./ลบม.
              </text>
              <text x={tx2} y={ty2} style={{ fontSize: 26, fill: "#F01818" }}>
                ■
              </text>
              <text x={tx3} y={ty2} style={text_style}>
                {tavrR13} มคก./ลบม.
              </text>
            </BarChart>
          </ResponsiveContainer>
        </Grid>

        <Grid item xs={12} md={6}>
          <ResponsiveContainer width="100%" height={360}>
            <BarChart
              width={Chart_width}
              height={Chart_height}
              data={dataChart14}
              margin={Chart_magin}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                textAnchor="end"
                sclaeToFit="true"
                verticalAnchor="start"
                interval={0}
                angle="-25"
                stroke="#8884d8"
                tick={{ fontSize: 14 }}
              />
              <YAxis />
              <Tooltip />
              <Legend wrapperStyle={{ top: 345 }} height={36} />
              <Bar dataKey={xd_name14} fill={Chart_color1} />
              <Bar dataKey={yd_name14} fill={Chart_color2} />

              <text x={tx1} y={ty1} style={text_style}>
                เฉลี่ย
              </text>
              <text x={tx2} y={ty1} style={{ fontSize: 26, fill: "#1B5FDE" }}>
                ■
              </text>
              <text x={tx3} y={ty1} style={text_style}>
                {tavrB14} มคก./ลบม.
              </text>
              <text x={tx2} y={ty2} style={{ fontSize: 26, fill: "#F01818" }}>
                ■
              </text>
              <text x={tx3} y={ty2} style={text_style}>
                {tavrR14} มคก./ลบม.
              </text>
            </BarChart>
          </ResponsiveContainer>
        </Grid>

        <Grid item xs={12} md={6}>
          <ResponsiveContainer width="100%" height={360}>
            <BarChart
              width={Chart_width}
              height={Chart_height}
              data={dataChart15}
              margin={Chart_magin}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                textAnchor="end"
                sclaeToFit="true"
                verticalAnchor="start"
                interval={0}
                angle="-25"
                stroke="#8884d8"
                tick={{ fontSize: 14 }}
              />
              <YAxis />
              <Tooltip />
              <Legend wrapperStyle={{ top: 345 }} height={36} />
              <Bar dataKey={xd_name15} fill={Chart_color1} />
              <Bar dataKey={yd_name15} fill={Chart_color2} />

              <text x={tx1} y={ty1} style={text_style}>
                เฉลี่ย
              </text>
              <text x={tx2} y={ty1} style={{ fontSize: 26, fill: "#1B5FDE" }}>
                ■
              </text>
              <text x={tx3} y={ty1} style={text_style}>
                {tavrB15} มคก./ลบม.
              </text>
              <text x={tx2} y={ty2} style={{ fontSize: 26, fill: "#F01818" }}>
                ■
              </text>
              <text x={tx3} y={ty2} style={text_style}>
                {tavrR15} มคก./ลบม.
              </text>
            </BarChart>
          </ResponsiveContainer>
        </Grid>

        <Grid item xs={12} md={6}>
          <ResponsiveContainer width="100%" height={360}>
            <BarChart
              width={Chart_width}
              height={Chart_height}
              data={dataChart16}
              margin={Chart_magin}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                textAnchor="end"
                sclaeToFit="true"
                verticalAnchor="start"
                interval={0}
                angle="-25"
                stroke="#8884d8"
                tick={{ fontSize: 14 }}
              />
              <YAxis />
              <Tooltip />
              <Legend wrapperStyle={{ top: 345 }} height={36} />
              <Bar dataKey={xd_name16} fill={Chart_color1} />
              <Bar dataKey={yd_name16} fill={Chart_color2} />

              <text x={tx1} y={ty1} style={text_style}>
                เฉลี่ย
              </text>
              <text x={tx2} y={ty1} style={{ fontSize: 26, fill: "#1B5FDE" }}>
                ■
              </text>
              <text x={tx3} y={ty1} style={text_style}>
                {tavrB16} มคก./ลบม.
              </text>
              <text x={tx2} y={ty2} style={{ fontSize: 26, fill: "#F01818" }}>
                ■
              </text>
              <text x={tx3} y={ty2} style={text_style}>
                {tavrR16} มคก./ลบม.
              </text>
            </BarChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
      <br />
      <br />
    </React.Fragment>
  );
};

export default Chart_data;
