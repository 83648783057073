import React from "react";

import { Container } from "@mui/material";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

import "./css/realtime.css";

const Iaq = () => {
  return (
    <React.Fragment>
      <div style={{ marginBottom: "100px" }} />

      <Navbar />

      <Container>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <h3
            className="lineUp"
            style={{ fontSize: "28px", color: "#009874", fontWeight: "100" }}
          >
            คุณภาพอากาศภายในอาคาร
          </h3>
        </div>

        <div style={{ marginBottom: "50px" }} />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #B3B6B7",
            borderRadius: "10px",
            padding: "2%",
            fontSize: "18px"
          }}
        >
          <ol>
            <li>
              <a href="/IAQ/RIHES1_IAQ" target="_blank" className="li-style">
                คุณภาพอากาศภายในอาคาร 1 สถาบันวิจัยวิทยาศาสตร์สุขภาพ
              </a>
            </li>
            <li>
              <a href="/IAQ/RIHES2_IAQ" target="_blank" className="li-style">
                คุณภาพอากาศภายในอาคารสำนักวิชาการวิจัยวิทยาศาสตร์สุขภาพ
              </a>
            </li>
            <li>
              <a href="/IAQ/IAQ_test" target="_blank" className="li-style">
                คุณภาพอากาศภายในห้องผู้อำนวยการ
              </a>
            </li>
            {/* <li>
              <a href="/IAQ/Pongnoi" target="_blank" className="li-style">
                คุณภาพอากาศภายในห้องเรียนโรงเรียนบ้านโป่งน้อย
              </a>
            </li> */}
            <li>
              <a href="/IAQ/Pasuk41" target="_blank" className="li-style">
                คุณภาพอากาศภายในห้อง 4/1 โรงเรียนผาสุกมณีจักรมิตรภาพที่ 116
              </a>
            </li>
            <li>
              <a href="/IAQ/Pasuk42" target="_blank" className="li-style">
                คุณภาพอากาศภายในห้อง 4/2 โรงเรียนผาสุกมณีจักรมิตรภาพที่ 116
              </a>
            </li>
            <li>
              <a href="/IAQ/Pasuk43" target="_blank" className="li-style">
                คุณภาพอากาศภายในห้อง 4/3 โรงเรียนผาสุกมณีจักรมิตรภาพที่ 116
              </a>
            </li>
            <li>
              <a href="/IAQ/Watkoo4" target="_blank" className="li-style">
                คุณภาพอากาศภายในห้องป.4 โรงเรียนวัดกู้ (นันทาภิวัฒน์วิทยา)
              </a>
            </li>
            <li>
              <a href="/IAQ/Watkoo5" target="_blank" className="li-style">
                คุณภาพอากาศภายในห้องป.5 โรงเรียนวัดกู้ (นันทาภิวัฒน์วิทยา)
              </a>
            </li>
          </ol>
        </div>
      </Container>

      <div style={{ marginBottom: "100px" }} />

      <Footer />
    </React.Fragment>
  );
};

export default Iaq;
