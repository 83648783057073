import React, { useState, useEffect } from "react";

import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";

import axios from "axios";
import moment from "moment";
import "moment/locale/th";

import * as xlsx from "xlsx";

function Tdata_month() {
  let xURL = "";
  // let xlocation = window.location.hostname;

  // if (xlocation === "localhost") {
  //   xURL = "http://localhost:5000/api/";
  // } else {
  //   xURL = "https://www2.ntaqhi.info/api/";
  // }

  xURL = "https://www2.ntaqhi.info/api/";

  const [dgdataSource, setdgdataSource] = useState([]);
  const [dgcolumns, setdgcolumns] = useState([]);

  useEffect(() => {
    axios
      .get(xURL + "getdate")
      .then(result => {
        //console.log(result.data);

        setColumn(result.data);

        axios
          .post(xURL + "getdataM", {
            rowColumn: result.data.length,
            datedata: result.data
          })
          .then(result2 => {
            const Tb1 = [];

            // console.log(result2);
            result2.data.forEach((i, idx) => {
              let zid = "";

              if (i.d_id) {
                if (i.entity === "NTAQHI") {
                  zid = "ID" + i.d_id;
                } else {
                  zid = i.d_id;
                }
              }

              //console.log(result.data.length);
              let ndate1 = 0,
                ndate2 = 0,
                ndate3 = 0,
                ndate4 = 0,
                ndate5 = 0,
                ndate6 = 0,
                ndate7 = 0,
                avr = 0,
                nlength = 0;

              if (i.date1) {
                ndate1 = i.date1;
                nlength = nlength + 1;
              }
              if (i.date2) {
                ndate2 = i.date2;
                nlength = nlength + 1;
              }
              if (i.date3) {
                ndate3 = i.date3;
                nlength = nlength + 1;
              }
              if (i.date4) {
                ndate4 = i.date4;
                nlength = nlength + 1;
              }
              if (i.date5) {
                ndate5 = i.date5;
                nlength = nlength + 1;
              }
              if (i.date6) {
                ndate6 = i.date6;
                nlength = nlength + 1;
              }
              if (i.date7) {
                ndate7 = i.date7;
                nlength = nlength + 1;
              }

              /*avr = Math.round(
                (ndate1 + ndate2 + ndate3 + ndate4 + ndate5 + ndate6 + ndate7) /
                  result.data.length
              );*/
              avr = Math.round(
                (ndate1 + ndate2 + ndate3 + ndate4 + ndate5 + ndate6 + ndate7) /
                  nlength
              );

              let xentity = "";
              if (i.entity == "Air4Th") {
                xentity = "PCD";
              } else {
                xentity = i.entity;
              }

              Tb1.push({
                key: idx,
                city: i.d_city,
                entity: xentity,
                id: zid,
                name: i.d_name,
                date1: i.date1,
                date2: i.date2,
                date3: i.date3,
                date4: i.date4,
                date5: i.date5,
                date6: i.date6,
                date7: i.date7,
                average: avr
              });
            });

            //console.log(Tb2);
            setdgdataSource(Tb1);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const setColumn = xdata => {
    const Tb1 = [];

    Tb1.push(
      {
        name: "city",
        header: "จังหวัด",
        maxWidth: 100,
        minWidth: 100,
        defaultFlex: 1,
        headerAlign: "center",
        textAlign: "center",
        sortable: false,
        rowspan: ({ value, dataSourceArray, rowIndex, column }) => {
          let rowspan = 1;

          const prevData = dataSourceArray[rowIndex - 1];
          if (prevData && prevData[column.name] === value) {
            return rowspan;
          }
          let currentRowIndex = rowIndex + 1;
          while (
            dataSourceArray[currentRowIndex] &&
            dataSourceArray[currentRowIndex][column.name] === value
          ) {
            rowspan++;
            currentRowIndex++;
          }
          return rowspan;
        }
      },
      {
        name: "entity",
        header: "จุดตรวจ",
        maxWidth: 80,
        minWidth: 80,
        defaultFlex: 1,
        headerAlign: "center",
        textAlign: "center",
        sortable: false,
        style: entityStyle
      },
      {
        name: "id",
        header: "รหัส",
        maxWidth: 80,
        minWidth: 80,
        defaultFlex: 1,
        headerAlign: "center",
        textAlign: "center",
        sortable: false,
        style: entityStyle
      },
      {
        name: "name",
        header: "ชื่อสถานีตรวจวัด",
        defaultWidth: 110,
        maxWidth: 220,
        minWidth: 110,
        defaultFlex: 1,
        headerAlign: "center",
        //textAlign: "center",
        sortable: false,
        style: entityStyle
      }
    );

    xdata.forEach((i, idx) => {
      let zdate = "";

      if (i.d_date) {
        zdate = moment(i.d_date).add(-1, "day").format("DD/MM/YYYY");
      }

      Tb1.push({
        name: "date" + parseInt(idx + 1),
        header: zdate,
        maxWidth: 93,
        minWidth: 93,
        defaultFlex: 1,
        headerAlign: "center",
        textAlign: "center",
        sortable: false,
        style: entityStyle
      });
    });

    Tb1.push({
      name: "average",
      header: "เฉลี่ย",
      maxWidth: 80,
      minWidth: 80,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false,
      style: entityStyle
    });

    setdgcolumns(Tb1);
  };

  const excelclick = () => {
    //console.log("test");
    let xdata = [];
    let header = [];

    dgcolumns.forEach((i, idx) => {
      header.push(i.header);
    });

    xdata.push(["คุณภาพอากาศ PM25 เดือนนี้"]);
    xdata.push([""]);
    xdata.push(header);

    for (var row in dgdataSource) {
      const xx = [];

      for (var col in dgdataSource[row]) {
        if (col !== "key") {
          xx.push(dgdataSource[row][col]);
        }
      }

      xdata.push(xx);
    }

    var wb = xlsx.utils.book_new(),
      ws = xlsx.utils.json_to_sheet(xdata, { skipHeader: true });

    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    xlsx.writeFile(wb, "Excel.xlsx");
  };

  const gridStyle = { minHeight: 1322 };

  const entityStyle = ({ data }) => {
    const colorMap = {
      PCD: "#1B5FDE",
      NTAQHI: "#F01818"
    };

    return {
      color: colorMap[data.entity]
    };
  };

  return (
    <React.Fragment>
      <h4 style={{ fontSize: "16px" }}>
        1. ตารางแสดงข้อมูลค่า PM2.5 เฉลี่ยรายวัน (มคก./ลบม.) ย้อนหลัง 7 วัน
      </h4>
      <ReactDataGrid
        idProperty="id"
        columns={dgcolumns}
        dataSource={dgdataSource}
        style={gridStyle}
        // showZebraRows={false}
      />
      <p style={{ fontSize: "14px", marginLeft: "40px" }}>
        หมายเหตุ : ค่าที่เป็น 0 หมายถึงไม่มีข้อมูลเนื่องจากเครื่องวัดออฟไลน์
      </p>
    </React.Fragment>
  );
}

export default Tdata_month;
