import React, { useState, useEffect } from "react";
import axios from "axios";

import moment from "moment";
import "moment/locale/th";

import {
  Container,
  Stack,
  IconButton,
  TextField,
  InputAdornment
} from "@mui/material";

import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";

import Navbar_Admin from "../../../components/Navbar_Admin";
import Footer from "../../../components/Footer";

import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";

import "./css/Maponline.css";

const MapOnline = () => {
  let xURL = "";
  xURL = "https://www2.ntaqhi.info/api/";

  const [dateLast, setdateLast] = useState();
  const [timeLast, settimeLast] = useState();

  const [dgdataSource, setdgdataSource] = useState([]);
  const [dgdataSource2, setdgdataSource2] = useState([]);

  const [xCount, setxCount] = useState();
  const [xCount2, setxCount2] = useState();

  useEffect(() => {
    const token = localStorage.getItem("token");

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          getDate();

          getMap("");

          getDeviceOnline("");
          getDeviceOffline("");
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/admin";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const getDate = () => {
    setdateLast(moment().format("DD/MM/YYYY").toString());
    settimeLast(moment().format("HH:mm").toString());
  };

  const getMap = () => {
    axios
      .get(xURL + "getmapAdmin")
      .then(result => {
        const Tb1 = [];
        let xcountOn = 0,
          xcountOff = 0;

        let xarea_point = "",
          xdevice_source = "";

        let xdistricts = "",
          xamphures = "",
          xprovinces = "";

        result.data.forEach((i, idx) => {
          xarea_point = "/?area-point=" + i.area_point;

          if (i.device_source == "cmaqhi") {
            xdevice_source = "NTAQHI";
          } else {
            xdevice_source = i.device_source;
          }

          if (i.districts) {
            xdistricts = "ต." + i.districts;
          } else {
            xdistricts = "";
          }

          if (i.amphures) {
            xamphures = "อ." + i.amphures;
          } else {
            xamphures = "";
          }

          if (i.provinces) {
            xprovinces = "จ." + i.provinces;
          } else {
            xprovinces = "";
          }

          Tb1.push({
            device_id: "ID " + i.d_id,
            name: i.d_name,
            lat: i.poi_lat,
            lon: i.poi_lng,
            status: i.status,
            device_source: xdevice_source,
            districts: xdistricts,
            amphures: xamphures,
            provinces: xprovinces
          });

          if (i.status == "Online") {
            xcountOn = xcountOn + 1;
          }

          if (i.status == "Offline") {
            xcountOff = xcountOff + 1;
          }
        });

        setxCount(xcountOn);
        setxCount2(xcountOff);

        setMap(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const getDeviceOnline = device_name => {
    let xdevice_name = "";

    if (!device_name) {
      xdevice_name = "";
    } else {
      xdevice_name = device_name;
    }

    axios
      .post(xURL + "getdeviceonline", { name: xdevice_name })
      .then(result => {
        const Tb1 = [];

        let xarea_point = "",
          xdevice_source = "",
          xdistricts = "",
          xamphures = "",
          xprovinces = "";

        result.data.forEach((i, idx) => {
          xarea_point = "/?area-point=" + i.area_point+'&pages=admin';

          if (i.districts) {
            xdistricts = "ต." + i.districts;
          } else {
            xdistricts = "";
          }

          if (i.amphures) {
            xamphures = "อ." + i.amphures;
          } else {
            xamphures = "";
          }

          if (i.provinces) {
            xprovinces = "จ." + i.provinces;
          } else {
            xprovinces = "";
          }

          if (i.device_source == "cmaqhi") {
            xdevice_source = "NTAQHI";
          } else {
            xdevice_source = i.device_source;
          }

          Tb1.push({
            Number: parseInt(idx + 1),
            online_entity: (
              <a
                style={{ textDecoration: "none" }}
                href={xarea_point}
                target="_blank"
              >
                {i.d_name} {xdistricts} {xamphures} {xprovinces}
              </a>
            ),
            device_id: "ID " + i.d_id,
            name: i.d_name,
            lat: i.poi_lat,
            lon: i.poi_lng,
            device_source: xdevice_source
          });
        });

        setdgdataSource(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const getDeviceOffline = device_name => {
    let xdevice_name = "";

    if (!device_name) {
      xdevice_name = "";
    } else {
      xdevice_name = device_name;
    }

    axios
      .post(xURL + "getdeviceoffline", { name: xdevice_name })
      .then(result => {
        const Tb1 = [];

        let xarea_point = "";

        let xdistricts = "",
          xamphures = "",
          xprovinces = "";

        result.data.forEach((i, idx) => {
          xarea_point = "/?area-point=" + i.area_point+'&pages=admin';

          if (i.districts) {
            xdistricts = "ต." + i.districts;
          } else {
            xdistricts = "";
          }

          if (i.amphures) {
            xamphures = "อ." + i.amphures;
          } else {
            xamphures = "";
          }

          if (i.provinces) {
            xprovinces = "จ." + i.provinces;
          } else {
            xprovinces = "";
          }

          Tb1.push({
            Number: parseInt(idx + 1),
            offline_entity: (
              <a
                style={{ textDecoration: "none" }}
                href={xarea_point}
                target="_blank"
              >
                {i.d_name} {xdistricts} {xamphures} {xprovinces}
              </a>
            ),
            device_id: "ID " + i.d_id,
            last_online: i.lastdate_off,
            name: i.d_name,
            lat: i.poi_lat,
            lon: i.poi_lng
          });
        });

        setdgdataSource2(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  function setMap(mark) {
    const script = document.createElement("script");
    script.src = `https://api.longdo.com/map/?key=16f9234b232cc53f721c5e7ac40f5965`;
    script.id = "longdoMapScript";
    document.body.appendChild(script);

    script.onload = () => {
      mapCallback(mark);
    };

    const mapCallback = () => {
      let longdo = window.longdo;
      let map = new window.longdo.Map({
        placeholder: document.getElementById("longdo-map-on"),
        language: "th"
      });

      map.location(
        {
          // พิกัด zoom 8
          // lat: 18.200195,
          // lon: 99.600751
          lat: 15.800195,
          lon: 101.500751
        },
        true
      );
      map.zoom(6);
      map.zoomRange({
        min: 6,
        max: 17
      });

      map.Layers.setBase(longdo.Layers.POLITICAL);

      map.Ui.LayerSelector.visible(true);
      map.Ui.Mouse.enable(true);
      map.Ui.Mouse.enableDrag(true);
      map.Ui.DPad.visible(true);
      map.Ui.LayerSelector.visible(false);
      map.Ui.Toolbar.visible(false);
      map.Ui.Fullscreen.visible(false);
      map.Ui.Crosshair.visible(false);
      map.Ui.Scale.visible(true);

      map.Overlays.clear();

      mark.forEach(i => {
        // console.log(i.aqhi)
        let titlepin = "",
          detailpin = "",
          markerpin = "";

        titlepin = "<p class='title-style'>" + i.name + "</p>";
        detailpin =
          " [" +
          i.device_id +
          "] " +
          i.districts +
          " " +
          i.amphures +
          " " +
          i.provinces;

        if (i.status == "Online") {
          // if (i.device_source == "NTAQHI")
          markerpin = "<div class='pinonline'></div>";
          // } else {
          //   markerpin = "<div class='pinnanogen'></div>";
          // }
        } else {
          markerpin = "<div class='pinoffline'></div>";
        }

        let marker = new longdo.Marker(
          { lon: i.lon, lat: i.lat },
          {
            title: titlepin,
            detail: detailpin,
            icon: {
              // url: piclocation,
              html: markerpin,
              offset: { x: 10, y: 10 }
            },

            size: {
              width: 200,
              height: 70
            }
          }
        );

        map.Overlays.add(marker);
      });
    };
  }

  const dgcolumns = [
    {
      name: "Number",
      header: "Number",
      maxWidth: 100,
      minWidth: 50,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      type: "number",
      sortable: false
    },
    {
      name: "device_id",
      header: "Device_id",
      maxWidth: 320,
      minWidth: 70,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      type: "number",
      sortable: false
    },
    {
      name: "online_entity",
      header: "สถานีตรวจวัดที่ออนไลน์",
      maxWidth: 900,
      minWidth: 175,
      defaultFlex: 1,
      headerAlign: "center",
      sortable: false
    },
    {
      name: "device_source",
      header: "จุดตรวจ",
      maxWidth: 320,
      minWidth: 100,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      type: "number",
      sortable: false
    }
  ];

  const dgcolumns2 = [
    {
      name: "Number",
      header: "Number",
      maxWidth: 100,
      minWidth: 50,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      type: "number",
      sortable: false
    },
    {
      name: "device_id",
      header: "Device_id",
      maxWidth: 250,
      minWidth: 100,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      type: "number",
      sortable: false
    },
    {
      name: "offline_entity",
      header: "สถานีตรวจวัดที่ออฟไลน์",
      maxWidth: 900,
      minWidth: 175,
      defaultFlex: 1,
      headerAlign: "center",
      sortable: false
    },
    {
      name: "last_online",
      header: "ออนไลน์ล่าสุด",
      maxWidth: 400,
      minWidth: 200,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false
    }
  ];

  const gridStyle = { minHeight: 442 };
  const gridStyle2 = { minHeight: 442 };

  const scrollProps = Object.assign(
    {},
    ReactDataGrid.defaultProps.scrollProps,
    {
      autoHide: false
    }
  );

  const scrollProps2 = Object.assign(
    {},
    ReactDataGrid.defaultProps.scrollProps,
    {
      autoHide: false
    }
  );

  const searchname = values => {
    // console.log(values);

    getDeviceOnline(values);
  };

  const searchname2 = values => {
    // console.log(values);

    getDeviceOffline(values);
  };

  return (
    <React.Fragment>
      <div style={{ marginBottom: "100px" }} />

      <Navbar_Admin />

      <Container>
        <h2>แผนที่และรายการเครื่องที่ออนไลน์/ออฟไลน์</h2>

        <Stack direction="row" spacing={1}>
          <IconButton
            style={{ marginTop: "-8px" }}
            color="primary"
            aria-label="refresh"
            onClick={() => {
              // window.location.reload(false);

              getDate();
              setdgdataSource([]);
              setdgdataSource2([]);

              getMap();
              getDeviceOnline("");
              getDeviceOffline("");
            }}
          >
            <RefreshIcon />
          </IconButton>

          <p style={{ fontSize: "17px", color: "#1452E1" }}>
            วันที่ {dateLast} เวลา {timeLast} น.
          </p>
        </Stack>

        <div
          id="longdo-map-on"
          style={{
            height: "450px",
            border: "1px solid #B3B6B7",
            borderRadius: "10px",
            padding: "1%"
          }}
        />

        <div style={{ marginBottom: "30px", backgroundColor: "#fff" }}>
          <span style={{ fontSize: "12px", color: "#748DA6" }}>
            - ขอขอบคุณ{" "}
            <a
              style={{ textDecoration: "none" }}
              href="https://map.longdo.com/"
            >
              Longdo Map
            </a>{" "}
            ที่สนับสนุนการทำแผนที่
          </span>
        </div>

        {/* <div style={{ float: "right", marginTop: "-60px" }}>
          <p style={{ fontSize: "14px" }}>น้ำเงิน = NTAQHI</p>
          <p style={{ fontSize: "14px", marginTop: "-10px" }}>ม่วง = NanoGen</p>
          <p style={{ fontSize: "14px", marginTop: "-10px" }}>แดง = Off-line</p>
        </div> */}

        <div style={{ marginTop: "-15px" }}>
          <p style={{ fontSize: "18px", color: "blue" }}>
            สถานีตรวจวัดที่ออนไลน์ทั้งหมด {xCount} จุด
          </p>
        </div>

        <TextField
          margin="normal"
          size="small"
          id="search_name"
          label="ค้นหาสถานีตรวจวัดออนไลน์"
          name="search_name"
          // autoFocus
          sx={{ width: 320, marginTop: "-15px" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          onChange={event => {
            searchname(event.target.value);
          }}
        />

        <ReactDataGrid
          idProperty="Number"
          style={gridStyle}
          enableClipboard
          scrollProps={scrollProps}
          rowIndexColumn
          columns={dgcolumns}
          dataSource={dgdataSource}
        />

        <div style={{ marginTop: "10px" }}>
          <p style={{ fontSize: "18px", color: "red" }}>
            สถานีตรวจวัดที่ออฟไลน์ทั้งหมด {xCount2} จุด
          </p>
        </div>

        <TextField
          margin="normal"
          size="small"
          id="search_name"
          label="ค้นหาสถานีตรวจวัดออฟไลน์"
          name="search_name"
          // autoFocus
          sx={{ width: 320, marginTop: "-15px" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          onChange={event => {
            searchname2(event.target.value);
          }}
        />

        <ReactDataGrid
          idProperty="Number"
          columns={dgcolumns2}
          dataSource={dgdataSource2}
          style={gridStyle2}
          // showZebraRows={false}
          enableClipboard
          scrollProps={scrollProps2}
        />
      </Container>

      <Footer />
    </React.Fragment>
  );
};

export default MapOnline;
