import React from "react";
import "../pages/safezone/css/setImg.css";
import "./css/Contact.css";

import { Container, Box, Grid } from "@mui/material";

import Icon_facebook from "../imgs/icons/facebook.png";
import Icon_youtube from "../imgs/icons/youtube.png";
import Icon_tiktok from "../imgs/icons/tiktok.png";
import Icon_line from "../imgs/icons/line.png";

const Contact = () => {
  return (
    <React.Fragment>
      <div className="div-style">
        <Container>
          <div
            style={{
              width: "75%",
              height: "auto",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto"
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  <p className="text-style">
                    ท่านใดมีคำถามหรือข้อเสนอแนะเกี่ยวกับ <br />การทำห้องปลอดฝุ่น
                    กรุณาติดต่อได้ที่
                  </p>
                </Grid>

                <Grid item xs={6} md={6} mt={4}>
                  <Grid container spacing={1}>
                    <Grid item xs={6} md={3}>
                      <a
                        href="https://www.facebook.com/FreshAir4Thai/"
                        target="_blank"
                      >
                        <img
                          style={{
                            width: "60px",
                            height: "60px"
                          }}
                          className="img-artboard"
                          src={Icon_facebook}
                          alt="facebook"
                        />
                      </a>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <a
                        href="https://www.youtube.com/channel/UCTHivwQ4-2s7IjpeXhba4KQ"
                        target="_blank"
                      >
                        <img
                          style={{
                            width: "60px",
                            height: "60px"
                          }}
                          className="img-artboard"
                          src={Icon_youtube}
                          alt="youtube"
                        />
                      </a>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <a
                        href="https://www.tiktok.com/@rihes.cmu"
                        target="_blank"
                      >
                        <img
                          style={{
                            width: "60px",
                            height: "60px"
                          }}
                          className="img-artboard"
                          src={Icon_tiktok}
                          alt="tiktok"
                        />
                      </a>
                    </Grid>
                    <Grid item xs={6} md={3} mt={-0.5}>
                      <a
                        href="https://liff.line.me/1645278921-kWRPP32q/?accountId=035jsmcl"
                        target="_blank"
                      >
                        <img
                          style={{
                            width: "70px",
                            height: "70px"
                          }}
                          className="img-artboard"
                          src={Icon_line}
                          alt="line"
                        />
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Contact;
