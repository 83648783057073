import React from "react";

import { Card, CardHeader, CardContent } from "@mui/material/";

import "./css/setImg.css";
import qa from "../../imgs/qa_1646705038975.jpg";
import qa2 from "../../imgs/qa_1646704864739.jpg";
import qa3 from "../../imgs/qa_1646704906292.jpg";

const Listdetail = () => {
  return (
    <React.Fragment>
      <h3 style={{ fontSize: "17px" }}>1. ต้นตอของปัญหาหมอกควัน</h3>
      <hr style={{ marginTop: "-15px" }} />

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม พื้นที่ใดของประเทศไทยที่มักประสบกับปัญหามลพิษทางอากาศ ?"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong>{" "}
            ทุกภาคของประเทศไทยประสบกับปัญหามลพิษทางอากาศทั้งสิ้น
            แต่อาจจะมากหรือน้อยไม่เท่ากัน
            และสาเหตุของการเกิดมลพิษทางอากาศก็แตกต่างกันในแต่ละพื้นที่
            สำหรับจังหวัดในพื้นที่ภาคเหนือตอนบนที่ประสบปัญหาหมอกควันซ้ำซาก
            ได้แก่ เชียงใหม่ เชียงราย แม่ฮ่องสอน ลำปาง ลำพูน แพร่ น่าน พะเยา
            และตาก
          </div>
        </CardContent>
      </Card>

      <br />

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม ในพื้นที่ภาคเหนือตอนบนเพิ่งประสบกับปัญหาหมอกควันหรือเป็นมานานแล้ว ?"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong>{" "}
            ที่จริงพื้นที่ภาคเหนือตอนบนประสบกับปัญหาหมอกควันมานานแล้ว
            เพียงแต่ในอดีตอาจจะไม่เด่นชัดนักเนื่องจากไม่มีข้อมูลเพียงพอ
            แต่ที่เริ่มพบว่าเป็นปัญหาอย่างชัดเจนและรุนแรงเริ่มขึ้นเมื่อประมาณ 10
            กว่าปีก่อน คือตั้งแต่ปี พ.ศ. 2550 เป็นต้นมา
          </div>
        </CardContent>
      </Card>

      <br />

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม ปัญหาหมอกควันในพื้นที่ภาคเหนือตอนบนเกิดขึ้นตลอดทั้งปีหรือเป็นเฉพาะบางช่วงของปี ?"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong>{" "}
            ปัญหาหมอกควันในพื้นที่ภาคเหนือตอนบนมักจะเกิดขึ้นในฤดูแล้งของทุกปี
            แต่มีความรุนแรงและยาวนานขึ้นเรื่อย ๆ
            จากเมื่อสิบกว่าปีก่อนมีปัญหาปีละ 2-3 เดือน แต่ปัจจุบันมีปัญหาปีละ
            4-5 เดือน
            ซึ่งอาจจะรุนแรงและยาวนานขึ้นกว่านี้หากไม่ได้รับการเยียวยาแก้ไขอย่างถูกต้อง
          </div>
        </CardContent>
      </Card>

      <br />

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม ปัญหาหมอกควันในพื้นที่ภาคเหนือตอนบนมีสาเหตุมาจากอะไร ?"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong>{" "}
            ปัจจัยสำคัญที่ทำให้เกิดปัญหาหมอกควันในพื้นที่ภาคเหนือตอนบนคือ
            <ol>
              <li>
                {" "}การเผาในที่โล่งแจ้ง เช่น
                การเผาเศษวัสดุเหลือใช้ทางการเกษตรในที่ราบและที่ดอน การเผาขยะ
                การเผาพื้นที่ไร่หมุนเวียนในที่สูงเพื่อเตรียมการเพาะปลูก
                การเผาป่า เป็นต้น{" "}
              </li>
              <li>
                {" "}สภาพภูมิประเทศ เช่น
                ตัวเมืองเชียงใหม่ที่มีลักษณะเป็นแอ่งกระทะ ฯลฯ{" "}
              </li>
              <li> สภาพภูมิอากาศและอุตุนิยมวิทยา และ </li>
              <li>
                {" "}หมอกควันข้ามแดนมาจากประเทศเพื่อนบ้านที่มีแนวโน้มเพิ่มขึ้นเพราะมีการเผาป่าและเตรียมการเกษตรมากขึ้น
                ได้แก่ สาธารณรัฐเมียนม่าร์ สาธารณรัฐประชาธิปไตยประชาชนลาว
                ราชอาณาจักรกัมพูชา และสาธารณรัฐสังคมนิยมเวียดนาม
              </li>
            </ol>
          </div>
        </CardContent>
      </Card>

      <h3 style={{ fontSize: "17px", marginTop: "50px" }}>
        2. ผลกระทบของปัญหาหมอกควันต่อสุขภาพ
      </h3>
      <hr style={{ marginTop: "-15px" }} />
      <p style={{ fontSize: "14px" }}>
        ดูรายละเอียดได้ที่{" "}
        <a style={{ textDecoration: "none" }} href="/cal">
          https://www2.ntaqhi.info/cal
        </a>
      </p>

      <h3 style={{ fontSize: "17px", marginTop: "50px" }}>
        3. การวัดมลพิษทางอากาศและการแจ้งเตือนแก่ประชาชน
      </h3>

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม ดัชนีคุณภาพอากาศ (Air Quality Index หรือ AQI) คืออะไร"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong> ดัชนีคุณภาพอากาศ (Air Quality Index)
            คือดัชนีที่หน่วยงานของรัฐในแต่ละประเทศใช้ในการแจ้งต่อสาธารณะว่าขณะนี้คุณภาพของอากาศเป็นอย่างไรและยังอาจพยากรณ์ว่าคุณภาพอากาศในอนาคตจะเป็นอย่างไร
            ซึ่งในแต่ละประเทศอาจจะมีการตั้งค่ามาตรฐานของ AQI แตกต่างกันได้ เช่น
            ประเทศสหรัฐอเมริกาใช้มาตรฐาน AQI ของ The United States Environmental
            Protection Agency (US EPA), ประเทศแคนาดาใช้ Air Quality Health Index
            (AQHI), ประเทศมาเลเซียใช้ Air Pollution Index (API),
            ประเทศสิงคโปร์ใช้ Pollutant Standards Index (PSI)
            และประเทศไทยดัดแปลง PSI มากำหนดเป็นมาตรฐาน AQI ของตนเอง อย่างไรก็ตาม
            วัตถุประสงค์ของการกำหนดมาตรฐาน AQI มาจากหลักการเดียวกันคือ
            ต้องการแจ้งเตือนให้ประชาชนรับทราบถึงความเสี่ยงที่จะได้รับผลกระทบต่อสุขภาพจากมลภาวะทางอากาศในขณะนั้นหรือในอนาคต
            การกำหนดมาตรฐาน AQI
            ในแต่ละประเทศมักสะท้อนถึงพัฒนาการด้านการจัดการมลภาวะทางอากาศของประเทศนั้นๆ
            ยิ่งมีการกำหนดค่าที่ต่ำกว่ามาตรฐานมากเพียงใด
            ก็แสดงว่าประเทศนั้นยังอยู่ในขั้นต้นๆของพัฒนาการด้านการจัดการมลภาวะทางอากาศมากเพียงนั้น
          </div>
        </CardContent>
      </Card>

      <br />

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม ทำไมประชาชนต้องรู้จักดัชนีคุณภาพอากาศ (Air Quality Index)"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong>{" "}
            จากหลักการดังกล่าวข้างต้นประชาชนจึงควรรู้จักดัชนีคุณภาพอากาศ หรือ
            AQI
            เพื่อจะได้รู้ว่าในขณะนั้นหรือในอนาคตข้างหน้าตนเองมีความเสี่ยงที่จะได้รับผลกระทบต่อสุขภาพจากมลภาวะทางอากาศมากน้อยเพียงใด
            และสามารถเตรียมตัวรับมือกับผลกระทบที่อาจเกิดขึ้นได้อย่างมีประสิทธิภาพและทันต่อเหตุการณ์
          </div>
        </CardContent>
      </Card>

      <br />

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม ดัชนีคุณภาพอากาศ (Air Quality Index) คำนวณจากการวัดระดับของมลพิษอะไรบ้าง"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong> มลพิษที่แขวนลอยอยู่ในอากาศมีมากมายหลายชนิด
            บางชนิดก็ยังไม่มีการกำหนดค่าความเข้มข้นมาตรฐานที่อาจก่อให้เกิดผลกระทบต่อสุขภาพเนื่องจากยังไม่มีข้อมูลจากการศึกษาวิจัยมากเพียงพอ
            ดังนั้นมลพิษหลายชนิดจึงยังไม่มีการกำหนดเป็น AQI
            ทั้งๆที่มีข้อมูลพื้นฐานที่ชัดเจนว่าเป็นอันตรายต่อสุขภาพ โดยทั่วไป
            ในการคำนวณค่า AQI จึงมีค่ามาตรฐานของมลพิษเพียงบางชนิดเท่านั้นเอง
            เช่น มลพิษที่เป็นก๊าซ ได้แก่ โอโซน (ozone), ซัลเฟอร์ไดออกไซด์
            (sulfur dioxide), คาร์บอนมอนอกไซด์ (carbon monoxide),
            ไนโตรเจนไดออกไซด์ (nitrogen dioxide) และมลพิษชนิดสารอนุภาคขนาดต่างๆ
            (Particle matter หรือ PM)
          </div>
        </CardContent>
      </Card>

      <br />

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม ดัชนีคุณภาพอากาศ (Air Quality Index) คำนวณอย่างไร"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong> AQI
            คำนวณจากผลการตรวจวัดความเข้มข้นของมลพิษในอากาศชนิดต่างๆจากเครื่องตรวจวัดคุณภาพอากาศหรือจากโมเดลในระยะเวลาที่กำหนด
            โดยที่มลพิษแต่ละชนิดจะมีระดับความเข้มข้นและระยะเวลาที่จะก่อให้เกิดอันตรายต่อร่างกายแตกต่างกัน
            ซึ่งผลกระทบต่อสุขภาพนี้ต้องมาจากการศึกษาวิจัยทางระบาดวิทยาเป็นสำคัญ
            โดยทั่วไปจะคำนวณค่า AQI ของมลพิษแต่ละชนิด
            ซึ่งมักจะแบ่งเป็นช่วงๆตามระดับความเข้มข้นของมลพิษชนิดนั้นๆที่อาจส่งผลกระทบต่อสุขภาพจากน้อยไปมาก
            และเพื่อให้ประชาชนเข้าใจได้ง่ายมักจะมีการให้สีตามระดับของ AQI ด้วย
            ตัวอย่างด้านล่างนี้เป็นการให้แถบสีของ US EPA
            ในการแจ้งเตือนแก่ประชาชนกลุ่มต่างๆ
            <br /> <br />
            <img
              style={{
                maxWidth: "60%",
                height: "auto",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto"
              }}
              className="img-artboard"
              src={qa}
              alt=""
            />
            <br />
            กลุ่มเสี่ยง หมายถึง เด็ก ผู้สูงอายุ สตรีมีครรภ์
            ผู้ที่อยู่ใกล้แหล่งกำเนิดมลพิษ ทำงาน ออกกำลังกาย เล่นกีฬากลางแจ้ง
            หรือผู้ที่มีโรคภูมิแพ้ ภูมิต้านทานต่ำ ระบบการหายใจ หัวใจ
            และหลอดเลือดสมอง
          </div>
        </CardContent>
      </Card>

      <br />

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม การแปลผลดัชนีคุณภาพอากาศทำได้อย่างไร"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong> เมื่อคำนวณได้ค่า AQI
            ของมลพิษแต่ละชนิดซึ่งอาจจะแตกต่างกันได้
            ตามมาตรฐานสากลจะถือเอาค่าที่สูงสุดเป็นค่า AQI ณ เวลานั้น
            และนำมาใช้ในการแจ้งเตือนแก่ประชาชน ตัวอย่างเช่น
            <br /> <br />
            <div>
              <table
                style={{
                  width: "320px",
                  margin: "0 auto",
                  border: "1px solid #08C5C7"
                }}
              >
                <tbody>
                  <tr height="35">
                    <th
                      width="217"
                      style={{ backgroundColor: "#fff", textAlign: "center" }}
                    >
                      <strong>มลพิษในอากาศ</strong>
                    </th>
                    <th width="246" style={{ backgroundColor: "#fff" }}>
                      <strong>ดัชนีคุณภาพอากาศ</strong>
                    </th>
                  </tr>
                  <tr height="35">
                    <td
                      width="217"
                      style={{ backgroundColor: "red", textAlign: "center" }}
                    >
                      โอโซน
                    </td>
                    <td
                      width="246"
                      style={{ backgroundColor: "red", textAlign: "center" }}
                    >
                      160
                    </td>
                  </tr>
                  <tr height="35">
                    <td
                      width="217"
                      style={{ backgroundColor: "yellow", textAlign: "center" }}
                    >
                      ซัลเฟอร์ไดออกไซด์
                    </td>
                    <td
                      width="246"
                      style={{ backgroundColor: "yellow", textAlign: "center" }}
                    >
                      70
                    </td>
                  </tr>
                  <tr height="35">
                    <td
                      width="217"
                      style={{ backgroundColor: "yellow", textAlign: "center" }}
                    >
                      คาร์บอนมอนอกไซด์
                    </td>
                    <td
                      width="246"
                      style={{ backgroundColor: "yellow", textAlign: "center" }}
                    >
                      60
                    </td>
                  </tr>
                  <tr height="35">
                    <td
                      width="217"
                      style={{ backgroundColor: "yellow", textAlign: "center" }}
                    >
                      ไนโตรเจนไดออกไซด์
                    </td>
                    <td
                      width="246"
                      style={{ backgroundColor: "yellow", textAlign: "center" }}
                    >
                      90
                    </td>
                  </tr>
                  <tr height="35">
                    <td
                      width="217"
                      style={{ backgroundColor: "yellow", textAlign: "center" }}
                    >
                      PM<sub>10</sub>
                    </td>
                    <td
                      width="246"
                      style={{ backgroundColor: "yellow", textAlign: "center" }}
                    >
                      80
                    </td>
                  </tr>
                  <tr height="35">
                    <td
                      width="217"
                      style={{ backgroundColor: "orange", textAlign: "center" }}
                    >
                      PM<sub>2.5</sub>
                    </td>
                    <td
                      width="246"
                      style={{ backgroundColor: "orange", textAlign: "center" }}
                    >
                      105
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              กรณีนี้จะต้องแจ้งแก่ประชาชนว่าในขณะนั้นมีปัญหาความเข้มข้นของก๊าซโอโซนสูงเกินไปในอากาศ
              โดยมีค่า AQI ที่คำนวณจากความเข้มข้นของโอโซนเป็น 160 (โซนสีแดง)
              ให้ประชาชนระมัดระวังผลกระทบของก๊าซโอโซนต่อสุขภาพเป็นสำคัญ เป็นต้น
            </div>
          </div>
        </CardContent>
      </Card>

      <br />

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม เป็นไปได้หรือไม่ที่ค่า AQI ของมลพิษจะสูงพร้อมๆกันหลายชนิด และประชาชนควรปฏิบัติตัวอย่างไร"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong>{" "}
            เป็นไปได้ที่ในช่วงเวลาใดช่วงเวลาหนึ่งอาจจะมีค่า AQI
            ของมลพิษสูงพร้อมๆกันหลายชนิด
            เนื่องจากต้นกำเนิดอาจจะทำให้เกิดมลพิษหลายชนิดพร้อมๆกันได้ เช่น
            กรณีของการรั่วซึมของมลพิษจากโรงงาน ซึ่งในกรณีเช่นนั้นอาจจะคำนวณแล้ว
            AQI รายมลพิษสูงเกินระดับอันตรายหลายตัว
            การรายงานผลอย่างเป็นทางการก็จะรายงานค่า AQI ที่สูงสุดเช่นเดิม
            โดยแจ้งว่าขณะนั้นมีมลพิษหลักคืออะไรและประชาชนกลุ่มต่างๆต้องปฏิบัติตัวอย่างไรเพื่อป้องกันอันตรายจากมลพิษหลักชนิดนั้น
            แต่จะมีหมายเหตุด้วยว่าในขณะนั้นมีมลพิษอื่นสูงเกินระดับอันตรายด้วย
            และแจ้งเตือนประชาชนให้เฝ้าระวังผลกระทบจากมลพิษที่สูงเกินค่ามาตรฐานเหล่านั้นด้วย
          </div>
        </CardContent>
      </Card>

      <br />

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม PM2.5 และ PM10 คืออะไร"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong> PM ย่อมาจาก Particle matter
            ตรงกับภาษาไทยว่าสารอนุภาคที่แขวนลอยอยู่ในอากาศ
            ซึ่งเป็นได้ทั้งอนุภาคที่เป็นของแข็งหรือเป็นหยดละอองของเหลว
            สารอนุภาคที่แขวนลอยในอากาศมีหลายขนาด
            แต่ที่พบว่ามีความสำคัญในด้านการเป็นมลพิษต่อสิ่งมีชีวิต ได้แก่
            สารอนุภาคที่มีขนาดเล็กกว่า 10 ไมครอน
            หรือที่เรียกกันโดยทั่วไปว่าฝุ่นละอองขนาดเล็ก หรือ PM<sub>10</sub>{" "}
            จำง่ายๆว่ามีขนาดเล็กว่าเม็ดทรายประมาณ 10 เท่า ซึ่ง PM<sub>10</sub>{" "}
            นี้เป็นที่ทราบกันมานานแล้วมีผลกระทบต่อสุขภาพของมนุษย์
            เนื่องจากเมื่อหายใจเอา PM<sub>10</sub>{" "}
            เข้าไปจะสามารถผ่านทางเดินหายใจส่วนบนเข้าไปสู่ปอดได้
            ซึ่งสารโลหะบนผิวของฝุ่นจะทำลายเซลล์ปอดและทำให้เกิดมะเร็งปอดได้
            นอกจากนั้นยังทำให้เกิดการอักเสบ การระคายเคือง และอุดกั้นทางเดินหายใจ
            ทำให้ผู้ที่มีโรคระบบทางเดินหายใจอยู่ก่อนเกิดการกำเริบได้ง่ายและอาจจมีอาการรุนแรงจนถึงแก่ชีวิต
            ส่วนที่พบว่าเป็นปัญหาสำคัญยิ่งกว่า PM<sub>10</sub> คือ
            สารอนุภาคที่มีขนาดเล็กกว่า 2.5 ไมครอน
            หรือที่เรียกกันโดยทั่วไปว่าฝุ่นละอองขนาดจิ๋ว หรือ PM<sub>2.5</sub>{" "}
            ซึ่งมีขนาดเล็กกว่า PM<sub>10</sub> ประมาณ 4 เท่า เนื่องจาก PM<sub>2.5</sub>{" "}
            มีขนาดเล็กกว่ามากนอกจากจะเข้าไปในปอดได้แล้ว
            ยังสามารถเข้าสู่ถุงลมปอดและเส้นเลือดได้ด้วย
            ทำให้เกิดการอักเสบและการอุดตันของเส้นเลือดในระบบต่าง ๆ
            เช่นหัวใจและสมอง ทำให้เกิดโรคกล้ามเนื้อหัวใจขาดเลือด
            โรคหลอดเลือดในสมอง รวมทั้งโรคสมองเสื่อม ประการสำคัญคือ PM<sub>2.5</sub>{" "}
            สามารถแขวนลอยอยู่ในอากาศได้เป็นเวลานาน
            รวมทั้งสามารถเคลื่อนที่ไปได้ไกล
            จึงมีโอกาสที่จะถูกสูดเข้าไปในร่างกายก่อนที่จะตกลงสู่พื้นเหมือนฝุ่นที่มีขนาดใหญ่กว่า
          </div>
        </CardContent>
      </Card>

      <br />

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม ทำไมต้องใช้ค่าความเข้มข้นของฝุ่นละอองขนาดจิ๋ว (PM2.5) ในการคำนวณดัชนีคุณภาพอากาศ"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong> ในอดีตก่อนที่เราจะรู้จัก PM<sub>2.5</sub>{" "}
            เป็นอย่างดี ทุกประเทศก็ใช้ค่าความเข้มข้นของฝุ่นละอองขนาดเล็ก หรือ PM<sub>10</sub>{" "}
            ในการคำนวณดัชนีคุณภาพอากาศหรือ AQI
            แต่เมื่อมีการศึกษาวิจัยมากขึ้นก็เป็นที่ยืนยันชัดเจนแล้วว่า
            ฝุ่นละอองขนาดจิ๋ว หรือ PM<sub>2.5</sub> มีอันตรายต่อสุขภาพมากกว่า PM<sub>10</sub>{" "}
            หลายเท่า ในปัจจุบันประเทศส่วนใหญ่ต่างก็นำค่าความเข้มข้นของ PM<sub>2.5</sub>{" "}
            มาร่วมในการคำนวณดัชนีคุณภาพอากาศหรือ AQI แล้วแทบทั้งสิ้น
            ซึ่งระดับที่มีผลเสียต่อสุขภาพนั้น ความเข้มข้นของ PM<sub>2.5</sub>{" "}
            จะต่ำกว่าของ PM<sub>10</sub> มาก จึงจะให้ค่า AQI ที่สูงกว่า PM<sub>10</sub>{" "}
            เป็นอย่างมาก ดังนั้นหากใช้แต่ PM<sub>10</sub> ในการคำนวณค่า AQI
            จะได้ค่าที่ต่ำกว่าความเป็นจริงมาก
            ประชาชนจึงอาจได้รับการแจ้งเตือนที่ไม่ถูกต้อง
            ทำให้ไม่ระมัดระวังผลกระทบต่อสุขภาพที่อาจเกิดจากมลภาวะทางอากาศโดยรู้เท่าไม่ถึงการณ์
          </div>
        </CardContent>
      </Card>

      <br />

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม ถ้าค่าความเข้มข้นของฝุ่นละอองขนาดจิ๋ว (PM2.5) ดีกว่าฝุ่นละอองขนาดเล็ก (PM10) ทำไมยังต้องวัดค่า PM10 อีก"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong>{" "}
            เนื่องจากต้นกำเนิดของฝุ่นละอองขนาดต่างๆมีความแตกต่างกัน
            โดยฝุ่นละอองขนาดเล็กหรือ PM<sub>10</sub> มักจะสูงขึ้นจากการจราจร
            การก่อสร้าง การรื้ออาคาร การขุดเจาะบ่อหรือปรับพื้นดิน สร้างถนน
            ฝุ่นเหมือง เป็นต้น แต่ฝุ่นละอองขนาดจิ๋วหรือ PM<sub>2.5</sub>{" "}
            มักเกิดจากการเผาในที่โล่งแจ้งและการเผาไหม้เชื้อเพลิงที่ไม่สมบูรณ์
            ซึ่งต้นกำเนิดของมลพิษอาจจะแตกต่างกันในแต่ละพื้นที่และแต่ละช่วงเวลา
            ดังนั้นจึงยังต้องมีการตรวจค่าความเข้มข้นของฝุ่นละอองทั้งสองขนาดคู่กันไป
            หากค่าใดสูงจะสามารถบอกถึงต้นกำเนิดของฝุ่นละอองในพื้นที่นั้นๆหรือ ณ
            เวลานั้นๆได้
          </div>
        </CardContent>
      </Card>

      <br />

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม ทำไมกรมควบคุมมลพิษยังใช้เฉพาะค่าฝุ่นละอองหยาบ (PM10) ในการวัดระดับคุณภาพอากาศ ประเทศไทยยังวัดค่า PM2.5 ไม่ได้หรือ"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong>{" "}
            ที่จริงในประเทศไทยโดยกรมควบคุมมลพิษได้ดำเนินการติดตั้งเครื่องวัดคุณภาพอากาศที่สามารถวัดค่า
            PM<sub>2.5</sub> ได้บ้างแล้ว เพียงแต่ยังไม่ครบทุกจังหวัดเท่านั้นเอง
            ทำให้ในช่วงก่อนหน้านี้จึงยังไม่ได้นำค่า PM<sub>2.5</sub>{" "}
            มาร่วมในการคำนวณค่า AQI
          </div>
        </CardContent>
      </Card>

      <br />

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม ในพื้นที่ที่สามารถตรวจวัด PM2.5 ได้แล้ว ควรใช้ค่า PM2.5 มาคำนวณคุณภาพอากาศเพื่อแจ้งเตือนประชาชนหรือไม่ หรือต้องรอให้ติดตั้งเครื่องวัดให้ครบทั่วประเทศก่อนค่อยนำมาใช้พร้อมกัน"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong> ที่จริงในพื้นที่ที่สามารถตรวจวัด PM<sub>2.5</sub>{" "}
            ได้แล้ว ควรใช้ค่า PM<sub>2.5</sub>{" "}
            มาคำนวณคุณภาพอากาศเพื่อแจ้งเตือนประชาชนเป็นอย่างยิ่ง
            ไม่จำเป็นต้องรอให้ติดตั้งเครื่องวัดให้ครบทั่วประเทศก่อนค่อยนำมาใช้พร้อมกันแต่อย่างใด
            ซึ่งเป็นที่น่ายินดีว่าในปีพ.ศ. 2562 ได้เริ่มนำค่า PM<sub>2.5</sub>{" "}
            มาร่วมในการคำนวณค่า AQI แล้ว
          </div>
        </CardContent>
      </Card>

      <br />

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม ในพื้นที่ที่ไม่สามารถตรวจวัด PM2.5 ได้ ต้องเชื่อค่า PM10 เท่านั้นหรือ มีวิธีคำนวณคุณภาพอากาศ PM2.5จากค่า PM10 ที่วัดได้หรือไม่"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong>{" "}
            ที่จริงในพื้นที่ที่ใกล้เคียงกันและมีต้นเหตุของมลภาวะทางอากาศคล้ายคลึงกัน
            สามารถใช้สมการทางคณิตศาสตร์คำนวณค่า PM<sub>2.5</sub> จากค่า PM<sub>10</sub>{" "}
            ได้
            หรืออาจใช้โมเดลทางภาพถ่ายดาวเทียมและทางอุตุนิยมวิทยามาใช้ในการคำนวณค่า
            PM<sub>2.5</sub> ได้เช่นเดียวกัน
          </div>
        </CardContent>
      </Card>

      <br />

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม CMAQHI คืออะไร"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong> CMAQHI ย่อมาจาก ChiangMai Air Quality Health
            Index หรือ ดัชนีคุณภาพอากาศเพื่อสุขภาพชาวเชียงใหม่
            ซึ่งเกิดขึ้นจากปรึกษาหารือของนักวิชาการด้านสิ่งแวดล้อมและด้านสุขภาพของมหาวิทยาลัยเชียงใหม่
            และได้ข้อสรุปร่วมกันว่าในจังหวัดเชียงใหม่ซึ่งประสบปัญหาหมอกควันในฤดูเผาเกษตรมาเป็นเวลานานกว่า
            10 ปี ควรนำวิธีการคำนวณค่า AQI
            ตามมาตรฐานสากลมาใช้ในการแจ้งคุณภาพอากาศแก่ประชาชน
            เพื่อให้สามารถเฝ้าระวังและป้องกันตนเองจากผลกระทบของปัญหาหมอกควันต่อสุขภาพได้อย่างมีประสิทธิภาพและทันต่อเหตุการณ์
            ดูรายละเอียดเพิ่มเติมได้ที่{" "}
            <a style={{ textDecoration: "none" }} href="/about">
              https://www2.ntaqhi.info/about
            </a>
          </div>
        </CardContent>
      </Card>

      <br />

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม NTAQHI คืออะไร"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong> ย่อมาจาก Northern Thailand Air Quality Health
            Index หรือ ดัชนีคุณภาพอากาศเพื่อสุขภาพชาวเหนือ ซึ่งพัฒนาต่อจาก
            CMAQHI โดยได้รับการสนับสนุนเพิ่มเติมจากมหาวิทยาลัยเชียงใหม่
            ในการบำรุงรักษาเครื่องวัดทั้ง 205
            เครื่องที่ติดตั้งทั่วจังหวัดเชียงใหม่ และปรับขยายการติดตั้งไปยัง 40
            พื้นที่ในจังหวัดลำพูน เชียงราย และแม่ฮ่องสอน
            ดูรายละเอียดเพิ่มเติมได้ที่{" "}
            <a style={{ textDecoration: "none" }} href="/about">
              https://www2.ntaqhi.info/about
            </a>
          </div>
        </CardContent>
      </Card>

      <h3 style={{ fontSize: "17px", marginTop: "50px" }}>
        4. เครื่องตรวจวัดคุณภาพอากาศด้วยเซนเซอร์ขนาดเล็ก
      </h3>

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม เครื่องตรวจวัดคุณภาพอากาศที่เป็นมาตรฐานมีอะไรบ้าง"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong>{" "}
            เครื่องตรวจวัดคุณภาพอากาศที่เป็นที่ยอมรับว่ามีความเที่ยงตรงและยอมรับให้ใช้อ้างอิงได้ในปัจจุบัน
            อาจแบ่งได้เป็น 2 ชนิดคือ
            <ol>
              <li>
                เครื่องตรวจวัดคุณภาพอากาศที่ได้รับการรับรองให้ใช้เป็นเครื่องอ้างอิงได้
                โดยเก็บตัวอย่างอากาศไปตรวจหาระดับมลพิษโดยตรง
                เป็นเครื่องที่ให้ค่าการตรวจวัดที่ได้มาตรฐานที่สุด
                ซึ่งหน่วยงานของรัฐมักจะใช้ในสถานีตรวจวัดต่างๆ ข้อเสียคือราคาแพง
                และใช้เวลาในการตรวจวัดค่อนข้างนาน จึงไม่ได้ค่าที่เป็น realtime
              </li>
              <li>
                เครื่องตรวจวัดคุณภาพอากาศที่ได้รับการรับรองเทียบเท่ากับเครื่องอ้างอิง
                เช่น TEOM, BAM และ EDM 180 เป็นต้น
                ข้อดีกว่าแบบแรกคือสามารถได้ค่าตรวจวัดเร็วกว่า เช่น
                เป็นรายชั่วโมงหรือเร็วกว่านั้น
                แต่ก็ยังมีราคาค่อนข้างสูงเช่นเดียวกัน
              </li>
            </ol>
          </div>
        </CardContent>
      </Card>

      <br />

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม ทำไม ntaqhi.info จึงเลือกใช้เครื่องตรวจวัดคุณภาพอากาศด้วยเซนเซอร์ขนาดเล็ก"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong>{" "}
            เนื่องจากเครื่องตรวจวัดคุณภาพอากาศระดับอ้างอิงที่สามารถวัดค่าทั้ง PM<sub>10</sub>{" "}
            และ PM<sub>2.5</sub> ได้
            ซึ่งกรมควบคุมมลพิษกำลังดำเนินการติดตั้งอยู่ในปัจจุบัน มีราคาแพง
            การบริหารจัดการยุ่งยาก
            และน่าจะใช้เวลาหลายปีกว่าจะกระจายติดตั้งได้ครบทุกจังหวัด
            นอกจากนี้ยังมีแผนติดตั้งเฉพาะในตัวเมืองใหญ่เท่านั้น
            ทำให้ไม่สามารถสะท้อนคุณภาพอากาศของพื้นที่รอบนอกได้
            ในปัจจุบันมีการพัฒนาเครื่องตรวจวัดคุณภาพอากาศด้วยเซนเซอร์ขนาดเล็กซึ่งมีความเชื่อถือได้พอสมควร
            ราคาถูก
            จึงสามารถนำไปติดตั้งในพื้นที่ห่างไกลได้ง่ายโดยใช้งบประมาณต่ำกว่าเครื่องวัดระดับอ้างอิงเป็นร้อยเท่าพันเท่า
          </div>
        </CardContent>
      </Card>

      <br />

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม การตรวจวัดคุณภาพอากาศด้วยเซนเซอร์ขนาดเล็ก มีหลักการทำงานอย่างไร"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong> เซนเซอร์ตรวจวัดคุณภาพอากาศอาจแบ่งได้เป็น 2
            ชนิดใหญ่ ๆ คือ เซนเซอร์ตรวจวัดความเข้มข้นของก๊าซ
            และเซนเซอร์ตรวจวัดความเข้มข้นของสารอนุภาค
            แต่ปัญหามลพิษทางอากาศในพื้นที่ภาคเหนือตอนบนของประเทศไทยมักเกิดจากการเผาในที่โล่ง
            จึงทำให้เกิดการปล่อยสารอนุภาคขนาดเล็กแขวนลอยอยู่ในอากาศเป็นสาเหตุหลัก
            โครงการนี้จึงเน้นการใช้เซนเซอร์ตรวจวัดความเข้มข้นของสารอนุภาคเท่านั้น
            ซึ่งการตรวจวัดความเข้มข้นของสารอนุภาคของเซนเซอร์ที่เราเลือกใช้เป็นการวัดทางอ้อมโดยอาศัยคุณสมบัติการกระเจิงของแสงที่ส่องผ่านสารอนุภาคขนาดต่าง
            ๆ และประมวลผลออกมาเป็นความเข้มข้นของสารอนุภาคขนาดต่างๆ
            ซึ่งในรุ่นปัจจุบันสามารถวัดสารอนุภาคได้ถึง 3 ขนาด คือ PM<sub>10</sub>,
            PM<sub>2.5</sub> และ PM<sub>1.0</sub>
          </div>
        </CardContent>
      </Card>

      <br />

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม เครื่องตรวจวัดคุณภาพอากาศด้วยเซนเซอร์ขนาดเล็กมีข้อดีอย่างไร"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong>{" "}
            ในปัจจุบันแม้ว่าจะมีการศึกษาจำนวนมากที่บ่งชี้ถึงประโยชน์ของการใช้เซนเซอร์ขนาดเล็กในการตรวจวัดคุณภาพอากาศ
            อย่างไรก็ตามยังไม่มีการพิสูจน์ถึงประโยชน์ในการใช้ในระยะยาว
            การใช้เซนเซอร์ขนาดเล็กมีประโยชน์ในหลายด้าน ได้แก่
            <ol>
              <li>
                ให้ข้อมูลเสริมหน่วยงานทางการซึ่งไม่สามารถกระจายเครื่องตรวจวัดมาตรฐานซึ่งมีขนาดใหญ่และราคาแพงไปอย่างทั่วถึงทุกพื้นที่ได้
                โดยเฉพาะอย่างยิ่งการตรวจวัดมลพิษที่อาจมีความเข้มข้นแตกต่างกันได้มาก
                ๆ ในสถานที่ห่างไกลออกไปจากสถานีตรวจวัดของทางการ เช่น
                สารอนุภาคขนาดเล็ก เป็นต้น
              </li>
              <li>
                ส่งเสริมให้เกิดการเฝ้าระวังการสร้างมลพิษ
                หากประชาชนได้รับรู้คุณภาพอากาศในท้องถิ่นของตน เช่น
                การปล่อยควันพิษจากโรงงาน การเผาเกษตร การเผาป่า เป็นต้น
              </li>
              <li>
                การเผ้าระวังผลกระทบจากมลภาวะทางอากาศต่อสุขภาพ
                หากประชาชนได้รับรู้คุณภาพอากาศในท้องถิ่นของตน
              </li>
            </ol>
          </div>
        </CardContent>
      </Card>

      <br />

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม ค่าที่วัดได้จากเครื่องตรวจวัดคุณภาพอากาศด้วยเซนเซอร์ขนาดเล็กเชื่อถือได้หรือไม่"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong>{" "}
            โดยทั่วไปเซนเซอร์ขนาดเล็กที่ได้รับการพัฒนาและออกสู่ท้องตลาดมักได้รับการสอบเทียบในห้องปฏิบัติการให้มีความเที่ยงตรงใกล้เคียงกับเครื่องวัดอ้างอิง
            โดยมักมีความคลาดเคลื่อนของค่าที่ตรวจวัดไม่เกิน 10%
            ซึ่งเซนเซอร์ขนาดเล็กที่เราเลือกนำมาใช้งานก็มีคุณสมบัติดังกล่าวเช่นเดียวกัน
            อย่างไรก็ตาม
            ส่วนใหญ่เซนเซอร์ขนาดเล็กจะถูกออกแบบและสอบเทียบให้ใช้งานภายในอาคาร
            ดังนั้น
            ในการนำมาประกอบเป็นเครื่องตรวจวัดคุณภาพอากาศเพื่อนำไปใช้งานนอกอาคาร
            ซึ่งจะมีปัจจัยทางด้านสิ่งแวดล้อมภายนอกรบกวนค่าที่ได้จากการวัดเป็นอย่างมาก
            เช่น อุณหภูมิ ความชื้นสัมพัทธ์ แรงและทิศทางลม เป็นต้น
            จึงต้องมีการสอบเทียบภาคสนามร่วมด้วยเพื่อยืนยันว่ามีความคลาดเคลื่อนของค่าที่ตรวจวัดเทียบเคียงกับค่าที่ได้จากเครื่องวัดอ้างอิงไม่มากเกินไปและเป็นที่ยอมรับได้
          </div>
        </CardContent>
      </Card>

      <br />

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม ผลการสอบเทียบภาคสนามของเครื่องตรวจวัดคุณภาพอากาศด้วยเซนเซอร์ขนาดเล็กเป็นอย่างไร"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong>{" "}
            การสอบเทียบภาคสนามของเครื่องตรวจวัดคุณภาพอากาศด้วยเซนเซอร์ขนาดเล็กทำขึ้นในระหว่างวันที่
            19-25 กุมภาพันธ์ 2562
            ซึ่งเป็นช่วงเวลาที่มีระดับมลพิษในอากาศทั้งสูงและต่ำ
            โดยได้รับความอนุเคราะห์จาก รศ. ดร. พานิช อินต๊ะ
            หัวหน้าหน่วยวิจัยสนามไฟฟ้าประยุกต์ในงานวิศวกรรม (RUEE)
            มหาวิทยาลัยเทคโนโลยีราชมงคลล้านนา ดอยสะเก็ด
            ให้นำเครื่องตรวจวัดคุณภาพอากาศด้วยเซนเซอร์ขนาดเล็กไปวัดเปรียบเทียบกับเครื่องวัดมาตรฐาน
            teom1405f ที่ได้รับการรับรองคุณภาพจาก US EPA และ รศ. ดร. พิศิษฐ์
            สิงห์ใจ หัวหน้าหน่วยวิจัยวัสดุศาสตร์ คณะวิทยาศาสตร์
            มหาวิทยาลัยเชียงใหม่
            ซึ่งเป็นผู้พัฒนาเครื่องตรวจวัดคุณภาพอากาศด้วยเซนเซอร์ขนาดเล็กนี้
            นำค่าความชื้นสัมพัทธ์มาปรับค่าของเครื่องวัดให้ได้ผลการวัดเปรียบเทียบใกล้เคียงกับเครื่องวัดมาตรฐาน
            ได้ผลการสอบเทียบดังนี้<br />
            <img
              style={{
                maxWidth: "100%",
                height: "auto",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto"
              }}
              className="img-artboard"
              src={qa2}
              alt=""
            />
            <br />
            รูปนี้เป็นกราฟเปรียบเทียบค่า PM<sub>2.5</sub>{" "}
            ที่วัดได้จากเครื่องตรวจวัดคุณภาพอากาศด้วยเซนเซอร์ขนาดเล็กซึ่งได้รับการปรับแก้ไขค่าที่คลาดเคลื่อนจากความชื้นสัมพัทธ์แล้ว
            (209RH) เทียบกับค่าที่วัดได้จากเครื่องวัดอ้างอิง (RUEE)
            พบว่ามีความสัมพันธ์กันเป็นอย่างดี
            โดยมีค่าสัมประสิทธ์ของความสัมพันธ์หรือ Pearson Correlation (R2) เป็น
            0.965 (ค่า P {"<"} 0.001) แปลผลได้ว่า
            การสอบเทียบภาคสนามยืนยันว่ามีความคลาดเคลื่อนของค่าที่ตรวจวัดเทียบเคียงกับค่าที่ได้จากเครื่องวัดอ้างอิงเพียง
            3.5% ซึ่งไม่เกิน 10% จึงน่าจะยอมรับได้ <br />
            อย่างไรก็ตามจะเห็นว่าในช่วงเวลาที่ทำการสอบเทียบภาคสนามนั้น ค่า PM<sub
            >
              2.5
            </sub>{" "}
            มีความเข้มข้นสูงสุดประมาณ 250 มคก./ลบ.ม. เท่านั้น
            ดังนั้นอาจต้องทำการทดสอบในสภาวะที่มีความเข้มข้นสูงกว่านี้ด้วย
            {/* 01/05/2023  */}
            <br
            />เพื่อให้เกิดความมั่นใจว่าข้อมูลที่ได้จากเครื่องตรวจวัดคุณภาพอากาศด้วยเซนเซอร์ขนาดเล็กของ
            NTAQHI
            มีความเที่ยงตรงและใกล้เคียงกับเครื่องวัดมาตรฐานของกรมควบคุมมลพิษ
            ได้ทำการเปรียบเทียบข้อมูลที่ได้จากเครื่องตรวจวัดของ NTAQHI
            กับเครื่องวัดของกรมควบคุมมลพิษ (PCD) ที่อยู่ในบริเวณใกล้เคียงกัน
            ดังรายละเอียดใน{" "}
            <a style={{ textDecoration: "none" }} href="/calibration">
              https://www2.ntaqhi.info/calibration/
            </a>
          </div>
        </CardContent>
      </Card>

      <br />

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม ขีดจำกัดของเครื่องตรวจวัดคุณภาพอากาศด้วยเซนเซอร์ขนาดเล็กเป็นอย่างไร ?"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong>{" "}
            เนื่องจากเครื่องตรวจวัดคุณภาพอากาศด้วยเซนเซอร์ขนาดเล็กมีราคาถูกเพียงหลักพันบาท
            ไม่มีทางที่จะมีประสิทธิภาพเทียบเท่ากับหรือนำไปใช้ทดแทนเครื่องวัดอ้างอิงที่มีราคาหลายล้านบาทได้
            การนำเครื่องตรวจวัดคุณภาพอากาศด้วยเซนเซอร์ขนาดเล็กไปใช้ในภาคสนามจึงมีบทบาทหลักที่จะให้ข้อมูลเสริมหน่วยงานภาครัฐโดยเฉพาะในพื้นที่ห่างไกลที่ไม่สามารถติดตั้งเครื่องตรวจวัดอ้างอิงได้
            และที่สำคัญผู้นำไปใช้ต้องรู้ขีดจำกัดของเซนเซอร์ขนาดเล็กเพื่อให้สามารถใช้งานได้อย่างถูกต้อง{" "}
            <br />
            ในช่วงที่มีคุณภาพอากาศค่อนข้างแปรปรวน เช่น
            เกิดไฟไหม้ป่าใกล้จุดตรวจวัดของมหาวิทยาลัยเทคโนโลยีราชมงคลล้านนา
            ดอยสะเก็ด พบว่าในช่วงเวลาดังกล่าวมีค่า PM<sub>2.5</sub>{" "}
            ผันผวนค่อนข้างมาก โดยบางขณะมีค่า PM<sub>2.5</sub> สูงเกิน 1000
            มคก./ลบ.ม. ซึ่งไม่ใช่สิ่งที่จะพบในภาวะปกติทั่วไป
            <br />
            <img
              style={{
                maxWidth: "60%",
                height: "auto",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto"
              }}
              className="img-artboard"
              src={qa3}
              alt=""
            />
            <br />
            รูปนี้เป็นกราฟที่ได้จากการสอบเทียบภาคสนามในช่วงเวลาที่มีคุณภาพอากาศแปรปรวนดังกล่าว
            พบว่าเครื่องตรวจวัดคุณภาพอากาศด้วยเซนเซอร์ขนาดเล็กจะมีความคลาดเคลื่อนของการวัดไม่เกิน
            10% เมื่อมีค่า PM<sub>2.5</sub> ไม่เกิน 350 มคก./ลบ.ม. (R2 = 0.903)
            ซึ่งเซนเซอร์ขนาดเล็กแทบทุกชนิดมักมีขีดจำกัดในการตรวจวัดค่ามลพิษที่สูงมากๆ
            โดยมักจะให้ค่าที่ต่ำกว่าความเป็นจริง อย่างไรก็ตาม
            ในทางปฏิบัติอาจไม่เป็นสาระสำคัญแต่อย่างใด
            เนื่องจากในการแจ้งเตือนผลกระทบต่อสุขภาพของประชาชน หากค่า PM<sub>2.5</sub>{" "}
            สูงเกิน 250 มคก./ลบ.ม. จะต้องแจ้งเตือน AQI เป็นระดับสูงสุด
            นั่นคือระดับอันตรายสีน้ำตาลอยู่แล้ว ดังนั้น
            การที่เครื่องตรวจวัดคุณภาพอากาศด้วยเซนเซอร์ขนาดเล็กรายงานค่า PM<sub>2.5</sub>{" "}
            ที่เกิน 350 มคก./ลบ.ม. ต่ำกว่าความเป็นจริง
            ยิ่งเป็นการแสดงว่าในขณะนั้นมีมลพิษสูงเกินกว่าระดับอันตรายอย่างแน่นอน
          </div>
        </CardContent>
      </Card>

      <h3 style={{ fontSize: "17px", marginTop: "50px" }}>
        5. การป้องกันตนเองจากปัญหาหมอกควัน
      </h3>

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม จะรู้ได้อย่างไรว่าเมื่อใดประชาชนควรจะเริ่มป้องกันตนเองจากปัญหาหมอกควัน ?"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong>{" "}
            ต้องติดตามคุณภาพอากาศในท้องที่ที่ตนเองอยู่อาศัยอย่างสม่ำเสมอ
            ถ้าอยู่ในพื้นที่จังหวัดเชียงใหม่ ให้ติดตามที่{" "}
            <mark>ntaqhi.info</mark> ซึ่งจะรายงานคุณภาพอากาศทั้งรายชั่วโมง
            รายวัน และข้อมูลย้อนหลังรายเดือนรายปี
            พร้อมทั้งการแจ้งเตือนว่าประชาชนกลุ่มต่าง ๆ
            ควรจะปฏิบัติตนอย่างไรในการป้องกันผลกระทบของปัญหาหมอกควันต่อสุขภาพในขณะนั้น
          </div>
        </CardContent>
      </Card>

      <br />

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม ในช่วงฤดูหมอกควัน ประชาชนทุกคนจะได้รับผลกระทบจากปัญหาหมอกควันเหมือนกันหรือไม่ ?"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong> ประชาชนกลุ่มต่าง ๆ
            มีความเสี่ยงในการได้รับผลกระทบจากปัญหาหมอกควันไม่เท่ากัน โดยแบ่งเป็น
            3 กลุ่ม เรียงตามความเสี่ยงในการได้รับผลกระทบจากมากไปน้อยดังนี้
            <ul>
              <li>
                ประชาชนที่ไวต่อมลพิษมากกว่าคนทั่วไป ได้แก่
                ผู้ที่มีโรคภูมิแพ้ต่อฝุ่นละอองอย่างรุนแรง
                กลุ่มนี้จะมีความเสี่ยงสูงสุด
                โดยอาจเกิดอันตรายจากการได้รับฝุ่นควันได้ง่ายแม้ว่าจะมีปริมาณของ
                PM<sub>2.5</sub> ในอากาศไม่สูงมาก เช่น เริ่มเกิน 12 มคก./ลบม.
                ขึ้นไป
              </li>
              <li>
                {" "}ประชาชนในกลุ่มเสี่ยง ได้แก่ เด็ก ผู้สูงอายุ สตรีมีครรภ์
                ผู้ที่อยู่ใกล้แหล่งกำเนิดมลพิษ ผู้ที่ทำงาน ออกกำลังกาย
                หรือเล่นกีฬากลางแจ้ง หรือผู้ที่มีโรคภูมิแพ้ ภูมิต้านทานต่ำ
                ระบบการหายใจ หัวใจ และหลอดเลือดสมอง
                กลุ่มนี้จะมีความเสี่ยงสูงกว่าประชาชนทั่วไป
                โดยอาจเกิดอันตรายจากการได้รับฝุ่นควันได้เมื่อปริมาณของ PM<sub>2.5</sub>{" "}
                ในอากาศสูงกว่ากลุ่มแรก เช่น เริ่มเกิน 35 มคก./ลบม. ขึ้นไป
              </li>
              <li>
                {" "}ประชาชนทั่วไป จะมีความเสี่ยงต่ำสุด
                เนื่องจากร่างกายสามารถทนต่อมลพิษได้ดีกว่า 2 กลุ่มแรก
                โดยอาจเกิดอันตรายจากการได้รับฝุ่นควันได้เมื่อปริมาณของ PM<sub>2.5</sub>{" "}
                ในอากาศสูงมาก เช่น เริ่มเกิน 55 มคก./ลบม. ขึ้นไป
              </li>
            </ul>
          </div>
        </CardContent>
      </Card>

      <br />

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม ประชาชนจะป้องกันตนเองจากปัญหาหมอกควันได้อย่างไร ?"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong>{" "}
            เมื่อได้ติดตามคุณภาพอากาศอย่างสม่ำเสมอและทราบว่าตนเองอยู่ในกลุ่มใด
            ในช่วงเวลาที่อากาศมีฝุ่นควัน PM<sub>2.5</sub>{" "}
            ในปริมาณสูงจนอาจเป็นอันตรายต่อสุขภาพ (PM<sub>2.5</sub> เกิน 12
            มคก./ลบม. สำหรับที่มีความไวต่อมลพิษมากกว่าคนทั่วไป 35 มคก./ลบม.
            สำหรับผู้ที่อยู่ในกลุ่มเสี่ยง หรือเกิน 55 มคก./ลบม.
            สำหรับประชาชนทั่วไป)
            จะได้รับการแจ้งเตือนให้ระวังผลกระทบของปัญหาหมอกควันต่อสุขภาพ
            สิ่งแรกที่ต้องทำคือ
            ควรหลีกเลี่ยงการทำกิจกรรมและการออกกำลังกายนอกบ้านเป็นเวลานาน ๆ
          </div>
        </CardContent>
      </Card>

      <br />

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม มีผู้แนะนำว่าถ้าอยู่ในอาคารหรือบ้าน ควรเปิดประตูหน้าต่างให้อากาศระบาย จริงหรือไม่ ?"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong> การตรวจวัดระดับฝุ่นควันในอาคารและในบ้าน พบว่า
            การอยู่ในอาคารหรือบ้านที่เปิดประตูหน้าต่างตามปกติ
            จะได้รับฝุ่นควันเท่ากับนอกบ้านทุกประการ
          </div>
        </CardContent>
      </Card>

      <br />

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม การอยู่ในห้องแอร์ เช่น ห้างสรรพสินค้า ร้านอาหาร ห้องเรียน ห้องทำงาน หรือห้องรับแขก จะปลอดภัยจากปัญหาหมอกควันหรือไม่ ?"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong> การตรวจวัดระดับฝุ่นควันในอาคารและในบ้าน พบว่า
            หากห้องแอร์เหล่านั้นซีลอากาศไม่สนิท
            มีช่องให้อากาศจากภายนอกเข้าได้ง่าย หรือมีคนเข้าออกตลอดเวลา
            ปริมาณฝุ่นควัน PM<sub>2.5</sub> ในห้องจะลดลงได้น้อยมาก
            หรือแทบจะไม่ได้เลย แต่สำหรับห้องแอร์ที่ซีลอากาศค่อนข้างดี
            ถ้าปิดประตูหน้าต่างให้สนิท จะทำให้ฝุ่นควัน PM<sub>2.5</sub>{" "}
            ลดลงได้บ้าง แต่จะลดลงได้ไม่เกินครึ่งหนึ่งของนอกบ้าน
          </div>
        </CardContent>
      </Card>

      <br />

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม ต้องจัดการอย่างไรจึงจะสามารถป้องกันตนเองจากปัญหาหมอกควันได้ดีที่สุด ?"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong> การตรวจวัดระดับฝุ่นควันในอาคารและในบ้าน พบว่า
            ห้องแอร์ที่ซีลอากาศค่อนข้างดีและปิดประตูหน้าต่างให้สนิท
            การใช้เครื่องฟอกอากาศชนิดมีแผ่นกรอง HEPA
            ที่มีขนาดเหมาะสมกับขนาดห้องจะช่วยลดปริมาณฝุ่นควันได้มากที่สุด
            เร็วที่สุด และสามารถลดจนได้ระดับคุณภาพอากาศที่ปลอดภัยได้
          </div>
        </CardContent>
      </Card>

      <br />

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม ถ้าไม่มีห้องแอร์จะทำอย่างไร ?"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong>{" "}
            สำหรับห้องที่ไม่ติดแอร์หรือในพื้นที่ที่มีอากาศเย็น
            การใช้เครื่องฟอกอากาศชนิดมีแผ่นกรอง HEPA สามารถลดปริมาณฝุ่นควันได้ดี
            แต่ต้องปิดประตูหน้าต่างทุกบานด้วย
            ไม่ต้องกลัวเรื่องอากาศไม่ถ่ายเทเพราะปกติจะมีช่องตามขอบหน้าต่างประตูให้อากาศภายนอกรั่วไหลเข้าไปในบ้านได้
            หากอากาศร้อนสามารถเปิดพัดลมได้โดยไม่จำเป็นต้องเป็นห้องแอร์
          </div>
        </CardContent>
      </Card>

      <br />

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม ในรถยนต์ควรติดเครื่องฟอกอากาศหรือไม่ ?"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong>{" "}
            ระบบปรับอากาศและกรองอากาศในรถยนต์ที่ได้รับการดูแลสม่ำเสมอได้ผลดีมาก
            สามารถลดปริมาณฝุ่นควันได้เร็วและถึงระดับปลอดภัยได้จนอาจไม่จำเป็นต้องใช้เครื่องฟอกอากาศช่วย
            อย่างไรก็ตาม ถ้าคุณภาพอากาศแย่มากๆ เช่น ปริมาณ PM<sub>2.5</sub> เกิน
            150 มคก./ลบม. ระบบกรองอากาศของรถยนต์อย่างเดียวอาจจะทำให้ฝุ่นควัน PM<sub>2.5</sub>{" "}
            ลดลงได้น้อยและช้า อาจต้องใช้เครื่องฟอกอากาศชนิดมีแผ่นกรอง HEPA ช่วย
          </div>
        </CardContent>
      </Card>

      <br />

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม หากจำเป็นต้องออกไปทำกิจกรรมหรือออกกำลังกายนอกบ้านจริง ๆ ควรทำอย่างไร ?"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong> หากจำเป็นจริง ๆ
            ควรใส่หน้ากากที่สามารถป้องกันฝุ่นควันขนาดเล็กมากได้ เช่น หน้ากากชนิด
            N95 หรือ FFP2
            โดยต้องใส่ให้ขอบแนบสนิทกับโครงรูปหน้าเพื่อไม่ให้อากาศภายนอกรั่วเข้าไป
          </div>
        </CardContent>
      </Card>

      <br />

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม ใช้หน้ากากอนามัยธรรมดาได้หรือไม่ ?"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong> หน้ากากอนามัยธรรมดาป้องกันได้แต่ฝุ่นหยาบ
            ไม่สามารถป้องกันฝุ่นละเอียดเข้าสู่ร่างกายได้
          </div>
        </CardContent>
      </Card>

      <br />

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม ในช่วงที่มีวิกฤติหมอกควัน มาตรการใดสำคัญที่สุดในการป้องกันตนเองจากมลพิษทางอากาศ ?"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong>{" "}
            มาตรการที่สำคัญที่สุดและเป็นที่ยอมรับว่าต้องทำเป็นอันดับแรกคือ
            ต้องให้ประชาชนโดยเฉพาะผู้ที่มีความเสี่ยงที่จะได้รับผลกระทบจากฝุ่นควันมาก
            เช่น เด็กๆ คนแก่หรือผู้ป่วย อยู่ในบ้านที่ติดตั้งเครื่องฟอกอากาศชนิด
            HEPA เป็นวิธีที่ดีที่สุดและต้องเน้นเรื่องนี้เป็นพิเศษก่อนเรื่องอื่น
            ส่วนการใช้หน้ากากกันฝุ่นเป็นมาตรการรองลงไปและสำคัญน้อยกว่ามาก
            หน้ากากกันฝุ่นจะใช้เมื่อมีความจำเป็นมากจริงๆที่จะต้องออกนอกบ้านเท่านั้น
            และต้องเน้นให้ใช้ระยะเวลาสั้นที่สุด
            เสร็จธุระแล้วต้องรีบกลับเข้าบ้านทันที
          </div>
        </CardContent>
      </Card>

      <br />

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม มีผู้เชี่ยวชาญออกมาบอกว่า ใช้หน้ากากอนามัย+กระดาษทิชชู 2 ชั้นสามารถกรอง PM2.5 ได้ดีมากจริงหรือไม่ ใช้แทนหน้ากากกันฝุ่นชนิด N95 ได้หรือไม่ ?"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong>{" "}
            จริงเพียงบางส่วนเท่านั้นและต้องตรวจสอบได้ว่างานวิจัยนั้นได้มาตรฐานหรือไม่
            การวิจัยหน้ากากกันฝุ่นชนิดต่างๆมักเน้นที่ประสิทธิภาพในการกรองฝุ่น
            (filtration efficiency-FE)
            ซึ่งมีหลายงานวิจัยที่พบว่าหน้ากากอนามัยที่ใช้ในห้องผ่าตัด (surgical
            mask) โดยเฉพาะถ้าใช้หลายชั้น หรือมีวัสดุอื่นเช่นผ้า กระดาษ
            หรือสารเคมีร่วมด้วย
            จะสามารถกรองฝุ่นละอองขนาดจิ๋วได้พอสมควรแต่ก็น้อยกว่าหน้ากากชนิด N95
            ทั้งสิ้น
            การวิจัยสมัยใหม่ซึ่งทำการศึกษาการใช้หน้ากากกันฝุ่นชนิดต่างๆในสถานการณ์จริง
            รวมทั้งมีการทดสอบว่ามีการรั่วไหลของอากาศจากภายนอกเข้าไปในหน้ากาศได้มากน้อยเท่าไร
            สรุปได้ค่อนข้างชัดเจนว่า แม้ว่าหน้ากากบางชนิดจะมี FE
            ดีแต่อาจใช้ป้องกัน PM<sub>2.5</sub> ในการใช้งานจริงได้ไม่ดี
            ที่ได้ผลดีที่สุดก็ยังคงเป็นหน้ากากชนิด N95
            ซึ่งอากาศสามารถรั่วเข้าไปได้เพียง 9%
            ในขณะที่หน้ากากที่ใช้ในห้องผ่าตัด อากาศรั่วเข้าไปได้ 35%
            แต่ถ้าใช้ผ้ายืดช่วยรัดที่ขอบหน้ากากจะช่วยให้อากาศรั่วเข้าได้น้อยลงเป็น
            24% แต่อาจทำให้ผู้ใช้รู้สึกอึดอัดได้
          </div>
        </CardContent>
      </Card>

      <br />

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{ backgroundColor: "#08C5C7", color: "white" }}
          titleTypographyProps={{ variant: "h7" }}
          title="คำถาม ในกรณีที่ไม่สามารถหาหน้ากากชนิด N95 ได้ ควรทำอย่างไร ?"
        />
        <CardContent style={{ border: "1px solid #08C5C7" }}>
          <div style={{ fontSize: "15px" }}>
            <strong>คำตอบ</strong> ดีที่สุดคือถ้าเกิดวิกฤติหมอกควัน
            ควรพยายามหลีกเลี่ยงการออกนอกบ้านให้มากที่สุดเท่าที่จะทำได้
            โดยเฉพาะอย่างยิ่งผู้ที่มีความเสี่ยงต่อผลกระทบจากฝุ่นควันหรือมีโรคประจำตัว
            แต่หากมีความจำเป็นต้องออกจากบ้านมากจริงๆ ควรใช้หน้ากากชนิด N95
            มากกว่า ส่วนประชาชนทั่วไปหรือกรณีไม่สามารถหาหน้ากากชนิด N95 ได้จริงๆ
            การใช้หน้ากากอนามัยที่ใช้ในห้องผ่าตัด อาจจะใช้หน้ากากหลายชั้น
            หรือใส่ผ้าหรือกระดาษเสริม ก็ยังได้ประโยชน์มากกว่าไม่ใส่หน้ากากเลย
            และต้องเน้นให้ใช้ระยะเวลาที่อยู่นอกบ้านให้สั้นที่สุด
            เสร็จธุระแล้วต้องรีบกลับเข้าบ้านทันที
          </div>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default Listdetail;
