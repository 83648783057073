import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Box,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Grid,
  Button,
  Stack
} from "@mui/material/";

const Listdetail = () => {
  const [cal1, setcal1] = useState(0);
  const [cal2, setcal2] = useState(0);
  const [cal3, setcal3] = useState(0);

  const [cal1_1, setcal1_1] = useState(0);

  const [calPercent1, setcalPercent1] = useState(0);
  const [calPercent2, setcalPercent2] = useState(0);
  const [calPercent3, setcalPercent3] = useState(0);
  const [calPercent4, setcalPercent4] = useState(0);

  const [value1, setValue1] = useState("");
  const [value2, setValue2] = useState("");

  const handleSubmit = event => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    //  console.log(value1);
    let xpd = 0,
      xpd2 = 0;

    if (value1 > 15) {
      xpd = (value1 - 15) * 0.06;
    } else {
      xpd = 0;
    }

    xpd2 = value1 / 22;

    // console.log(xpd);

    setcal1(parseFloat(xpd).toFixed(1));
    setcal1_1(parseFloat(xpd2).toFixed(1));
  };

  const handleSubmit2 = event => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let xyear = 0,
      xcount = 0;

    if (value2 > 5) {
      xyear = (value2 - 5) * 0.098;
      xcount = (value2 - 5) * 0.65856;
    } else {
      xyear = 0;
      xcount = 0;
    }

    setcal2(parseFloat(xyear).toFixed(1));
    setcal3(Math.ceil(xcount));

    if (value2 <= 5) {
      setcalPercent1(0);
      setcalPercent2(0);
      setcalPercent3(0);
      setcalPercent4(0);
    } else {
      setcalPercent1(parseFloat((value2 - 5) * 0.5).toFixed(1));
      setcalPercent2(parseFloat((value2 - 5) * 0.88).toFixed(1));
      setcalPercent3(parseFloat((value2 - 5) * 0.56).toFixed(1));
      setcalPercent4(parseFloat((value2 - 5) * 0.25).toFixed(1));
    }
  };

  const bClearClick1 = () => {
    setcal1(0);
    setcal1_1(0);
    setValue1("");
  };

  const bClearClick2 = () => {
    setcal2(0);
    setcal3(0);
    setValue2("");
  };

  return (
    <React.Fragment>
      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{
            backgroundColor: "#00b395",
            color: "white",
            textAlign: "center"
          }}
          titleTypographyProps={{
            variant: "h6",
            fontFamily: "kanit"
          }}
          title="1. ผลกระทบต่อสุขภาพระยะสั้น"
        />
        <CardContent style={{ border: "1px solid #00b395" }}>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4} md={2}>
                  <p>ค่า PM 2.5 เฉลี่ย 24 ชม.</p>
                </Grid>
                <Grid item xs={12} sm={8} md={2}>
                  <div style={{ fontSize: "15px" }}>
                    <FormControl
                      sx={{ m: 1, width: "26ch" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        id="outlined-pm1"
                        endAdornment={
                          <InputAdornment position="end">
                            มคก./ลบม.
                          </InputAdornment>
                        }
                        size="small"
                        placeholder="0.00"
                        style={{ fontFamily: "kanit" }}
                        name="pm1"
                        value={value1}
                        onChange={newValue => {
                          setValue1(newValue.target.value);
                        }}
                        type="number"
                      />
                    </FormControl>
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4} md={4}>
                  <p>
                    PM2.5 ระดับนี้ เทียบเท่ากับการสูบบุหรี่ ={" "}
                    <a style={{ backgroundColor: "#F0E0DD", padding: "4px" }}>
                      {cal1_1}
                    </a>{" "}
                    มวน
                  </p>
                </Grid>
              </Grid>
            </Box>

            <br />

            <p style={{ textAlign: "center" }}>
              ถ้าในพื้นที่ของท่านมีฝุ่นควัน PM2.5 เฉลี่ยต่อวันสูงเท่านี้
              ในวันนั้นประชาชนในพื้นที่จะเสียชีวิตจากมลพิษทางอากาศ{" "}
              <a style={{ backgroundColor: "#F0E0DD", padding: "4px" }}>
                {cal1}
              </a>{" "}
              คน
            </p>

            <br />

            <Stack spacing={1} direction="row">
              <Button
                type="submit"
                variant="contained"
                style={{ fontFamily: "kanit" }}
              >
                คำนวณ
              </Button>
              <Button
                variant="outlined"
                style={{ fontFamily: "kanit" }}
                onClick={() => {
                  bClearClick1();
                }}
              >
                เริ่มใหม่
              </Button>
            </Stack>
          </Box>
        </CardContent>
      </Card>

      <br />

      <Card sx={{ maxWidth: "100%", borderRadius: "5px" }}>
        <CardHeader
          style={{
            backgroundColor: "#00b395",
            color: "white",
            textAlign: "center"
          }}
          titleTypographyProps={{
            variant: "h6",
            fontFamily: "kanit"
          }}
          title="2. ผลกระทบต่อสุขภาพระยะยาว"
        />
        <CardContent style={{ border: "1px solid #00b395" }}>
          <Box
            component="form"
            onSubmit={handleSubmit2}
            noValidate
            sx={{ mt: 1 }}
          >
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4} md={2}>
                  <p>ค่า PM 2.5 เฉลี่ยต่อปี</p>
                </Grid>
                <Grid item xs={12} sm={8} md={2}>
                  <div style={{ fontSize: "15px" }}>
                    <FormControl
                      sx={{ m: 1, width: "26ch" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        id="outlined-pm1"
                        endAdornment={
                          <InputAdornment position="end">
                            มคก./ลบม.
                          </InputAdornment>
                        }
                        size="small"
                        placeholder="0.00"
                        style={{ fontFamily: "kanit" }}
                        name="pm2"
                        value={value2}
                        onChange={newValue => {
                          setValue2(newValue.target.value);
                        }}
                        type="number"
                      />
                    </FormControl>
                  </div>
                </Grid>
              </Grid>
            </Box>

            <br />

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <div>
                <p>
                  ในปีถัดไป ผู้สูงอายุ (อายุ 65 ปีขึ้นไป)
                  ในพื้นที่ของท่านจะมีโอกาสเสียชีวิต
                </p>
                <p style={{ marginLeft: "30px" }}>
                  1. จากทุกสาเหตุเพิ่มขึ้น{" "}
                  <a style={{ backgroundColor: "#F0E0DD", padding: "4px" }}>
                    {calPercent1}
                  </a>{" "}
                  %
                </p>
                <p style={{ marginLeft: "30px" }}>
                  2. จากโรคหัวใจและหลอดเลือดเพิ่มขึ้น{" "}
                  <a style={{ backgroundColor: "#F0E0DD", padding: "4px" }}>
                    {calPercent2}
                  </a>{" "}
                  %
                </p>
                <p style={{ marginLeft: "30px" }}>
                  3. จากโรคระบบทางเดิมหายใจเพิ่มขึ้น{" "}
                  <a style={{ backgroundColor: "#F0E0DD", padding: "4px" }}>
                    {calPercent3}
                  </a>{" "}
                  %
                </p>
                <p style={{ marginLeft: "30px" }}>
                  4. จากโรคมะเร็งเพิ่มขึ้น{" "}
                  <a style={{ backgroundColor: "#F0E0DD", padding: "4px" }}>
                    {calPercent4}
                  </a>{" "}
                  %
                </p>
              </div>
            </div>

            <p style={{ textAlign: "center" }}>
              ถ้าในพื้นที่ของท่านมีฝุ่นควัน PM2.5 เฉลี่ยต่อปีสูงแบบนี้ไปตลอด
              ประชาชนในพื้นที่จะมีอายุขัยเฉลี่ยสั้นลง{" "}
              <a style={{ backgroundColor: "#F0E0DD", padding: "4px" }}>
                {cal2}
              </a>{" "}
              ปี
            </p>
            <hr />
            <p style={{ textAlign: "center" }}>
              ฝุ่นควันพิษระดับนี้ทำให้อายุสั้นลงเท่ากับการสูบบุหรี่{" "}
              <a style={{ backgroundColor: "#F0E0DD", padding: "4px" }}>
                {cal3}
              </a>{" "}
              มวนต่อวันติดต่อกัน 30 ปี
            </p>

            <br />

            <Stack spacing={1} direction="row">
              <Button
                type="submit"
                variant="contained"
                style={{ fontFamily: "kanit" }}
              >
                คำนวณ
              </Button>
              <Button
                variant="outlined"
                style={{ fontFamily: "kanit" }}
                onClick={() => {
                  bClearClick2();
                }}
              >
                เริ่มใหม่
              </Button>
            </Stack>
          </Box>
        </CardContent>
      </Card>

      <br />
      <em style={{ fontSize: "14px" }}>
        Reference:
        <ul>
          <li>
            ศ. นพ. ชายชาญ โพธิรัตน์
            การศึกษาผลกระทบของหมอกควันต่อสุขภาพในจังหวัดเชียงใหม่
            โดยข้อมูลจำนวนผู้เสียชีวิตจากแผนกทะเบียนราษฎร์จังหวัดเชียงใหม่
            และข้อมูลคุณภาพอากาศจากกรมควบคุมมลพิษ
          </li>
          <li>
            <a
              style={{ textDecoration: "none" }}
              href="https://aqli.epic.uchicago.edu/the-index/"
            >
              https://aqli.epic.uchicago.edu/the-index/
            </a>
          </li>
          <li>
            Wang et al. Environmental Health (2020) 19:20{" "}
            <a
              style={{ textDecoration: "none" }}
              href="https://ehjournal.biomedcentral.com/articles/10.1186/s12940-020-00575-0"
            >
              https://doi.org/10.1186/s12940-020-00575-0
            </a>
          </li>
          <li>
            <a
              style={{ textDecoration: "none" }}
              href="https://berkeleyearth.org/air-pollution-and-cigarette-equivalence/"
            >
              https://berkeleyearth.org/air-pollution-and-cigarette-equivalence/
            </a>
          </li>
        </ul>
      </em>
    </React.Fragment>
  );
};

export default Listdetail;
