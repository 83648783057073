import React from "react";

import { Container } from "@mui/material";

import "./css/setImg.css";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Contact from "../../components/Contact";

import Pic_CleanRoom from "../../imgs/clean_room1_small.png";

const Cleanroom_Main2 = () => {
  return (
    <React.Fragment>
      <Navbar />

      <Container>
        <div style={{ marginTop: "100px" }} />

        <img
          style={{
            maxWidth: "75%",
            height: "auto",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto"
          }}
          className="img-artboard"
          src={Pic_CleanRoom}
          alt="CleanRoom"
        />

        <p style={{ marginTop: "30px", fontWeight: 600 }}>
          หลักการทำห้องปลอดฝุ่น
        </p>

        <ol>
          <li>
            <b>การป้องกันฝุ่นจากภายนอก</b> โดยการปิดประตูหน้าต่างทั้งหมด
            ร่วมกับการอุดรูรั่วต่างๆตามฝาผนัง ขอบหน้าต่าง ช่องลม ฯลฯ
            เพื่อป้องกันไม่ให้ฝุ่นจากภายนอกเข้ามาในบ้าน
            เป็นวิธีที่ง่ายที่สุดแต่มักได้ผลไม่ค่อยดี
            เนื่องจากฝุ่นที่ค้างอยู่ภายในห้องลดลงได้ยากและใช้เวลานาน
            รวมทั้งการเปิดประตูหน้าต่างบ่อยๆและมักไม่สามารถอุดรูรั่วทั้งหมดได้
            ทำให้ฝุ่นจากภายนอกเล็ดลอดเข้ามาในห้องได้ตลอดเวลา
          </li>

          <li>
            <b>การฟอกอากาศเพื่อลดฝุ่นในห้อง</b>
            โดยการติดตั้งเครื่องฟอกอากาศเพื่อกรองฝุ่นที่อยู่ภายในห้อง
            เป็นวิธีที่สามารถลดฝุ่น PM2.5 ในห้องได้ดีพอสมควร
            แต่ประสิทธิภาพอาจลดลงถ้าอากาศภายนอกมีฝุ่น PM2.5 ในระดับที่สูงมาก
            มีการเปิดประตูหน้าต่างบ่อยๆ และไม่สามารถอุดรูรั่วทั้งหมดได้
            รวมทั้งเครื่องฟอกอากาศยังมีราคาค่อนข้างแพง
          </li>

          <li>
            <b>การเติมอากาศสะอาดเข้าในห้อง</b>
            โดยการติดตั้งเครื่องเติมอากาศจากภายนอกที่ผ่านการฟอกอากาศก่อน
            เพื่อทำให้แรงดันอากาศภายในห้องเป็นบวกช่วยดันฝุ่น PM2.5
            ออกไปตามรูรั่วต่างๆ และป้องกันไม่ให้ฝุ่น PM2.5 ภายนอกเข้ามาในห้อง
            เป็นวิธีที่ได้ผลดีแม้อากาศภายนอกจะมีฝุ่น PM2.5
            ในระดับสูงหรือไม่สามารถอุดรูรั่วทั้งหมดได้
            แต่ข้อจำกัดที่สำคัญคือเครื่องเติมอากาศยังมีราคาค่อนข้างแพงมาก
          </li>
        </ol>

        <p style={{ marginTop: "30px", fontWeight: 600 }}>
          การทำทั้ง 3 อย่างร่วมกันจะได้ผลดีที่สุด แต่ก็จะมีค่าใช้จ่ายสูงขึ้น
          โดยเฉพาะอย่างยิ่งบ้านหรืออาคารที่มีจำนวนห้องมากๆ
          ค่าใช้จ่ายก็จะสูงขึ้นเป็นทวีคูณ
        </p>
      </Container>

      <div style={{ height: "50px" }} />
      <Contact />
      <Footer />
    </React.Fragment>
  );
};

export default Cleanroom_Main2;
