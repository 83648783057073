import React, { useState, useEffect } from "react";
import axios from "axios";

import moment from "moment";
import "moment/locale/th";

import { Container } from "@mui/material";

import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";

import Navbar_Admin from "../../../components/Navbar_Admin";
import Footer from "../../../components/Footer";

const Humidity = () => {
  let xURL = "";
  xURL = "https://www2.ntaqhi.info/api/";

  const [dgdataSource, setdgdataSource] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          getData();
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/admin";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const getData = () => {
    axios
      .get(xURL + "getHumidity")
      .then(result => {
        const Tb1 = [];

        let xarea_point = "";

        let xdistricts = "",
          xamphures = "",
          xprovinces = "";

        result.data.forEach((i, idx) => {
          xarea_point = "/?area-point=" + i.area_point+'&pages=admin';

          if (i.districts) {
            xdistricts = "ต." + i.districts;
          } else {
            xdistricts = "";
          }

          if (i.amphures) {
            xamphures = "อ." + i.amphures;
          } else {
            xamphures = "";
          }

          if (i.provinces) {
            xprovinces = "จ." + i.provinces;
          } else {
            xprovinces = "";
          }

          Tb1.push({
            Number: parseInt(idx + 1),
            device_name: (
              <a
                style={{ textDecoration: "none" }}
                href={xarea_point}
                target="_blank"
              >
                {i.d_name} {xdistricts} {xamphures} {xprovinces}
              </a>
            ),
            device_id: "ID " + i.d_id,
            max: i.hr_max,
            min: i.hr_min,
            average: i.hr_avr
          });
        });

        setdgdataSource(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const dgcolumns = [
    {
      name: "Number",
      header: "Number",
      maxWidth: 100,
      minWidth: 70,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      type: "number",
      sortable: false
    },
    {
      name: "device_id",
      header: "Device ID",
      maxWidth: 200,
      minWidth: 100,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      type: "number",
      sortable: false
    },
    {
      name: "device_name",
      header: "ชื่อจุดตรวจ",
      maxWidth: 700,
      minWidth: 250,
      defaultFlex: 2,
      headerAlign: "center",
      sortable: false
    },
    {
      name: "max",
      header: "RHmax",
      maxWidth: 150,
      minWidth: 100,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false
    },
    {
      name: "min",
      header: "RHmin",
      maxWidth: 150,
      minWidth: 100,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false
    },
    {
      name: "average",
      header: "RHmax- RHmin",
      maxWidth: 150,
      minWidth: 100,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false
    }
  ];

  const gridStyle = { minHeight: 603 };

  const scrollProps = Object.assign(
    {},
    ReactDataGrid.defaultProps.scrollProps,
    {
      autoHide: false
    }
  );

  return (
    <React.Fragment>
      <div style={{ marginBottom: "100px" }} />

      <Navbar_Admin />

      <Container>
        <h2>รายการเครื่องที่ค่าความชื้นสัมพัทธ์ค้าง</h2>

        <p style={{ fontSize: "16px" }}>
          จุดตรวจที่มีความชื้นสัมพัทธ์ค้าง (สูงสุด-ต่ำสุดน้อยกว่า 5%)
        </p>
        <p style={{ fontSize: "16px", color: "red" }}>
          ช่วงวันที่ {moment().add(-1, "day").format("DD MMM YYYY")} เวลา 00.00
          น. ถึง {moment().format("DD MMM YYYY")} เวลา 00.00 น.
        </p>

        <ReactDataGrid
          idProperty="Number"
          columns={dgcolumns}
          dataSource={dgdataSource}
          style={gridStyle}
          enableClipboard
          scrollProps={scrollProps}
        />
      </Container>

      <Footer />
    </React.Fragment>
  );
};

export default Humidity;
