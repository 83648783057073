import React, { useState, useEffect } from "react";
import axios from "axios";

import moment from "moment";
import "moment/locale/th";

import {
  Box,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Button,
  Stack
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";

import Swal from "sweetalert2";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

const Chart_day = () => {
  let xURL = "";
  xURL = "https://www2.ntaqhi.info/api/";

  const Monthdata = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม"];
  const [MonthName, setMonthName] = useState([""]);
  const [MonthId, setMonthId] = useState("");

  const [Yeardata, setYeardata] = useState([""]);
  const [dYearName, setdYearName] = useState([""]);

  const [dataChart, setdataChart] = useState();

  const [dataCountIn, setdataCountIn] = useState();
  const [dataCountOut, setdataCountOut] = useState();

  const [show, setshow] = useState(false);

  useEffect(() => {
    getYear();
  }, []);

  const getYear = () => {
    var xyear = 0;
    let tb = [];

    xyear = moment().format("YYYY");

    tb.push("");
    for (let i = 0; i < 10; i++) {
      let oyear = parseInt(xyear - i);

      if (oyear >= 2020) {
        tb.push(parseInt(xyear - i));
      }
    }

    setYeardata(tb);
  };

  const bclearClick = () => {
    // window.location.reload(false);
    setdYearName([""]);
    setMonthName([""]);
    setMonthId("");

    setdataChart([""]);

    setshow(false);
  };

  const bSearchClick = () => {
    if (MonthName == "") {
      Swal.fire({
        title: "ค้นหาไม่ได้!",
        text: "ยังไม่ได้เลือก เดือน",
        icon: "warning",
        showConfirmButton: false,
        timer: 2000,
        customClass: {
          container: "my-swal"
        }
      });
    } else if (dYearName == "") {
      Swal.fire({
        title: "ค้นหาไม่ได้!",
        text: "ยังไม่ได้เลือก ปี",
        icon: "warning",
        showConfirmButton: false,
        timer: 2000,
        customClass: {
          container: "my-swal"
        }
      });
    } else {
      getData();
    }
  };

  const getData = () => {
    // console.log(MonthId);
    axios
      .post(xURL + "getIAQDay", { xdate: dYearName + "-" + MonthId })
      .then(result => {
        const Tb1 = [];

        let xcountIn = 0,
          xcountOut = 0;

        result.data.forEach((i, idx) => {
          if (i.Outdoor > 35) {
            xcountOut = xcountOut + 1;
          }

          if (i.Indoor > 35) {
            xcountIn = xcountIn + 1;
          }

          Tb1.push({
            name: moment(i.th_d).format("DD MMMM YYYY"),
            Outdoor: i.Outdoor,
            Indoor: i.Indoor
          });
        });

        setdataChart(Tb1);

        setdataCountOut(xcountOut);
        setdataCountIn(xcountIn);

        setshow(true);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const handleChange = event => {
    setdYearName(event.target.value);
  };

  const handleChange2 = event => {
    setMonthName(event.target.value);

    if (event.target.value == "มกราคม") {
      setMonthId("01");
    } else if (event.target.value == "กุมภาพันธ์") {
      setMonthId("02");
    } else if (event.target.value == "มีนาคม") {
      setMonthId("03");
    } else if (event.target.value == "เมษายน") {
      setMonthId("04");
    } else if (event.target.value == "พฤษภาคม") {
      setMonthId("05");
    }
  };

  return (
    <React.Fragment>
      <p>เลือกเดือนและปีที่ต้องการ แล้วกดปุ่มค้นหา</p>

      <Box component="form" noValidate sx={{ mt: 1, mb: 5 }}>
        <Stack direction="row" spacing={1}>
          <FormControl margin="normal" required>
            <InputLabel id="demo-select-small">เดือน</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              size="small"
              name="month"
              value={MonthName}
              label="เดือน"
              onChange={handleChange2}
              sx={{ width: 150 }}
            >
              {Monthdata.map(Monthdata =>
                <MenuItem key={Monthdata} value={Monthdata}>
                  {Monthdata}
                </MenuItem>
              )}
            </Select>
          </FormControl>

          <FormControl margin="normal" required>
            <InputLabel sx={{ mt: 2 }} id="demo-select-small">
              ปี
            </InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              size="small"
              name="year"
              value={dYearName}
              label="ปี"
              onChange={handleChange}
              sx={{ width: 150, mt: 2 }}
            >
              {Yeardata.map(Yeardata =>
                <MenuItem key={Yeardata} value={Yeardata}>
                  {Yeardata}
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </Stack>

        <Button
          //   type="submit"
          variant="contained"
          sx={{ mt: 1, mr: 1, width: 150 }}
          startIcon={<SearchIcon />}
          style={{ fontFamily: "kanit" }}
          onClick={() => {
            bSearchClick();
          }}
        >
          ค้นหา
        </Button>
        <Button
          variant="contained"
          color="error"
          sx={{ mt: 1, width: 150 }}
          startIcon={<CleaningServicesIcon />}
          onClick={() => {
            bclearClick();
          }}
          style={{ fontFamily: "kanit" }}
        >
          เคลียร์
        </Button>
      </Box>

      {show
        ? <div>
            <h3
              style={{
                textAlign: "center",
                marginBottom: "50px"
              }}
            >
              ค่า PM2.5 เฉลี่ยรายวัน นอกและในอาคาร 1 (มคก./ลบม.)
            </h3>

            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                data={dataChart}
                margin={{ right: 10, left: 10, bottom: 25 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  textAnchor="end"
                  sclaeToFit="true"
                  verticalAnchor="start"
                  interval={0}
                  angle="-45"
                  tick={{ fontSize: 13 }}
                />
                <YAxis />
                <Tooltip />
                <Legend wrapperStyle={{ top: 400 }} height={36} />
                <Bar dataKey="Outdoor" fill="red" />
                <Bar dataKey="Indoor" fill="#009874" />
              </BarChart>
            </ResponsiveContainer>

            <table className="realtime" style={{ marginTop: "80px" }}>
              <tbody>
                <tr style={{ height: "35px" }}>
                  <th
                    style={{
                      textAlign: "left",
                      paddingLeft: "15px",
                      border: "1px solid black"
                    }}
                  >
                    จำนวนวันที่ PM2.5 นอกอาคารสูงกว่า 35 มคก./ลบม.
                  </th>
                  <th
                    style={{
                      paddingLeft: "15px",
                      border: "1px solid black",
                      width: "200px"
                    }}
                  >
                    {dataCountOut}
                  </th>
                </tr>

                <tr style={{ height: "35px" }}>
                  <th
                    style={{
                      textAlign: "left",
                      paddingLeft: "15px",
                      border: "1px solid black"
                    }}
                  >
                    จำนวนวันที่ PM2.5 ในอาคารสูงกว่า 35 มคก./ลบม.
                  </th>
                  <th
                    style={{
                      paddingLeft: "15px",
                      border: "1px solid black",
                      width: "200px"
                    }}
                  >
                    {dataCountIn}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        : <div />}
    </React.Fragment>
  );
};

export default Chart_day;
