import React, { useEffect } from "react";

import axios from "axios";

import "./css/Map.css";

import { TextField, Button, Box } from "@mui/material/";
import SearchIcon from "@mui/icons-material/Search";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";

import Swal from "sweetalert2";

const Map = () => {
  let xURL = "";
  xURL = "https://www2.ntaqhi.info/api/";

  useEffect(() => {
    getMarker();
  }, []);

  const handleSubmit = event => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    // console.log(data.get("date1"));

    if (!data.get("date1")) {
      Swal.fire({
        title: "ค้นหาไม่ได้!",
        text: "ยังไม่ได้เลือก วันที่แสดง",
        icon: "warning",
        showConfirmButton: false,
        timer: 2000,
        customClass: {
          container: "my-swal"
        }
      });
    } else {
      getMarker(data.get("date1"));
    }

    // getMarker();
    // setMap();
  };

  const bclearClick = () => {
    window.location.reload(false);
  };

  function setMap(mark) {
    const script = document.createElement("script");
    script.src = `https://api.longdo.com/map/?key=16f9234b232cc53f721c5e7ac40f5965`;
    script.id = "longdoMapScript";
    document.body.appendChild(script);

    script.onload = () => {
      mapCallback(mark);
    };

    const mapCallback = () => {
      let longdo = window.longdo;
      let map = new window.longdo.Map({
        placeholder: document.getElementById("longdo-map"),
        language: "th"
      });

      map.location(
        {
          // พิกัด zoom 8
          // lat: 18.200195,
          // lon: 99.600751
          // พิกัด zoom 7
          // lat: 15.800195,
          // lon: 101.500751
          // พิกัด zoom 8 new
          lat: 18.700195,
          lon: 99.600751
        },
        true
      );
      map.zoom(8);
      map.zoomRange({
        min: 7,
        max: 17
      });

      // map.Layers.setBase(longdo.Layers.POLITICAL);
      map.Layers.setBase(longdo.Layers.TOMTOM_NORMAL);

      map.Ui.LayerSelector.visible(true);
      map.Ui.Mouse.enable(true);
      map.Ui.Mouse.enableDrag(true);
      map.Ui.DPad.visible(true);
      map.Ui.LayerSelector.visible(false);
      map.Ui.Toolbar.visible(false);
      // map.Ui.Fullscreen.visible(false);
      map.Ui.Crosshair.visible(false);
      map.Ui.Scale.visible(true);

      map.Overlays.clear();

      mark.forEach(i => {
        let titlepin = "",
          detailpin = "",
          markerpin = "",
          color_marker = "",
          labelpin = "";

        if (i.pm25 >= 0 && i.pm25 <= 12) {
          color_marker = "pingreen";
          labelpin = "label1";
        } else if (i.pm25 > 12 && i.pm25 <= 35) {
          color_marker = "pinyellow";
          labelpin = "label1";
        } else if (i.pm25 > 35 && i.pm25 <= 55) {
          color_marker = "pinorenge";
          labelpin = "label1";
        } else if (i.pm25 > 55 && i.pm25 <= 150) {
          color_marker = "pinred";
          labelpin = "label2";
        } else if (i.pm25 > 150 && i.pm25 <= 250) {
          color_marker = "pinpurple";
          labelpin = "label2";
        } else if (i.pm25 > 250) {
          color_marker = "pinblack";
          labelpin = "label2";
        }

        titlepin = "<p class='title-style'>" + i.name + "</p>";
        detailpin = "<p class='detail-style'>pm2.5 = " + i.pm25 + "</p>";

        markerpin =
          "<div class=" +
          color_marker +
          "><p class=" +
          labelpin +
          ">" +
          i.pm25 +
          "</p></div>";

        let marker = new longdo.Marker(
          { lon: i.lon, lat: i.lat },
          {
            title: titlepin,
            detail: detailpin,
            icon: {
              html: markerpin,
              offset: { x: 10, y: 10 }
            },

            size: {
              width: 190,
              height: 170
            }
          }
        );

        map.Overlays.add(marker);
      });
    };
  }

  function getMarker(zdate) {
    axios
      .post(xURL + "getmappm", { sdate: zdate })
      .then(result => {
        // console.log(result.data);
        const Tb1 = [];

        result.data.forEach((i, idx) => {
          Tb1.push({
            name: i.d_name,
            lat: i.poi_lat,
            lon: i.poi_lng,
            pm25: i.pm25
          });
        });

        //  console.log(Tb1);

        setMap(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }

  return (
    <React.Fragment>
      <p>
        เลือกวันที่ต้องการดูแผนที่คุณภาพอากาศย้อนหลัง (PM2.5 เฉลี่ย 24 ชม.)
        แล้วกดปุ่มค้นหา
      </p>

      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          size="small"
          id="date1"
          label="วันที่แสดง"
          name="date1"
          type="date"
          InputLabelProps={{
            shrink: true
          }}
          sx={{ mt: 1, mr: 1, width: 305 }}
        />

        <br />
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 1, mr: 1, width: 150 }}
          startIcon={<SearchIcon />}
          style={{ fontFamily: "kanit" }}
        >
          ค้นหา
        </Button>

        <Button
          variant="contained"
          color="error"
          sx={{ mt: 1, width: 150 }}
          startIcon={<CleaningServicesIcon />}
          onClick={() => {
            bclearClick();
          }}
          style={{ fontFamily: "kanit" }}
        >
          เคลียร์
        </Button>
      </Box>

      <div
        id="longdo-map"
        style={{
          height: "700px",
          border: "1px solid #B3B6B7",
          borderRadius: "10px",
          padding: "1%",
          marginTop: "20px"
        }}
      />

      <div style={{ marginBottom: "30px", backgroundColor: "#fff" }}>
        <span style={{ fontSize: "12px", color: "#748DA6" }}>
          - ขอขอบคุณ{" "}
          <a style={{ textDecoration: "none" }} href="https://map.longdo.com/">
            Longdo Map
          </a>{" "}
          ที่สนับสนุนการทำแผนที่
        </span>
      </div>
    </React.Fragment>
  );
};

export default Map;
