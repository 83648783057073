import React from "react";

const Listdetail = () => {
  return (
    <React.Fragment>
      <h3 style={{ fontSize: "16px" }}>
        <strong>
          กรณีค่า AQHI ที่คำนวณจากค่า PM<sub>2.5</sub> เกิน 500
        </strong>
      </h3>
      <h4 style={{ marginTop: "20px", fontSize: "15px" }}>
        ในบางโอกาสอาจมีระดับมลพิษ PM<sub>2.5</sub> เกิน 500 ซึ่งสูงมากๆได้
        ประชาชนควรปฏิบัติตัวอย่างไร
      </h4>

      <p style={{ marginTop: "20px", fontSize: "14px" }}>
        <ol>
          <li>
            ให้ปฏิบัติตัวตามคำแนะนำสำหรับ AQHI 301-500 คือระดับอันตราย สีน้ำตาล
          </li>
          <li>
            ที่สำคัญที่สุดประชาชนทุกกลุ่มควรหลีกเลี่ยงการสูดอากาศที่มีมลพิษสูงเช่นนี้ให้มากที่สุด
            โดย
            <ol>
              <li>งดกิจกรรมนอกอาคารทุกชนิด</li>
              <li>
                อยู่ในบ้านหรืออาคารซึ่งมีห้องที่ติดตั้งเครื่องฟอกอากาศชนิดที่มีไส้กรอง
                HEPA
              </li>
              <li>ลดกิจกรรมหรือการออกกำลังกายหนัก ทำกิจกรรมที่เบาๆเท่านั้น</li>
              <li>
                หากมีความจำเป็นต้องออกนอกบ้านจริงๆ ควรใส่หน้ากากชนิด N95 หรือ
                FFP2
              </li>
            </ol>
          </li>
          <li>
            ที่อยู่ในกลุ่มเสี่ยงต้องระมัดระวังมากเป็นพิเศษ ได้แก่
            ผู้ที่เป็นโรคหัวใจ ปอด ผู้สูงอายุ เด็ก หญิงมีครรภ์
            และอาจรวมถึงคนอ้วนและผู้ป่วยเบาหวานด้วย
          </li>
          <li>
            สังเกตอาการที่อาจเกิดจากผลกระทบของมลพิษทางอากาศต่อสุขภาพ เช่น
            แน่นหน้าอก หายใจขัด หอบเหนื่อย เหงื่อออก คลื่นไส้ เวียนศีรษะ
            ปวดศีรษะ หน้ามืด แขนขาชาหรืออ่อนแรง สับสน ซึมลง พูดไม่ชัด ตาพร่ามัว
            เดินเซ เป็นต้น หากมีอาการดังกล่าวควรรีบไปพบแพทย์โรงพยาบาล
          </li>
          <li>
            ติดตามสภาวะอากาศอย่างใกล้ชิด
            เพื่อจะได้เฝ้าระวังและปฏิบัติตัวได้อย่างเหมาะสม
          </li>
        </ol>
      </p>
    </React.Fragment>
  );
};

export default Listdetail;
