import React, { useState } from "react";

import { AppBar, Box, IconButton, Toolbar } from "@mui/material/";

import "./css/Navbar_Admin.css";
import logo from "../imgs/NTAQHI_small1.png";

import LogoutIcon from "@mui/icons-material/Logout";

import Swal from "sweetalert2";

const Navbar_Admin = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <React.Fragment>
      <div className="Navbar">
        <Box sx={{ flexGrow: 1 }}>
          <AppBar
            position="fixed"
            style={{
              backgroundColor: "#009874"
              // opacity: 0.9
            }}
          >
            <Toolbar>
              <a class="navbar-brand" href="/">
                <img src={logo} alt="CMAQHI" width="120" />
              </a>

              <a
                href="#"
                class="toggle-button"
                onClick={() => setToggle(!toggle)}
              >
                <span class="bar" />
                <span class="bar" />
                <span class="bar" />
              </a>

              <div className="navbar-links">
                <ul>
                  <li>
                    <a style={{ fontSize: "14px" }} href="/admin/online">
                      จุดตรวจที่ออนไลน์
                    </a>
                  </li>
                  <li>
                    <a style={{ fontSize: "14px" }} href="/admin/humidity">
                      จุดตรวจที่ค่าความชื้นค้าง
                    </a>
                  </li>
                  <li>
                    <a style={{ fontSize: "14px" }} href="/admin/device">
                      แก้ไขข้อมูลจุดตรวจ
                    </a>
                  </li>
                  <li>
                    <a style={{ fontSize: "14px" }} href="/admin/line_noti">
                      แจ้งเตือนไลน์
                    </a>
                  </li>
                </ul>

                <div
                  style={{
                    float: "right",
                    position: "static",
                    marginTop: "-40px"
                  }}
                >
                  <IconButton sx={{ color: "white" }}>
                    <LogoutIcon
                      fontSize="inherit"
                      onClick={() => {
                        Swal.fire({
                          title: "LogOut!",
                          text: "LogOut สำเร็จ",
                          icon: "success",
                          showConfirmButton: false,
                          timer: 2000,
                          customClass: {
                            container: "my-swal"
                          }
                        }).then(() => {
                          localStorage.removeItem("token");
                          window.location = "/admin";
                        });
                      }}
                    />
                  </IconButton>
                </div>
              </div>
            </Toolbar>

            {toggle &&
              <ul className="list-group">
                <li>
                  <a href="/admin/online">จุดตรวจที่ออนไลน์</a>
                </li>
                <li>
                  <a href="/admin/humidity">จุดตรวจที่ค่าความชื้นค้าง</a>
                </li>
                <li>
                  <a href="/admin/device">แก้ไขข้อมูลจุดตรวจ</a>
                </li>
                <li>
                  <a href="/admin/line_noti">แจ้งเตือนไลน์</a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      Swal.fire({
                        title: "LogOut!",
                        text: "LogOut สำเร็จ",
                        icon: "success",
                        showConfirmButton: false,
                        timer: 2000,
                        customClass: {
                          container: "my-swal"
                        }
                      }).then(() => {
                        localStorage.removeItem("token");
                        window.location = "/admin";
                      });
                    }}
                  >
                    <LogoutIcon /> Logout
                  </a>
                </li>
              </ul>}
          </AppBar>
        </Box>
      </div>
    </React.Fragment>
  );
};

export default Navbar_Admin;
