import React from "react";

const Remark = () => {
  return (
    <React.Fragment>
      <div style={{ fontSize: "16px" }}>
        <p>หมายเหตุ</p>
        <p>
          1. ข้อมูลของเดือนและปีปัจจุบัน คำนวณถึงวันที่สืบค้นข้อมูลเท่านั้น
          เนื่องจากยังมีข้อมูลไม่ครบเดือนและปี
        </p>
        <p>
          2. ข้อมูลค่า PM2.5 อาจไม่สมบูรณ์ถึง 100%
          เนื่องจากบางสถานีตรวจวัดไม่ได้ออนไลน์ตลอด 24 ชม.
          หากท่านพบว่าข้อมูลของสถานีตรวจวัดใดสูงหรือต่ำผิดสังเกต
          กรุณาตรวจสอบว่าสถานีตรวจวัดดังกล่าวมีข้อมูลครบถ้วนหรือไม่
        </p>
        <p>3. ค่าที่เป็น 0 หมายถึงไม่มีข้อมูลเนื่องจากเครื่องวัดออฟไลน์</p>
      </div>
    </React.Fragment>
  );
};

export default Remark;
