import React from "react";

const Remark = () => {
  return (
    <React.Fragment>
      <div style={{ fontSize: "15.5px" }}>
        <p>
          * หมายเหตุ : รายการคู่เทียบจุดตรวจของกรมควบคุมมลพิษ (PCD) กับของ
          NTAQHI
        </p>
        <p>
          1. PCD 35t (ศูนย์ราชการ อ.เมืองเชียงใหม่) กับ NTAQHI ID11
          (ทต.ช้างเผือก)
        </p>
        <p>
          2. PCD 36t (โรงเรียนยุพราช อ.เมืองเชียงใหม่) กับ NTAQHI ID501
          (สถาบันวิจัยวิทยาศาสตร์สุขภาพ อาคาร 1)
        </p>
        <p>
          3. PCD o28 (ตำบลช่างเคิ่ง อ.แม่แจ่ม เชียงใหม่) กับ NTAQHI ID81
          (อบต.ช่างเคิ่ง)
        </p>
        <p>
          4. PCD 58t (สำนักงานทรัพยากรธรรมชาติและสิ่งแวดล้อม อ.เมืองแม่ฮ่องสอน)
          กับ NTAQHI ID99 (สถานีวิทยุกระจายเสียงแห่งประเทศไทย อ.เมืองแม่ฮ่องสอน)
        </p>
        <p>
          5. PCD o29 (สถานีตรวจวัดตำบลแม่คง อ.แม่สะเรียง แม่ฮ่องสอน) กับ NTAQHI
          ID137 (รพ.สต.ทุ่งแล้ง)
        </p>
        <p>
          6. PCD 67t (สถานีตรวจวัดตำบลในเวียง อ.เมืองน่าน) กับ NTAQHI ID284
          (สสจ.น่าน)
        </p>
        <p>
          7. PCD 70t (สถานีตรวจวัดตำบลบ้านต๋อม อ.เมืองพะเยา) กับ NTAQHI ID279
          (สสจ.พะเยา)
        </p>
        <p>
          8. PCD 76t (สถานีตรวจวัด ต.แม่ปะ อ.แม่สอด ตาก) กับ NTAQHI ID186
          (โรงพยาบาลแม่สอด)
        </p>
        <p>
          9. PCD 37t (สถานีตรวจวัด ต.พระบาท อ.เมืองลำปาง) กับ NTAQHI ID237
          (ทต.ต้นธงชัย)
        </p>
        <p>
          10. PCD 57t (สถานีตรวจวัด ต.เวียง อ.เมืองเชียงราย) กับ NTAQHI ID216
          (รพ.สต.แม่ยาว)
        </p>
        <p>
          11. PCD 69t (สถานีตรวจวัด ต.นาจักร อ.เมืองแพร่) กับ NTAQHI ID225
          (รพ.สต.บ้านแม่ลัว)
        </p>
        <p>
          12. PCD 86t (สวนชมน่านเฉลิมพระเกียรติ อ.เมืองพิษณุโลก) กับ NTAQHI
          ID264 (ตำบลท่าโพธิ์)
        </p>
        <p>
          13. PCD 27t (สถานีตรวจวัด ต.มหาชัย อ.เมืองสมุทรสาคร) กับ NTAQHI ID511
          (วิทยาลัยการศึกษาและการจัดการทางทะเล มช.)
        </p>
        <p>
          14. PCD 22t (สถานีตรวจวัด ต.บางพูด อ.ปากเกร็ด นนทบุรี) กับ NTAQHI ID87
          (โรงเรียนผาสุกมณีจักรมิตรภาพที่ 116)
        </p>
        <p>
          15. PCD 29t (สถานีตรวจวัด ต.มาบตาพุด อ.เมืองระยอง) กับ NTAQHI ID302
          (ตำบลท่าโพธิ์)
        </p>
        <p>
          16. PCD 31t (สถานีตรวจวัด ต.ห้วยโป่ง อ.เมืองระยอง) กับ NTAQHI ID304
          (ศพด.วัดห้วยโป่ง)
        </p>
      </div>
    </React.Fragment>
  );
};

export default Remark;
