import React, { useState, useEffect } from "react";
import axios from "axios";

import moment from "moment";
import "moment/locale/th";

import { Button, Stack } from "@mui/material/";

import Header from "./Header";
import Descript_firmsnasa from "./Descript_firmsnasa";

import "./css/MapAqhi.css";

import Fire_Icon from "../../imgs/icons/fire.png";
import Fire_Icon2 from "../../imgs/icons/fire2.png";
import Fire_Icon3 from "../../imgs/icons/flame2.png";
import Fire_Icon_Small from "../../imgs/icons/fire_small75.png";
import Fire_Icon2_Small from "../../imgs/icons/fire2_small75.png";
import Fire_Icon3_Small from "../../imgs/icons/flame2_small75.png";

const MapAqhi24hr = () => {
  let xURL = "";
  xURL = "https://www2.ntaqhi.info/api/";

  const [hname, sethname] = useState("แผนที่คุณภาพอากาศรายวัน");

  const [btVariant, setbtVariant] = useState("contained");
  const [btVariant2, setbtVariant2] = useState("outlined");

  useEffect(() => {
    getMarker(1);
  }, []);

  function getMarker(types) {
    let dateed = moment().utcOffset(7).format("YYYY-MM-DD HH:00");

    function setMap(mark, mark2, types) {
      const script = document.createElement("script");
      script.src = `https://api.longdo.com/map/?key=16f9234b232cc53f721c5e7ac40f5965`;
      script.id = "longdoMapScript";
      document.body.appendChild(script);

      script.onload = () => {
        mapCallback(mark, mark2);
      };

      const mapCallback = () => {
        let longdo = window.longdo;
        let map = new window.longdo.Map({
          placeholder: document.getElementById("longdo-map"),
          language: "th"
        });

        map.location(
          {
            // พิกัด zoom 8
            // lat: 18.200195,
            // lon: 99.600751
            // พิกัด zoom 7
            // lat: 15.800195,
            // lon: 101.500751
            // พิกัด zoom 8 new
            lat: 18.700195,
            lon: 99.600751
          },
          true
        );
        map.zoom(8);
        map.zoomRange({
          min: 7,
          max: 17
        });

        // map.Layers.setBase(longdo.Layers.POLITICAL);
        map.Layers.setBase(longdo.Layers.TOMTOM_NORMAL);

        map.Ui.LayerSelector.visible(true);
        map.Ui.Mouse.enable(true);
        map.Ui.Mouse.enableDrag(true);
        map.Ui.DPad.visible(true);
        map.Ui.LayerSelector.visible(false);
        map.Ui.Toolbar.visible(false);
        // map.Ui.Fullscreen.visible(false);
        map.Ui.Crosshair.visible(false);
        map.Ui.Scale.visible(true);

        map.Overlays.clear();

        //ค่าฝุ่น
        mark.forEach(i => {
          // console.log(i.aqhi)
          let titlepin = "",
            detailpin = "",
            markerpin = "",
            color_marker = "",
            labelpin = "";

          if (types == 1) {
            if (i.aqhi >= 0 && i.aqhi <= 50) {
              color_marker = "pingreen";
              labelpin = "label1";
            } else if (i.aqhi > 50 && i.aqhi <= 100) {
              color_marker = "pinyellow";
              labelpin = "label1";
            } else if (i.aqhi > 100 && i.aqhi <= 150) {
              color_marker = "pinorenge";
              labelpin = "label1";
            } else if (i.aqhi > 150 && i.aqhi <= 200) {
              color_marker = "pinred";
              labelpin = "label2";
            } else if (i.aqhi > 200 && i.aqhi <= 300) {
              color_marker = "pinpurple";
              labelpin = "label2";
            } else if (i.aqhi > 300) {
              color_marker = "pinblack";
              labelpin = "label2";
            }
          } else if (types == 2) {
            if (i.aqhi >= 0 && i.aqhi <= 12) {
              color_marker = "pingreen";
              labelpin = "label1";
            } else if (i.aqhi > 12 && i.aqhi <= 35) {
              color_marker = "pinyellow";
              labelpin = "label1";
            } else if (i.aqhi > 35 && i.aqhi <= 55) {
              color_marker = "pinorenge";
              labelpin = "label1";
            } else if (i.aqhi > 55 && i.aqhi <= 150) {
              color_marker = "pinred";
              labelpin = "label2";
            } else if (i.aqhi > 150 && i.aqhi <= 250) {
              color_marker = "pinpurple";
              labelpin = "label2";
            } else if (i.aqhi > 250) {
              color_marker = "pinblack";
              labelpin = "label2";
            }
          }

          titlepin = "<p class='title-style'>" + i.name + "</p>";

          if (types == 1) {
            detailpin =
              "<p class='detail-style'>AQHI = " +
              i.aqhi +
              "</p> <br> <div style='margin: 0 auto; text-align: center'><a href='/?area-point=" +
              i.area_point +
              "' target='_blank' class='btn-link'>ดูข้อมูล</a></div>";
          } else if (types == 2) {
            detailpin =
              "<p class='detail-style'>PM2.5 = " +
              i.aqhi +
              "</p> <br> <div style='margin: 0 auto; text-align: center'><a href='/?area-point=" +
              i.area_point +
              "' target='_blank' class='btn-link'>ดูข้อมูล</a></div>";
          }

          markerpin =
            "<div class=" +
            color_marker +
            "><p class=" +
            labelpin +
            ">" +
            i.aqhi +
            "</p></div>";

          let marker = new longdo.Marker(
            { lon: i.lon, lat: i.lat },
            {
              title: titlepin,
              detail: detailpin,
              icon: {
                // url: piclocation,
                html: markerpin,
                offset: { x: 10, y: 10 }
              },

              size: {
                width: 190,
                height: 170
              }
            }
          );

          map.Overlays.add(marker);
        });

        //ค่าจุดความร้อน
        mark2.forEach(i => {
          // console.log(i.aqhi)
          let titlepin = "",
            detailpin = "",
            xcountry = "",
            xdate = "";

          titlepin = "<p class='title-style'>" + i.satellite + "</p>";

          if (i.country == "THA") {
            xcountry = "ไทย";
          } else if (i.country == "MMR") {
            xcountry = "พม่า";
          } else if (i.country == "LAO") {
            xcountry = "ลาว";
          }

          // xdate = moment(i.dates + " " + i.times.substring()).format("lll");
          if (i.times.toString().length == 3) {
            // console.log(i.times.substring(0,1),i.times.substring(1,3));
            xdate = i.times.substring(0, 1) + ":" + i.times.substring(1, 3);
          } else {
            // console.log(i.times.substring(0,2),i.times.substring(2,4));
            xdate = i.times.substring(0, 2) + ":" + i.times.substring(2, 4);
          }

          xdate = moment(i.dates + " " + xdate).add(7, "hours").format("lll");
          // console.log(xdate);

          detailpin =
            "<p class='detail-style-nasa'>ประเทศ : " +
            xcountry +
            "</p> <br> <p class='detail-style-nasa' style='margin-top: -25px;'>ละติจูด : " +
            i.lat +
            "</p> <br> <p class='detail-style-nasa' style='margin-top: -25px;'>ลองจิจูด : " +
            i.long +
            "</p> <br> <p class='detail-date-nasa' '>วันที่ " +
            xdate +
            "</p> <br> <a class='detail-link-nasa' href='https://www.google.com/maps/search/?api=1&query=" +
            i.lat +
            "," +
            i.long +
            "' target='_blank'>เปิดสถานที่บน google map</a>";

          let iconFire = "";

          if (i.satellite.substring(0, 1) == "V") {
            if (i.satellite.substring(6, 7) == "S") {
              iconFire = Fire_Icon_Small;
            } else if (i.satellite.substring(6, 7) == "N") {
              iconFire = Fire_Icon3_Small;
            }
          } else if (i.satellite.substring(0, 1) == "M") {
            iconFire = Fire_Icon2_Small;
          }

          let marker2 = new longdo.Marker(
            { lon: i.long, lat: i.lat },
            {
              title: titlepin,
              detail: detailpin,
              icon: {
                url: iconFire,
                offset: { x: 7.5, y: 7.5 }
              },

              size: {
                width: 190,
                height: 190
              }
            }
          );

          map.Overlays.add(marker2);
        });
      };
    }

    if (types == 1) {
      axios
        .post(xURL + "getMarker", { dates: dateed })
        .then(result => {
          //console.log(result.data);
          const Tb1 = [],
            Tb2 = [];

          result.data.forEach((i, idx) => {
            Tb1.push({
              name: i.d_name,
              lat: i.cnd_lat,
              lon: i.cnd_long,
              aqhi: i.aqhi_24h,
              area_point: i.area_point
            });
          });

          //console.log(Tb1);
          // setmark(Tb1);

          axios
            .get(xURL + "getFirmsNasa")
            .then(result => {
              //console.log(result.data);

              result.data.forEach((i, idx) => {
                Tb2.push({
                  country: i.country_id,
                  lat: i.nasa_lat,
                  long: i.nasa_lng,
                  dates: i.nasa_date,
                  times: i.nasa_time,
                  satellite: i.satellite
                });
              });

              //console.log(Tb1);
              // setmark(Tb1);

              setMap(Tb1, Tb2, types);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          // setMap(Tb1, types);
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    } else if (types == 2) {
      axios
        .post(xURL + "getMarker", { dates: dateed })
        .then(result => {
          //console.log(result.data);
          const Tb1 = [],
            Tb2 = [];

          result.data.forEach((i, idx) => {
            Tb1.push({
              name: i.d_name,
              lat: i.cnd_lat,
              lon: i.cnd_long,
              aqhi: i.pm25_24h,
              area_point: i.area_point
            });
          });

          axios
            .get(xURL + "getFirmsNasa")
            .then(result => {
              //console.log(result.data);

              result.data.forEach((i, idx) => {
                Tb2.push({
                  country: i.country_id,
                  lat: i.nasa_lat,
                  long: i.nasa_lng,
                  dates: i.nasa_date,
                  times: i.nasa_time,
                  satellite: i.satellite
                });
              });

              //console.log(Tb1);
              // setmark(Tb1);

              setMap(Tb1, Tb2, types);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          // setMap(Tb1, types);
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    }
  }
  return (
    <React.Fragment>
      <Header name={hname} pages={4} />

      <div style={{ marginBottom: "10px" }}>
        <Stack spacing={1} direction="row">
          <Button
            variant={btVariant}
            color="success"
            style={{ fontFamily: "kanit" }}
            onClick={() => {
              setbtVariant("contained");
              setbtVariant2("outlined");
              getMarker(1);
            }}
          >
            AQHI
          </Button>
          <Button
            variant={btVariant2}
            color="success"
            style={{ fontFamily: "kanit" }}
            onClick={() => {
              setbtVariant("outlined");
              setbtVariant2("contained");
              getMarker(2);
            }}
          >
            PM2.5
          </Button>
        </Stack>
      </div>

      <div
        id="longdo-map"
        style={{
          height: "700px",
          border: "1px solid #B3B6B7",
          borderRadius: "10px",
          padding: "1%"
        }}
      />

      <div style={{ marginBottom: "30px", backgroundColor: "#fff" }}>
        <span style={{ fontSize: "12px", color: "#748DA6" }}>
          - ข้อมูลจุดความร้อนได้จาก{" "}
          <a
            style={{ textDecoration: "none" }}
            href="https://firms.modaps.eosdis.nasa.gov/api/"
          >
            https://firms.modaps.eosdis.nasa.gov/api/
          </a>{" "}
          โดยแสดงจุดความร้อนจากดาวเทียม {<img src={Fire_Icon2} />} MODIS,{" "}
          {<img src={Fire_Icon} />} VIIRS/S-NPP และ {<img src={Fire_Icon3} />}{" "}
          VIIRS/NOAA-20 แบบ Near Real-time (NRT) หรือประมาณ 12-24 ชม.ที่ผ่านมา
        </span>

        <br />

        <span style={{ fontSize: "12px", color: "#748DA6" }}>
          - นับจุดความร้อนจากดาวเทียมแต่ละดวงแยกกัน
          เนื่องจากจุดความร้อนที่ได้จากดาวเทียม 3 ดวงอาจซ้ำกันได้
        </span>

        <br />

        <span style={{ fontSize: "12px", color: "#748DA6" }}>
          - ขอขอบคุณ{" "}
          <a style={{ textDecoration: "none" }} href="https://map.longdo.com/">
            Longdo Map
          </a>{" "}
          ที่สนับสนุนการทำแผนที่
        </span>
      </div>

      <Descript_firmsnasa />
    </React.Fragment>
  );
};

export default MapAqhi24hr;
