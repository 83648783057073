import React from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsAccessibility from "highcharts/modules/accessibility";

HighchartsExporting(Highcharts);
HighchartsMore(Highcharts);
HighchartsAccessibility(Highcharts);

const Highcharts_realtime = prop => {
  //   console.log(prop.dataRealtime1);
  //   console.log(prop.dataRealtime2);

  const getOptions = () => ({
    title: {
      text: ""
    },
    subtitle: {
      text: ""
    },
    chart: {
      height: "450px",
      plotBorderColor: "#000",
      plotBorderWidth: 1
    },
    yAxis: {
      title: {
        text: ""
      }
    },
    xAxis: {
      categories: prop.dateRealtime,
      crosshair: true,
      tickInterval: 30
    },
    legend: {
      layout: "vertical",
      align: "center",
      verticalAlign: "bottom"
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false
        }
      }
    },
    series: [
      {
        name: "PM2.5 Outdoor",
        data: prop.dataRealtime1,
        color: "red"
      },
      {
        name: "PM2.5 ห้องผู้อำนวยการ",
        data: prop.dataRealtime2,
        color: "#009874"
        // zones: [
        //   {
        //     value: 13,
        //     color: "#00e400"
        //   },
        //   {
        //     value: 36,
        //     color: "#ffff00"
        //   },
        //   {
        //     value: 151,
        //     color: "#ff7e00"
        //   },
        //   {
        //     value: 251,
        //     color: "#ff0000"
        //   },
        //   {
        //     value: 301,
        //     color: "#99004c"
        //   },
        //   {
        //     value: 501,
        //     color: "#4c0026"
        //   }
        // ]
      }
    ],
    exporting: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom"
            }
          }
        }
      ]
    }
  });

  return (
    <React.Fragment>
      <div>
        <HighchartsReact highcharts={Highcharts} options={getOptions()} />
      </div>
    </React.Fragment>
  );
};

export default Highcharts_realtime;
