import React from "react";

import "./css/Footer.css";

import { Box, Grid, Stack } from "@mui/material";

import pic1 from "../imgs/cmu_logo.png";
import pic2 from "../imgs/logo-ruee.png";
import pic3 from "../imgs/logo-cm.png";
import pic4 from "../imgs/RIHES Logo_Small.png";

const Footer = () => {
  return (
    <React.Fragment>
      <div className="footer">
        <h4 style={{ fontSize: "30px", marginTop: "0px" }}>
          โดยความร่วมมือของ
        </h4>

        <Box sx={{ flexGrow: 1, marginTop: "-40px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <p style={{ textAlign: "right" }}>
                <p
                  style={{
                    fontSize: "12px",
                    opacity: "0.7",
                    marginTop: "20px",
                    marginRight: "10px"
                  }}
                >
                  สถาบันวิจัยวิทยาศาสตร์สุขภาพ
                </p>
                <p
                  style={{
                    fontSize: "12px",
                    opacity: "0.7",
                    marginTop: "-10px",
                    marginRight: "10px"
                  }}
                >
                  มหาวิทยาลัยเชียงใหม่
                </p>
                <p
                  style={{
                    fontSize: "12px",
                    opacity: "0.7",
                    marginTop: "-10px",
                    marginRight: "10px"
                  }}
                >
                  มหาวิทยาลัยเทคโนโลยีราชมงคลล้านนา ดอยสะเก็ด
                </p>
                <p
                  style={{
                    fontSize: "12px",
                    opacity: "0.7",
                    marginTop: "-10px",
                    marginRight: "10px"
                  }}
                >
                  และจังหวัดเชียงใหม่
                </p>
              </p>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack direction="" spacing={2}>
                <a
                  href="https://www.rihes.cmu.ac.th/home/index.php"
                  target="_blank"
                >
                  <img
                    src={pic4}
                    alt="logo4"
                    style={{
                      width: "120px",
                      marginTop: "-8px",
                      marginRight: "-25px"
                    }}
                  />
                </a>

                <a href="https://www.cmu.ac.th" target="_blank">
                  <img
                    src={pic1}
                    alt="logo1"
                    style={{
                      width: "100px"
                    }}
                  />
                </a>

                <a href="https://www.rmutl.ac.th/" target="_blank">
                  <img
                    src={pic2}
                    alt="logo2"
                    style={{
                      width: "100px"
                    }}
                  />
                </a>
                <a href="http://www.chiangmai.go.th" target="_blank">
                  <img
                    src={pic3}
                    alt="logo3"
                    style={{
                      width: "100px"
                    }}
                  />
                </a>
              </Stack>
            </Grid>
          </Grid>
        </Box>

        <p className="copyright" style={{ marginTop: "40px" }}>
          <strong>&copy; 2024</strong> | www2.ntaqhi.info
        </p>
      </div>
    </React.Fragment>
  );
};

export default Footer;
