import React, { useState, useEffect } from "react";
import axios from "axios";

import moment from "moment";
import "moment/locale/th";

import {
  Box,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Button
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";

import Swal from "sweetalert2";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

const Chart_month = prop => {
  let xURL = "";
  xURL = "https://www2.ntaqhi.info/api/";

  const [Yeardata, setYeardata] = useState([""]);
  const [dYearName, setdYearName] = useState([""]);

  const [dataChart, setdataChart] = useState();
  const [dataTable, setdataTable] = useState([]);

  const [show, setshow] = useState(false);

  useEffect(() => {
    getYear();
  }, []);

  const getYear = () => {
    var xyear = 0;
    let tb = [];

    xyear = moment().format("YYYY");

    tb.push("");
    for (let i = 0; i < 10; i++) {
      let oyear = parseInt(xyear - i);

      if (oyear >= 2020) {
        tb.push(parseInt(xyear - i));
      }
    }

    setYeardata(tb);
  };

  const bclearClick = () => {
    // window.location.reload(false);
    setdYearName([""]);
    setdataChart([""]);

    setshow(false);
  };

  const bSearchClick = () => {
    if (dYearName == "") {
      Swal.fire({
        title: "ค้นหาไม่ได้!",
        text: "ยังไม่ได้เลือก ปี",
        icon: "warning",
        showConfirmButton: false,
        timer: 2000,
        customClass: {
          container: "my-swal"
        }
      });
    } else {
      getData();
    }
  };

  const getData = () => {
    axios
      .post(xURL + "getoIAQMonth", {
        xyear: dYearName,
        device1: prop.device1,
        device2: prop.device2
      })
      .then(result => {
        const Tb1 = [],
          Tb2 = [];

        result.data.forEach((i, idx) => {
          Tb1.push({
            name: moment(i.th_m).format("MMMM YYYY"),
            Outdoor: i.Outdoor,
            Indoor: i.Indoor
          });

          Tb2.push({
            column: moment(i.th_m).format("MMMM YYYY"),
            Outdoor: i.Outdoor,
            Indoor: i.Indoor,
            maxOut: i.maxpmOut,
            maxIn: i.maxpmIn
          });
        });

        setdataChart(Tb1);
        setdataTable(Tb2);

        setshow(true);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const handleChange = event => {
    setdYearName(event.target.value);
  };

  return (
    <React.Fragment>
      <p>เลือกปีที่ต้องการ แล้วกดปุ่มค้นหา</p>

      <Box component="form" noValidate sx={{ mt: 1, mb: 5 }}>
        <FormControl margin="normal" required>
          <InputLabel id="demo-select-small">ปี</InputLabel>
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            size="small"
            name="year"
            value={dYearName}
            label="ปี"
            onChange={handleChange}
            sx={{ width: 150 }}
          >
            {Yeardata.map(Yeardata =>
              <MenuItem key={Yeardata} value={Yeardata}>
                {Yeardata}
              </MenuItem>
            )}
          </Select>
        </FormControl>

        <br />
        <Button
          //   type="submit"
          variant="contained"
          sx={{ mt: 1, mr: 1, width: 150 }}
          startIcon={<SearchIcon />}
          style={{ fontFamily: "kanit" }}
          onClick={() => {
            bSearchClick();
          }}
        >
          ค้นหา
        </Button>

        <Button
          variant="contained"
          color="error"
          sx={{ mt: 1, width: 150 }}
          startIcon={<CleaningServicesIcon />}
          onClick={() => {
            bclearClick();
          }}
          style={{ fontFamily: "kanit" }}
        >
          เคลียร์
        </Button>
      </Box>

      {show
        ? <div>
            <h3
              style={{
                textAlign: "center",
                marginBottom: "50px"
              }}
            >
              ค่า PM2.5 เฉลี่ยรายเดือน นอกและใน {prop.head} (มคก./ลบม.)
            </h3>

            <ResponsiveContainer width="100%" height={335}>
              <BarChart data={dataChart} margin={{ right: 10, left: 10 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="Outdoor" fill="red" />
                <Bar dataKey="Indoor" fill="#009874" />
              </BarChart>
            </ResponsiveContainer>

            <table className="realtime" style={{ marginTop: "30px" }}>
              <thead>
                <tr style={{ height: "40px" }}>
                  <th style={{ width: "400px", border: "1px solid black" }} />

                  {dataTable.map(year =>
                    <th
                      style={{
                        width: "100px",
                        border: "1px solid black"
                      }}
                    >
                      {year.column}
                    </th>
                  )}
                </tr>
              </thead>

              <tbody>
                <tr style={{ height: "35px" }}>
                  <th
                    style={{
                      textAlign: "left",
                      paddingLeft: "15px",
                      border: "1px solid black"
                    }}
                  >
                    ค่า PM2.5 เฉลี่ยนอกอาคารในช่วงเวลา 08.00-18.00 น.
                    (มคก./ลบม.)
                  </th>

                  {dataTable.map(i =>
                    <td
                      style={{
                        border: "1px solid black",
                        paddingLeft: "15px"
                      }}
                    >
                      {i.Outdoor}
                    </td>
                  )}
                </tr>
                <tr style={{ height: "35px" }}>
                  <th
                    style={{
                      textAlign: "left",
                      paddingLeft: "15px",
                      border: "1px solid black"
                    }}
                  >
                    ค่า PM2.5 เฉลี่ยภายในอาคารในช่วงเวลา 08.00-18.00 น.
                    (มคก./ลบม.)
                  </th>

                  {dataTable.map(i =>
                    <td
                      style={{
                        border: "1px solid black",
                        paddingLeft: "15px"
                      }}
                    >
                      {i.Indoor}
                    </td>
                  )}
                </tr>

                <tr style={{ height: "35px" }}>
                  <th
                    style={{
                      textAlign: "left",
                      paddingLeft: "15px",
                      border: "1px solid black"
                    }}
                  >
                    จำนวนวันที่ PM2.5 นอกอาคารสูงกว่า 35 มคก./ลบม.
                  </th>

                  {dataTable.map(i =>
                    <td
                      style={{
                        border: "1px solid black",
                        paddingLeft: "15px"
                      }}
                    >
                      {i.maxOut}
                    </td>
                  )}
                </tr>
                <tr style={{ height: "35px" }}>
                  <th
                    style={{
                      textAlign: "left",
                      paddingLeft: "15px",
                      border: "1px solid black"
                    }}
                  >
                    จำนวนวันที่ PM2.5 ในอาคารสูงกว่า 35 มคก./ลบม.
                  </th>

                  {dataTable.map(i =>
                    <td
                      style={{
                        border: "1px solid black",
                        paddingLeft: "15px"
                      }}
                    >
                      {i.maxIn}
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        : <div />}
    </React.Fragment>
  );
};

export default Chart_month;
