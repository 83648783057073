import React, { useState, useEffect } from "react";
import axios from "axios";

import {
  Box,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button
} from "@mui/material/";

import AccountCircle from "@mui/icons-material/AccountCircle";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import "./admin.css";

import Swal from "sweetalert2";

const Admin_login = () => {
  let xURL = "";
  xURL = "https://www2.ntaqhi.info/api/";

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  useEffect(() => {
    //localStorage.removeItem("token");
    //console.log(localStorage.token);
    if (localStorage.token) {
      window.location = "/admin/online";
    }
  }, []);

  const handleLogin = event => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    // console.log(data.get("username"), data.get("password"));

    axios
      .post(xURL + "login", {
        username: data.get("username"),
        password: data.get("password")
      })
      .then(result => {
        if (result.data.status === "ok") {
          // alert("login sucess");

          Swal.fire({
            title: "Login สำเร็จ!",
            text: "Login สำเร็จ",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
            customClass: {
              container: "my-swal"
            }
          }).then(() => {
            localStorage.setItem("token", result.data.token);

            window.location = "/admin/online";
          });
        } else {
          Swal.fire({
            title: "Login ไม่สำเร็จ!",
            text: "Username หรือ Password ไม่ถูกต้อง",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
            customClass: {
              container: "my-swal"
            }
          });
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  return (
    <React.Fragment>
      <div className="admin-body" style={{ width: "100%", height: "100vh" }}>
        <Card
          sx={{ minWidth: 300 }}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "20%",
            height: "290px"
          }}
        >
          <CardContent>
            <p
              style={{
                fontSize: "30px",
                marginTop: "8px",
                textAlign: "center"
              }}
            >
              <span style={{ color: "red" }}>Admin</span>NTAQHI
            </p>
            <hr style={{ marginTop: "-15px" }} />

            <Box component="form" onSubmit={handleLogin} noValidate>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                sx={{ mt: 2 }}
              >
                <InputLabel>Username</InputLabel>
                <OutlinedInput
                  name="username"
                  startAdornment={
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  }
                  label="Username"
                />
              </FormControl>

              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                sx={{ mt: 2 }}
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  startAdornment={
                    <InputAdornment position="start">
                      <VpnKeyIcon />
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>

              <Button
                fullWidth
                variant="contained"
                style={{
                  fontFamily: "kanit"
                }}
                type="submit"
                sx={{ mt: 2 }}
              >
                Login
              </Button>
            </Box>
          </CardContent>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default Admin_login;
