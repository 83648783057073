import React, { useState, useEffect } from "react";
import axios from "axios";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import "./css/setImg.css";
import "./css/dataset.css";

import {
  Box,
  Grid,
  Stack,
  Button,
  Backdrop,
  CircularProgress,
  Autocomplete,
  TextField
} from "@mui/material";

import moment from "moment";
import "moment/locale/th";

import SearchIcon from "@mui/icons-material/Search";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";

import Swal from "sweetalert2";

import board from "../../imgs/Artboard 2.png";

const DatasetAir = () => {
  let xURL = "";
  xURL = "https://www2.ntaqhi.info/api/";

  const [deviceName, setdeviceName] = useState([""]);
  const [value, setValue] = useState([]);

  const [open, setOpen] = useState(false);
  const [show, setshow] = useState(false);

  const [mark0, setmark0] = useState([]);
  const [markG, setmarkG] = useState([]);
  const [markY, setmarkY] = useState([]);
  const [markO, setmarkO] = useState([]);
  const [markR, setmarkR] = useState([]);
  const [markP, setmarkP] = useState([]);
  const [markB, setmarkB] = useState([]);

  const xmonth = [
    { month: "Jan", value: "01" },
    { month: "Feb", value: "02" },
    { month: "Mar", value: "03" },
    { month: "Apr", value: "04" },
    { month: "May", value: "05" },
    { month: "Jun", value: "06" },
    { month: "Jul", value: "07" },
    { month: "Aug", value: "08" },
    { month: "Sep", value: "09" },
    { month: "Oct", value: "10" },
    { month: "Nov", value: "11" },
    { month: "Dec", value: "12" }
  ];

  // const xyear1 = ["2554", "2555", "2556", "2557", "2558"];

  const [xyear, setxyear] = useState([]);

  const [YearAver, setYearAver] = useState([""]);

  useEffect(() => {
    getDevice();
  }, []);

  const bclearClick = () => {
    window.location.reload(false);
  };

  const getDevice = () => {
    axios
      .get(xURL + "getdevice2")
      .then(result => {
        //console.log(result.data);
        const Tb1 = [];

        result.data.forEach((i, idx) => {
          Tb1.push({
            label: i.name,
            cmaqhi_device_id: i.d_id
          });
        });

        setdeviceName(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const handleSubmit = event => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (!value.label || value.label == null) {
      Swal.fire({
        title: "ค้นหาไม่ได้!",
        text: "ยังไม่ได้เลือก สถานีตรวจวัด",
        icon: "warning",
        showConfirmButton: false,
        timer: 2000,
        customClass: {
          container: "my-swal"
        }
      });
    } else {
      getYear();

      // getData();
      // getAverage();
    }
  };

  const getYear = () => {
    axios
      .post(xURL + "getyearAir", {
        entity: value.label.slice(-4).substr(0, 3),
        order: 1
      })
      .then(result => {
        let Tb = [];

        result.data.forEach((i, idx) => {
          Tb.push((parseInt(i.d_date) + 543).toString());
        });

        axios
          .post(xURL + "getyearAir", {
            entity: value.label.slice(-4).substr(0, 3),
            order: 2
          })
          .then(result2 => {
            result2.data.forEach((i, idx) => {
              Tb.push((parseInt(i.d_date) + 543).toString());
            });

            setxyear(Tb);

            getData(Tb);
            // getAverage(Tb);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const getData = years => {
    setOpen(!open);

    axios
      .post(xURL + "getinfoAir", {
        entity: value.label.slice(-4).substr(0, 3),
        years: years,
        order: 1
      })
      .then(result => {
        // console.log(result.data);
        setshow(true);

        const Tb0 = [],
          TbG = [],
          TbY = [],
          TbO = [],
          TbR = [],
          TbP = [],
          TbB = [];

        result.data.forEach((i, idx) => {
          if (i.pm25 == 0) {
            Tb0.push(i.d_date);
          } else if (i.pm25 <= 12) {
            TbG.push(i.d_date);
          } else if (i.pm25 > 12 && i.pm25 <= 35) {
            TbY.push(i.d_date);
          } else if (i.pm25 > 35 && i.pm25 <= 55) {
            TbO.push(i.d_date);
          } else if (i.pm25 > 55 && i.pm25 <= 150) {
            TbR.push(i.d_date);
          } else if (i.pm25 > 150 && i.pm25 <= 250) {
            TbP.push(i.d_date);
          } else if (i.pm25 > 250) {
            TbB.push(i.d_date);
          }
        });

        axios
          .post(xURL + "getinfoAir", {
            entity: value.label.slice(-4).substr(0, 3),
            years: years,
            order: 2
          })
          .then(result2 => {
            result2.data.forEach((i, idx) => {
              if (i.pm25 == 0) {
                Tb0.push(i.d_date);
              } else if (i.pm25 <= 12) {
                TbG.push(i.d_date);
              } else if (i.pm25 > 12 && i.pm25 <= 35) {
                TbY.push(i.d_date);
              } else if (i.pm25 > 35 && i.pm25 <= 55) {
                TbO.push(i.d_date);
              } else if (i.pm25 > 55 && i.pm25 <= 150) {
                TbR.push(i.d_date);
              } else if (i.pm25 > 150 && i.pm25 <= 250) {
                TbP.push(i.d_date);
              } else if (i.pm25 > 250) {
                TbB.push(i.d_date);
              }
            });

            setmark0(Tb0);
            setmarkG(TbG);
            setmarkY(TbY);
            setmarkO(TbO);
            setmarkR(TbR);
            setmarkP(TbP);
            setmarkB(TbB);

            setOpen(false);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  /*const getAverage = years => {
    axios
      .post(xURL + "getinfoAver", {
        entity: value.label.slice(-4).substr(0, 3),
        years: years,
        order: 1
      })
      .then(result => {
        // console.log(result.data[0].pm25);

        let Tbaver = [];

        result.data.forEach((i, idx) => {
          Tbaver.push(i.pm25);
        });

        axios
          .post(xURL + "getinfoAver", {
            entity: value.label.slice(-4).substr(0, 3),
            years: years,
            order: 2
          })
          .then(result2 => {
            result2.data.forEach((i, idx) => {
              Tbaver.push(i.pm25);
            });

            setYearAver(Tbaver);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };*/

  return (
    <React.Fragment>
      <p className="p-char">
        หน้านี้แสดง infographic คุณภาพอากาศใน 9 จังหวัดภาคเหนือตอนบน ตั้งแต่ปี
        พ.ศ. 2554 จนถึงปัจจุบัน โดยใช้ค่า PM2.5 เฉลี่ย 24 ชั่วโมง (มคก./ลบม.)
        ที่ได้จากสถานีตรวจวัดคุณภาพอากาศของกรมควบคุมมลพิษ
        แสดงสีของแต่ละวันตามระดับของ US AQI
      </p>
      <p className="p-char">
        เลือกสถานีตรวจวัดที่ต้องการแสดง infographic และกดปุ่มค้นหา
      </p>

      <br />

      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <Autocomplete
          disablePortal
          name="entity"
          id="combo-box-demo"
          size="small"
          options={deviceName}
          sx={{ width: 310 }}
          renderInput={params => <TextField {...params} label="สถานีตรวจวัด" />}
          onChange={(event, newValue) => {
            if (newValue) {
              setValue(newValue);
            } else {
              setValue("");
            }

            setshow(false);
          }}
        />

        <br />
        <Button
          style={{ fontFamily: "kanit" }}
          type="submit"
          variant="contained"
          sx={{ mt: 1, mr: 1, width: 150 }}
          startIcon={<SearchIcon />}
        >
          ค้นหา
        </Button>

        <Button
          style={{ fontFamily: "kanit" }}
          variant="contained"
          color="error"
          sx={{ mt: 1, width: 150 }}
          startIcon={<CleaningServicesIcon />}
          onClick={() => {
            bclearClick();
          }}
        >
          เคลียร์
        </Button>
      </Box>

      {show
        ? <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              {xyear.map((year, idx) =>
                <Grid item xs={6} sm={4} md={3} lg={2}>
                  <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: "bold",
                      color: "blueviolet",
                      marginLeft: "35px"
                    }}
                  >
                    {year - 543 + " (" + year + ")"}
                  </p>

                  {xmonth.map(i =>
                    <Stack
                      direction="row"
                      spacing={1.5}
                      style={{ marginTop: "2px" }}
                    >
                      <Grid item xs={1}>
                        <p
                          style={{
                            fontSize: "14px",
                            marginTop: "0"
                          }}
                        >
                          {i.month}
                        </p>
                      </Grid>
                      <Grid
                        item
                        xs={11}
                        // style={{ height: "250px" }}
                      >
                        <Calendar
                          showNavigation={false}
                          showNeighboringMonth={false}
                          // showFixedNumberOfWeeks

                          defaultValue={
                            parseInt(year) - 543 + "-" + i.value + "-01"
                          }
                          value=""
                          locale="en-US"
                          formatShortWeekday={(locale, date) =>
                            moment(date).format("dd")}
                          tileClassName={({ date, view }) => {
                            if (
                              mark0.find(
                                x => x === moment(date).format("DD-MM-YYYY")
                              )
                            ) {
                              return "highlight0";
                            } else if (
                              markG.find(
                                x => x === moment(date).format("DD-MM-YYYY")
                              )
                            ) {
                              return "highlightG";
                            } else if (
                              markY.find(
                                x => x === moment(date).format("DD-MM-YYYY")
                              )
                            ) {
                              return "highlightY";
                            } else if (
                              markO.find(
                                x => x === moment(date).format("DD-MM-YYYY")
                              )
                            ) {
                              return "highlightO";
                            } else if (
                              markR.find(
                                x => x === moment(date).format("DD-MM-YYYY")
                              )
                            ) {
                              return "highlightR";
                            } else if (
                              markP.find(
                                x => x === moment(date).format("DD-MM-YYYY")
                              )
                            ) {
                              return "highlightP";
                            } else if (
                              markB.find(
                                x => x === moment(date).format("DD-MM-YYYY")
                              )
                            ) {
                              return "highlightB";
                            } else {
                              return "highlight0";
                            }
                          }}
                        />
                      </Grid>
                    </Stack>
                  )}

                  <br />
                  {/* <hr />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "15px"
                    }}
                  >
                    <p>
                      PM2.5 เฉลี่ย รายปี -- {YearAver[idx]}
                    </p>
                  </div> */}
                </Grid>
              )}
            </Grid>

            <img
              src={board}
              style={{
                maxWidth: "100%",
                height: "auto",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "50px"
              }}
              className="img-artboard"
            />
          </Box>
        : <div />}
      <Backdrop
        sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
};

export default DatasetAir;
