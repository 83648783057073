import React, { useState } from "react";

import PropTypes from "prop-types";
import { Tabs, Tab, Box, AppBar } from "@mui/material";

import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";

import DatasetAir from "./DatasetAir";
import DatasetNTAQHI from "./datasetNTAQHI";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index &&
        <Box sx={{ p: 3 }}>
          {children}
        </Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const Maindata = () => {
  const [value, setValue] = useState(0);

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          width: "100%"
        }}
      >
        <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
          <AppBar position="static" style={{ background: "#F0F5FF" }}>
            <Tabs
              value={value}
              onChange={TabChange}
              aria-label="basic tabs example"
              variant="fullWidth"
              indicatorColor="primary"
              textColor="secondary"
            >
              <Tab
                icon={<OnlinePredictionIcon />}
                label={
                  <p
                    style={{
                      marginTop: "-2px",
                      marginBottom: "-2px",
                      fontWeight: "bold",
                      fontFamily: "kanit"
                    }}
                  >
                    จุดตรวจของ NTAQHI
                  </p>
                }
                {...a11yProps(0)}
              />
              <Tab
                icon={<OnlinePredictionIcon />}
                label={
                  <p
                    style={{
                      marginTop: "-2px",
                      marginBottom: "-2px",
                      fontWeight: "bold",
                      fontFamily: "kanit"
                    }}
                  >
                    จุดตรวจกรมควบคุมมลพิษ
                  </p>
                }
                {...a11yProps(1)}
              />
            </Tabs>
          </AppBar>
        </Box>

        <TabPanel value={value} index={0}>
          <DatasetNTAQHI />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DatasetAir />
        </TabPanel>
      </Box>
    </React.Fragment>
  );
};

export default Maindata;
